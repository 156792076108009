import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDashboardStore } from '../../../dashboardStore'
import {
  cardBorderGrey,
  errorRed,
} from '../../../../../../../assets/styles/variables'
import TextArea from 'antd/lib/input/TextArea'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'
import { DashboardType } from '../../../types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AnnotationType } from './types'
import { useAuthContext } from '../../../../../../auth'
import { Tooltip } from 'antd'
import { AvatarWrapper } from '../../../../alerts/Components/AlertCard'
import { InnerTitle } from '../../../../../../../components/InnerTitle'
import _ from 'lodash'
import { updateDashboard } from '../../../model'
import { toast } from 'react-toastify'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'

type Props = {
  dashboard: DashboardType
  annotations: AnnotationType[]
  setAnnotations: (annotations: AnnotationType[]) => void
}
type Inputs = {
  // title: string
  description: string
}

export const NewAnnotation: React.FC<Props> = ({
  dashboard,
  annotations,
  setAnnotations,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const selectedChartInfo = useDashboardStore(
    (state) => state.selectedChartInfo
  )

  const authContext = useAuthContext()

  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setIsEditing(!!selectedChartInfo)
  }, [selectedChartInfo])

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newAnnotation: AnnotationType = {
      ...data,
      component: selectedChartInfo ? { ...selectedChartInfo } : null,
      create_time: Date.now(),
      author: {
        id: authContext?.user?.attributes?.sub,
        username: authContext?.user?.attributes?.sub,
        first_name: authContext?.user?.attributes?.given_name,
        last_name: authContext?.user?.attributes?.family_name,
        email: authContext?.user?.attributes?.email,
      },
      id: (annotations.length ?? 0).toString(),
    }
    setAnnotations([...annotations, newAnnotation])

    const newSettings = _.cloneDeep(dashboard.dashboard_settings)
    newSettings.annotations = [...annotations, newAnnotation]
    updateDashboard(projectId, {
      dashboard_id: dashboard.id,
      name: dashboard.name,
      dashboard_settings: newSettings,
    })
      .then((res) => {
        // toast.success('Chart added to dashboard')
      })
      .catch((err) => {
        toast.error('Error adding comment')
        setAnnotations(annotations.filter((a) => a.id !== newAnnotation.id))
      })
    setIsEditing(false)
    reset()
  }

  const manualSubmit = () => {
    handleSubmit(onSubmit)()
  }

  return (
    <AnnotationContainer
      isEditing={isEditing}
      onClick={() => {
        if (isEditing) return
        setIsEditing(!isEditing)
      }}
    >
      {!isEditing ? (
        <AnnotationButton
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <PlusOutlined />
          <div>Add Annotation</div>
        </AnnotationButton>
      ) : (
        <AnnotationEditor>
          <Header>
            <Left>
              {authContext?.user?.attributes?.given_name &&
                authContext?.user?.attributes?.family_name && (
                  <Tooltip title={authContext?.user?.attributes?.email}>
                    <AvatarWrapper>
                      {authContext?.user?.attributes?.given_name[0]}
                      {authContext?.user?.attributes?.family_name[0]}
                    </AvatarWrapper>
                  </Tooltip>
                )}
              <div>
                <InnerTitle style={{ margin: 0, fontSize: 16 }}>
                  {authContext?.user?.attributes?.given_name}{' '}
                  {authContext?.user?.attributes?.family_name}
                </InnerTitle>
                {/*<p style={{ margin: 0, fontSize: 12, color: lightText }}>*/}
                {/*  {new Date(annotation.create_time).toLocaleTimeString()}{' '}*/}
                {/*  {new Date(annotation.create_time).toLocaleDateString()}*/}
                {/*</p>*/}
              </div>
              {/*<InnerTitle style={{ margin: 0 }}>{annotation.title}</InnerTitle>*/}
            </Left>
            <Right>
              <CloseOutlined onClick={() => setIsEditing(false)} />
            </Right>
          </Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AnnotationForm>
              {selectedChartInfo && (
                <>
                  <div>Chart: {selectedChartInfo.chartTitle}</div>
                  <div>
                    {selectedChartInfo.tier1}: {selectedChartInfo.tier1_value}
                  </div>
                </>
              )}
              {/*<YogiInput placeholder={"Title"}></YogiInput>*/}
              {/*<Controller*/}
              {/*  name={'title'}*/}
              {/*  control={control}*/}
              {/*  rules={{ required: true }}*/}
              {/*  render={({ field }) => (*/}
              {/*    <YogiInput*/}
              {/*      {...field}*/}
              {/*      placeholder="Title"*/}
              {/*      style={{*/}
              {/*        // background: cardBorderGrey,*/}
              {/*        borderColor:*/}
              {/*          errors.title?.type === 'required'*/}
              {/*            ? errorRed*/}
              {/*            : cardBorderGrey,*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*/>*/}

              <Controller
                name={'description'}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    placeholder="Description"
                    style={{
                      width: '100%',
                      height: '100px',
                      border: '2px solid ' + cardBorderGrey,
                      borderRadius: '6px',
                      padding: '4px 11px',
                      borderColor:
                        errors.description?.type === 'required'
                          ? errorRed
                          : cardBorderGrey,
                    }}
                  />
                )}
              />
              {/*<TextArea*/}
              {/*  style={{*/}
              {/*    width: "100%",*/}
              {/*    height: "100px",*/}
              {/*    border: "2px solid " + cardBorderGrey,*/}
              {/*    borderRadius: "6px",*/}
              {/*    padding: "4px 11px"*/}
              {/*  }}*/}
              {/*  placeholder={"Description"}*/}
              {/*></TextArea>*/}
              <ButtonContainer>
                <YogiButton onClick={() => setIsEditing(false)}>
                  Cancel
                </YogiButton>
                <YogiButton type={'primary'} onClick={() => manualSubmit()}>
                  Save
                </YogiButton>
              </ButtonContainer>
            </AnnotationForm>
          </form>
        </AnnotationEditor>
      )}
    </AnnotationContainer>
  )
}

export const AnnotationContainer = styled.div<{ isEditing?: boolean }>`
  width: 100%;
  border-radius: 8px;
  background: #f4f5ff;
  cursor: ${(props) => (props.isEditing ? 'default' : 'pointer')};
  transition: 0.15s ease-out;
  border: 1px solid var(--button-blue);
  &:hover {
    background: ${(props) => (props.isEditing ? '#f4f5ff' : '#e9ebff')};
  }
  padding: 10px;
  margin-bottom: 16px;
`

const AnnotationButton = styled.div`
  color: var(--button-blue);
  font-weight: 500;
  font-size: 18px;
`

const AnnotationEditor = styled.div`
  height: 100%;
  width: 100%;
`

const AnnotationForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
  *:placeholder-shown {
    font-style: italic;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 5px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`

const Left = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const Right = styled.div`
  display: flex;
  gap: 10px;
`
