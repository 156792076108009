import React, { useState } from 'react'
import { Form, Input, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { tooltip } from '../../../../../../../utils/tooltip-data'

type Props = {
  modal: boolean | null
  onCreate: (s: string) => void
  onClose: () => void
  title?: string
}

export const NewCluster: React.FC<Props> = ({
  modal,
  onCreate,
  onClose,
  title,
}) => {
  const [clusterName, setClusterName] = useState<string>('')

  return (
    <Modal
      title={title || 'Custom Theme'}
      visible={!!modal}
      onOk={() => {
        if (clusterName.length) {
          onCreate(clusterName)
          onClose()
        }
      }}
      onCancel={onClose}
      okText="Create"
    >
      <Form.Item
        label={
          <Tooltip title={tooltip['theme-form-name']}>
            <span>
              Name <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
      >
        <Input
          name="clusterName"
          onChange={(e) => setClusterName(e.target.value)}
          value={clusterName}
          placeholder="Theme name"
        />
      </Form.Item>
    </Modal>
  )
}
