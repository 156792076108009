import React from 'react'
import { OutlierType } from '../../model'
import { Container, Header, Title, Bookmark } from './InsightDetail.styles'
import { lightBlueFontColor } from '../../../../../../assets/styles/variables'
import smartDashboard from 'assets/images/graphics/smartDashboard.png'
import YogiInfo from '../../../../../../components/UI/YogiInfo/YogiInfo'
import InsightCharts from './components/InsightCharts/InsightCharts'
import { Filters } from '../../../../types'
import InsightAnalysis from './components/InsightAnalysis/InsightAnalysis'
import { getOutlierTitle } from '../../utils'
import FavoriteInsightButton from '../FavoriteInsightButton/FavoriteInsightButton'
import { ErrorImage, InfoContainer } from '../../AutoInsights.styles'
import { BookmarkBorder } from '@mui/icons-material'
import useOutlierAnalysis from '../../hooks/useOutlierAnalysis'

interface Props {
  outlier: OutlierType
  filterValues: Filters
  activeTab: number
}

const InsightDetail: React.FC<Props> = ({
  outlier,
  filterValues,
  activeTab,
}) => {
  // const { parsedAnalysis } = useOutlierAnalysis(outlier)

  if (!outlier)
    return (
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ErrorImage src={smartDashboard} />
        <InfoContainer style={{ margin: '0 auto', fontSize: 18 }}>
          <YogiInfo
            color={lightBlueFontColor}
            body={
              <>
                {activeTab < 3 ? (
                  <div>
                    No Insights found in the last 12 months. This Hub may need
                    to have its reviews updated!
                  </div>
                ) : (
                  <div>
                    No Bookmarked Insights found. Click on the{' '}
                    <BookmarkBorder
                      fontSize={'small'}
                      style={{ marginBottom: -5 }}
                    />{' '}
                    icon to save an Insight!
                  </div>
                )}
              </>
            }
          ></YogiInfo>
        </InfoContainer>
      </Container>
    )

  return (
    <Container>
      <Header>
        {/*<Title>{parsedAnalysis?.stinger ?? getOutlierTitle(outlier)}</Title>*/}
        <Title>{outlier.analysis.stinger ?? getOutlierTitle(outlier)}</Title>
        <Bookmark>
          <FavoriteInsightButton
            outlier={outlier}
            filterValues={filterValues}
          />
        </Bookmark>
      </Header>
      <YogiInfo
        color={lightBlueFontColor}
        body={
          "[PLACEHOLDER TEXT] Here is where we'd talk about the average sentiment for the product vs the competition."
        }
        style={{ marginBottom: '20px' }}
      />
      <InsightCharts outlier={outlier} filterValues={filterValues} />
      <InsightAnalysis
        key={(outlier.product ?? 'product') + (outlier.theme ?? 'theme')}
        outlier={outlier}
        filterValues={filterValues}
      />
    </Container>
  )
}

export default InsightDetail
