import React from 'react'
import styled from 'styled-components'
import { Menu } from 'antd'
import { useClusterContext } from '../features/cluster'
import { ContextPosition } from '../features/graph/types'

type Props = ContextPosition & {
  onCreateCluster: () => void
  onAssignCluster: () => void
}

export const ContextMenu: React.FC<Props> = ({
  x,
  y,
  onCreateCluster,
  onAssignCluster,
}) => {
  const { isThemeDomain } = useClusterContext()
  return (
    <Wrapper style={{ left: x, top: y }}>
      <Menu style={{ width: 150 }}>
        <Menu.Item key="1" onClick={onCreateCluster}>
          Custom Theme
        </Menu.Item>
        {isThemeDomain && (
          <Menu.Item key="2" onClick={onAssignCluster}>
            Assign to Theme
          </Menu.Item>
        )}
      </Menu>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  box-shadow: var(--default-box-shadow);
`
