import { useQuery } from 'react-query'
import { getBookmarkUrlPost } from '../../../../url-params/model'
import { CHARTS_URL } from '../../../utils'

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import { Chart } from '../../dashboard/components/Dashboard/Charts/chart'
import { Link } from 'react-router-dom'
import mixpanel from '../../../../trackers/mixpanel'
import { mainText } from '../../../../../assets/styles/variables'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { OpenInNewOutlined } from '@mui/icons-material'
import { LoadingOutlined } from '@ant-design/icons'

type Props = {
  chartItem: any
}

const FindingChart: React.FC<Props> = ({ chartItem }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const { data: linkData } = useQuery(
    [
      'getFilterHash',
      projectId,
      chartItem.filterValues,
      chartItem.dashboardControls,
    ],
    () =>
      getBookmarkUrlPost(projectId, {
        page: `${CHARTS_URL}`,
        filterString: JSON.stringify({
          filters: [chartItem.filterValues],
          pageControls: [chartItem.dashboardControls],
          // pageControls: [chartItem.dashboardControls].map((el) => ({
          //   ...el,
          //   chart_id: chartItem.dashboardControls.chartId,
          //   tier1: tierName,
          //   multiChart: true,
          // })),
        }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!chartItem?.dashboardControls,
    }
  )
  return (
    <>
      <ChartTitle>
        {chartItem.title}
        {linkData ? (
          <Link
            target={'_blank'}
            to={`${CHARTS_URL}?filters=${linkData}`}
            onClick={() => {
              mixpanel.track('catalog', {
                action: 'open chart link',
                value: chartItem.dashboardControls.chartId,
              })
            }}
            style={{
              color: mainText,
              marginTop: -5,
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              marginRight: 10,
            }}
          >
            <YogiButton
              type={'ghost'}
              // icon={<OpenInNewOutlined />}
              style={{ display: 'flex', alignItems: 'center', gap: 5 }}
            >
              <OpenInNewOutlined fontSize={'inherit'} />
            </YogiButton>
          </Link>
        ) : (
          <LoadingOutlined />
        )}
      </ChartTitle>
      <Chart
        filters={chartItem.filters}
        chartId={chartItem.dashboardControls.chartId}
        tier1={chartItem.dashboardControls.tier1}
        comparativeIndex={0}
        isComparative={false}
        dashboardControls={chartItem.dashboardControls}
        item={chartItem}
        hideHeader
      />
    </>
  )
}

export default FindingChart

const Container = styled.div``

const ChartTitle = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
