import axios from 'axios'
import { Modal } from 'antd'
import { User } from '../features/auth/types'
import { Auth } from 'aws-amplify'

export const env: string = process.env.REACT_APP_ENV || ''

export const isProd = env === 'prod'

let API_URL_BASE: string
if (env === 'prod') {
  API_URL_BASE = 'https://api.meetyogi.com'
} else if (env === 'staging') {
  API_URL_BASE = 'https://api-staging.meetyogi.com'
} else if (env === 'dev') {
  API_URL_BASE = 'https://api-dev.meetyogi.com'
} else if (env === 'sandbox') {
  API_URL_BASE = 'https://api-sandbox.meetyogi.com'
} else if (env === 'local') {
  API_URL_BASE = 'http://localhost:8000'
} else {
  API_URL_BASE = 'https://api-dev.meetyogi.com'
}

export const API_BASE_URL = API_URL_BASE // it will be good to move base url to .env

export const axiosInstance = axios.create({
  baseURL: API_URL_BASE,
  timeout: 180000,
})

export const setToken = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = token
}
export const setSharedToken = (token: string) => {
  axiosInstance.defaults.headers.common['project-token'] = token
}
export const hasTemporaryToken = () => {
  return !!axiosInstance.defaults.headers.common['project-token']
}

// TODO this is what is fricking up in dev
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      try {
        const user: User = await Auth.currentAuthenticatedUser()
        setToken(user.signInUserSession.idToken.jwtToken)
        error.config.headers['Authorization'] =
          user.signInUserSession.idToken.jwtToken
        return axios.request(error.config)
      } catch (e) {
        Modal.confirm({
          title: 'Error',
          content: 'To continue working with Yogi, please click OK.',
          onOk() {
            window.location.reload()
          },
        })
        return error
      }
    }
    throw error
  }
)
