import React from 'react'
import { Container, InsightsListItemsWrapper } from './InsightList.styles'
import InsightListOptions from './components/InsightListOptions/InsightListOptions'
import { OutlierType } from '../../model'
import InsightListItem from './components/InsightListItem/InsightListItem'
import YogiInfo from '../../../../../../components/UI/YogiInfo/YogiInfo'
import { lightBlueFontColor } from '../../../../../../assets/styles/variables'
import _ from 'lodash'
import { Filters } from '../../../../types'
import { BookmarkBorder } from '@mui/icons-material'
import { YogiButton } from '../../../../../../components/UI/YogiButton'

interface Props {
  outliers: OutlierType[]
  selectedOutlier: OutlierType | undefined
  setSelectedOutlier: (outlier: OutlierType | undefined) => void
  activeTab: number
  setActiveTab: (index: number) => void
  filterValues: Filters
  incrementPage: () => any
  isPageLoading: boolean
  hasMoreOutliers: boolean
}

const InsightList: React.FC<Props> = ({
  outliers,
  selectedOutlier,
  setSelectedOutlier,
  activeTab,
  setActiveTab,
  filterValues,
  incrementPage,
  isPageLoading,
  hasMoreOutliers,
}) => {
  return (
    <Container>
      <InsightListOptions
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      ></InsightListOptions>
      <InsightsListItemsWrapper>
        {outliers.length === 0 && (
          <YogiInfo
            color={lightBlueFontColor}
            body={
              <>
                {activeTab < 3 ? (
                  <div>
                    No Insights found in the last 12 months. This Hub may need
                    to have its reviews updated!
                  </div>
                ) : (
                  <div>
                    No Bookmarked Insights found. Click on the{' '}
                    <BookmarkBorder
                      fontSize={'small'}
                      style={{ marginBottom: -5 }}
                    />{' '}
                    icon to save an Insight!
                  </div>
                )}
              </>
            }
          />
        )}
        {outliers.map((item, index) => (
          <InsightListItem
            key={`${activeTab}_${index}`}
            outlier={item}
            isSelected={
              // if we have outlier IDs, then use those to compare
              selectedOutlier ? _.isEqual(item, selectedOutlier) : index === 0
            }
            setSelectedOutlier={setSelectedOutlier}
            filterValues={filterValues}
          ></InsightListItem>
        ))}
        <YogiButton
          loading={isPageLoading}
          disabled={!hasMoreOutliers}
          onClick={incrementPage}
        >
          {isPageLoading ? 'Loading More' : 'Load More'}
        </YogiButton>
      </InsightsListItemsWrapper>
    </Container>
  )
}

export default InsightList
