import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { SaveOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Popover, Tooltip } from 'antd'
import {
  deleteFilterPreset,
  saveFilterPreset,
  loadFilterPresets,
} from 'features/project/features/filters/model'
import { SavePresetForm } from './filter-preset-save-form'
import { FilterPresetLoadForm } from './filter-preset-load-form'
import { FilterPreset, Filters, ProjectState } from 'features/project/types'
import {
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
  FilterOption,
  FilterOptionDate,
  RelativeDateFilterIdx,
} from '../../types'
import { HardcodedFilterOptionsEnum } from '../../helpers'
import { periods } from '../date-panel/date-panel'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { cardBorderGrey } from '../../../../../../assets/styles/variables'
import { useQuery, useQueryClient } from 'react-query'
import { fetchChartsForSearching } from '../../../charts/model'

type Props = {
  comparativeIndex: number
  filterValues: Filters
  filterList: FilterOption[]
}

export const FilterPresetManager: React.FC<Props> = ({
  comparativeIndex,
  filterValues,
  filterList,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const setFilterValues = useProjectStore(
    (state: ProjectState) => state.setFilterValues
  )
  const setSearchQuery = useProjectStore(
    (state: ProjectState) => state.setSearchQuery
  )
  const setSearchCondition = useProjectStore(
    (state: ProjectState) => state.setSearchCondition
  )
  const filterPresets = useProjectStore(
    (state: ProjectState) => state.filterPresets
  )
  const setFilterPresets = useProjectStore(
    (state: ProjectState) => state.setFilterPresets
  )
  const relativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.relativeDateFilterIdx
  )
  const setRelativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.setRelativeDateFilterIdx
  )
  const setSelectedCountry = useProjectStore(
    (state: ProjectState) => state.setSelectedCountry
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions
  )

  const queryClient = useQueryClient()

  const [isSaving, setIsSavingPreset] = useState<boolean>(false)
  const [isLoading, setIsLoadingPreset] = useState<boolean>(false)

  const option = filterList?.find(
    (filterOption) =>
      filterOption.field === HardcodedFilterOptionsEnum.CREATE_TIME
  ) as FilterOptionDate
  const inputOption = filterValues.values.find(
    (filterValue) =>
      filterValue.field === HardcodedFilterOptionsEnum.CREATE_TIME
  ) as AppliedFilterOptionRange
  const maxDate = option?.cur_max || inputOption?.max || option?.max

  // const updateFilters = (filter_preset: FilterPreset) => {
  //   // debugger
  //   updateSelectedOptionField(comparativeIndex, '')
  //   clearOpenedOptions(comparativeIndex)
  //   const currentRelativeDateFilterIdx = filter_preset.relative_date_filter_idx
  //   let criteria
  //   if (
  //     currentRelativeDateFilterIdx != null &&
  //     currentRelativeDateFilterIdx !== -1
  //   ) {
  //     criteria = filter_preset.criteria.filter(
  //       ({ field }) => field !== HardcodedFilterOptionsEnum.CREATE_TIME
  //     )
  //
  //     criteria.push({
  //       field: HardcodedFilterOptionsEnum.CREATE_TIME,
  //       max: maxDate,
  //       min: periods[currentRelativeDateFilterIdx].getValues(option.max)[0],
  //     } as AppliedFilterOptionRange)
  //     setRelativeDateFilterIdx([currentRelativeDateFilterIdx])
  //   } else {
  //     criteria = filter_preset.criteria
  //   }
  //
  //   const newFilterValues = criteria.map((item) => {
  //     return { ...item, field: item.field }
  //   })
  //
  //   setFilterValues(comparativeIndex, newFilterValues)
  //   setSearchQuery(comparativeIndex, filter_preset.search_terms)
  //   setSearchCondition(comparativeIndex, filter_preset.search_criteria)
  //
  //   let country = criteria.find((el) => el.field === 'country')
  //   if (country) {
  //     setSelectedCountry((country as AppliedFilterOptionSelect)?.values)
  //   }
  //
  //   setIsLoadingPreset(false)
  // }
  //
  // const deletePreset = (preset_name: string) =>
  //   deleteFilterPreset(projectId, preset_name)
  //     .then(() => {
  //       toast.success('Filter deleted')
  //       reloadFilterPresets()
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.detail.msg || 'Error deleting filter.')
  //     })
  //     .finally(() => {
  //       // refetch() TODO?
  //       setIsLoadingPreset(false)
  //     })

  const { data, refetch } = useQuery(
    ['filter-preset', projectId],
    () => loadFilterPresets(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )

  useEffect(() => {
    if (data) {
      setFilterPresets(data.filter_presets)
    }
  }, [data])

  const savePreset = (presetName: string, saveRelativeDate: boolean) => {
    return saveFilterPreset(
      projectId,
      presetName,
      filterValues.values,
      filterValues.searchCondition,
      filterValues.searchQuery,
      saveRelativeDate
        ? relativeDateFilterIdx[comparativeIndex || 0]
        : RelativeDateFilterIdx.Unset
    )
      .then(() => {
        toast.success('Filter saved')
        refetch()
      })
      .catch((error) => {
        toast.error(
          error.response.data.detail.msg || 'Error saving filter preset.'
        )
      })
      .finally(() => {
        queryClient.resetQueries(['filter-presets', projectId])
        setIsSavingPreset(false)
      })
  }

  const anyFilterApplied =
    filterValues.values.length > 0 ||
    filterValues.searchQuery.length > 0 ||
    filterValues.searchCondition.length > 0

  return (
    <Wrapper>
      <Popover
        visible={isSaving}
        onVisibleChange={(v: boolean) =>
          setIsSavingPreset(v && anyFilterApplied)
        }
        title={<div style={{ padding: '5px' }}>Save Current Filter</div>}
        trigger="click"
        placement={'bottom'}
        content={
          <div style={{ width: '300px' }}>
            <SavePresetForm
              saveFunction={savePreset}
              relativeDateFilterIdx={
                relativeDateFilterIdx[comparativeIndex || 0]
              }
            />
          </div>
        }
      >
        <Tooltip placement="topLeft" title="Save Filter">
          <Button
            type="text"
            icon={<SaveOutlined />}
            disabled={!anyFilterApplied}
            style={{
              border: `2px solid ${cardBorderGrey}`,
              borderRadius: '4px',
              boxShadow: 'none',
            }}
          />
        </Tooltip>
      </Popover>
      {/*<Tooltip placement="top" title="Load a filter.">*/}
      {/*  <Popover*/}
      {/*    visible={isLoading}*/}
      {/*    onVisibleChange={(v: boolean) =>*/}
      {/*      setIsLoadingPreset(v && filterPresets.length > 0)*/}
      {/*    }*/}
      {/*    placement={'bottom'}*/}
      {/*    title="Load a filter"*/}
      {/*    trigger="click"*/}
      {/*    content={*/}
      {/*      <FilterPresetLoadForm*/}
      {/*        filters={filterPresets}*/}
      {/*        deletePreset={deletePreset}*/}
      {/*        updateFilterValues={updateFilters}*/}
      {/*      />*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      type="text"*/}
      {/*      icon={<FilterOutlined />}*/}
      {/*      disabled={!filterPresets.length}*/}
      {/*      style={{*/}
      {/*        border: `2px solid ${cardBorderGrey}`,*/}
      {/*        borderRadius: '4px',*/}
      {/*        boxShadow: 'none',*/}
      {/*        marginRight: '10px',*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Popover>*/}
      {/*</Tooltip>*/}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`
