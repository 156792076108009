import React, { useMemo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { Filters } from '../../../types'
import { Chart } from '../../dashboard/components/Dashboard/Charts/chart'
import { PanelBadges } from '../../filters/features/badge-panel/panel-badges'
import OutlierAnalysis from './OutlierAnalysis'
import OutlierGrading from './OutlierGrading'
import useThemes from '../../../hooks/useThemes'
import OutlierChart from './OutlierChart'
import TimeSeriesOutlierAnalysis from './TimeSeriesOutlierAnalysis'

type OutlierProps = {
  outlier: any // Consider creating a more specific type for outlier
  filterValues: Filters
  model: string
}

const TIER_NAME_SENTIMENT = 'Sentiment'
const TIER_NAME_REVIEW_COUNT = 'Review Count'

const TimeSeriesOutlier: React.FC<OutlierProps> = ({
  outlier,
  filterValues,
  model,
}) => {
  const { getClusterUUIDsFromThemeName } = useThemes()

  const activeFilters = useMemo(() => {
    const tempFilters = _.cloneDeep(filterValues)
    tempFilters.values = filterValues.values.filter(
      (filter) => !['product_hierarchy', 'theme'].includes(filter.field)
    )

    // tempFilters.values.push(
    //   { field: 'product_hierarchy', values: [outlier.product] },
    //   {
    //     field: 'theme',
    //     values: getClusterUUIDsFromThemeName(
    //       outlier.theme.split(': ')[0],
    //       outlier.theme.split(': ')[1]
    //     ),
    //   },
    //   { field: 'score', min: 0, max: 5, value_type: 1 }
    // )

    return tempFilters
  }, [filterValues, outlier, getClusterUUIDsFromThemeName])

  const outlierChartItems = [
    // createChartItem(
    //   '1_1_8_posts',
    //   TIER_NAME_REVIEW_COUNT,
    //   '# Reviews',
    //   activeFilters
    // ),
    createChartItem(
      '3_2_17_sentiment',
      TIER_NAME_SENTIMENT,
      'Sentiment Breakdown',
      activeFilters
    ),
    createChartItem(
      '3_1_17_sentiment',
      TIER_NAME_SENTIMENT,
      'Average Sentiment',
      activeFilters
    ),
    createChartItem(
      '3_4_21_sentiment',
      TIER_NAME_SENTIMENT,
      'Sentiment over Time',
      activeFilters
    ),
  ]

  return (
    <Container>
      <InfoSection>
        <OutlierInfo outlier={outlier} />
        <PanelBadges
          filterValues={activeFilters}
          comparativeIndex={0}
          infoOnly
          isSummary
        />

        {outlierChartItems.map((item, index) => (
          <ChartSection
            key={index}
            style={{
              height: item.title === 'Sentiment Breakdown' ? '400px' : 'auto',
            }}
          >
            <OutlierChart chartItem={item} activeFilters={activeFilters} />
          </ChartSection>
        ))}
      </InfoSection>
      <AnalysisSection>
        <TimeSeriesOutlierAnalysis outlier={outlier} model={model} />
      </AnalysisSection>
      {/*<GradingSection>*/}
      {/*  <OutlierGrading outlier={outlier} />*/}
      {/*</GradingSection>*/}
    </Container>
  )
}

const OutlierInfo: React.FC<{ outlier: any }> = ({ outlier }) => (
  <InfoContainer>
    {/*<InfoItem>*/}
    {/*  Brand: <InfoValue>{outlier.brand}</InfoValue>*/}
    {/*</InfoItem>*/}
    {/*<InfoItem>*/}
    {/*  Product: <InfoValue>{outlier.product}</InfoValue>*/}
    {/*</InfoItem>*/}
    {/*<InfoItem>*/}
    {/*  Theme: <InfoValue>{outlier.theme}</InfoValue>*/}
    {/*</InfoItem>*/}
    <InfoItem>
      Volume: <InfoValue>{outlier.volume}</InfoValue>
    </InfoItem>
    <InfoItem>
      Outlier Intensity:{' '}
      <InfoValue>{outlier.weighted_zscore.toFixed(2)}</InfoValue>
    </InfoItem>
  </InfoContainer>
)

const createChartItem = (
  chartId: string,
  tier1: string,
  title: string,
  filters: Filters
) => ({
  title: title,
  type: 'chart',
  content: {
    name: 'Rating',
    image: '4_16.png',
    chart_ids: [{ chart_id: chartId, model: null, field: null }],
    tier1,
  },
  dashboardControls: {
    chartId,
    tier1,
    tier2: null,
    tier3: null,
    legend: false,
    fullAxis: true,
    hasLabels: true,
    hasOverlay: false,
    treeView: false,
    origin: true,
    labels: true,
    trendlines: false,
    shadedZones: true,
    sortedByCategory: false,
    isAlignScales: true,
    isAlignOrder: true,
    multiChart: false,
    fontSize: 'medium',
    timeInterval: null,
    reviewsCount: 0,
    postType: 'post',
    interval: null,
    isExpandChart: false,
    order: null,
    isDelta: false,
    showPercentChange: true,
    wrapLabels: true,
    maxAxesLength: 30,
    hideXAxis: true,
    hideYAxis: true,
  },
  filterValues: filters,
})

const Container = styled.div`
  display: flex;
  gap: 20px;
`

const InfoSection = styled.div`
  min-width: 400px;
  max-width: 400px;
  border-right: 4px solid #f2f2f2;
  flex-grow: 0;
`

const AnalysisSection = styled.div`
  padding-right: 10px;
  border-right: 4px solid #f2f2f2;
  flex-grow: 1;
`

const GradingSection = styled.div`
  min-width: 250px;
`

const InfoContainer = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  padding-right: 10px;
`

const InfoItem = styled.div`
  margin-bottom: 5px;
`

const InfoValue = styled.span`
  font-weight: 500;
`

const ChartContainer = styled.div`
  height: 400px;
`

const ChartSection = styled.div`
  margin-top: 20px;
`

export default TimeSeriesOutlier
