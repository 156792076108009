import React from 'react'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'

export const CardNew = ({ text }: { text?: string }) => {
  return (
    <Wrapper>
      <Icon>
        <PlusOutlined />
      </Icon>
      <Text>{text ? text : 'Add Source'}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  padding: var(--default-padding-half);
  box-shadow: var(--default-box-shadow);
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  transform: scale(1);
  border-radius: var(--border-radius);
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
`
const Icon = styled.div`
  width: 48px;
  height: 48px;
  font-size: var(--font-size-l);
  border: 2px solid black;
  border-radius: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey);
  margin: 0 auto var(--default-padding-half);
`
const Text = styled.div`
  font-size: var(--font-size-md);
  text-align: center;
  color: #000;
`
