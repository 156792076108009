import React from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { ErrorMessage, Section, StyledInput } from '../CreateAlert.styles'
import { AlertType } from '../../../model'

interface Props {
  control: Control<AlertType>
  errors: FieldErrors<AlertType>
}

const AlertName: React.FC<Props> = ({ control, errors }) => {
  return (
    <Section>
      <h3>Name Your Alert</h3>
      <Controller
        name="title"
        control={control}
        rules={{ required: 'Title is required' }}
        render={({ field }) => (
          <StyledInput
            {...field}
            placeholder="Alert Title"
            aria-label="Alert Title"
            isError={!!errors.title}
          />
        )}
      />
      {!!errors.title && (
        <ErrorMessage style={{ paddingLeft: 8, marginTop: 3 }}>
          Please name your alert!
        </ErrorMessage>
      )}
    </Section>
  )
}

export default AlertName
