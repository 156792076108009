import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Input, Select, Tooltip } from 'antd'
import * as Yup from 'yup'
import { Loader } from 'shared/components'
import { ProjectState } from 'features/project/types'
import { Post, PostDetailModal, PostsGrid, PostsSummary } from './'
import { getFeedback, getSummary } from './model'
import { HeaderFilterPanel } from '../filters'
import { FilterTypes } from '../filters/types'
import { isInitialFlow } from 'features/home/utils'
import _ from 'lodash'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../projectStore/projectStore'
import { useQuery } from 'react-query'
import { YogiButton } from '../../../../components/UI/YogiButton'
import { Fetcher } from '../../../../shared/components/fetcher'
import { cardBorderGrey } from '../../../../assets/styles/variables'
import { UndoOutlined } from '@mui/icons-material'

export const orderNames: any = {
  asc: '↑',
  desc: '↓',
}

type Props = { comparativeIndex: number }

const FeedbackContent: React.FC<Props> = ({ comparativeIndex }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative
  )
  const filterList = useProjectStore(
    (state: ProjectState) => state.filterList[comparativeIndex]
  )
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )
  const feedbackControls = useProjectStore(
    (state: ProjectState) => state.feedbackControls
  )
  const updateFeedbackControls = useProjectStore(
    (state: ProjectState) => state.updateFeedbackControls
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions
  )

  const { order, orderField } = feedbackControls[comparativeIndex]

  const [postDetailUuid, setPostDetailUuid] = useState<string>()
  const [page, setPage] = useState<number>(1)
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)
  const [resetting, setResetting] = useState<boolean>(false)
  const [hasFormatting, setHasFormatting] = useState<boolean>(false)

  useEffect(() => {
    mixpanel.track('feedback page')
  }, [])

  const toggleOrder = () => {
    setPage(1)
    const newOrder = order === 'asc' ? 'desc' : 'asc'
    updateFeedbackControls(comparativeIndex, 'order', newOrder)
  }

  const changeOrderField = (value: string) => {
    setPage(1)
    updateFeedbackControls(comparativeIndex, 'orderField', value)
  }

  useEffect(() => {
    setPage(1)
    // Whenever a filter is applied, opened options should be reset
    clearOpenedOptions(comparativeIndex)
  }, [
    filterValues.values,
    filterValues.searchCondition,
    filterValues.searchQuery,
  ])

  // get Verbatims
  const {
    data: postData,
    isFetching: postsFetching,
    isLoading,
  } = useQuery(
    [
      `feedback-posts_` + comparativeIndex,
      projectId,
      filterValues.values,
      filterValues.searchQuery,
      filterValues.searchCondition,
      order,
      orderField,
      page,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
          page,
          order,
          order_field: orderField,
          page_size:
            // one project had a LOT of dupes - this was a hackaround
            projectId === 'ca805d9d-b814-47dd-bdbd-74b1737eb9d4' ? 120 : 24,
          // projectId === '5ecaef81-57d1-4d5d-935b-e7e7375d141e' ? 120 : 24,
        },
        body: {
          criteria: filterValues.values,
          search_terms: filterValues.searchQuery,
          search_criteria: filterValues.searchCondition,
        },
      }
      const { data } = await getFeedback(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    }
  )

  useEffect(() => {
    if (postData) {
      const seenReviews = new Set<string>()
      const dedupedPosts: Post[] = []

      for (const post of postData.posts) {
        const reviewKey = post.title + post.body
        if (!seenReviews.has(reviewKey)) {
          seenReviews.add(reviewKey)
          dedupedPosts.push(post)
        }
      }

      setPosts([...(page > 1 ? posts : []), ...dedupedPosts])
      setLoadMoreLoading(false)
    }
  }, [postData])

  const [posts, setPosts] = useState<Post[]>(postData ? postData.posts : [])

  // get Feedback Summary
  const {
    data: summaryData,
    isFetching: summaryFetching,
    isLoading: summaryLoading,
  } = useQuery(
    [
      `feedback-summary_` + comparativeIndex,
      projectId,
      filterValues?.values,
      filterValues?.searchCondition,
      filterValues?.searchQuery,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
          page,
          order,
          order_field: orderField,
        },
        body: {
          criteria: filterValues.values,
          search_terms: filterValues.searchQuery,
          search_criteria: filterValues.searchCondition,
        },
      }

      const { data } = await getSummary(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    }
  )

  const hasMore = (postData?.n_pages || 0) > page
  const numericOptions = filterList
    ? filterList.filter(
        (option) =>
          option.filter_type === FilterTypes.WINDOW ||
          option.filter_type === FilterTypes.DATE
      )
    : []

  let orderFieldOptions = numericOptions.map((e) => ({
    value: e.field,
    label: e.alias,
  }))

  orderFieldOptions.sort(function (a, b) {
    if (a.label > b.label) {
      return 1
    }
    if (a.label < b.label) {
      return -1
    }
    return 0
  })

  const onClosePostDetail = useCallback(() => setPostDetailUuid(undefined), [])

  if (isInitialFlow(details.state) || !postData) {
    return (
      <>
        <Loader />
        <LoaderTitle>
          {`Loading ${details.clientSettings.project.pages.feedback.alias} Page`}
        </LoaderTitle>
      </>
    )
  }

  const settings = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Right>
        <Order>
          Sort by{' '}
          <span style={{ display: 'flex' }}>
            <Select
              value={orderField}
              onChange={(value) => changeOrderField(value)}
              options={orderFieldOptions}
              style={{ minWidth: '150px' }}
            />
            <Tooltip title={'Asc/Desc'}>
              <YogiButton
                id="feedback-sort-button"
                type="primary"
                onClick={toggleOrder}
              >
                {orderNames[order]}
              </YogiButton>
            </Tooltip>
          </span>
        </Order>
      </Right>
    </div>
  )

  const reset = hasFormatting ? (
    <Tooltip title={'Reset Formatting'}>
      <Button
        type="text"
        style={{
          border: `2px solid ${cardBorderGrey}`,
          borderRadius: '4px',
          boxShadow: 'none',
          display: 'flex',
          padding: '4px 8px',
        }}
        onClick={() => {
          setResetting(true)
          setHasFormatting(false)
          setTimeout(() => {
            setResetting(false)
          }, 50)
        }}
      >
        <UndoOutlined fontSize={'small'} />{' '}
      </Button>
    </Tooltip>
  ) : null

  return (
    <ContentWrapper>
      <HeaderFilterPanel
        reset={reset}
        settings={settings}
        filterValues={filterValues}
        comparativeIndex={comparativeIndex}
      />
      <ContentBody isComparative={isComparative} id="feedback-container">
        <>
          <SummaryContainer
            id="summary-container"
            isComparative={isComparative}
          >
            {summaryData ? (
              <PostsSummary
                data={summaryData}
                isComparative={isComparative}
                loading={summaryLoading}
              />
            ) : (
              <Loader small />
            )}
          </SummaryContainer>

          {postData && (
            <>
              <PostsContainer isComparative={isComparative} id="post-container">
                <PostsGrid
                  posts={posts}
                  onPostClick={(postId: string) => setPostDetailUuid(postId)}
                  isLoading={isLoading}
                  isFetching={postsFetching}
                  resetView={resetting}
                  setHasFormatting={setHasFormatting}
                />
                {postData?.posts.length > 0 && hasMore && (
                  <StyledButton
                    onClick={() => {
                      setLoadMoreLoading(true)
                      setPage(page + 1)
                    }}
                    disabled={loadMoreLoading}
                  >
                    {loadMoreLoading ? 'Loading' : 'Load more'}
                  </StyledButton>
                )}
              </PostsContainer>
              <PostDetailModal
                postUuid={postDetailUuid}
                onClose={onClosePostDetail}
                fragment={false}
              />
            </>
          )}
          {postsFetching && postData && (
            <LoadingLayer>
              <Fetcher paddingTop={'50px'} />
            </LoadingLayer>
          )}
        </>
      </ContentBody>
    </ContentWrapper>
  )
}

export const FeedbackContainer: React.FC = () => {
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const feedbackControls = useProjectStore(
    (state: ProjectState) => state.feedbackControls
  )
  const filters = useProjectStore((state: ProjectState) => state.filters)
  if (
    feedbackControls.length < comparativePanelsNumber ||
    filters.length < comparativePanelsNumber
  ) {
    return <Container />
  }

  return (
    <Container>
      <>
        <Wrapper>
          {_.times(comparativePanelsNumber, (index) => (
            <Panel key={index}>
              <FeedbackContent comparativeIndex={index} />
            </Panel>
          ))}
        </Wrapper>
      </>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0px 16px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
  gap: var(--default-padding-half);
  margin: 16px;
`

const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
`
const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 0 var(--default-padding-half);
  height: 100%;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  padding: var(--default-padding-half) 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`
const PostsContainer = styled.div<{ readonly isComparative: boolean }>`
  flex: 1;
  max-width: 100%;
  margin: 0 auto;
  order: ${(props) => (props.isComparative ? 2 : 1)};
  padding-right: ${(props) =>
    props.isComparative ? 0 : 'var(--default-padding-half)'};
  overflow-y: ${(props) => (props.isComparative ? 'visible' : 'scroll')};
  height: ${(props) => (props.isComparative ? 'auto' : '100%')};
`
const ContentBody = styled.div<{ readonly isComparative: boolean }>`
  display: flex;
  padding-top: calc(var(--default-padding-half) - var(--filter-margin-bottom));
  flex-direction: ${(props) => (props.isComparative ? 'column' : 'row')};
  align-items: ${(props) => (props.isComparative ? 'center' : 'flex-start')};
  //height: calc(100% - var(--filter-height-total));
  height: 100%;
  overflow-y: ${(props) => (props.isComparative ? 'scroll' : 'hidden')};
  position: relative;
`

const SummaryContainer = styled.div<{ readonly isComparative: boolean }>`
  display: block;
  max-width: 100%;
  border-radius: var(--border-radius);
  margin-bottom: 0;
  box-shadow: none;
  order: ${(props) => (props.isComparative ? 1 : 2)};
  position: ${(props) => (props.isComparative ? 'relative' : 'sticky')};
  width: ${(props) => (props.isComparative ? '100%' : '300px')};
  margin-left: 10px;
`
const Order = styled.div`
  button {
    margin: 0 10px;
  }
`
const Left = styled.div``
const Right = styled.div`
  margin-left: auto;
  display: flex;

  & > div {
    padding: 0;
  }
`
const StyledButton = styled(Button)`
  display: block;
  margin: 20px auto;
`
const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`
