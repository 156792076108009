import { useQuery } from 'react-query'
import { initialState, useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { getProject } from '../model'
import mixpanel from '../../trackers/mixpanel'

export const useComparativeNavigation = () => {
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative
  )
  const setIsComparative = useProjectStore(
    (state: ProjectState) => state.setIsComparative
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const setComparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.setComparativePanelsNumber
  )
  const filters = useProjectStore((state: ProjectState) => state.filters)
  const setFilters = useProjectStore((state: ProjectState) => state.setFilters)

  const filterList = useProjectStore((state: ProjectState) => state.filterList)
  const setFilterList = useProjectStore(
    (state: ProjectState) => state.setFilterList
  )
  const relativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.relativeDateFilterIdx
  )
  const setRelativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.setRelativeDateFilterIdx
  )
  const dashboardControls = useProjectStore(
    (state: ProjectState) => state.dashboardControls
  )
  const setDashboardControls = useProjectStore(
    (state: ProjectState) => state.setDashboardControls
  )
  const networkControls = useProjectStore(
    (state: ProjectState) => state.networkControls
  )
  const setNetworkControls = useProjectStore(
    (state: ProjectState) => state.setNetworkControls
  )
  const keywordsControls = useProjectStore(
    (state: ProjectState) => state.keywordsControls
  )
  const setKeywordsControls = useProjectStore(
    (state: ProjectState) => state.setKeywordsControls
  )
  const feedbackControls = useProjectStore(
    (state: ProjectState) => state.feedbackControls
  )
  const setFeedbackControls = useProjectStore(
    (state: ProjectState) => state.setFeedbackControls
  )
  const openedOptions = useProjectStore(
    (state: ProjectState) => state.openedOptions
  )
  const setOpenedOptions = useProjectStore(
    (state: ProjectState) => state.setOpenedOptions
  )
  const selectedOptionField = useProjectStore(
    (state: ProjectState) => state.selectedOptionField
  )
  const setSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.setSelectedOptionField
  )
  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls
  )
  const chartData = useProjectStore((state: ProjectState) => state.chartData)
  const setChartData = useProjectStore(
    (state: ProjectState) => state.setChartData
  )
  const currentChartList = useProjectStore(
    (state: ProjectState) => state.currentChartList
  )
  const setCurrentChartList = useProjectStore(
    (state: ProjectState) => state.setCurrentChartList
  )

  const addPanel = () => {
    // TODO this will fire when loading a saved view with compare
    mixpanel.track('compare mode', { action: 'add panel' })
    // duplicate base filter to the end
    // could have this go to a default instead
    setFilters([...filters, initialState.filters[0]])
    setFilterList([...filterList, []])
    setRelativeDateFilterIdx([
      ...relativeDateFilterIdx,
      relativeDateFilterIdx[0],
    ])
    // increment other lists
    setDashboardControls([
      ...dashboardControls,
      initialState.dashboardControls[0],
    ])
    setNetworkControls([...networkControls, initialState.networkControls[0]])
    setKeywordsControls([...keywordsControls, initialState.keywordsControls[0]])
    setFeedbackControls([...feedbackControls, initialState.feedbackControls[0]])
    setOpenedOptions([...openedOptions, initialState.openedOptions[0]])
    setSelectedOptionField([
      ...selectedOptionField,
      initialState.selectedOptionField[0],
    ])
    setCurrentChartList([...currentChartList, initialState.currentChartList[0]])
    // setChartData([...chartData, initialState.chartData])
    if (!isComparative) {
      updateDashboardControls(0, 'legend', false)
      setIsComparative(true)
    }
    setComparativePanelsNumber(comparativePanelsNumber + 1)
  }

  const removePanel = () => {
    mixpanel.track('compare mode', { action: 'remove panel' })
    // TODO there has to be a better way to do this
    // https://linear.app/yo-yo-gi/issue/EFE-98/condense-comparative-navigation-incrementdecrement-functions
    setFilters([...filters.slice(0, -1)])
    setFilterList([...filterList.slice(0, -1)])
    setRelativeDateFilterIdx([...relativeDateFilterIdx.slice(0, -1)])
    setDashboardControls([...dashboardControls.slice(0, -1)])
    setNetworkControls([...networkControls.slice(0, -1)])
    setKeywordsControls([...keywordsControls.slice(0, -1)])
    setFeedbackControls([...feedbackControls.slice(0, -1)])
    setOpenedOptions([...openedOptions.slice(0, -1)])
    setSelectedOptionField([...selectedOptionField.slice(0, -1)])
    setChartData([...chartData.slice(0, -1)])
    setCurrentChartList([...currentChartList.slice(0, -1)])
    if (comparativePanelsNumber === 2) {
      updateDashboardControls(0, 'legend', true)
      setIsComparative(false)
    }
    setComparativePanelsNumber(comparativePanelsNumber - 1)
  }
  return { addPanel, removePanel }
}
