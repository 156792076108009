import React, { useMemo } from 'react'
import styled from 'styled-components'
import { initialState } from '../../projectStore/projectStore'
import { Filters } from '../../types'
import { FocusListOption } from './PDPList'
import Analysis from './Components/Analysis'
import { ProductHierarchyInfo } from '../products/types'

interface FocusDetailProps {
  lens: string
  filterValues: Filters
  item: ProductHierarchyInfo
}

const PDPDetailContent: React.FC<FocusDetailProps> = ({
  item,
  lens,
  filterValues,
}) => {
  const chartId = '5_4_16_rating'
  const tier1 = lens

  const localFilterValues = useMemo(() => {
    return {
      ...filterValues,
      values: [
        ...filterValues.values,
        { field: lens, values: [item.hierarchy] },
      ],
    }
  }, [filterValues, lens, item.hierarchy])

  const dashboardControls = JSON.parse(
    JSON.stringify(initialState.dashboardControls[0])
  )
  dashboardControls.legend = false

  return (
    <>
      <Content>
        <MainBody>
          <Analysis
            item={item}
            filterValues={localFilterValues}
            tier1={tier1}
            chartId={chartId}
          />
        </MainBody>
      </Content>
    </>
  )
}

const Content = styled.div`
  width: 100%;
`

const MainBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

export default PDPDetailContent
