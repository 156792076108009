import moment from 'moment'
import queryString from 'query-string'
import {
  AppliedFilterOption,
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
  ApplyFilterPayload,
  ApplyGroupFilterPayload,
  FilterOption,
  FilterOptionDate,
  FilterOptionRange,
  FilterOptionSelect,
} from './types'
import _ from 'lodash'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'

export enum DateFilterFieldNamesEnum {
  date,
  create_time,
}

export enum HardcodedFilterOptionsEnum {
  CREATE_TIME = 'create_time',
  THEME_GROUP = 'theme_group',
}

export function isAppliedFilterOptionSelect(
  option: AppliedFilterOption
): option is AppliedFilterOptionSelect {
  return 'values' in option
}

export function isAppliedFilterOptionRange(
  option: AppliedFilterOption
): option is AppliedFilterOptionSelect {
  return 'min' in option && 'max' in option
}

export const applyFilterValue = (
  payload: ApplyFilterPayload,
  appliedFilters: Array<AppliedFilterOption>
) => {
  const { field, value } = payload
  const alreadyAppliedValuesForThisField = appliedFilters.find(
    (f) => f.field === field
  )
  if (!alreadyAppliedValuesForThisField) {
    return [...appliedFilters, convertFilterPayload(payload)]
  }
  const filteredAppliedFilters = appliedFilters.filter((f) => f.field !== field)
  if (isAppliedFilterOptionSelect(alreadyAppliedValuesForThisField)) {
    const newValue = value as string
    const values = alreadyAppliedValuesForThisField.values
    const newFilterOption: AppliedFilterOptionSelect = {
      field,
      values: values.includes(newValue)
        ? values.filter((v) => v !== newValue)
        : [...values, newValue],
    }
    if (newFilterOption.values.length) {
      return [...filteredAppliedFilters, newFilterOption]
    }
    return filteredAppliedFilters
  }
  if (isAppliedFilterOptionRange(alreadyAppliedValuesForThisField)) {
    return [...filteredAppliedFilters, convertFilterPayload(payload)]
  }
  return appliedFilters
}

export const applyGroupFilterValue = (
  payload: ApplyGroupFilterPayload,
  appliedFilters: Array<AppliedFilterOption>
) => {
  const { field, value } = payload
  const alreadyAppliedValuesForThisField = appliedFilters.find(
    (f) => f.field === field
  )
  if (!alreadyAppliedValuesForThisField) {
    return [...appliedFilters, convertGroupFilterPayload(payload)]
  }
  const filteredAppliedFilters = appliedFilters.filter((f) => f.field !== field)
  if (isAppliedFilterOptionSelect(alreadyAppliedValuesForThisField)) {
    const newValues = value as string[]
    const values = alreadyAppliedValuesForThisField.values
    const newFilterOption: AppliedFilterOptionSelect = {
      field,
      values: newValues.every((v) => values.includes(v))
        ? values.filter((v) => !newValues.includes(v))
        : [...values, ...newValues],
    }
    if (newFilterOption.values.length) {
      return [...filteredAppliedFilters, newFilterOption]
    }
    return filteredAppliedFilters
  }
  // if (isAppliedFilterOptionRange(alreadyAppliedValuesForThisField)) {
  //   return [...filteredAppliedFilters, convertFilterPayload(payload)]
  // }
  return appliedFilters
}

function convertFilterPayload({
  field,
  value,
}: ApplyFilterPayload): AppliedFilterOptionSelect | AppliedFilterOptionRange {
  if (typeof value === 'object') {
    return { field, ...value }
  }
  return { field, values: [value] }
}

function convertGroupFilterPayload({
  field,
  value,
}: ApplyGroupFilterPayload):
  | AppliedFilterOptionSelect
  | AppliedFilterOptionRange {
  // if (typeof value === 'object') {
  //   return { field, ...value }
  // }
  return { field, values: value }
}

export const selectedFiltersCountByComparativeIndex = (
  filterList: AppliedFilterOption[]
) =>
  filterList.reduce((acc, el) => {
    if (Object.keys(DateFilterFieldNamesEnum).includes(el.field)) {
      return acc
    }
    return acc + (isAppliedFilterOptionSelect(el) ? el.values.length : 1)
  }, 0)

export function reducerToComputeFiltersCount(
  sum: number,
  item: AppliedFilterOption
): number {
  if (Object.keys(DateFilterFieldNamesEnum).includes(item.field)) {
    return sum
  }

  const numberOfFilters = isAppliedFilterOptionSelect(item)
    ? item.field.includes('sentiment')
      ? item.values.filter((item) => item !== '1' && item !== '0').length
      : item.values.length
    : 1

  return sum + numberOfFilters
}

export function convertFiltersToUriParams(
  values: Array<AppliedFilterOption>
): any {
  return values.reduce((res, current) => {
    if (isAppliedFilterOptionSelect(current)) {
      return { ...res, [current.field]: current.values }
    }
    if (isAppliedFilterOptionRange(current)) {
      if (current.field.match(/score_\d/))
        return {
          ...res,
          [current.field]: JSON.stringify({
            min: current.min,
            max: current.max,
            value_type: current.value_type,
          }),
        }
      return {
        ...res,
        [current.field]: JSON.stringify({ min: current.min, max: current.max }),
      }
    }
    return res
  }, {})
}

export function formatUriParams(values: Array<AppliedFilterOption>): string {
  const result = convertFiltersToUriParams(values)
  return queryString.stringify(result)
}

export function parseUriParams(url: string): Array<AppliedFilterOption> {
  const parsedUrl = queryString.parse(url)
  return parseUriPayload(parsedUrl)
}

export const getFilterValueName = (value: any) => {
  return (value as string)?.slice(0, -2)
}

export function parseUriPayload(parsedUrl: any): AppliedFilterOption[] {
  const result: Array<AppliedFilterOption> = []

  for (const field in parsedUrl) {
    const value = parsedUrl[field]

    if (Array.isArray(value)) {
      result.push({ field, values: value })
    }
    if (typeof value === 'string') {
      // TODO: refactor -- wtf is this try / catch for checking string
      // ans - i think it catches values that are already parsed and handles them
      try {
        const parsed = JSON.parse(value) as any
        if (
          (typeof parsed === 'number' && field.startsWith('theme')) ||
          typeof parsed === 'boolean'
        )
          JSON.parse('cringe and refactor')
        if (typeof parsed === 'object' && parsed.min && parsed.max) {
          result.push({
            field,
            min: parsed.min,
            max: parsed.max,
            value_type: parsed?.value_type,
          } as any)
        } else {
          result.push({ field, values: parsed })
        }
      } catch (e) {
        result.push({ field, values: [value] })
      }
    }
  }

  return result
}

interface Makrs {
  [index: number]: Object
}

export function generateSliderMarks(
  start: number,
  end: number,
  step: number
): Makrs {
  const mark = {
    label: null,
  }
  const marks: Makrs = {}
  let next: number = Math.ceil(start / step) * step
  if (end - start <= step) {
    marks[start - step] = mark
    marks[start] = mark
    marks[end + step] = mark
  } else {
    marks[start] = mark
    while (next < end) {
      marks[parseFloat(next.toFixed(2))] = mark
      next += step
    }
    marks[end] = mark
  }
  return marks
}

export const getBadgeLabels = (
  value: AppliedFilterOption,
  options: FilterOption[] | undefined
) => {
  const foundOption = options?.find((option) => option?.field === value?.field)
  if (foundOption?.field.match('sentiment')) {
    return (value as AppliedFilterOptionSelect)?.values.filter(
      (item) => item !== '0'
    )
  }
  if (foundOption?.field.match('create_time')) {
    return [
      formatUTCDateToCurrentTimezoneDate(
        moment.unix((value as AppliedFilterOptionRange).min)
      ).format('MM/DD/yy') +
        ' - ' +
        formatUTCDateToCurrentTimezoneDate(
          moment.unix((value as AppliedFilterOptionRange).max)
        ).format('MM/DD/yy'),
    ]
  }

  if (foundOption?.field.match('score')) {
    const type =
      (value as AppliedFilterOptionRange).value_type === 1
        ? 'Reviews'
        : (value as AppliedFilterOptionRange).value_type === 2
        ? 'Ratings'
        : 'Reviews & Ratings'
    const min = (value as FilterOptionRange).min
    const max = (value as FilterOptionRange).max
    return min && max ? [type + ' ' + min + ' - ' + max] : [type]
  }

  if (
    foundOption?.field.match('theme') ||
    foundOption?.field.match('proj_uuid')
  ) {
    return (foundOption as FilterOptionSelect)?.values
      ?.filter(
        (item) =>
          (value as AppliedFilterOptionSelect).values.includes(item.value) ||
          (item.cluster_uuid &&
            (value as AppliedFilterOptionSelect).values.includes(
              item.cluster_uuid
            ))
      )
      .map((el) => {
        if (
          foundOption?.field === 'theme' &&
          el.value.split(': ').length > 1 &&
          (el.value.split(': ')[0] === el.value.split(': ')[1] ||
            el.value.includes('Fans / Attractors') ||
            el.value.includes('Critics / Detractors'))
        ) {
          return el.value.split(':')[0]
        }
        return el.value
      })
  }

  // generalize to recognize custom range fields without select boxes
  // if (
  //   foundOption?.hasOwnProperty('field') &&
  //   foundOption?.hasOwnProperty('max')
  // ) {
  //   const type = foundOption?.field
  //   const min = (value as FilterOptionRange).min
  //   const max = (value as FilterOptionRange).max
  //   return min && max ? [type + ' ' + min + ' - ' + max] : [type]
  // }
  if (value?.hasOwnProperty('field') && value?.hasOwnProperty('max')) {
    const type = value?.field
    const min = (value as FilterOptionRange).min
    const max = (value as FilterOptionRange).max
    return min && max ? [type + ' ' + min + ' - ' + max] : [type]
  }

  return _.cloneDeep((value as AppliedFilterOptionSelect)?.values)
}

// DATES

export const formatDateToUTCMidnight = (date: moment.Moment) => {
  return date.utc().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
}

export const formatDateToUTCEndOfDay = (date: moment.Moment) => {
  return date.utc().set({
    hour: 23,
    minute: 59,
    second: 0,
    millisecond: 0,
  })
}

export const formatUTCDateToCurrentTimezoneDate = (date: moment.Moment) => {
  return moment(date.utc().format('MM/DD/YYYY HH:mm'), 'MM/DD/YYYY HH:mm')
}

export const formatDateToMidnight = (date: moment.Moment): moment.Moment => {
  return moment(
    moment(date.format('MM/DD/YYYY HH:mm'), 'MM/DD/YYYY HH:mm').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  )
}

export const formatDateToEndOfDay = (date: moment.Moment): moment.Moment => {
  return moment(
    moment(date.format('MM/DD/YYYY HH:mm'), 'MM/DD/YYYY HH:mm').set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    })
  )
}

// @ts-ignore
export const updateFilterList = (filterList, openedOptions, values) => {
  let temp: FilterOption[] = JSON.parse(JSON.stringify(filterList))
  if (temp.length > 0 && openedOptions.length) {
    temp.forEach((el, index) => {
      temp[index] = { ...el, ...values[index] }
    })
  } else {
    return values
  }
  return temp
}
