import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { Panel as ColorPickerPanel } from 'rc-color-picker'
import { convertHexToRGB } from 'utils/color-helpers'

type Props = {
  onChange: (color: string) => void
  color: string
  isColorPickerOpened: boolean
  setIsColorPickerOpened: (b: boolean) => void
}

export const ColorPicker: React.FC<Props> = ({
  color,
  onChange,
  isColorPickerOpened,
  setIsColorPickerOpened,
}) => {
  const [selectedColor, setSelectedColor] = useState<string>(color)
  const [isColorChanging, setIsColorChanging] = useState(false)

  useEffect(() => {
    setIsColorPickerOpened(isColorChanging)
  }, [isColorChanging])

  return (
    <Wrapper>
      {isColorChanging && (
        <Modal>
          <ColorPickerPanel
            defaultColor={color}
            mode="RGB"
            onChange={(colorObject: any) => {
              const color: any = convertHexToRGB(colorObject.color)

              if (Number.isNaN(color)) {
                alert('Something went wrong')
                return
              }

              setSelectedColor(color)
            }}
          />
          <ButtonWrapper>
            <Button
              type="text"
              onClick={() => {
                setIsColorChanging(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onChange(selectedColor)
                setIsColorChanging(false)
              }}
            >
              Save
            </Button>
          </ButtonWrapper>
        </Modal>
      )}
      <Color
        style={{ backgroundColor: color }}
        onClick={() => !isColorPickerOpened && setIsColorChanging(true)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const Color = styled.div`
  width: 12px;
  height: 12px;
`
const Modal = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #fff;
  padding: var(--default-padding-half);
  border: 1px solid var(--color-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`
