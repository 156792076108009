import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ChartData } from 'chart.js'
import ChartJS from 'chart.js/auto'
import { numberFormatter } from 'utils/numberFormat'
import type { KeywordsBarChart } from '../types'
import postsEmpty from '../../../../../assets/images/empty/PostsEmpty.png'
import { PostsEmpty } from '../../../../../components/PostsEmpty'

type Props = {
  data: KeywordsBarChart
  onPhraseSelect: (c: string) => void
  animationDisabled?: boolean
  sortBySentiment?: boolean
  chartRef: React.RefObject<HTMLCanvasElement>
}

export const Chart: React.FC<Props> = ({
  data,
  onPhraseSelect,
  animationDisabled,
  sortBySentiment,
  chartRef,
}) => {
  // const chartRef = useRef<HTMLCanvasElement>(null)
  const [chartAPI, setChartAPI] = useState<ChartJS | null>(null)

  useEffect(() => {
    if (chartRef.current) {
      const labels = []
      const colors = []
      const values = []
      const sortedValues = [...data.values]
      if (sortBySentiment)
        sortedValues.sort(
          (a, b) =>
            (b.hover['Avg Sentiment'] as number) -
            (a.hover['Avg Sentiment'] as number)
        )
      for (const value of sortedValues) {
        const category =
          value.category.length > 30
            ? value.category.substr(0, 30) + '...'
            : value.category
        labels.push(category)
        colors.push(value.color)
        values.push(value.volume)
      }

      const chartData: ChartData = {
        labels: labels,
        datasets: [
          {
            label: data.x_key,
            data: values,
            backgroundColor: colors,
            borderRadius: 10,
            maxBarThickness: 40,
          },
        ],
      }

      const chartOptions: any = {
        indexAxis: 'y',
        scales: {
          x: {
            position: 'top',
            title: {
              display: true,
              text: 'Frequency',
            },
          },
        },
        plugins: {
          datalabels: {
            formatter: (value: number) => {
              return numberFormatter(value)
            },
            color: 'black',
            anchor: 'end',
            align: 'right',
          },
          legend: false,
          tooltip: {
            callbacks: {
              label: () => '',
              afterBody: function (t: any) {
                const index = t[0].dataIndex
                if (index === undefined) return ''
                const currentItem = sortedValues[index]

                return Object.keys(currentItem.hover)
                  .map(
                    (key) =>
                      `${key}: ${numberFormatter(currentItem.hover[key])}`
                  )
                  .join('\n')
              },
            },
          },
        },
        animation: {
          duration: animationDisabled ? 0 : 1000,
        },
        layout: {
          padding: {
            right: 60,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        onClick: function (event: any, chartElements: any[]) {
          if (chartElements && chartElements[0]) {
            const currentElementIndex: number = (chartElements[0] as any).index
            const currentElement = (chartData.labels as string[])?.find(
              (item, index) => index === currentElementIndex
            )
            onPhraseSelect(currentElement || '')
          }
        },
        onHover: (event: any, chartElement: any) => {
          event.native.target.style.cursor = chartElement[0]
            ? 'pointer'
            : 'default'
        },
      }

      if (chartAPI) {
        chartAPI.destroy()
      }
      const chart = new ChartJS(chartRef.current, {
        type: 'bar',
        options: chartOptions,
        data: chartData,
      })
      setChartAPI(chart)
    }
  }, [sortBySentiment, data])

  if (!data.values.length) {
    return <PostsEmpty>No matches found.</PostsEmpty>
  }
  return (
    <Container>
      <Wrapper>
        <canvas
          id="keywords-chart"
          ref={chartRef}
          style={{
            paddingLeft: 10,
            width: '100%',
            minHeight: data.values.length * 50 + 50,
          }}
        />
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  background: white;
  box-shadow: var(--default-box-shadow);
  border-radius: var(--border-radius);
  padding: 15px 0px;
`

const Wrapper = styled.div``

const NoData = styled.div`
  text-align: center;
  margin: var(--default-padding);
  font-size: var(--font-size-l);
  color: #f2863e;
  font-weight: bold;
`
