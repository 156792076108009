import React from 'react'
import { useCallback, useState } from 'react'
import { Typography, Modal, Tooltip } from 'antd'
import styled from 'styled-components'
import mixpanel from 'features/trackers/mixpanel'
import { SourceGroup } from '../types'
import { SourceDetail } from '../components'
import { numberFormatter } from 'utils/numberFormat'
import { SourceIcon as Icon } from 'shared/components'
import { pageBackgroundGrey } from '../../../../../assets/styles/variables'

type Props = {
  source: SourceGroup
  syndicated: boolean
  projectId: string
  role: string
}

export const SourceCard: React.FC<Props> = ({
  source,
  syndicated,
  projectId,
  role,
}) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false)

  const openModal = useCallback(() => {
    mixpanel.track('source data', {
      action: 'source type contents',
      value: source.source,
    })
    setModalOpened(true)
  }, [])

  const handleCancel = useCallback((e) => {
    e.stopPropagation()
    setModalOpened(false)
  }, [])
  const iconStyle = {
    width: '100px',
    height: '100px',
  }
  return (
    <>
      <SourceBox className="source-box" onClick={openModal}>
        <SourceContent>
          {/*<SourceIcon>*/}
          {/*  <Icon*/}
          {/*    source={syndicated ? 'syndicated' : source.source}*/}
          {/*    style={iconStyle}*/}
          {/*  />*/}
          {/*</SourceIcon>*/}
          <SourceTitle>
            <Tooltip title={source.source} placement="top">
              <Typography.Title ellipsis level={4}>
                {source.source}
              </Typography.Title>
            </Tooltip>
          </SourceTitle>
          <Typography.Text>
            {numberFormatter(source.reviews)} Review(s){' '}
          </Typography.Text>
          <Typography.Text>
            {numberFormatter(source.nsources)} Source(s){' '}
          </Typography.Text>
        </SourceContent>
      </SourceBox>
      {modalOpened ? (
        <Modal
          className="source-modal"
          title={source.source}
          visible={true}
          footer={null}
          onCancel={handleCancel}
          width={850}
        >
          <SourceDetail
            role={role}
            sourcename={source.source}
            projectId={projectId}
            syndicated={syndicated}
          ></SourceDetail>
        </Modal>
      ) : null}
    </>
  )
}

const SourceContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 auto;
  width: 250px;
`
const SourceTitle = styled.div`
  //margin-bottom: 8px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`

const SourceIcon = styled.div`
  color: black;
`

const SourceBox = styled.div`
  display: flex;
  //width: 300px;
  //height: 300px;
  margin: 8px;
  padding: 10px;
  flex: 1 0 21%;
  background: #fff;
  box-shadow: var(--default-box-shadow);
  color: black;
  transition: transform ease 0.1s;
  transform: scale(1);
  border-radius: var(--border-radius);

  &:hover {
    background: ${pageBackgroundGrey};
  }

  cursor: pointer;
`
