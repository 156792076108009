import React, { useEffect } from 'react'
import { Select } from 'antd'
import mixpanel from 'features/trackers/mixpanel'
import { useClusterContext } from './cluster-provider'
import { Domain } from './types'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'

type Props = {
  comparativeIndex: number
}
export const DomainSelector: React.FC<Props> = ({ comparativeIndex }) => {
  const { selectedDomain, domains, setSelectedDomain } = useClusterContext()

  const updateNetworkControls = useProjectStore(
    (state: ProjectState) => state.updateNetworkControls
  )

  if (!selectedDomain) return null
  const onDomainChange = (value: string) => {
    if (value === selectedDomain.name) return
    mixpanel.track('network lens', { value: value })
    setSelectedDomain(domains.find((el: Domain) => el.name === value) as Domain)
    // just co-opt this for setting the cluster field
    updateNetworkControls(comparativeIndex, 'clusterField', value)
  }

  // virtual scroll issue with 8 items: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.
  return (
    <Select
      virtual={false}
      defaultValue={selectedDomain.alias}
      style={{ width: '100%' }}
      onChange={onDomainChange}
    >
      {domains.map((field: any) => (
        <Select.Option value={field.name} key={field.name}>
          {field.alias}
        </Select.Option>
      ))}
    </Select>
  )
}
