import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useProjectStore } from '../../../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../../../types'
import {
  getFeedback,
  Post,
  PostDetailModal,
  PostsGrid,
} from '../../../../../../feedback'
import { LogoSpinnerSmall } from '../../../../../../../../../components/LogoSpinnerSmall'
import { OutlierType } from '../../../../../model'
import { YogiButton } from '../../../../../../../../../components/UI/YogiButton'
import { ButtonWrapper } from './InsightReviews.styles'

interface Props {
  outlier: OutlierType
  filterValues: Filters
}

const InsightReviews: React.FC<Props> = ({ outlier, filterValues }) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // local state
  const [postDetailUuid, setPostDetailUuid] = useState<string>()
  const [page, setPage] = useState<number>(1)
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // const needed for api
  const order = 'desc'
  const orderField = 'create_time'

  // api
  const {
    data: postData,
    isFetching: postsFetching,
    isLoading: postsLoading,
  } = useQuery(
    [
      `feedback-posts_`,
      projectId,
      filterValues.values,
      filterValues.searchQuery,
      filterValues.searchCondition,
      order,
      orderField,
      page,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
          page,
          order,
          order_field: orderField,
          page_size:
            // one project had a LOT of dupes - this was a hackaround
            projectId === 'ca805d9d-b814-47dd-bdbd-74b1737eb9d4' ? 120 : 24,
          // projectId === '5ecaef81-57d1-4d5d-935b-e7e7375d141e' ? 120 : 24,
        },
        body: {
          criteria: filterValues.values,
          search_terms: filterValues.searchQuery,
          search_criteria: filterValues.searchCondition,
        },
      }
      const { data } = await getFeedback(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )

  // pagination handling and deduping - there may be a better way to do this
  useEffect(() => {
    if (postData) {
      const seenReviews = new Set<string>()
      const dedupedPosts: Post[] = []

      for (const post of postData.posts) {
        const reviewKey = post.title + post.body
        if (!seenReviews.has(reviewKey)) {
          seenReviews.add(reviewKey)
          dedupedPosts.push(post)
        }
      }

      setPosts([...(page > 1 ? posts : []), ...dedupedPosts])
      setLoadMoreLoading(false)
    }
  }, [postData])

  // weird post api local state
  const [posts, setPosts] = useState<Post[]>(postData ? postData.posts : [])

  // derived constants
  const hasMore = (postData?.n_pages || 0) > page
  const onClosePostDetail = useCallback(() => setPostDetailUuid(undefined), [])

  return (
    <>
      {postData ? (
        <>
          <PostsGrid
            posts={posts}
            onPostClick={(postId: string) => setPostDetailUuid(postId)}
            isLoading={postsLoading}
            isFetching={postsFetching}
          />
          <ButtonWrapper>
            {postData?.posts.length > 0 && hasMore && (
              <YogiButton
                onClick={() => {
                  setLoadMoreLoading(true)
                  setPage(page + 1)
                }}
                disabled={loadMoreLoading}
              >
                {loadMoreLoading ? 'Loading' : 'Load more'}
              </YogiButton>
            )}
          </ButtonWrapper>
        </>
      ) : (
        <LogoSpinnerSmall>
          <p>Fetching Reviews...</p>
        </LogoSpinnerSmall>
      )}
      <PostDetailModal
        postUuid={postDetailUuid}
        onClose={onClosePostDetail}
        fragment={false}
      />
    </>
  )
}

export default InsightReviews
