import React, { useState, useEffect, useRef } from 'react'
import { ChartData } from 'chart.js'
import Chart from 'chart.js/auto'
import styled from 'styled-components'
import { numberFormatter } from 'utils/numberFormat'
import { Loader } from 'shared/components'
import { HyperDiv } from '../../../../components/HyperCopy/HyperDiv'

type Props = {
  data: any
  loading: boolean
  isComparative: boolean
  withAnimations?: boolean
}
// Sorted score 1, 2, 3, 4, 5, Null
const COLORS = [
  '#d55e00',
  '#e9a207',
  '#f0e442',
  '#7fc65c',
  '#009e73',
  '#0085d1',
]
const formatter = new Intl.NumberFormat()

export const PostsSummary: React.FC<Props> = ({
  data,
  loading,
  isComparative,
  withAnimations = true,
}) => {
  const [chartAPI, setChartAPI] = useState<Chart | null>(null)
  const chartRef = useRef<HTMLCanvasElement>(null)
  const [isChartHidden, setIsChartHidden] = useState(false)

  const getPercent = (percentData: any[], value: number) => {
    return percentData.find((item) => item.volume === value).percent
  }

  useEffect(() => {
    if (data) {
      if (chartRef.current) {
        const scores = data?.scores as any
        setIsChartHidden(
          Object.values(scores).reduce((a: any, b: any) => a + b.volume, 0) ===
            0
        )
        const percentData: any[] = []
        const values: number[] = []
        const labels: string[] = []

        Object.keys(scores)
          .sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
          })
          .forEach((key: string) => {
            if (key.toLowerCase() !== 'null') {
              values.push(scores[key].volume)
              percentData.push(scores[key])
              labels.push(
                key === '1'
                  ? key + ' Star'
                  : key === 'Null'
                  ? key
                  : key + ' Stars'
              )
            }
          })

        const dataObject: ChartData = {
          datasets: [
            {
              data: values,
              backgroundColor: COLORS,
            },
          ],
          labels: labels,
        }
        const options: any = {
          responsive: true,
          borderWidth: 1,
          layout: {
            padding: {
              left: 30,
              right: 30,
              top: 10,
              bottom: 0,
            },
          },
          plugins: {
            datalabels: {
              formatter: function (value: any) {
                if (getPercent(percentData, value) < 4) return ''
                return (
                  numberFormatter(value) +
                  '\n' +
                  getPercent(percentData, value) +
                  '%'
                )
              },
              display: 'auto',
              color: '#000000',
            },
            legend: {
              reverse: true,
              position: 'bottom',
              onHover: function (e: any) {
                e.native.target.style.cursor = 'pointer'
              },
              onLeave: function (e: any) {
                e.native.target.style.cursor = 'default'
              },
            },
            tooltip: {
              callbacks: {
                label: () => '',
                afterBody: function (t: any) {
                  const index = t[0].dataIndex
                  if (index === undefined) return ''
                  const volume = t[0].dataset.data[index]

                  return `${labels[index]}\nReviews: ${numberFormatter(
                    volume
                  )}\n% Volume: ${getPercent(percentData, volume)}%`
                },
              },
            },
          },
        }

        if (!withAnimations) {
          options.animation = false
        }

        if (!chartAPI) {
          const ch = new Chart(chartRef.current, {
            type: 'pie',
            data: dataObject,
            options: options,
          })

          setChartAPI(ch)
        } else {
          chartAPI.options = options
          chartAPI.data = dataObject

          chartAPI.update()
        }
      }
    }
  }, [data])
  return (
    <Wrapper
      id="summary-post"
      style={{
        flexDirection: isComparative ? 'row' : 'column',
      }}
    >
      <Card elementName={'posts-summary'}>
        <div>
          <Title>
            Number of <br /> Reviews & Ratings
          </Title>
          <PostsCount>{formatter.format(data?.n_posts || 0)}</PostsCount>
          <TitleDivider />
          <Title>Avg Rating</Title>
          <PostsCount>
            {data?.n_rating_avg || data?.n_rating_avg === 0
              ? formatter.format(data?.n_rating_avg)
              : 'No Value'}
          </PostsCount>
        </div>
        <div>
          <Title>
            Number of <br /> Sentences
          </Title>
          <PostsCount>{formatter.format(data?.n_fragments || 0)}</PostsCount>
          <TitleDivider />
          <Title>Avg Sentiment</Title>
          <PostsCount>
            {data?.n_sentiment_avg || data?.n_sentiment_avg === 0
              ? formatter.format(data?.n_sentiment_avg)
              : 'No Value'}
          </PostsCount>
        </div>
      </Card>

      <Card
        elementName={'posts-summary-chart'}
        style={{ display: isComparative || isChartHidden ? 'none' : 'block' }}
      >
        <div>
          <Title>Rating Breakdown</Title>

          {data && data.n_posts === 0 && (
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                fontStyle: 'italic',
                color: 'rgba(0, 0, 0, 0.45)',
                position: 'absolute',
                top: '45%',
                left: 0,
              }}
            >
              No sentences for selected filters
            </p>
          )}
          <ChartBody>
            <canvas
              ref={chartRef}
              style={{
                width: 300,
                height: 200,
                visibility: data?.n_posts === 0 ? 'hidden' : 'visible',
              }}
            />
          </ChartBody>
        </div>
      </Card>

      {loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </Wrapper>
  )
}

const Card = styled(HyperDiv)`
  padding: var(--default-padding-half);
  background: #fff;
  box-shadow: var(--default-box-shadow);
  margin-bottom: var(--default-padding-half);
  position: relative;
  border-radius: var(--border-radius);
  display: flex;
  width: 100%;
  height: fit-content;

  & > div {
    flex: 1;
  }
`
const Title = styled.div`
  font-size: var(--font-size-m);
  text-align: center;
`
const TitleDivider = styled.div`
  margin-bottom: var(--default-padding-half);
`

const PostsCount = styled.div`
  font-size: var(--font-size-l);
  font-weight: bold;
  text-align: center;
`
const ChartBody = styled.div`
  position: relative;
  margin-left: -16px;
  display: block;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
