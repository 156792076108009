import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  max-width: 350px;
  overflow: auto;
  min-width: 250px;
  padding-bottom: 20px;
`

export const InsightsListItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
`
