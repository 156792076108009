import React from 'react'
import styled from 'styled-components'
import { useSortable } from '@dnd-kit/sortable'
import {
  lightText,
  superLightGrey,
} from '../../../../../../assets/styles/variables'

type Props = {
  children: React.ReactNode
  onClick: () => void
  isSelected: boolean
  isApplied: boolean
  id: string
  disabled?: boolean
}

type ItemProps = {
  selected: boolean
  applied: boolean
  sort_transform: import('@dnd-kit/utilities').Transform | null
  transition: string | null
  disabled?: boolean
}

export const FilterPanelListItem: React.FC<Props> = React.memo(
  ({ children, onClick, isSelected, isApplied, id, disabled }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: id })

    return (
      <Item
        onClick={onClick}
        ref={setNodeRef}
        id={id}
        selected={isSelected}
        applied={isApplied}
        sort_transform={transform}
        transition={transition}
        {...attributes}
        {...listeners}
        disabled={disabled}
      >
        {children}
      </Item>
    )
  }
)

const Item = styled.div`
  cursor: pointer;
  user-select: none;
  padding: 12px 20px 12px 24px;
  background: ${({ selected }: ItemProps) => (selected ? '#e7edf0' : '#fff')};
  font-weight: ${({ applied }: ItemProps) => (applied ? '600' : '400')};
  font-size: 14px;
  border-bottom: 1px solid #e7edf0;
  display: flex;
  justify-content: space-between;
  opacity: ${({ disabled }: ItemProps) => (disabled ? '.5' : '')};

  &:hover {
    background: #e7edf0;
  }
`
