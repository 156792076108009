import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Collapse, Table, Button, Select } from 'antd'
import { useQuery } from 'react-query'
import mixpanel from 'features/trackers/mixpanel'
import { Loader } from 'shared/components'
import { fetchRecommendations, Recommendation } from './model'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useProject } from '../../hooks/useProject'
import { fetchProjectsByGroup } from '../../../home/model'
import { useGroupProjectList } from '../../hooks/useGroupProjectList'

const { Panel } = Collapse
const columns = [
  {
    title: 'Question',
    dataIndex: 'question',
    key: 'question',
  },
  {
    title: 'Insight',
    dataIndex: 'insight',
    key: 'insight',
  },
  {
    title: 'Recommendation',
    dataIndex: 'recommendation',
    key: 'recommendation',
  },
]

export const Recommendations = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const [selectedProject, setSelectedProject] = useState('')
  const activeProject = selectedProject ? selectedProject : projectId

  const { data: details } = useProject(projectId)
  const { data, isLoading } = useQuery(['recommendations', activeProject], () =>
    fetchRecommendations(activeProject)
  )

  const { data: groupProjects } = useGroupProjectList(projectId)

  useEffect(() => {
    mixpanel.track('reports page')
  }, [])

  if (isLoading) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading ${
          details?.clientSettings?.project?.pages?.recommendations?.alias ??
          'Reports'
        } Page`}</LoaderTitle>
      </>
    )
  }

  return (
    <Wrapper>
      {groupProjects?.data?.projects?.length &&
        groupProjects?.data?.projects?.length > 1 && (
          <Select
            size="large"
            value={selectedProject}
            onChange={(e) => setSelectedProject(e)}
            style={{ marginBottom: '20px', minWidth: '200px' }}
          >
            <Select.Option value={''}>{'All Datasets'}</Select.Option>
            {groupProjects.data.projects.map((project) => (
              <Select.Option key={project.uuid} value={project.uuid}>
                {project.title}
              </Select.Option>
            ))}
          </Select>
        )}
      {data &&
        Object.keys(data).map((key) => (
          <span key={key}>
            {!selectedProject && (
              <h2>
                {
                  groupProjects?.data.projects.find((el) => el.uuid === key)
                    ?.title
                }
              </h2>
            )}
            <Collapse>
              {data[key].length &&
                data[key].map((item, index) => {
                  return (
                    <Panel header={item.title} key={index}>
                      {item.recommendations.length > 0 && (
                        <Table
                          bordered
                          dataSource={item.recommendations}
                          columns={columns}
                          pagination={false}
                          style={{ whiteSpace: 'pre-wrap' }}
                        />
                      )}
                      <Buttons>
                        {item.files.map((file) => (
                          <Button
                            onClick={() => {
                              window.open(file.url)
                              mixpanel.track('report file', {
                                action: 'download',
                                value: item.title,
                              })
                            }}
                            key={file.filename}
                          >
                            {file.filename}
                          </Button>
                        ))}
                      </Buttons>
                    </Panel>
                  )
                })}
            </Collapse>
          </span>
        ))}
      {data && !Object.keys(data)?.length && (
        <TextWrapper>
          <Text>
            <h2>No information found.</h2>
            <div>
              Please reach out to your Yogi Customer Success rep for access to
              your information, or to{' '}
              <a href="mailto:support@meetyogi.com">support@meetyogi.com</a> to
              learn about the personalized insights we can generate for you.
            </div>
          </Text>
        </TextWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: var(--default-padding);
`
const Buttons = styled.div`
  margin: var(--default-padding-half) 0;

  & > button {
    margin-right: var(--default-padding-half);
    margin-bottom: var(--default-padding-half);
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: rgba(255, 255, 255, 0.85);
  //position: absolute;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const Text = styled.div`
  max-width: 600px;
  text-align: center;
`

const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const SelectBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin: 0 10px;
`

// import React, { useEffect, useState } from 'react'
// import styled from 'styled-components'
// import { Collapse, Table, Button, Select, Modal } from 'antd'
// import { useQuery } from 'react-query'
// import mixpanel from 'features/trackers/mixpanel'
// import { Loader } from 'shared/components'
// import { fetchRecommendations, Recommendation } from './model'
// import { useProjectStore } from '../../projectStore/projectStore'
// import { ProjectState } from '../../types'
// import { useProject } from '../../hooks/useProject'
// import { fetchProjectsByGroup } from '../../../home/model'
// import { useGroupProjectList } from '../../hooks/useGroupProjectList'
// import { useHistory } from 'react-router'
// import { toast } from 'react-toastify'
// import { YogiButton } from '../../../../components/UI/YogiButton'
//
// const { Panel } = Collapse
// const columns = [
//   {
//     title: 'Question',
//     dataIndex: 'question',
//     key: 'question',
//   },
//   {
//     title: 'Insight',
//     dataIndex: 'insight',
//     key: 'insight',
//   },
//   {
//     title: 'Recommendation',
//     dataIndex: 'recommendation',
//     key: 'recommendation',
//   },
// ]
//
// export const Recommendations = () => {
//   const projectId = useProjectStore((state: ProjectState) => state.projectId)
//   const [selectedProject, setSelectedProject] = useState('')
//   const activeProject = selectedProject ? selectedProject : projectId
//   const [activeFile, setActiveFile] = useState(
//     new URLSearchParams(window.location.search).get('file')
//   )
//   const [activeFileTitle, setActiveFileTitle] = useState('')
//
//   const { data: details } = useProject(projectId)
//   const { data, isLoading } = useQuery(['recommendations', activeProject], () =>
//     fetchRecommendations(activeProject)
//   )
//
//   const history = useHistory()
//   const { data: groupProjects } = useGroupProjectList(projectId)
//
//   useEffect(() => {
//     mixpanel.track('reports page')
//     if (activeFile) {
//       mixpanel.track('report file', {
//         proj_uuid: projectId,
//         action: 'view from link',
//         value: activeFileTitle,
//       })
//     }
//   }, [])
//
//   const Header = (item: Recommendation) => {
//     return (
//       <div>
//         <div style={{ display: 'flex' }}>
//           <h2 style={{ fontWeight: '400' }}>{item.title} - </h2>
//         </div>
//       </div>
//     )
//   }
//
//   useEffect(() => {
//     let searchParams = new URLSearchParams(window.location.search)
//     if (activeFile) {
//       searchParams.set('file', activeFile)
//       history.replace(window.location.pathname + '?' + searchParams.toString())
//     } else {
//       history.replace(window.location.pathname)
//     }
//   }, [activeFile])
//
//   if (isLoading) {
//     return (
//       <>
//         <Loader />
//         <LoaderTitle>{`Loading ${
//           details?.clientSettings?.project?.pages?.recommendations?.alias ??
//           'Reports'
//         } Page`}</LoaderTitle>
//       </>
//     )
//   }
//
//   return (
//     <Wrapper>
//       {groupProjects?.data?.projects?.length &&
//         groupProjects?.data?.projects?.length > 1 && (
//           <Select
//             size="large"
//             value={selectedProject}
//             onChange={(e) => setSelectedProject(e)}
//             style={{ marginBottom: '20px', minWidth: '200px' }}
//           >
//             <Select.Option value={''}>{'All Datasets'}</Select.Option>
//             {groupProjects.data.projects.map((project) => (
//               <Select.Option key={project.uuid} value={project.uuid}>
//                 {project.title}
//               </Select.Option>
//             ))}
//           </Select>
//         )}
//       {data &&
//         Object.keys(data).map((key) => (
//           <span key={key}>
//             {!selectedProject && (
//               <h2>
//                 {
//                   groupProjects?.data.projects.find((el) => el.uuid === key)
//                     ?.title
//                 }
//               </h2>
//             )}
//             <Collapse>
//               {data[key].length &&
//                 data[key].map((item, index) => {
//                   return (
//                     <Panel header={item.title} key={index}>
//                       {item.recommendations.length > 0 && (
//                         <Table
//                           bordered
//                           dataSource={item.recommendations}
//                           columns={columns}
//                           pagination={false}
//                           style={{ whiteSpace: 'pre-wrap' }}
//                         />
//                       )}
//                       <Buttons>
//                         {item.files.map((file) => (
//                           <>
//                             <Button
//                               onClick={() => {
//                                 // window.open(file.url)
//                                 setActiveFile(file.url)
//                                 setActiveFileTitle(file.filename)
//                                 mixpanel.track('report file', {
//                                   proj_uuid: projectId,
//                                   action: 'view',
//                                   value: file.filename,
//                                 })
//                               }}
//                               key={file.filename}
//                             >
//                               {file.filename}
//                             </Button>
//                             {/*todo throw this in a modal?*/}
//                             {/*<iframe*/}
//                             {/*  src={file.url}*/}
//                             {/*  width="100%"*/}
//                             {/*  height="800px"*/}
//                             {/*/>*/}
//                           </>
//                         ))}
//                       </Buttons>
//                     </Panel>
//                   )
//                 })}
//             </Collapse>
//           </span>
//         ))}
//       {data && !Object.keys(data)?.length && (
//         <TextWrapper>
//           <Text>
//             <h2>No information found.</h2>
//             <div>
//               Please reach out to your Yogi Customer Success rep for access to
//               your information, or to{' '}
//               <a href="mailto:support@meetyogi.com">support@meetyogi.com</a> to
//               learn about the personalized findings we can generate for you.
//             </div>
//           </Text>
//         </TextWrapper>
//       )}
//       <StyledModal
//         visible={!!activeFile}
//         width={'100%'}
//         style={{ paddingBottom: '0px', margin: '0px auto', width: '100vw' }}
//         bodyStyle={{ height: 'calc(100vh - 53px)', marginTop: '-100px' }}
//         onOk={() => {
//           navigator.clipboard.writeText(window.location.href)
//           toast.info('Link copied to clipboard')
//         }}
//         onCancel={() => {
//           setActiveFile('')
//           setActiveFileTitle('')
//         }}
//         footer={[
//           <YogiButton
//             type={'ghost'}
//             onClick={() => {
//               setActiveFile('')
//               setActiveFileTitle('')
//             }}
//           >
//             Close
//           </YogiButton>,
//           <YogiButton
//             type={'primary'}
//             onClick={() => {
//               mixpanel.track('report file', {
//                 proj_uuid: projectId,
//                 action: 'share',
//                 value: activeFileTitle,
//               })
//               navigator.clipboard.writeText(window.location.href)
//               toast.info('Link copied to clipboard')
//             }}
//           >
//             Share
//           </YogiButton>,
//         ]}
//       >
//         <iframe src={activeFile + '#view=FitH'} width="100%" height={'100%'} />
//
//         {/*<embed*/}
//         {/*  src={activeFile + '#view=FitH&toolbar=0'}*/}
//         {/*  width="100%"*/}
//         {/*  height={'100%'}*/}
//         {/*/>*/}
//       </StyledModal>
//     </Wrapper>
//   )
// }
//
// const StyledModal = styled(Modal)`
//   .ant-modal-close-x {
//     width: 28px;
//     line-height: 28px;
//   }
// `
//
// const Wrapper = styled.div`
//   max-width: 1400px;
//   width: 100%;
//   margin: 0 auto;
//   padding: var(--default-padding);
// `
// const Buttons = styled.div`
//   margin: var(--default-padding-half) 0;
//
//   & > button {
//     margin-right: var(--default-padding-half);
//     margin-bottom: var(--default-padding-half);
//   }
// `
//
// const TextWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   //background: rgba(255, 255, 255, 0.85);
//   //position: absolute;
//   justify-content: center;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
// `
//
// const Text = styled.div`
//   max-width: 600px;
//   text-align: center;
// `
//
// const LoaderTitle = styled.div`
//   //margin-top: var(--default-padding);
//   text-align: center;
//   font-size: var(--font-size-l);
//   font-weight: 500;
// `
//
// const SelectBody = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   min-width: 200px;
//   margin: 0 10px;
// `
