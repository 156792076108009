import React, { useRef } from 'react'
import styled from 'styled-components'
import { AlertResponse, getAlerts } from '../model'
import ShareAlert from './ShareAlert'
import ViewAlert from './ViewAlert'
import CreateAlert from './CreateAlert/CreateAlert'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { useQuery } from 'react-query'

type Props = {
  alertData?: AlertResponse
  isShare?: boolean
  isView?: boolean
}
export const AlertDrawer: React.FC<Props> = ({
  alertData,
  isShare,
  isView,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const isAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isAlertDrawerOpen
  )
  const setIsAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsAlertDrawerOpen
  )
  const alertRequest = useProjectStore(
    (state: ProjectState) => state.alertRequest
  )

  const ref = useRef<HTMLDivElement>(null)

  // current config for alerts requires refreshing the list of alerts after create
  const { refetch } = useQuery(
    ['alerts', projectId],
    () => {
      return getAlerts(projectId)
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )

  return (
    <DrawerContainer ref={ref} isOpen={isAlertDrawerOpen}>
      <Wrapper>
        {isShare && alertData ? (
          <ShareAlert
            alertData={alertData}
            setIsOpen={setIsAlertDrawerOpen}
            refetch={refetch}
          />
        ) : (
          <>
            {isView ? (
              <ViewAlert
                key={alertData?.alert?.id}
                alertData={alertData}
                setIsOpen={setIsAlertDrawerOpen}
                refetch={refetch}
              />
            ) : (
              <CreateAlert
                key={alertData?.alert?.id}
                alertData={alertData}
                setIsOpen={setIsAlertDrawerOpen}
                refetch={refetch}
                alertRequest={alertRequest}
              />
            )}
          </>
        )}
      </Wrapper>
    </DrawerContainer>
  )
}

const DrawerContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 100;
  height: 100vh;
  width: 50%;
  min-width: 700px;
  max-width: 800px;
  background: white;
  right: 0px;
  top: 0px;
  margin-top: -40px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0px 0px 100px -20px rgba(50, 50, 93, 0.25),
    -10px 0px 60px -30px rgba(0, 0, 0, 0.3);
  //box-shadow: -16px 0 16px rgba(17, 17, 26, 0.1);
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.25s ease-in-out;
`

const Wrapper = styled.div`
  position: relative;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 100%;
`

const Arrow = styled.div`
  position: absolute;
  z-index: -1;
  top: 45vh;
  left: -40px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: white;
  cursor: pointer;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  box-shadow: -8px 4px 8px rgba(17, 17, 26, 0.1),
    -4px -4px 8px rgba(17, 17, 26, 0.1);
`
