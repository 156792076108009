import React, { useState } from 'react'
import { Modal, Select } from 'antd'
import { Group } from '../types'
import { useClusterContext } from '../index'

type Props = {
  onCreate: (s: string) => void
  onClose: () => void
  isVisible: boolean
}

const { Option } = Select

export const AssignThemeGroup: React.FC<Props> = ({
  isVisible,
  onCreate,
  onClose: onDefaultClose,
}) => {
  const [selectedCluster, setSelectedCluster] = useState<string | undefined>(
    undefined
  )
  const [id, setId] = useState<string | null>(null)
  const { groups } = useClusterContext()

  const onClose = () => {
    onDefaultClose()
  }

  const filteredGroups = [
    ...groups,
  ]

  return (
    <Modal
      visible={isVisible}
      title="Assign to Theme Group"
      onOk={() => {
        onCreate(id ? id : '')
        onClose()
        // if (id) {
        //   onCreate(id ? id : '')
        //   onClose()
        // }
      }}
      onCancel={onClose}
      okText="Assign"
    >
      <Select
        defaultValue="Select a theme group"
        style={{ width: '100%' }}
        value={selectedCluster}
        onChange={(v) => {
          setSelectedCluster(v as string)
          setId(v as string)
        }}
      >
        {filteredGroups.map((group: Group) => (
          <Option key={group.group} value={group.group}>
            {group.group}
          </Option>
        ))}
      </Select>
    </Modal>
  )
}
