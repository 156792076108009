//https://stackoverflow.com/questions/25246472/prevent-css-hover-style-propagation
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../features/project/projectStore/projectStore'
import { ProjectState } from '../../features/project/types'
import { useHyperCopy } from '../../features/project/hooks/useHyperCopy'
import { CheckOutlined, CopyOutlined, LoadingOutlined } from '@ant-design/icons'
import { pageBackgroundGrey } from '../../assets/styles/variables'
import { YogiButton } from '../UI/YogiButton'
import { Copy } from './Copy'
import { Checkbox } from 'antd'
import mixpanel from '../../features/trackers/mixpanel'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  elementName: string
  children: React.ReactNode
  getHighlightedText?: (text: string) => void
  // ref: React.RefObject<HTMLDivElement>
}

export const HyperDiv: React.FC<Props> = ({
  elementName,
  className,
  children,
  style,
  onClick,
  getHighlightedText,
  // ref,
  ...rest
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const isHyperCopy = useProjectStore(
    (state: ProjectState) => state.isHyperCopy
  )
  const [selectedElement, setSelectedElement] = useState<HTMLDivElement | null>(
    null
  )
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (window?.getSelection()?.toString()) {
      // console.log(window?.getSelection()?.toString())
      getHighlightedText &&
        // @ts-ignore
        getHighlightedText(window?.getSelection()?.toString().trim())
    }
  }, [window?.getSelection()?.toString()])

  const { isCopying, lastCopied } = useHyperCopy(
    selectedElement,
    setSelectedElement
  )

  const [copyVisible, setCopyVisible] = useState(false)
  const [copyHovered, setCopyHovered] = useState(false)
  const [showCheck, setShowCheck] = useState(false)

  useEffect(() => {
    if (lastCopied === ref.current) {
      setShowCheck(true)
    }
  }, [lastCopied])

  useEffect(() => {
    if (showCheck && !copyHovered) {
      setShowCheck(false)
      setSelectedElement(null)
    }
  }, [copyHovered])

  return (
    <div
      ref={ref}
      className={
        isHyperCopy
          ? className + ' hyper-copy-active'
          : className + ' hyper-copy'
      }
      style={{ ...style, position: 'relative' }}
      onClick={(e) => {
        if (isHyperCopy) {
          setSelectedElement(ref.current)
          e.stopPropagation()
        } else {
          // @ts-ignore
          onClick && onClick(e)
        }
      }}
      onMouseEnter={() => setCopyVisible(true)}
      onMouseLeave={() => setCopyVisible(false)}
      {...rest}
    >
      {/* TODO generalize this*/}
      {copyVisible && (
        <Copy
          id={'copy-button'}
          data-html2canvas-ignore={'true'}
          onClick={(e) => {
            // setCopyVisible(false)
            setSelectedElement(ref.current)
            mixpanel.track('copy', {
              proj_uuid: projectId,
              value: elementName,
            })
            e.stopPropagation()
          }}
          onMouseEnter={() => {
            setCopyVisible(true)
            setCopyHovered(true)
          }}
          onMouseLeave={() => setCopyHovered(false)}
        >
          {/*TODO show spinner when copying, and disable. also - use yogibutton?*/}

          <YogiButton
            type={'ghost'}
            style={{
              boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
              // background: 'rgba(255, 255, 255, 0.4)',
              opacity: copyHovered ? '1' : '.4',
              padding: '4px 8px',
            }}
          >
            {isCopying ? (
              <LoadingOutlined />
            ) : showCheck ? (
              <CheckOutlined />
            ) : (
              <CopyOutlined />
            )}
          </YogiButton>
        </Copy>
      )}
      {children}
    </div>
  )
}
