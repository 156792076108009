import { useQuery } from 'react-query'
import { fetchDashboard } from '../features/charts/model'
import { useProjectStore } from '../projectStore/projectStore'
import { Filters } from '../types'
import { PostType, TimeInterval } from '../features/charts/types'
import _ from 'lodash'

export const useChartData = (
  comparativeIndex: number,
  filterValues: Filters | undefined,
  tier1: string | undefined,
  chartId: string | undefined,
  postType: PostType | undefined,
  timeInterval: TimeInterval | undefined,
  reviewsCount: number | undefined,
  disabled?: boolean
) => {
  const projectId = useProjectStore((state) => state.projectId)
  const details = useProjectStore((state) => state.details)
  const hasLoadedFilters = useProjectStore((state) => state.hasLoadedFilters)

  const sortedFilterValues = _.cloneDeep(filterValues)

  // This step prevents cache misses due to the order of the filters
  sortedFilterValues?.values?.sort((a, b) => a.field.localeCompare(b.field))
  sortedFilterValues?.values?.forEach((filter) => {
    if ('values' in filter) {
      filter?.values?.sort((a, b) => a.localeCompare(b))
    }
  })

  return useQuery(
    [
      'charts',
      projectId,
      sortedFilterValues,
      chartId,
      tier1,
      postType,
      timeInterval,
      reviewsCount,
    ],
    async () => {
      const query = {
        tier1: tier1,
        chart_ids: chartId ? [{ chart_id: chartId }] : [],
        criteria: sortedFilterValues?.values || [],
        search_terms: sortedFilterValues?.searchQuery || [],
        search_criteria: sortedFilterValues?.searchCondition || [],
        post_type: postType,
        time_interval:
          timeInterval ||
          details?.clientSettings.project.pages.dashboard.chart_interval,
        reviews_filter: reviewsCount || 0,
      }

      const response = await fetchDashboard(projectId, query)
      return response
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters && !disabled,
    }
  )
}
