import React, { createContext } from 'react'
import { useGraph } from './useGraph'
import { GraphContext as GraphContextType } from './types'

type Props = { children: React.ReactNode }

const GraphContext = createContext<GraphContextType | undefined>(undefined)

export const GraphProvider: React.FC<Props> = ({ children }) => {
  const value = useGraph()
  return <GraphContext.Provider value={value}>{children}</GraphContext.Provider>
}

export const useGraphContext = () => {
  const context = React.useContext(GraphContext)
  if (!context) {
    throw new Error('useGraphContext must be used within a GraphProvider.')
  }
  return context
}
