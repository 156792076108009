import { DashboardFilterHeader } from '../components/DashboardFilterHeader'
import { Grid } from '@mui/material'
import { Chart } from '../Charts/chart'
import { DisplayWidget } from '../components/DisplayWidget'
import { TextWidget } from '../components/TextWidget'
import React, { type FC } from 'react'
import { useQuery } from 'react-query'
import { generateDashboard } from '../../../model'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { LogoSpinner } from '../../../../../../../components/LogoSpinner'
import styled from 'styled-components'

type Props = {
  filterValues: any
  setFilters: any
  isPublic: boolean
  updateItem: any
  addToRefs: any
  category: string
  value: string
  theme: string
}

const DashboardViewContent: FC<Props> = ({
  filterValues,
  setFilters,
  isPublic,
  updateItem,
  addToRefs,
  category,
  value,
  theme,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const comparativeIndex = 0
  const rowHeight = 50

  // TODO - possible to have selectedtheme in a product_hierarchy dashboard causing cache misses
  const { data: dashboard, isLoading } = useQuery(
    ['generate-dashboard', projectId, category, value, theme],
    () => {
      if (!projectId || !value || (category === 'brand' && !theme)) {
        return Promise.resolve(undefined)
      }
      return generateDashboard(projectId, category, value, theme)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled:
        !!projectId &&
        (category !== 'brand' || (category === 'brand' && !!theme)),
    }
  )

  if (isLoading) {
    return (
      <LoadingWrapper>
        <LogoSpinner>{`Loading Dashboard...`}</LogoSpinner>
      </LoadingWrapper>
    )
  }

  return (
    <>
      {dashboard && (
        <DashboardFilterHeader
          filterValues={dashboard.filters}
          setFilters={setFilters}
          comparativeIndex={0}
          disabled={true}
          isCurrent={dashboard?.isCurrent ?? false}
          timeResolution={dashboard?.timeResolution ?? 'Month'}
          trailingDaysCount={dashboard?.trailingDaysCount}
        />
      )}
      <Grid container style={{ padding: 10 }} spacing={1}>
        {dashboard?.items.map((item: any, index: number) => {
          if (item.type === 'chart') {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={dashboard.layout[index].w}
                style={{
                  // react-grid-layout adds 10px to the height of each row (except one)
                  height: (rowHeight + 10) * dashboard.layout[index].h - 10,
                  position: 'relative',
                }}
              >
                {/*TODO should this be DisplayChart imported from /charts?*/}
                <Chart
                  ref={addToRefs}
                  filters={filterValues}
                  chartId={
                    item.dashboardControls.chartId ??
                    item.content.chart_ids[0].chart_id
                  }
                  comparativeIndex={comparativeIndex}
                  isComparative={false}
                  dashboardControls={item.dashboardControls}
                  tier1={item.dashboardControls.tier1 ?? item.content.tier1}
                  isEditing={false}
                  item={item}
                  index={index.toString()}
                  updateItem={updateItem}
                  isLink={!isPublic}
                  disableControls={true}
                />
              </Grid>
            )
          } else if (item.type === 'widget') {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={dashboard.layout[index].w}
                style={{
                  height: (rowHeight + 10) * dashboard.layout[index].h - 10,
                  position: 'relative',
                }}
              >
                <DisplayWidget
                  ref={addToRefs}
                  name={item.name}
                  filters={filterValues}
                  item={item}
                  index={index.toString()}
                  updateItem={updateItem}
                  layout={dashboard.layout[index]}
                  dashboardControls={item.dashboardControls}
                  disabled={true}
                />
              </Grid>
            )
          } else if (item.type === 'text') {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={dashboard.layout[index].w}
                style={{
                  height: (rowHeight + 10) * dashboard.layout[index].h - 10,
                  position: 'relative',
                }}
              >
                <TextWidget
                  ref={addToRefs}
                  item={item}
                  index={index.toString()}
                />
              </Grid>
            )
          }
        })}
      </Grid>
    </>
  )
}
export default DashboardViewContent

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: white;
  border-radius: 8px;
  margin: 10px 20px;
`
