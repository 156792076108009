import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Button, Collapse } from 'antd'
import Markdown from 'marked-react'
import { Loader } from 'shared/components'

import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { getOutlierAnalysis } from '../model'
import { pageBackgroundGrey } from '../../../../../assets/styles/variables'
import { constructThemeOutlierPrompt } from '../utils'
import KeyPhrase from './KeyPhrase'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import AIcon from '../../../../../assets/images/icons/AIcon_180x180.png'

type OutlierAnalysisProps = {
  outlier: {
    data: Array<{ body: string }>
    weighted_zscore: number
    theme: string
    product: string
  }
  model: string
  threshold: number
  isAnalysisOpen: boolean
  setIsAnalysisOpen: (value: boolean) => void
}

const { Panel } = Collapse

const OutlierAnalysis: React.FC<OutlierAnalysisProps> = ({
  outlier,
  model,
  threshold,
  isAnalysisOpen,
  setIsAnalysisOpen,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const reviews = outlier.data.map((el) => el.body).filter(Boolean)

  const prompt = constructThemeOutlierPrompt(outlier)

  const {
    data: analysis,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['analysis', projectId, reviews, prompt, model],
    () => getOutlierAnalysis(projectId, reviews, prompt, model),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    }
  )

  const analysisIsValidJSON = useMemo(() => {
    if (!analysis) return false
    try {
      JSON.parse(analysis)
      return true
    } catch (error) {
      return false
    }
  }, [analysis])

  return (
    <Container>
      <Content>
        <YogiButton
          type={isAnalysisOpen ? 'default' : 'primary'}
          onClick={() => setIsAnalysisOpen(!isAnalysisOpen)}
          style={{ marginBottom: 10 }}
        >
          <img
            style={{
              height: '100%',
              marginRight: 5,
            }}
            src={AIcon}
          />
          {isAnalysisOpen
            ? 'Return to Outlier Overview'
            : 'View Full Yogi AI Analysis'}
        </YogiButton>
        {isLoading || isFetching ? (
          <LoaderWrapper>
            <Loader style={{ height: 100 }} />
          </LoaderWrapper>
        ) : (
          <>
            {analysis && analysisIsValidJSON && JSON.parse(analysis) && (
              <AnalysisContainer>
                <Markdown>{'## ' + JSON.parse(analysis).header}</Markdown>
                {JSON.parse(analysis).body.map(
                  (finding: any, index: number) => (
                    <AnalysisSection>
                      {/*<Markdown>{'### ' + finding.title}</Markdown>*/}
                      <KeyPhrase
                        key={finding.title}
                        phrase={finding.title}
                        products={outlier.product ? [outlier.product] : []}
                        // themes={outlier.theme ? [outlier.theme] : []}
                        threshold={threshold}
                      />
                      <Markdown>{finding.explanation}</Markdown>
                      <Markdown>{finding.evidence}</Markdown>
                      <Markdown>{finding.implication}</Markdown>
                    </AnalysisSection>
                  )
                )}
                {/*<Collapse>*/}
                {/*  {JSON.parse(analysis).body.map(*/}
                {/*    (finding: any, index: number) => (*/}
                {/*      <Panel header={finding.title} key={index}>*/}
                {/*        <Markdown>{finding.explanation}</Markdown>*/}
                {/*        // evidence will accept multiple quotes*/}
                {/*        <Markdown>{finding.evidence}</Markdown>*/}
                {/*        <Markdown>{finding.implication}</Markdown>*/}
                {/*      </Panel>*/}
                {/*    )*/}
                {/*  )}*/}
                {/*</Collapse>*/}
              </AnalysisContainer>
            )}
            <Collapse style={{ maxHeight: 400, overflow: 'auto' }}>
              <Panel header={`Reviews (${reviews.length})`} key="reviews">
                {reviews.map((review, index) => (
                  <ReviewItem key={index}>{review}</ReviewItem>
                ))}
              </Panel>
            </Collapse>
          </>
        )}
      </Content>
      <ButtonWrapper>
        <Button onClick={() => refetch()}>Refetch</Button>
      </ButtonWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  margin-bottom: 10px;
`

const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ReviewItem = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: ${pageBackgroundGrey};
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  margin: 10px 0;
`

const AnalysisContainer = styled.div`
  max-height: 800px;
  overflow: auto;
`

const AnalysisSection = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  box-shadow: var(--default-box-shadow);
  background: ${pageBackgroundGrey};
`

export default OutlierAnalysis
