import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useQuery, useQueryClient } from 'react-query'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { fetchSharedUsers } from '../../../share/model'
import { AlertBody, AlertType, saveAlert, updateAlert } from '../../model'
import { Loader } from '../../../../../../shared/components'
import mixpanel from '../../../../../trackers/mixpanel'
import AlertDefinition from './components/AlertDefinition'
import AlertCriteria from './components/AlertCriteria'
import AlertRecipients from './components/AlertRecipients'
import AlertName from './components/AlertName'
import { CreateAlertProps } from './types'
import {
  Container,
  Form,
  FormWrapper,
  Body,
  Footer,
  Section,
  SubmitButton,
  Overlay,
  Header,
} from './CreateAlert.styles'
import _ from 'lodash'
import { Filters } from '../../../../types'
import { emptyFilters } from '../../../../utils'
import { conditionOptionsList, defaultValues } from '../../constants'
import { Typography } from 'antd'
const { Title } = Typography

const CreateAlert: React.FC<CreateAlertProps> = ({
  alertData = { alert: undefined, owners: [], viewers: [] },
  setIsOpen,
  refetch,
  alertRequest,
}) => {
  const { alert, owners, viewers } = alertData
  const projectId = useProjectStore((state) => state.projectId)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filterValues, setFilterValues] = useState<Filters>(
    alertRequest ? alertRequest : _.cloneDeep(emptyFilters)
  )
  const [selectedTriggerCategory, setSelectedTriggerCategory] = useState(
    conditionOptionsList.find((el) => el.value === alert?.trigger_type)?.type ??
      'outlier'
  )
  const [recipients, setRecipients] = useState<string[]>(
    viewers.map((el) => el.email)
  )

  const queryClient = useQueryClient()

  const { data: sharedUsers } = useQuery(
    `shared-users-${projectId}`,
    () => fetchSharedUsers(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  )

  // would love to do this without useEffect - but currently updating alertRequest doesn't update filterValues
  // maybe it could be a key in CreateAlert?
  useEffect(() => {
    let currentFilters = _.cloneDeep(emptyFilters)
    if (alert) {
      currentFilters.values = alert.filters
      currentFilters.searchQuery = alert.search_terms
      currentFilters.searchCondition = alert.search_criteria
    } else if (alertRequest) {
      currentFilters = alertRequest
    }
    setFilterValues(currentFilters)
  }, [alert, alertRequest])

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useForm<AlertType>({
    defaultValues: alert || defaultValues,
  })

  const onSubmit = async (data: AlertType) => {
    setIsSubmitting(true)
    const alertData: AlertBody = {
      ...data,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
      filters: filterValues.values,
      shared_users: recipients,
      status: 'Live',
    }
    let message = 'Alert Created'
    try {
      let newAlert
      if (alert) {
        mixpanel.track('alerts', { action: 'update', value: alert?.id })
        message = 'Alert Updated'
        newAlert = await updateAlert(projectId, { ...alert, ...alertData })
      } else {
        mixpanel.track('alerts', {
          action: 'create',
          type: 'submit',
          value: 'new',
        })
        newAlert = await saveAlert(projectId, alertData)
      }
      await refetch()
      // queryClient.setQueryData(['alerts', projectId], (alerts: any) => {
      //   if (alerts) {
      //     return {
      //       ...oldData.alerts.map((alert: any) => {
      //         if (alert.id === newAlert.id) {
      //           return newAlert
      //         }
      //         return alert
      //       }),
      //     }
      //   }
      //   return [newAlert]
      // })
      setIsSubmitting(false)
      setIsOpen(false)
      reset()
      setFilterValues(_.cloneDeep(emptyFilters))
      setRecipients([])
      setSelectedTriggerCategory('outlier')
      toast.success(message)
    } catch (e) {
      toast.error(`Error ${alert ? 'updating' : 'creating'} alert`)
    }
  }

  function handleResetForm() {
    reset()
    setFilterValues(_.cloneDeep(emptyFilters))
    setRecipients([])
    setSelectedTriggerCategory('outlier')
  }

  return (
    <Container>
      <Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Title level={4}>
            {alertData.alert ? 'Edit Alert' : 'Create Alert'}
          </Title>
          <Title
            level={4}
            onClick={() => {
              // TODO reset form if edit?
              setIsOpen(false)
              handleResetForm()
            }}
            style={{ fontWeight: 600, cursor: 'pointer', marginTop: 0 }}
          >
            X
          </Title>
        </div>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <Body>
            <AlertDefinition
              filterValues={filterValues}
              setFilterValues={setFilterValues}
            />
            <AlertCriteria
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              selectedTriggerCategory={selectedTriggerCategory}
              setSelectedTriggerCategory={setSelectedTriggerCategory}
              trigger={trigger}
            />
          </Body>
          <Footer>
            <AlertName control={control} errors={errors} />
            <AlertRecipients
              recipients={recipients}
              setRecipients={setRecipients}
              sharedUsers={sharedUsers}
              owners={owners}
            />
            <Section
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                width: '100%',
              }}
            >
              <SubmitButton type={'submit'}>Create Alert</SubmitButton>
            </Section>
          </Footer>
        </FormWrapper>
      </Form>
      {isSubmitting && (
        <Overlay>
          <Loader paddingTop={false} style={{ top: -100 }}>
            {alert ? 'Updating' : 'Creating'} Alert...
          </Loader>
        </Overlay>
      )}
    </Container>
  )
}

export default CreateAlert
