import React from 'react'
import styled from 'styled-components'
import LoadingShperesIcon from 'assets/images/LoadingSpheres.svg'

type Props = { paddingTop?: string }
export const Fetcher: React.FC<Props> = ({ paddingTop = '' }) => (
  <LoaderWrapper paddingTop={paddingTop}>
    <Spheres src={LoadingShperesIcon} />
  </LoaderWrapper>
)

const LoaderWrapper = styled.div`
  padding-top: ${({ paddingTop }: { paddingTop: string }) =>
    paddingTop ? paddingTop : '0'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
`
const Spheres = styled.img`
  animation: spin 1s linear infinite;
  animation-direction: normal;
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
`
const Face = styled.img`
  width: 50px;
  position: absolute;
  left: calc(50% - 25px);
  ${({ paddingTop }: { paddingTop: boolean }) =>
    paddingTop ? 'top: 50%;' : ''};
`
