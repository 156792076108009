// COLORS
export const cardBorderGrey = '#EFF0F6'
export const pageBackgroundGrey = '#FCFAFA'
export const darkBlue = '#28364F'
export const buttonBlue = '#4070c0'
export const buttonHover = '#2d5dac'

// BRAND COLORS
export const primary = '#28364F'
export const secondary = '#FAB49B'
export const accent = '#9FB7C3'
export const offWhite = '#FCFAFA'
export const fieldBackground = '#9FB7C3'
export const mainText = '#343A40'
export const lightText = '#8A8C8F'

// SUPPLEMENTARY COLORS
export const secondaryNavy = '#5E7CB0'
export const secondaryNavyHover = '#4c6891'
export const secondaryRed = '#CD6C5C'
export const secondaryGreen = '#1DBD91'
export const tertiaryGreen = '#2e9376'
export const secondaryOrange = '#FFC46B'
export const secondaryYellow = '#FFE135'
export const secondaryBlue = '#3582F5'
export const superLightGrey = '#9fb7c31a'

// OTHER COLORs
export const starYellow = '#EBCB13'
export const errorRed = '#ff9685'
export const newRed = '#ea3920'

// YDS 2024
export const cardBorder = '#d8d8d8'
export const cardBoxShadow = '0px 4px 10px 0px #00000026'
export const toggleBorderSelected = '#d3d3d3'
export const toggleBorderUnselected = '#F0F6FF'
export const lightBlueFontColor = '#5072a4'
export const lightBlueFontColorHalfOpacity = '#A7B8D1'
export const selectableCardBackgroundSelected = '#5072a4'
export const selectableCardBackgroundNotSelected = '#F2F6FD'
export const selectableCardBorderSelected = '#486794'
export const selectableCardBorderNotSelected = '#E7EAEF'
export const greyBackgroundAlternate = '#f5f3f3'
export const newButtonBlue = '#3F70BF'
export const deselectedFontColor: string = '#A7B6CF'
export const selectedFontColor: string = '#28364F'

export const darkBlueFontColor = '#28364F'
