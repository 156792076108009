import {
  GEMINI_CHART,
  OUTLIER_ANALYSIS_STREAM_URL,
  OUTLIER_ANALYSIS_URL,
  OUTLIER_BETA_ANALYSIS_URL,
  OUTLIERS_URL,
  SEMANTIC_QUERY,
  TIMESERIES_OUTLIERS_URL,
} from 'utils/apiLinks'
import { API_BASE_URL, axiosInstance } from 'utils/axios'
import { Filters } from '../../types'

export type OutlierResponse = {
  outliers: any[]
  posts: any[]
}

export type OutlierType = {
  data: Array<{ body: string }>
  weighted_zscore: number
  theme?: string
  product?: string
  volume?: number
  date?: string
  window_start_date?: string
}

export type OutlierAnalysisResponse = {
  header: string
  subheader: string
  key_phrases: string[]
  body: string[]
  footer: string
}

export const getOutliers = async (
  proj_uuid: string,
  filterValues: Filters
): Promise<OutlierResponse> => {
  const { data } = await axiosInstance.post(
    OUTLIERS_URL,
    {
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
    },
    { params: { proj_uuid } }
  )
  return data
}
export const getTimeSeriesOutliers = async (
  proj_uuid: string,
  filterValues: Filters
): Promise<OutlierResponse> => {
  const { data } = await axiosInstance.post(
    TIMESERIES_OUTLIERS_URL,
    {
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
    },
    { params: { proj_uuid } }
  )
  return data
}

export const getOutlierAnalysis = async (
  proj_uuid: string,
  reviews: any[],
  user_prompt: string,
  model: string
): Promise<string> => {
  const { data } = await axiosInstance.post(
    OUTLIER_ANALYSIS_URL,
    {
      prompt: user_prompt,
      reviews,
      model,
    },
    { params: { proj_uuid } }
  )
  return data
}

export const getOutlierAnalysisStream = async (
  proj_uuid: string,
  reviews: any[],
  user_prompt: string,
  model: string,
  token: string
): Promise<ReadableStream<Uint8Array>> => {
  // const authToken =
  //   'eyJraWQiOiIxRExaNFk0MWZNSk1HNkVTSk44dzI4YkQ4K3VEUlplUXByd0ZtbHBMVDdNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhMzU0ZmViNC04MzI1LTRhYzktYmJkMS04MTFiZjI1NDViODkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfWVFWVFk2TXZZIiwiY29nbml0bzp1c2VybmFtZSI6ImEzNTRmZWI0LTgzMjUtNGFjOS1iYmQxLTgxMWJmMjU0NWI4OSIsImdpdmVuX25hbWUiOiJUb21teSIsImF1ZCI6IjIyODdtaDg4MmJrZ2NnNGxpcXFsMzJhdjBlIiwiZXZlbnRfaWQiOiIxMGFkNjczYS0zNDUyLTRmYTMtOTFjZC03YzMyYTA1YWY5YzIiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcyMDczNTQyNywiZXhwIjoxNzIwODI1MjE1LCJpYXQiOjE3MjA4MjE2MTUsImZhbWlseV9uYW1lIjoiU2hhZmVyIiwiZW1haWwiOiJ0b21teS5zaGFmZXJAbWVldHlvZ2kuY29tIn0.By_kzPG2JQ736r4p0OkBw0n7lTUG_vuVGRWYXWUiml7BRpaWDIEkWsou8aAZPO0573idQcf8er59DtLcm1SxrUPxxhWDYSsLPpg_E_Z4QOdq5vLkrYND3tr5R-kxZtUJ2e-lcZgM62WDu5l86IdtRd_86jyYsk8qcAdLbZjS88iiGkULpM1d_yv1s0OIuEIx9IfOLZj7-8F-RCiIK8aX6vUjGVHdkxeYaUXSZqglSUTh7bPUp773ev9RpBBKEuh0uoOdeh8ZAciNwWtrS5H98IEv-f47qwIf1tw3GGkabQeIbPDxE940BF44m6JJrFgzfF2zKXbOR3qcIuOMeKaP6g'
  const response = await fetch(
    `${API_BASE_URL}${OUTLIER_ANALYSIS_STREAM_URL}?proj_uuid=${proj_uuid}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
        Authorization: `${token}`, // Add the auth token to the headers
      },
      body: JSON.stringify({
        prompt: user_prompt,
        reviews,
        model,
        // proj_uuid,
      }),
    }
  )

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  if (!response.body) {
    throw new Error('ReadableStream not supported')
  }

  return response.body
}

export const getChartId = async (proj_uuid: string, question: string) => {
  const { data } = await axiosInstance.post(
    GEMINI_CHART,
    {
      question,
    },
    { params: { proj_uuid, question } }
  )
  return data
}

export const getSemanticReferences = async (
  proj_uuid: string,
  query: string,
  brands?: string[],
  products?: string[],
  themes?: string[],
  limit?: number,
  threshold?: number
): Promise<any[]> => {
  const { data } = await axiosInstance.post(
    SEMANTIC_QUERY,
    {
      query,
      brands,
      products,
      themes,
      limit,
      threshold: (threshold ?? 30) / 100,
      pc_index: 'openai-sentence',
    },
    { params: { proj_uuid } }
  )
  return data
}
