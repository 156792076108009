import React from 'react'
import styled from 'styled-components'
import { Button, Form } from 'antd'
import { FormikProps } from 'formik'
import { Loader } from 'shared/components'
import { FormAdditionalValues } from '../types'
import { CardNew } from './card-new'
import { SourceCard } from '../features/source/components'

type Props = {
  title?: string
  isLoading: boolean
  handleOpenSource: () => void
  handleOpenProduct: () => void
  formik: FormikProps<FormAdditionalValues>
  setSourceToEditIndex: (n: number) => void
  setProductToEditIndex: (n: number) => void
}

export const EditProject: React.FC<Props> = ({
  title,
  formik,
  isLoading,
  handleOpenProduct,
  handleOpenSource,
  setSourceToEditIndex,
  setProductToEditIndex,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Form.Item label="Products" wrapperCol={{ span: 16 }}>
        <Sources>
          <SourceCardWrapper onClick={handleOpenProduct}>
            <CardNew text={'Add Product'} />
          </SourceCardWrapper>
          {formik.values.products.map((product, index) => (
            <SourceCardWrapper key={product.id}>
              <SourceCard
                icon={product.name[0]}
                title={product.name}
                onClick={() => {
                  handleOpenProduct()
                  setProductToEditIndex(index)
                }}
              />
            </SourceCardWrapper>
          ))}
        </Sources>
      </Form.Item>
      <Form.Item
        label="Sources"
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sources ? 'error' : ''}
        help={formik.errors.sources}
      >
        <Sources>
          <SourceCardWrapper onClick={handleOpenSource}>
            <CardNew />
          </SourceCardWrapper>
          {formik.values.sources.map((source, index) => (
            <SourceCardWrapper key={index}>
              <SourceCard
                error={!!formik.errors.sources && !source.product_id}
                icon={source.source === 'File' ? 'CSV' : 'URL'}
                title={source.title}
                onClick={() => {
                  handleOpenSource()
                  setSourceToEditIndex(index)
                }}
              />
            </SourceCardWrapper>
          ))}
        </Sources>
      </Form.Item>
      <Form.Item style={{ marginTop: 'auto', marginBottom: 0 }}>
        <SubmitButton
          type="primary"
          htmlType="submit"
          onClick={formik.submitForm}
          disabled={!formik.isValid || formik.isSubmitting}
        >
          Create
        </SubmitButton>
      </Form.Item>
      {isLoading && (
        <Overlay>
          <Loader />
        </Overlay>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 800px;
  height: 480px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: auto;
    min-width: 300px;
  }
`
const Sources = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`
const SourceCardWrapper = styled.div`
  width: 50%;
  padding: 10px;
  display: flex;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
const SubmitButton = styled(Button)`
  display: block;
  margin: var(--default-padding) auto 0;
`
const Title = styled.h1`
  text-align: center;
`
const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
