import { ChartData, ChartDataset } from 'chart.js'
import { StackBarChart, DataForChart } from '../types'
import { getGroupedChartValues, sortLegend, splitLabelForChart } from '../utils'
import { numberFormatter } from 'utils/numberFormat'

export const configureStackBarChart = (
  currentChart: StackBarChart,
  fullAxes?: boolean,
  hasLabels?: boolean,
  isComparative?: boolean,
  startIndex?: number,
  limit?: number,
  wrapLabels?: boolean,
  maxAxesLength?: number,
  expandedCategories?: string[],
  order?: 'asc' | 'desc',
  sortedByCategory?: boolean,
  alignOrder?: boolean,
  comparativePanelsNumber?: number,
  hideXAxis?: boolean,
  hideYAxis?: boolean,
  disableGrouping?: boolean
): DataForChart => {
  const datasetObject = new Map()
  const datasets: ChartDataset[] = []
  const endIndex =
    startIndex !== undefined && limit !== undefined
      ? startIndex + limit
      : undefined

  let values = currentChart.values

  if (
    (comparativePanelsNumber === 1 || !alignOrder) &&
    currentChart.chart_id !== '2_4_22_rating' &&
    currentChart.chart_id !== '3_4_23_sentiment'
  ) {
    values = getGroupedChartValues(
      currentChart,
      expandedCategories ?? [],
      !!sortedByCategory,
      order ?? 'desc',
      disableGrouping
    )
  }

  const labels = values
    .slice(startIndex, endIndex)
    .map((v) => v[currentChart.x_key] as string)
  const legend: DataForChart['legend'] = []
  let totalVolume = 0
  const maxAxesLabelLength = isComparative ? 15 : 30
  values.slice(startIndex, endIndex).forEach((v) => {
    v.stacked_categories?.forEach((c) => {
      const name = c[currentChart.x_key]
      if (!datasetObject.has(name)) {
        datasetObject.set(name, {
          data: [],
          label: name,
          color: c.color,
          hover: [],
        })
      }
    })
  })
  values.slice(startIndex, endIndex).forEach((v, step) => {
    datasetObject.forEach((value, key) => {
      const foundElement = !v.hidden
        ? v.stacked_categories?.find((c) => c.category === key)
        : undefined
      totalVolume += foundElement?.volume ?? 0
      value.data.push(foundElement ? foundElement[currentChart.y_key] : 0)
      value.hover.push(foundElement ? foundElement.hover : {})
    })
  })
  datasetObject.forEach((value, key) => {
    datasets.push({
      label: key,
      data: value.data,
      backgroundColor: value.color,
      hover: value.hover,
      borderRadius: 10,
    } as any)
    legend.push({
      label: key,
      color: value.color,
      disabled: false,
    })
  })
  // YP-000 Fix Bar Chart Order
  datasets.sort((a, b) => {
    if (
      a.label &&
      Number.isInteger(a.label) &&
      b.label &&
      Number.isInteger(b.label)
    ) {
      return (b.label as any) - (a.label as any)
    }
    return 0
  })
  const getAxes = () => {
    const xAxes = {
      stacked: true,
      ticks: {
        callback: function (value: any) {
          value = (this as any).getLabelForValue(value)
          if (!value) return 'Null'
          if (wrapLabels) {
            return splitLabelForChart(value, maxAxesLength || 30)
          }
          return fullAxes
            ? value
            : value?.length > maxAxesLabelLength
            ? value?.substr(0, maxAxesLabelLength) + '...'
            : value
        },
        // padding: 30,
        // autoSkip: false,
        // maxTicksLimit: Math.max(limit || 0, 20),
      },
      title: { display: !hideXAxis, text: currentChart.x_title },
    }
    const yAxes = {
      stacked: true,
      ticks: {
        callback: function (value: any) {
          if (typeof value === 'number') value = value.toFixed(2)
          return numberFormatter(value)
        },
      },
      title: { display: !hideYAxis, text: currentChart.y_title },
      suggestedMax: limit
        ? values
            .map((el) =>
              el.stacked_categories?.reduce((a, b) => a + b.volume, 0)
            )
            .sort((a, b) => b - a)[0]
        : currentChart.max_value,
    }
    return (currentChart as StackBarChart).orientation === 'horizontal'
      ? [yAxes, xAxes]
      : [xAxes, yAxes]
  }
  const data: ChartData = {
    labels,
    datasets,
  }
  const options: any = {
    plugins: {
      datalabels: hasLabels
        ? {
            formatter: (value: number, data: any) => {
              const totalPercent = (value / totalVolume) * 100
              const res = +data.dataset.hover[data.dataIndex]['% Volume']
                ?.toString()
                .slice(0, -1)
              return totalPercent > 1 ? res.toFixed(0) + '%' : ''
            },
            color: '#ffffff',
            font: { size: values.length > 12 ? 9 : 13 },
            display: 'auto',
            rotation:
              (currentChart as StackBarChart).orientation === 'horizontal'
                ? 0
                : -90,
          }
        : false,
      legend: false,
      tooltip: {
        intersect: true,
        position: 'nearest',
        callbacks: {
          label: function (data: any) {
            const value = data.dataset.data[data.dataIndex]
            if (!value) return ''
            const hover = Object.entries(
              data.dataset.hover[data.dataIndex]
            ).map(([key, value]) => key + ': ' + value)
            return `${data.dataset.label}: ${numberFormatter(value)} (${hover})`
          },
        },
      },
    },
    animation: {
      colors: {
        type: 'color',
        duration: 300,
      },
    },
    indexAxis:
      (currentChart as StackBarChart).orientation === 'horizontal' ? 'y' : 'x',
    responsive: true,
    resizeDelay: 0,
    maintainAspectRatio: false,
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    scales: {
      x: getAxes()[0],
      y: getAxes()[1],
    },
  }
  return {
    data,
    options,
    type: 'bar',
    legend: sortLegend(legend, (currentChart as StackBarChart).legend_order),
    height: 600,
  }
}
