import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

type Props = {
  setup: (s: HTMLCanvasElement) => boolean
  children: React.ReactNode
  onClick: () => void
}

export const NetworkDiagram: React.FC<Props> = (props) => {
  const [isSetup, setSetup] = useState(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (canvasRef.current !== null && !isSetup) {
      const result = props.setup(canvasRef.current)
      setSetup(result)
    }
  }, [canvasRef.current])

  return (
    <Wrapper id="network-diagram">
      <div
        ref={canvasRef as any}
        onContextMenu={(event) => event.preventDefault()}
        onClick={(event) => {
          if (event.type === 'click' && !event.shiftKey) {
            event.stopPropagation()
            props.onClick()
          }
        }}
        style={{
          background: 'rgb(255, 255, 255)',
          height: '100%',
          zIndex: -2,
          borderRadius: 'var(--border-radius)',
        }}
      />
      {props.children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`
