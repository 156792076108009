import React from 'react'
import styled from 'styled-components'
import { mainText } from '../../../assets/styles/variables'

interface Props {
  title?: React.ReactNode
  body?: React.ReactNode
  color?: string
  style?: React.CSSProperties
}

const YogiInfo: React.FC<Props> = ({ title, body, color, style }) => {
  return (
    <Container style={{ ...style }}>
      <ColorBar color={color} />
      <Content>
        {title && <Title color={color}>{title}</Title>}
        {body && <Body color={color}>{body}</Body>}
      </Content>
    </Container>
  )
}

export default YogiInfo

const Container = styled.div<{ color?: string }>`
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  flex-shrink: 0;

  //background: #fcfafa;
  //border: 1px solid #eff0f6;
  //box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.04),
  //  inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  border-radius: 10px;

  color: ${(props) => (props.color ? props.color : '#28364F')};
`

const ColorBar = styled.div<{ color?: string }>`
  width: 8px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
`

const Content = styled.div`
  padding: 5px 10px;
`

const Title = styled.h2<{ color?: string }>`
  color: ${({ color }) => (color ? color : mainText)};
`

const Body = styled.div<{ color?: string }>`
  color: ${({ color }) => (color ? color : mainText)};
`
