import { DateWindows, Filters } from '../types'
import {
  FilterOption,
  FilterOptionDate,
  FilterOptionRange,
} from '../features/filters/types'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
  HardcodedFilterOptionsEnum,
} from '../features/filters/helpers'
import { findDateWindow } from '../utils'
import { DateRange } from '../features/dashboard/components/Dashboard/components/DashboardDateSelect'
import moment from 'moment'
import {
  ChartLegend as ChartLegendType,
  DataForChart,
  TimeInterval,
} from '../features/charts/types'
import React from 'react'

const periodToSeconds: Record<string, number> = {
  week: 604800,
  month: 2592000,
  quarter: 7776000,
  half: 15552000,
  year: 31536000,
}

type Props = {
  filters: Filters
  dateWindows: DateWindows
  defaultFilterList: FilterOption[]
  prevIsDelta: boolean
  comparePeriod?: 'year' | 'quarter' | 'half' | 'week' | 'month'
}
export const usePrevFilters = ({
  filters,
  dateWindows,
  defaultFilterList,
  prevIsDelta,
  comparePeriod,
}: Props) => {
  const prevFilters: Filters = JSON.parse(JSON.stringify(filters || {}))
  let newDelta = prevIsDelta
  let prevDateIsBeforeFirstReview = false

  // TODO THIS IS DUPLICATED CODE  -  DisplayWidget.270
  let dateFilter = prevFilters?.values?.find(
    ({ field }) => field === HardcodedFilterOptionsEnum.CREATE_TIME
  ) as FilterOptionDate | undefined

  if (!!comparePeriod && periodToSeconds[comparePeriod] && !!dateFilter) {
    // check if max/min match an item from the list, otherwise use below calc
    const foundDate = findDateWindow(
      dateWindows,
      dateFilter.min,
      dateFilter.max
    )
    // also, the comparePeriod has to match the type of the foundDate
    if (foundDate?.type?.toLowerCase() === comparePeriod) {
      const foundIndex = dateWindows[foundDate.type].findIndex(
        (el: DateRange) => el.label === foundDate.label
      )
      // this is so stupid
      const prevIndex =
        foundIndex !== dateWindows[foundDate.type].length - 1
          ? foundIndex + 1
          : dateWindows[foundDate.type].length - 1
      dateFilter.min = JSON.parse(
        dateWindows[foundDate.type][prevIndex].value
      ).min
      dateFilter.max = JSON.parse(
        dateWindows[foundDate.type][prevIndex].value
      ).max
    } else {
      const maxDate = moment.unix(dateFilter.max)
      const minDate = moment.unix(dateFilter.min)
      let maxDatePrev
      let minDatePrev
      if (comparePeriod === 'half') {
        maxDatePrev = maxDate.subtract(6, 'month')
        minDatePrev = minDate.subtract(6, 'month')
      } else {
        maxDatePrev = maxDate.subtract(1, comparePeriod)
        minDatePrev = minDate.subtract(1, comparePeriod)
      }
      dateFilter.max = maxDatePrev.unix()
      dateFilter.min = minDatePrev.unix()
      if (
        dateFilter.min <
        (
          defaultFilterList.find(
            (el) => el.field === 'create_time'
          ) as FilterOptionRange
        )?.min
      ) {
        prevDateIsBeforeFirstReview = true
      }
    }
  } else if (!!dateFilter) {
    // check if max/min match an item from the list, otherwise use below calc
    const foundDate = findDateWindow(
      dateWindows,
      dateFilter.min,
      dateFilter.max
    )
    // trailing, we don't want to grab the constructed next instance, we want
    // the equal time period immediately preceding, which doesn't have an entry
    if (foundDate?.type && foundDate.type !== 'Trailing') {
      const foundIndex = dateWindows[foundDate.type].findIndex(
        (el: DateRange) => el.label === foundDate.label
      )
      // this is so stupid
      const prevIndex =
        foundIndex !== dateWindows[foundDate.type].length - 1
          ? foundIndex + 1
          : dateWindows[foundDate.type].length - 1
      dateFilter.min = JSON.parse(
        dateWindows[foundDate.type][prevIndex].value
      ).min
      dateFilter.max = JSON.parse(
        dateWindows[foundDate.type][prevIndex].value
      ).max
    } else {
      const timeDiff = Math.abs(dateFilter.max - dateFilter.min)

      const min = formatDateToUTCMidnight(
        moment.unix(dateFilter.min - timeDiff)
      ).unix()
      const max = formatDateToUTCEndOfDay(
        moment.unix(dateFilter.min - 60)
      ).unix()
      if (
        min <
        (
          defaultFilterList.find(
            (el) => el.field === 'create_time'
          ) as FilterOptionRange
        )?.min
      ) {
        dateFilter = undefined
        // we can't calculate a delta against a time period that doesn't exist
        newDelta = false
        prevDateIsBeforeFirstReview = true
      } else {
        dateFilter.max = max
        dateFilter.min = min
      }
    }
  } else {
    // newDelta = false
  }

  return { newDelta, dateFilter, prevFilters, prevDateIsBeforeFirstReview }
}
