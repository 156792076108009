import { YogiButton } from '../../../../components/UI/YogiButton'
import mixpanel from '../../../trackers/mixpanel'
import {
  LoadingOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Badge, InputProps } from 'antd'
import React from 'react'
import { useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { getBadgeLabels, reducerToComputeFiltersCount } from './helpers'
import { FilterOption } from './types'

type Props = {
  filterValues: Filters
  filterList: FilterOption[]
  isSearchPanelVisible: boolean
  setSearchPanelVisible: any
  filterOptionsLoading: boolean
  comparativePanelsNumber: number
  isSmall?: boolean
}
export const SearchButton: React.FC<Props> = ({
  filterValues,
  filterList,
  isSearchPanelVisible,
  setSearchPanelVisible,
  filterOptionsLoading,
  comparativePanelsNumber,
  isSmall,
}) => {
  const { isDashboard, isNetwork, isFeedback, isKeywords } = useProjectStore(
    (state: ProjectState) => state.route
  )
  const countries = useProjectStore((state: ProjectState) => state.countries)

  const globalAppliedFiltersCount = filterValues.values
    .filter(
      (el) =>
        !el.field.startsWith('search_query') &&
        !el.field.startsWith('search_condition') &&
        (countries?.enabled ? !el.field.startsWith('country') : true)
    )
    .map((el) => ({
      field: el.field,
      // @ts-ignore
      values: [...new Set(getBadgeLabels(el, filterList))],
    }))
    .reduce(reducerToComputeFiltersCount, 0)
  return (
    <Badge count={filterValues.searchQuery?.length} size="small">
      <YogiButton
        id="search-button"
        type="ghost"
        onClick={() => {
          setSearchPanelVisible((prev: boolean) => !prev)
        }}
        style={{ padding: isSmall ? '0 10px' : '' }}
      >
        {comparativePanelsNumber === 3 || isSmall ? (
          <span>
            <SearchOutlined />
          </span>
        ) : (
          <span>
            <SearchOutlined
              style={{
                pointerEvents: 'none',
                marginLeft: -3,
                marginRight: 4,
              }}
            />{' '}
            Search
          </span>
        )}
      </YogiButton>
    </Badge>
  )
}
