import React, { FC } from 'react'
import {
  PartitionOutlined,
  FileSearchOutlined,
  CommentOutlined,
  PieChartOutlined,
} from '@ant-design/icons'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import { DraggableWidget } from './DraggableWidget'
import { useQuery } from 'react-query'
import { getSummary } from '../../../../charts/model'
import { Stats } from '../../../../summary/components/Stats'
import { Themes } from '../../../../summary/components/Themes'
import { Keywords } from '../../../../summary/components/Keywords'
import { FilterOption } from '../../../../filters/types'
import { Verbatims } from '../../../../summary/components/Verbatims'
import StatsIcon from 'assets/images/custom-dashboards/statsWidgetIcon.png'
import ThemesIcon from 'assets/images/custom-dashboards/themesWidgetIcon.png'
import KeywordsIcon from 'assets/images/custom-dashboards/keywordsWidgetIcon.png'
import ReviewsIcon from 'assets/images/custom-dashboards/reviewsWidgetIcon.png'

export interface DraggableChartCardsProps {
  filters: Filters
  filterListData: FilterOption[]
  onAddItem: (item: any, layoutItem: any) => void
}

export const Widgets: FC<DraggableChartCardsProps> = ({
  filters,
  filterListData,
  onAddItem,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const summaryRequest = {
    headers: {
      proj_uuid: projectId,
    },
    body: {
      chart_id: '5_5_7_rating_sentiment',
      tier1: 'Brand',
      tier1_value: '',
      tier2_value: null,
      tier3_value: null,
      search_terms: filters.searchQuery,
      search_criteria: filters.searchCondition,
      criteria: filters.values,
    },
  }

  const { data } = useQuery(
    ['summary', summaryRequest],
    () => getSummary(summaryRequest.headers, summaryRequest.body),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!summaryRequest,
    }
  )

  const obj = [
    {
      name: 'Stats',
      icon: <PieChartOutlined />,
      image: StatsIcon,
      showComponent: false,
      component: (
        <Stats
          data={data}
          summaryRequest={summaryRequest}
          filterValues={filters}
          filterList={filterListData}
        />
      ),
      layout: {
        w: 12,
        h: 6,
        // @ts-ignore
        minW: 4,
        // @ts-ignore
        minH: 4,
      },
    },
    // {
    //   name: '# Reviews & Ratings',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_reviews_and_rating'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: '# Sentences',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_fragments'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: '# Reviews',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_posts'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: '# Ratings Only',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_rating_only'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: 'Average Rating',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_rating_avg'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: 'Average Sentiment',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_sentiment_avg'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: 'Promotional Reviews',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_promo_posts'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: 'Product Recommendations',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_recommended_posts'} />,
    //   layout: infoLayout,
    // },
    // {
    //   name: '# Related Themes',
    //   showComponent: true,
    //   component: <Stats data={data} cardName={'n_themes_total'} />,
    //   layout: infoLayout,
    // },
    {
      name: 'Yogi Key Themes',
      icon: <PartitionOutlined />,
      image: ThemesIcon,
      component: (
        <Themes
          data={data}
          summaryRequest={summaryRequest}
          localFilters={filters}
          selectedTheme={''}
        />
      ),
      layout: {
        w: 12,
        h: 9,
        // @ts-ignore
        minW: 3,
        // @ts-ignore
        minH: 4,
      },
    },
    {
      name: 'Most Frequent Keywords',
      icon: <FileSearchOutlined />,
      image: KeywordsIcon,
      component: (
        <Keywords
          data={data}
          summaryRequest={summaryRequest}
          selectedTheme={''}
          filterListData={filterListData}
        />
      ),
      layout: {
        w: 12,
        h: 4,
        // @ts-ignore
        minW: 3,
        // @ts-ignore
        minH: 3,
      },
    },
    {
      name: 'Reviews',
      icon: <CommentOutlined />,
      image: ReviewsIcon,
      component: (
        <Verbatims
          summaryRequest={summaryRequest}
          isThemeTier={
            filters?.values?.filter((el: any) => el.field === 'theme')
              ?.length === 1 || summaryRequest?.body?.tier1 === 'Theme'
          }
        />
      ),
      layout: {
        w: 12,
        h: 12,
        // @ts-ignore
        minW: 6,
        // @ts-ignore
        minH: 4,
      },
    },
  ]

  return (
    <div style={{ maxWidth: 300, margin: '0 auto' }}>
      {obj &&
        obj.map((item: any) => (
          <div key={item.name} style={{ padding: 5 }}>
            <DraggableWidget
              key={item.field}
              item={item}
              onAddItem={onAddItem}
            />
          </div>
        ))}
    </div>
  )
}
