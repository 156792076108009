import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Button, InputNumber, Radio, RadioChangeEvent, Select } from 'antd'
import moment from 'moment'

import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { getOutliers, getTimeSeriesOutliers } from './model'
import { Loader } from '../../../../shared/components'
import LoadingText from '../../../../components/Loading/LoadingText'

import { Header } from './sections/header'
import { HeaderFilterPanel } from '../filters'
import { Summary } from '../summary'
import Outlier from './components/Outlier'
import { mainText } from '../../../../assets/styles/variables'
import TimeSeriesOutlier from './components/TimeSeriesOutlier'
import OutlierContainer from './components/OutlierContainer'

const COMPARATIVE_INDEX = 0
const ITEMS_PER_PAGE = 3

const Automated: React.FC = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[COMPARATIVE_INDEX]
  )

  const [page, setPage] = useState<number>(1)
  const [sentimentFilter, setSentimentFilter] = useState('negative')
  const [sortDirection, setSortDirection] = useState('desc')
  const [outlierStrictness, setOutlierStrictness] = useState('moderate')
  const [outlierType, setOutlierType] = useState('theme')
  const [tempMinReviewCount, setTempMinReviewCount] = useState(10)
  const [minReviewCount, setMinReviewCount] = useState(10)
  const [tempSemanticThreshold, setTempSemanticThreshold] = useState(30)
  const [semanticThreshold, setSemanticThreshold] = useState(30)
  const [modelProvider, setModelProvider] = useState('gemini')
  const [model, setModel] = useState('gemini-1.5-flash-latest')

  const {
    data: outliers,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['outliers', projectId, filterValues],
    () => getOutliers(projectId, filterValues),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    }
  )
  const {
    data: timeSeriesOutliers,
    isLoading: timeSeriesLoading,
    isFetching: timeSeriesFetching,
    refetch: refetchTimeSeries,
  } = useQuery(
    ['timeseries-outliers', projectId, filterValues],
    () => getTimeSeriesOutliers(projectId, filterValues),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    }
  )

  const filteredOutliers = useMemo(() => {
    // if (!outliers?.outliers) return []

    let filtered =
      outliers?.outliers.filter(
        (outlier) => outlier.volume >= minReviewCount
      ) ?? []

    let timeSeriesFiltered =
      timeSeriesOutliers?.outliers.filter(
        (outlier) => outlier.volume >= minReviewCount
      ) ?? []

    let filteredCombined
    if (outlierType === 'theme') {
      filteredCombined = [
        ...filtered.map((el) => ({ ...el, type: 'lens' })),
        // ...timeSeriesFiltered.map((el) => ({ ...el, type: 'timeseries' })),
      ]
    } else if (outlierType === 'timeseries') {
      filteredCombined = [
        ...timeSeriesFiltered.map((el) => ({ ...el, type: 'timeseries' })),
      ]
    } else {
      filteredCombined = [
        ...filtered.map((el) => ({ ...el, type: 'lens' })),
        ...timeSeriesFiltered.map((el) => ({ ...el, type: 'timeseries' })),
      ]
    }

    if (sentimentFilter === 'positive') {
      filteredCombined = filteredCombined.filter(
        (outlier) => outlier.weighted_zscore > 0
      )
    } else if (sentimentFilter === 'negative') {
      filteredCombined = filteredCombined.filter(
        (outlier) => outlier.weighted_zscore < 0
      )
    }

    return filteredCombined.sort((a, b) => {
      const comparison =
        Math.abs(b.weighted_zscore) - Math.abs(a.weighted_zscore)
      return sortDirection === 'desc' ? comparison : -comparison
    })
  }, [
    outliers,
    timeSeriesOutliers,
    outlierType,
    sentimentFilter,
    sortDirection,
    minReviewCount,
  ])

  const handleSortDirectionChange = (e: RadioChangeEvent) => {
    setSortDirection(e.target.value)
  }

  const handleModelChange = (e: RadioChangeEvent) => {
    setModel(e.target.value)
  }

  const handleMinReviewCountChange = (value: number | null) => {
    setTempMinReviewCount(value || 10)
  }

  const handleSemanticThresholdChange = (value: number | null) => {
    setTempSemanticThreshold(value || 3)
  }

  const updateMinReviewCount = () => {
    setMinReviewCount(tempMinReviewCount)
  }

  const updateSemanticThreshold = () => {
    setSemanticThreshold(tempSemanticThreshold)
  }

  const loadMoreOutliers = () => {
    setPage((prev) => prev + 1)
  }

  return (
    <Container>
      <Header />
      <Divider />
      <HeaderFilterPanel
        filterPanelHeight={`calc(100vh - 180px - ${40}px)`}
        filterValues={filterValues}
        comparativeIndex={COMPARATIVE_INDEX}
      />

      <Section>
        <Controls>
          <ControlPanel>
            <h2>Outliers</h2>
            <Button onClick={() => refetch()}>Refetch</Button>
            <Select
              value={outlierType}
              onChange={setOutlierType}
              style={{ width: 150 }}
            >
              <Select.Option value="all">All Types</Select.Option>
              <Select.Option value="timeseries">
                Time Series Outliers
              </Select.Option>
              <Select.Option value="theme">Theme Outliers</Select.Option>
            </Select>
            {/*<Select*/}
            {/*  value={outlierStrictness}*/}
            {/*  onChange={setOutlierStrictness}*/}
            {/*  style={{ width: 120 }}*/}
            {/*>*/}
            {/*  <Select.Option value="strict">Strict</Select.Option>*/}
            {/*  <Select.Option value="moderate">Moderate</Select.Option>*/}
            {/*  <Select.Option value="relaxed">Relaxed</Select.Option>*/}
            {/*</Select>*/}
            <Select
              value={sentimentFilter}
              onChange={setSentimentFilter}
              style={{ width: 120 }}
            >
              <Select.Option value="all">All Outliers</Select.Option>
              <Select.Option value="positive">Positive</Select.Option>
              <Select.Option value="negative">Negative</Select.Option>
            </Select>
            <Radio.Group
              value={sortDirection}
              onChange={handleSortDirectionChange}
            >
              <Radio.Button value="desc">Descending</Radio.Button>
              <Radio.Button value="asc">Ascending</Radio.Button>
            </Radio.Group>

            <MinReviewCountControl>
              <span>Min Reviews:</span>
              <InputNumber
                min={1}
                value={tempMinReviewCount}
                onChange={handleMinReviewCountChange}
              />
              <Button onClick={updateMinReviewCount}>Update</Button>
            </MinReviewCountControl>

            <MinReviewCountControl>
              <span>Threshold:</span>
              <InputNumber
                min={0}
                max={100}
                value={tempSemanticThreshold}
                onChange={handleSemanticThresholdChange}
              />
              <Button onClick={updateSemanticThreshold}>Update</Button>
            </MinReviewCountControl>
          </ControlPanel>

          <ControlPanel>
            <Radio.Group value={modelProvider}>
              <Radio.Button value="gemini">Gemini</Radio.Button>
            </Radio.Group>
            <Radio.Group value={model} onChange={handleModelChange}>
              <Radio.Button value="gemini-1.5-flash-latest">Flash</Radio.Button>
              <Radio.Button value="gemini-1.5-pro-latest">Pro</Radio.Button>
            </Radio.Group>
          </ControlPanel>
        </Controls>

        <AnswerContainer>
          {isLoading || isFetching ? (
            <LoaderContainer>
              <Loader style={{ height: '100%', maxHeight: 300 }} />
              <LoaderTitle>
                <LoadingText
                  key={moment().format('ss')}
                  strings={[
                    'Grabbing the Latest Reviews',
                    'Formulizing Prompt',
                    'Shipping off to Automated',
                    'Exercising Patience',
                    'Prettifying Response',
                  ]}
                  interval={8000}
                />
              </LoaderTitle>
            </LoaderContainer>
          ) : (
            <OutliersContainer>
              <OutlierList>
                {!filteredOutliers?.length && (
                  <NoOutliersMessage>
                    No outliers found for the selected filters
                  </NoOutliersMessage>
                )}
                {filteredOutliers
                  ?.slice(0, ITEMS_PER_PAGE * page)
                  .map((outlier, index) => (
                    <OutlierContainer
                      key={index}
                      outlier={outlier}
                      filterValues={filterValues}
                      model={model}
                      outlierType={outlier.type}
                      threshold={semanticThreshold}
                    />
                  ))}
              </OutlierList>

              <LoadMoreButton
                onClick={loadMoreOutliers}
                disabled={
                  !filteredOutliers ||
                  filteredOutliers?.length <= ITEMS_PER_PAGE * page
                }
              >
                View More
              </LoadMoreButton>
            </OutliersContainer>
          )}
        </AnswerContainer>
      </Section>
      <Summary />
    </Container>
  )
}

export default Automated

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 40px);
  overflow: auto;
  background: var(--color-background-grey);
  background: var(--card-background-white);
`

const Divider = styled.div`
  width: 100%;
  border: 1px solid rgba(159, 183, 195, 0.15);
`

const Section = styled.div`
  padding: 20px 40px;
  width: 100%;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const ControlPanel = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  h2 {
    margin-bottom: 0;
  }
`

const MinReviewCountControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
  margin-top: 20px;
`

const OutliersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const OutlierList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const NoOutliersMessage = styled.div`
  text-align: center;
  font-style: italic;
  color: ${mainText};
`

const LoadMoreButton = styled(Button)`
  align-self: center;
`
