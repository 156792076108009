import React from 'react'
import styled from 'styled-components'
import { numberFormatter } from 'utils/numberFormat'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'

export enum NoDataModalTypes {
  NO_DATA = 'no-data',
  OUT_OF_LIMIT = 'out-of-limit',
}

type Props = { modal: NoDataModalTypes }

export const NodataOverlay: React.FC<Props> = ({ modal }) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const isAdmin = details?.role === 'admin'
  const nodesLimit =
    details?.clientSettings.project.pages.network.nodes_limit || 5000
  const limit = isAdmin ? 0 : nodesLimit

  return (
    <Wrapper id="no-network-diagram">
      {/*<Face src={FaceIcon} />*/}
      {modal === NoDataModalTypes.NO_DATA && (
        <Text>
          <h2>No results found using current filter</h2>
        </Text>
      )}
      {modal === NoDataModalTypes.OUT_OF_LIMIT && (
        <Text>
          <h2>Apply more filters to visualize results</h2>
          <div>
            Your account is currently able to visualize a maximum of{' '}
            {numberFormatter(limit)} data points. Reach out to Yogi Support if
            you need to increase this limit.
          </div>
        </Text>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  position: absolute;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const Face = styled.img`
  width: 50px;
`
const Text = styled.div`
  max-width: 600px;
  text-align: center;
`
