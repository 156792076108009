export const getRandomColor = (): string =>
  '#' + Math.floor(Math.random() * 16777215).toString(16)

export function convertHexToRGB(hex: string, alpha?: string): string {
  if (hex.indexOf('#') === -1) return hex
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  return `rgb(${r}, ${g}, ${b})`
}
