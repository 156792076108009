import React from 'react'
import { DashboardResponse } from '../../types'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { accent, secondaryRed } from '../../../../../../assets/styles/variables'
import { EditOutlined } from '@ant-design/icons'
import { GroupsOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useAuthContext } from '../../../../../auth'
import _ from 'lodash'
import mixpanel from '../../../../../trackers/mixpanel'
const pluralize = require('pluralize')

type DashboardCardProps = {
  dashboardData: DashboardResponse
  onSelect: (dashboard: DashboardResponse) => void
  actions: any
  listType: 'list' | 'grid'
  notifications: any
}
const DashboardCard: React.FC<DashboardCardProps> = ({
  dashboardData,
  onSelect,
  actions,
  listType,
  notifications,
}) => {
  const authContext = useAuthContext()
  const userEmail = authContext.user?.attributes?.email

  const { dashboard, viewers, owners } = dashboardData
  const { name, created_dt } = dashboard

  return (
    <DashboardContainer
      isActive={false}
      onClick={() => {
        mixpanel.track('alerts', {
          action: 'open',
          value: dashboardData?.dashboard.id,
        })
        onSelect(dashboardData)
      }}
    >
      {listType === 'list' ? (
        <Grid container spacing={1}>
          {/*  TODO this should be a table probably */}
        </Grid>
      ) : (
        <Card>
          <CardControls>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h3 style={{ margin: 0 }}>
                {name}
                {owners?.find((el) => el.email === userEmail) && (
                  <Tooltip title={'You are an owner of this Custom Dashboard'}>
                    <EditOutlined style={{ marginLeft: 10, color: accent }} />
                  </Tooltip>
                )}
              </h3>
              {actions}
            </div>
          </CardControls>
          <CardContent>
            <CardInfo>
              <CardTime>
                Created on {new Date(created_dt).toLocaleDateString()}
              </CardTime>
              <CardSharingInfo container spacing={2}>
                {dashboard.author ? (
                  <>
                    <Grid item xs={6}>
                      <Tooltip
                        title={viewers.concat(owners)?.map((user: any) => (
                          <div>{user.email}</div>
                        ))}
                      >
                        <SharingInfoBox style={{ cursor: 'pointer' }}>
                          <GroupsOutlined fontSize={'small'} />
                          <div>
                            {viewers.concat(owners)?.length ?? 0}{' '}
                            {pluralize(
                              'Recipients',
                              viewers.concat(owners)?.length ?? 0
                            )}
                          </div>
                        </SharingInfoBox>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <SharingInfoBox>
                        {dashboard.author.email === userEmail ? (
                          <div>Created by me</div>
                        ) : (
                          <div>Shared with me</div>
                        )}
                      </SharingInfoBox>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <SharingInfoBox>
                      <div>Yogi Report</div>
                    </SharingInfoBox>
                  </Grid>
                )}
              </CardSharingInfo>
            </CardInfo>
          </CardContent>
        </Card>
      )}
    </DashboardContainer>
  )
}

const DashboardContainer = styled.div<{ isActive?: boolean }>`
  padding: var(--default-padding-half);
  background: ${(props) => (props.isActive ? '#FFF2F2' : '#fff')};
  box-shadow: var(--default-box-shadow);
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  border-color: ${(props) => (props.isActive ? secondaryRed : 'transparent')};
  display: flex;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }
`

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const CardControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
`
const CardInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`
const CardTime = styled.div`
  font-size: 12px;
  color: #8a8c8f;
`
const CardSharingInfo = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SharingInfoBox = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(159, 183, 195, 0.15);
`

export default DashboardCard
