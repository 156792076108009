import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import styled from 'styled-components'
import {
  MoreOutlined,
  CopyOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import {
  buttonBlue,
  cardBorderGrey,
} from '../../../../../../../assets/styles/variables'
import { useQuery } from 'react-query'
import { getSummary, getSummaryPosts } from '../../../../charts/model'
import { Stats } from '../../../../summary/components/Stats'
import { Themes } from '../../../../summary/components/Themes'
import { Keywords } from '../../../../summary/components/Keywords'
import { Verbatims } from '../../../../summary/components/Verbatims'
import { ChartHeaderFilterPanel } from '../Charts/chart-header-filter-panel'
import {
  arrayDiff,
  diff,
  generateReadableFilters,
  mergeFilters,
} from '../../../utils'
import _ from 'lodash'
import html2canvas from 'html2canvas'
import logo from '../../../../../../../assets/images/logos/Peach_Horizontal.png'
import { Loader } from '../../../../../../../shared/components'
import { Layout } from 'react-grid-layout'
import { Badge, Button, Input, Popover, Tooltip, Typography } from 'antd'
import { toast } from 'react-toastify'
import useResizeObserver from 'use-resize-observer'
const { Title } = Typography

export interface DraggableChartCardsProps {
  filters: Filters
  name: string
  item: any
  index: string
  updateItem: (
    index: string,
    filterValues: Filters | null,
    dashboardControls: any,
    title?: string
  ) => void
  isEditing?: boolean
  removeItem?: () => void
  duplicateItem?: (item: any, layout: Layout, filters: Filters) => void
  layout: Layout
  dashboardControls?: any
  updateItemLayout?: (
    item: any,
    layout: Layout,
    filters: Filters,
    dashboardControls?: any
  ) => void
  disabled?: any
}

export interface WidgetHandle {
  onExportChart: () => Promise<
    { base64: string; aspectRatio: number } | undefined
  >
  index: string
}

export const DisplayWidget = forwardRef<WidgetHandle, DraggableChartCardsProps>(
  (
    {
      filters,
      name,
      item,
      index,
      updateItem,
      isEditing,
      removeItem,
      duplicateItem,
      layout,
      dashboardControls,
      updateItemLayout,
      disabled,
    },
    ref
  ) => {
    const projectId = useProjectStore((state: ProjectState) => state.projectId)
    const route = useProjectStore((state: ProjectState) => state.route)
    const defaultFilterList = useProjectStore(
      (state: ProjectState) => state.defaultFilterList
    )

    const [localDashboardControls, setLocalDashboardControls] = useState(
      dashboardControls ?? {}
    )

    const { isUnlinked } = localDashboardControls

    const [mergedFilters, setMergedFilters] = useState<Filters>(
      item.filterValues
    )
    const [chartTitle, setChartTitle] = useState<string | undefined>(
      item?.title
    )

    const widgetRef = useRef<HTMLDivElement>(null)

    const { ref: chartFilterHeaderRef } = useResizeObserver<HTMLDivElement>()

    useEffect(() => {
      if (item.title !== chartTitle) {
        updateItem && index && updateItem(index, null, null, chartTitle)
      }
    }, [chartTitle])

    // TODO some issue with clearing a filter
    // this could initialize to the diff of the master filters and the local filters
    const [localFilterValues, setLocalFilterValues] = useState<Filters>({
      values: diff(item.filterValues.values, filters?.values ?? []),
      searchQuery: arrayDiff(
        item.filterValues.searchQuery,
        filters?.searchQuery ?? []
      ),
      searchCondition: arrayDiff(
        item.filterValues.searchCondition,
        filters?.searchCondition ?? []
      ),
    })

    const [enabled, setEnabled] = useState<boolean>(false)

    useEffect(() => {
      const mergedObj = mergeFilters(filters, localFilterValues, isUnlinked)
      setMergedFilters(mergedObj)
      if (!_.isEqual(item.filterValues, localFilterValues)) {
        if (index && updateItem) {
          updateItem(index, localFilterValues, null)
        }
      }
      // wait until merged to allow query
      setEnabled(true)
    }, [filters, localFilterValues, isUnlinked])

    // TODO we can put this somewhere else. we should probably put this all in a hook
    const summaryRequest = {
      headers: {
        proj_uuid: projectId,
      },
      body: {
        chart_id: '5_5_7_rating_sentiment',
        tier1: 'Brand',
        tier1_value: '',
        tier2_value: null,
        tier3_value: null,
        search_terms: mergedFilters.searchQuery,
        search_criteria: mergedFilters.searchCondition,
        criteria: mergedFilters.values,
      },
    }

    // TODO catch error
    const { isLoading, data, refetch, isError } = useQuery(
      ['summary', summaryRequest],
      () => getSummary(summaryRequest.headers, summaryRequest.body),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !!summaryRequest && enabled,
      }
    )

    const { isLoading: postsLoading } = useQuery(
      ['summary-posts', summaryRequest],
      () => getSummaryPosts(summaryRequest.headers, summaryRequest.body),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !!summaryRequest && enabled,
      }
    )

    const obj = [
      {
        name: 'Stats',
        showComponent: false,
        component: (
          <Stats
            data={data}
            summaryRequest={summaryRequest}
            filterValues={mergedFilters}
            filterList={defaultFilterList}
          />
        ),
      },
      // {
      //   name: '# Reviews & Ratings',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_reviews_and_rating'} />,
      // },
      // {
      //   name: '# Sentences',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_fragments'} />,
      // },
      // {
      //   name: '# Reviews',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_posts'} />,
      // },
      // {
      //   name: '# Ratings Only',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_rating_only'} />,
      // },
      // {
      //   name: 'Average Rating',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_rating_avg'} />,
      // },
      // {
      //   name: 'Average Sentiment',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_sentiment_avg'} />,
      // },
      // {
      //   name: 'Promotional Reviews',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_promo_posts'} />,
      // },
      // {
      //   name: 'Product Recommendations',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_recommended_posts'} />,
      // },
      // {
      //   name: '# Related Themes',
      //   showComponent: true,
      //   component: <Stats data={data} cardName={'n_themes_total'} />,
      // },
      {
        name: 'Yogi Key Themes',
        component: (
          <Themes
            data={data}
            summaryRequest={summaryRequest}
            localFilters={mergedFilters}
            selectedTheme={''}
            defaultOpened={false}
            isWidget={true}
          />
        ),
        layout: {},
      },
      {
        name: 'Most Frequent Keywords',
        component: (
          <Keywords
            data={data}
            summaryRequest={summaryRequest}
            selectedTheme={''}
            filterListData={defaultFilterList}
          />
        ),
      },
      {
        name: 'Reviews',
        component: (
          <Verbatims
            summaryRequest={summaryRequest}
            isThemeTier={
              filters?.values?.filter((el: any) => el.field === 'theme')
                ?.length === 1 || summaryRequest?.body?.tier1 === 'Theme'
            }
          />
        ),
      },
    ]

    const updateDashboardControls = (key: string, value: any) => {
      const tempDashboardControls = JSON.parse(
        JSON.stringify(localDashboardControls)
      )
      tempDashboardControls[key] = value
      setLocalDashboardControls(tempDashboardControls)
      if (updateItem && index) {
        updateItem(index, mergedFilters, tempDashboardControls)
      }
    }

    useImperativeHandle(
      ref,
      () => ({
        onExportChart: async () => {
          if (widgetRef.current) {
            if (widgetRef.current && item) {
              let canvas
              canvas = await html2canvas(widgetRef.current, { scale: 2 })
              const base64 = canvas.toDataURL('image/jpg')
              const returnObj = {
                base64,
                aspectRatio: canvas.width / canvas.height,
                title: item.name,
                filters: generateReadableFilters(
                  localFilterValues,
                  defaultFilterList
                ),
                type: 'widget',
              }
              return returnObj
            }
          }
        },
        index: index ?? '0',
      }),
      [item, localFilterValues, defaultFilterList]
    )

    const onCopyWidget = async () => {
      if (widgetRef.current) {
        setTimeout(async () => {
          if (widgetRef.current) {
            let canvas
            canvas = await html2canvas(widgetRef.current)
            var tempCanvas = document.createElement('canvas')
            var tempCtx = tempCanvas.getContext('2d')
            const text = 'Yogi'

            if (tempCtx) {
              let cw: number, ch: number
              cw = tempCanvas.width = canvas.width
              ch = tempCanvas.height = canvas.height
              tempCtx.drawImage(canvas, 0, 0)
              tempCtx.font = '24px verdana'
              let textWidth = tempCtx.measureText(text).width
              tempCtx.globalAlpha = 0.5
              // // bottom left
              // tempCtx.fillStyle = 'white'
              // tempCtx.fillText(text, 0 + textWidth + 100, ch - 20)
              // tempCtx.fillStyle = 'black'
              // tempCtx.fillText(text, 0 + textWidth + 100 + 2, ch - 20 + 2)
              // // top middle
              // tempCtx.fillStyle = 'white'
              // tempCtx.fillText(text, cw - 150, 70)
              // tempCtx.fillStyle = 'black'
              // tempCtx.fillText(text, cw - 148, 72)

              // let img = new Image(5, 5)
              // img.onload = function () {
              //   // @ts-ignore
              //   tempCtx!.drawImage(img, cw / 2, ch / 2)
              // }
              // img.src = logo

              // TODO enable logo - has new styling in it
              const logoImg = document.createElement('img')
              logoImg.height = 50
              logoImg.width = 50
              logoImg.style.position = 'absolute'
              logoImg.style.left = '50%'
              logoImg.style.top = '0px'
              // logoImg.style.opacity = '.5'

              logoImg.onload = function () {
                // tempCtx!.drawImage(logoImg, cw / 2 - 174, -10, 174, 75)

                tempCanvas.toBlob(function (blob) {
                  const item = new ClipboardItem({ 'image/png': blob ?? '' })
                  navigator.clipboard.write([item])
                })
              }
              logoImg.src = logo
            }
          }
        }, 10)
      }
    }

    return (
      <Wrapper isEditing={!!isEditing}>
        <Tooltip
          title={
            'This chart is Unlinked from the Parent Filter and as such may have Filters applied that are inconsistent with the Parent Filter'
          }
        >
          <Badge
            count={!!isUnlinked ? '·' : 0}
            size="small"
            style={{ position: 'absolute', right: 0 }}
            className={
              isEditing ? 'unlinked-badge-edit' : 'unlinked-badge-view'
            }
          >
            <div></div>
          </Badge>
        </Tooltip>

        {isLoading || !enabled || (name === 'Reviews' && postsLoading) ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Loader style={{ height: '50%', maxHeight: 300 }} />
            <LoaderTitle>{`Loading Widget`}</LoaderTitle>
          </div>
        ) : (
          <div>
            <div
              className={'drag-handle'}
              style={{ cursor: isEditing ? 'move' : 'inherit' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {isEditing ? (
                  <>
                    <Input
                      placeholder={'Optional Title'}
                      suffix={<EditOutlined style={{ color: '#9FB8C3' }} />}
                      style={{
                        border: '2px solid ' + cardBorderGrey,
                        borderRadius: '6px',
                        minWidth: 200,
                        marginBottom: 10,
                      }}
                      value={chartTitle ?? ''}
                      onChange={(event) => setChartTitle(event.target.value)}
                      className={'drag-cancel'}
                    />
                    <>
                      <Popover
                        trigger={'click'}
                        content={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '5px',
                            }}
                          >
                            {layout && index && updateItemLayout && (
                              <Button
                                onClick={() => {
                                  const layoutCopy = _.cloneDeep(layout)
                                  layoutCopy.w = layout.w > 6 ? 6 : 12
                                  updateItemLayout(
                                    item,
                                    layoutCopy,
                                    localFilterValues
                                    // localDashboardControls
                                  )
                                }}
                              >
                                {layout.w > 6
                                  ? 'Make Half Width'
                                  : 'Make Full Width'}
                              </Button>
                            )}
                            <Button
                              onClick={() =>
                                duplicateItem &&
                                duplicateItem(item, layout, localFilterValues)
                              }
                            >
                              Duplicate
                            </Button>
                            <Button onClick={() => removeItem && removeItem()}>
                              Delete
                            </Button>
                          </div>
                        }
                        placement="bottomRight"
                        overlayClassName="action-popover"
                      >
                        <Button
                          icon={<MoreOutlined />}
                          style={{ marginLeft: 10, marginTop: 1 }}
                        ></Button>
                      </Popover>
                    </>
                  </>
                ) : (
                  <>
                    {chartTitle && (
                      <Title
                        level={5}
                        style={{
                          marginRight: 20,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          marginBottom: 10,
                        }}
                      >
                        {chartTitle}
                      </Title>
                    )}
                  </>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'default',
                  }}
                  ref={chartFilterHeaderRef}
                  className={'drag-cancel'}
                >
                  <ChartHeaderFilterPanel
                    filterValues={localFilterValues}
                    updateLocalFilters={setLocalFilterValues}
                    comparativeIndex={0}
                    mergedFilters={mergedFilters}
                    parentFilters={filters}
                    disabled={disabled}
                    isEditing={!!isEditing}
                    isWidget
                    isUnlinked={!!isUnlinked}
                  />
                </div>
                <div>
                  <Tooltip
                    title={`Unlinked charts can include data outside of the Dashboard Filter\n\nCurrent Status:${
                      isUnlinked ? ' Unlinked' : ' Linked'
                    }`}
                    placement={'topLeft'}
                  >
                    <Button
                      type={isUnlinked ? 'default' : 'primary'}
                      icon={<LinkOutlined />}
                      onClick={() => {
                        mixpanel.track('toggle component link', {
                          ...route,
                          value: !isUnlinked,
                        })
                        updateDashboardControls('isUnlinked', !isUnlinked)
                      }}
                      style={{
                        border: `2px solid ${
                          !isUnlinked ? buttonBlue : cardBorderGrey
                        }`,
                        borderRadius: '4px',
                        boxShadow: 'none',
                        marginRight: '10px',
                        color: !isUnlinked ? '#fff' : buttonBlue,
                        background: !isUnlinked ? buttonBlue : '#fff',
                      }}
                    />
                  </Tooltip>
                  {!isEditing && (
                    <Tooltip
                      title={'Copy Widget to Clipboard'}
                      placement={'topLeft'}
                    >
                      <Button
                        type="text"
                        icon={<CopyOutlined />}
                        onClick={() => {
                          mixpanel.track('dashboard download', {
                            action: 'copy',
                            type: 'widget',
                            value: name,
                          })
                          onCopyWidget().then(() =>
                            toast.info('Widget copied to clipboard')
                          )
                        }}
                        style={{
                          border: `2px solid ${cardBorderGrey}`,
                          borderRadius: '4px',
                          boxShadow: 'none',
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div ref={widgetRef} style={{ paddingTop: 10 }}>
              {obj.find((o) => o.name === name)?.component ?? <div />}
            </div>
          </div>
        )}
      </Wrapper>
      // TODO calculate this height ig....
    )
  }
)

const Wrapper = styled.div<{ isEditing: boolean }>`
  background: white;
  padding: 10px;
  border-radius: 8px;
  height: 100%;
  overflow: ${({ isEditing }) => (isEditing ? 'visible' : 'auto')};

  .unlinked-badge-view {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .unlinked-badge-edit {
    position: absolute;
    left: 5px;
    top: 5px;
  }
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`
