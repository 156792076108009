import { Button, Popover } from 'antd'
import React from 'react'
import { Cluster } from '../types'
import { ActionButtons } from './action-buttons'

type Props = {
  visibilityCondition: boolean
  setSelectedStateCallback: () => void
  fetchClusters: () => void
  selectedClusterUUID: string[]
  clusterOps: any
  availableActionsForCluster: { [key: string]: boolean }
  hiddenButtons: { [key: string]: boolean }
  clusters: Cluster[]
  confirmationWrapper: (callback: () => void) => void
}

export const ActionButtonsPopover: React.FC<Props> = ({
  visibilityCondition,
  setSelectedStateCallback,
  fetchClusters,
  selectedClusterUUID,
  clusterOps,
  availableActionsForCluster,
  hiddenButtons,
  clusters,
  confirmationWrapper,
}) => (
  <div onClick={(e) => e.stopPropagation()}>
    <Popover
      onVisibleChange={(v: boolean) => v && visibilityCondition}
      trigger="click"
      placement="rightTop"
      content={
        <ActionButtons
          fetchClusters={fetchClusters}
          selectedClusters={selectedClusterUUID || []}
          operations={clusterOps}
          restrictedOperations={availableActionsForCluster}
          hiddenButtons={hiddenButtons}
          clusters={clusters}
          confirmationWrapper={confirmationWrapper}
        />
      }
    >
      <Button onClick={() => setSelectedStateCallback()}>Actions</Button>
    </Popover>
  </div>
)
