import { DateWindows, Filters } from '../../types'
import {
  AppliedFilterOption,
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
  FilterOption,
  FilterOptionDate,
  FilterOptionRange,
} from '../filters/types'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
  getBadgeLabels,
  HardcodedFilterOptionsEnum,
} from '../filters/helpers'
import { exportDashboardToPPTX } from './model'
import { toast } from 'react-toastify'
import { Layout } from 'react-grid-layout'
import moment from 'moment'
import { DateRange } from './components/Dashboard/components/DashboardDateSelect'
import { initialState } from '../../projectStore/projectStore'
const pluralize = require('pluralize')

export const generateReadableFilters = (
  filters: Filters,
  filterList: FilterOption[] | undefined
): string => {
  const readableFilters = filters.values.map(
    (filterValue: AppliedFilterOption) => {
      const filterOption = filterList?.find(
        (o) => o.field === filterValue.field
      )
      const valueLabels = getBadgeLabels(filterValue, filterList)
      const filterOptionName = filterOption?.alias
        ? pluralize(filterOption?.alias, valueLabels?.length ?? 0)
        : filterValue.field.toLocaleUpperCase()
      return `${filterOptionName}:  ${valueLabels?.join(', ') ?? ''}`
    }
  )

  return readableFilters?.join('\n') ?? ''
}

export const diff = (
  childFilters: Array<AppliedFilterOption>,
  parentFilters: Array<AppliedFilterOption>
): Array<AppliedFilterOption> => {
  return childFilters.filter(
    (item1) =>
      !parentFilters.some((item2) => {
        if (!item1.hasOwnProperty('max')) {
          return (
            item1.field === item2.field &&
            // @ts-ignore todo select types
            JSON.stringify([...item1.values].sort()) ===
              // @ts-ignore
              JSON.stringify([...item2.values].sort())
          )
        } else {
          // take child range over parent range if available
          if (item1.field === item2.field) {
            const rangeItem1 = item1 as FilterOptionRange
            const rangeItem2 = item2 as FilterOptionRange
            // console.log(rangeItem1, rangeItem2)
            if (
              rangeItem1.min === rangeItem2.min &&
              rangeItem1.max === rangeItem2.max
            ) {
              return true
            }
          }
          return false
        }
      })
  )
}
export const arrayDiff = (arr1: string[], arr2: string[]): string[] => {
  return arr1.filter((item) => !arr2.includes(item))
}

export const mergeFilters = (
  parentFilters: Filters,
  childFilters: Filters,
  isUnlinked: boolean
): Filters => {
  let mergedObj: Filters = {
    values: [],
    searchQuery: [],
    searchCondition: [],
  }

  // todo we have to do this for the values as well
  for (let key in parentFilters) {
    // @ts-ignore
    mergedObj[key] = [
      // @ts-ignore
      ...new Set([...(mergedObj[key] || []), ...parentFilters[key]]),
    ]
  }

  for (let key in childFilters) {
    // @ts-ignore
    mergedObj[key] = [
      // @ts-ignore
      ...new Set([...(mergedObj[key] || []), ...childFilters[key]]),
    ]
  }

  let mergeValues = mergedObj.values.reduce<{
    [key: string]: AppliedFilterOption
  }>((acc, obj) => {
    if (acc[obj.field]) {
      if (Object.hasOwn(acc[obj.field], 'values')) {
        // TODO support range
        // @ts-ignore
        acc[obj.field].values = isUnlinked
          ? // @ts-ignore
            obj?.values ?? []
          : // @ts-ignore
          obj.values.some((v) =>
              // @ts-ignore
              acc[obj.field].values.includes(v)
            )
          ? [
              // @ts-ignore
              ...new Set([
                // @ts-ignore
                ...obj.values.filter((v) =>
                  // @ts-ignore
                  acc[obj.field].values.includes(v)
                ),
              ]),
            ]
          : // @ts-ignore
            acc[obj.field].values
      } else {
        acc[obj.field] = { ...obj }
      }
    } else {
      acc[obj.field] = { ...obj }
    }
    return acc
  }, {})

  let result = Object.values(mergeValues)

  mergedObj.values = result
  return mergedObj
}

export const constructChildFilterList = (
  filterList: FilterOption[],
  filters: Filters,
  localFilters?: Filters
): FilterOption[] => {
  const tempFilterList: FilterOption[] = filterList.map((item: any) => {
    const matchingFilter = filters.values.find(
      (filter: AppliedFilterOption) => {
        return filter.field === item.field
      }
    )
    const matchingLocalFilter = localFilters?.values.find(
      (filter: AppliedFilterOption) => {
        return filter.field === item.field
      }
    )
    if (matchingFilter && item.values) {
      if ((matchingFilter as AppliedFilterOptionRange).max) {
        return item
      } else {
        return {
          ...item,
          values: item.values
            .filter((value: any) => {
              return (
                matchingFilter as AppliedFilterOptionSelect
              ).values.includes(value.value)
            })
            // @ts-ignore
            .concat(
              matchingLocalFilter
                ? item.values.filter((value: any) => {
                    return (
                      (
                        matchingLocalFilter as AppliedFilterOptionSelect
                      )?.values.includes(value.value) ?? []
                    )
                  })
                : []
            ),
        }
      }
    } else {
      return item
    }
  })
  return tempFilterList
}

export const exportAllImages = async (
  layout: Layout[],
  chartRefsMap: { [key: string]: any },
  dashboard: any,
  projectId: string,
  filterValues: Filters,
  defaultFilterList: FilterOption[],
  title: string,
  date: string,
  setIsExporting: any
) => {
  const orderedLayout = layout.sort((a, b) => {
    if (a.y !== b.y) {
      return a.y - b.y
    } else {
      return a.x - b.x
    }
  })

  const slides = await Promise.all(
    // orderedRefs.map((ref) => ref?.onExportChart())
    Array.from(Object.values(chartRefsMap)).map((ref) => ref?.onExportChart())
  )

  const trimmedSlides = slides.filter((s) => !!s)
  // console.log(trimmedSlides)
  const orderedSlides: any[] = []
  orderedSlides.fill(undefined, 0, orderedLayout.length)
  // console.log(orderedSlides)
  // this should make a list of lists, where each list is a row, track width counts and reset when > 12
  let currentWidth = 0
  let rowIndex = 0
  orderedLayout.forEach((l, index) => {
    currentWidth += l.w
    if (currentWidth > 12) {
      rowIndex += 1
      currentWidth = l.w
    }

    // if dashboard is set, the slides are already ordered, so we don't need to reference the layout index
    if (dashboard) {
      if (!orderedSlides[rowIndex]) {
        orderedSlides[rowIndex] = [trimmedSlides[index]]
      } else {
        orderedSlides[rowIndex].push(trimmedSlides[index])
      }
    } else {
      if (!orderedSlides[rowIndex]) {
        orderedSlides[rowIndex] = [trimmedSlides[Number(l.i)]]
      } else {
        orderedSlides[rowIndex].push(trimmedSlides[Number(l.i)])
      }
    }
  })

  let searchParams = new URLSearchParams(window.location.search)
  searchParams.delete('filters')
  const search = !!searchParams.toString()?.length
    ? `?${searchParams.toString()}`
    : ''
  const url = `${window.location.origin}${window.location.pathname}` + search

  // Now you have all the base64s, you can send them to an API
  // console.log(slides.filter((s) => !!s))
  exportDashboardToPPTX(projectId, {
    slides: orderedSlides.filter((s) => !!s),
    // TODO should these be formatted with alias at this point, probably
    filters: generateReadableFilters(filterValues, defaultFilterList),
    layout: layout,
    url: url,
    title: title,
    date_string: date,
  })
    .then(async (res: any) => {
      // if (!res.ok) {
      //   throw new Error('HTTP error ' + res.status)
      // }

      // Get the content and the headers of the response
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      })
      const contentDisposition = res.headers['content-disposition']

      // Extract filename from content-disposition header
      let fileName = 'presentation.pptx' // Default filename
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1]
      }
      // Create a new blob object using the response data
      const url = window.URL.createObjectURL(blob)

      // Create a temporary invisible link
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      // Append the link to the body
      document.body.appendChild(link)

      // Simulate click on the link
      link.click()

      // Clean up by removing the link
      document.body.removeChild(link)
      toast.info('Powerpoint Exported')
    })
    .catch((err: any) => {
      console.log(err)
      setIsExporting(false)
      toast.error('Error exporting powerpoint')
    })
    .finally(() => {
      setIsExporting(false)
    })
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const generateDates = (
  filterList: FilterOption[]
): Record<string, DateRange[]> => {
  const datesObj: Record<string, DateRange[]> = {
    Week: [],
    Month: [],
    Quarter: [],
    Half: [],
    Year: [],
    YTD: [],
    Trailing: [],
    allDates: [],
  }

  const option = filterList?.find(
    (filterOption) =>
      filterOption.field === HardcodedFilterOptionsEnum.CREATE_TIME
  ) as FilterOptionDate

  const currentDate = option ? new Date(option?.max * 1000) : new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  // const minDate = formatDateToMidnight(moment.unix(option.min))
  // console.log(minDate)
  const beginningDate = option
    ? new Date(option?.min * 1000)
    : new Date(2019, 0, 1)
  const beginningYear = beginningDate.getFullYear()
  const beginningMonth = beginningDate.getMonth()

  // TODO times should only go up to most recently available data, from earliest available data
  // TODO need to load in selected date range from saved dashboard

  const getTimeInSec = (date: Date) => {
    return Math.floor(date.getTime() / 1000)
  }
  for (let year = beginningYear; year <= currentYear; year++) {
    const maxWeek =
      year === currentYear
        ? Math.ceil((currentDate.getDate() + currentMonth * 31) / 7) + 1
        : 52
    const minWeek =
      year === beginningYear ? Math.ceil(beginningDate.getDate() / 7) : 1
    for (let week = minWeek; week <= maxWeek; week++) {
      let startDate
      if (year === beginningYear && week === minWeek) {
        // If it's the first week of the first year, adjust the start date to the most recent previous Sunday
        startDate = new Date(beginningDate)
        startDate.setDate(
          startDate.getDate() - ((startDate.getDay() + 7 - 0) % 7)
        )
      } else {
        startDate = new Date(year, 0, 1 + (week - 1) * 7)
      }
      const endDate = new Date(startDate)
      endDate.setDate(endDate.getDate() + 6)
      if (endDate > currentDate) break
      const date = {
        label: `${monthNames[startDate.getMonth()]} ${startDate.getDate()} - ${
          monthNames[endDate.getMonth()]
        } ${endDate.getDate()}, ${year}`,
        value: JSON.stringify({
          // min: getTimeInSec(startDate),
          // max: getTimeInSec(endDate),
          min: formatDateToUTCMidnight(
            moment.unix(getTimeInSec(startDate))
          ).unix(),
          max: formatDateToUTCEndOfDay(
            moment.unix(getTimeInSec(endDate))
          ).unix(),
        }),
      }
      datesObj.Week.push(date)
      datesObj.allDates.push({ ...date, type: 'Week' })
    }
  }
  for (let year = 2019; year <= currentYear; year++) {
    const maxMonth = year === currentYear ? currentMonth : 11
    const minMonth = year === beginningYear ? beginningMonth : 0
    for (let month = minMonth; month <= maxMonth; month++) {
      const startDate = new Date(year, month, 1)
      const endDate = new Date(year, month + 1, 0)
      const date = {
        label: `${monthNames[month]} ${year}`,
        value: JSON.stringify({
          min: formatDateToUTCMidnight(
            moment.unix(getTimeInSec(startDate))
          ).unix(),
          max: formatDateToUTCEndOfDay(
            moment.unix(getTimeInSec(endDate))
          ).unix(),
        }),
      }
      datesObj.Month.push(date)
      datesObj.allDates.push({ ...date, type: 'Month' })
    }
  }
  for (let year = 2019; year <= currentYear; year++) {
    const maxQuarter =
      year === currentYear ? Math.floor(currentMonth / 3) + 1 : 4
    const minQuarter =
      year === beginningYear ? Math.floor(beginningMonth / 3) : 1
    for (let quarter = minQuarter; quarter <= maxQuarter; quarter++) {
      const startDate = new Date(year, (quarter - 1) * 3, 1)
      const endDate = new Date(year, quarter * 3, 0)
      const date = {
        label: `Q${quarter} ${year}`,
        value: JSON.stringify({
          min: formatDateToUTCMidnight(
            moment.unix(getTimeInSec(startDate))
          ).unix(),
          max: formatDateToUTCEndOfDay(
            moment.unix(getTimeInSec(endDate))
          ).unix(),
        }),
      }
      datesObj.Quarter.push(date)
      datesObj.allDates.push({ ...date, type: 'Quarter' })
    }
  }
  for (let year = beginningYear; year <= currentYear; year++) {
    const startDate1 = new Date(year, 0, 1)
    const endDate1 = new Date(year, 5, 30)
    if (startDate1 <= currentDate) {
      const date = {
        label: `H1 ${year}`,
        value: JSON.stringify({
          min: formatDateToUTCMidnight(
            moment.unix(getTimeInSec(startDate1))
          ).unix(),
          max: formatDateToUTCEndOfDay(
            moment.unix(getTimeInSec(endDate1))
          ).unix(),
        }),
      }
      datesObj.Half.push(date)
      datesObj.allDates.push({ ...date, type: 'Half' })
    }
    const startDate2 = new Date(year, 6, 1)
    const endDate2 = new Date(year, 11, 31)
    if (startDate2 <= currentDate) {
      const date = {
        label: `H2 ${year}`,
        value: JSON.stringify({
          min: formatDateToUTCMidnight(
            moment.unix(getTimeInSec(startDate2))
          ).unix(),
          max: formatDateToUTCEndOfDay(
            moment.unix(getTimeInSec(endDate2))
          ).unix(),
        }),
      }
      datesObj.Half.push(date)
      datesObj.allDates.push({ ...date, type: 'Half' })
    }
  }
  for (let year = beginningYear; year <= currentYear; year++) {
    const startDate = new Date(year, 0, 1)
    const endDate = new Date(year, currentMonth, currentDate.getDate())
    const date = {
      label: `YTD ${year}`,
      value: JSON.stringify({
        min: formatDateToUTCMidnight(
          moment.unix(getTimeInSec(startDate))
        ).unix(),
        max: formatDateToUTCEndOfDay(moment.unix(getTimeInSec(endDate))).unix(),
      }),
    }
    datesObj.YTD.push(date)
    datesObj.allDates.push({ ...date, type: 'YTD' })
  }
  for (let year = beginningYear; year <= currentYear; year++) {
    const startDate = new Date(year, 0, 1)
    const endDate = new Date(year, 11, 31)
    const date = {
      label: `${year}`,
      value: JSON.stringify({
        min: formatDateToUTCMidnight(
          moment.unix(getTimeInSec(startDate))
        ).unix(),
        max: formatDateToUTCEndOfDay(moment.unix(getTimeInSec(endDate))).unix(),
      }),
    }
    datesObj.Year.push(date)
    datesObj.allDates.push({ ...date, type: 'Year' })
  }
  const trailingDates: DateRange[] = [
    {
      label: '2 Years',
      value: JSON.stringify({
        min: formatDateToUTCMidnight(moment().subtract(730, 'days')).unix(),
        max: formatDateToUTCEndOfDay(moment()).unix(),
      }),
      type: 'Trailing',
      daysCount: 730,
    },
    {
      label: '12 Months',
      value: JSON.stringify({
        min: formatDateToUTCMidnight(moment().subtract(365, 'days')).unix(),
        max: formatDateToUTCEndOfDay(moment()).unix(),
      }),
      type: 'Trailing',
      daysCount: 365,
    },
    {
      label: '6 Months',
      value: JSON.stringify({
        min: formatDateToUTCMidnight(moment().subtract(182, 'days')).unix(),
        max: formatDateToUTCEndOfDay(moment()).unix(),
      }),
      type: 'Trailing',
      daysCount: 182,
    },
    {
      label: '3 Months',
      value: JSON.stringify({
        min: formatDateToUTCMidnight(moment().subtract(91, 'days')).unix(),
        max: formatDateToUTCEndOfDay(moment()).unix(),
      }),
      type: 'Trailing',
      daysCount: 91,
    },
    {
      label: '1 Month',
      value: JSON.stringify({
        min: formatDateToUTCMidnight(moment().subtract(30, 'days')).unix(),
        max: formatDateToUTCEndOfDay(moment()).unix(),
      }),
      type: 'Trailing',
      daysCount: 30,
    },
    // {
    //   label: '7 Days',
    //   value: JSON.stringify({
    //     min: formatDateToUTCMidnight(moment().subtract(7, 'days')).unix(),
    //     max: formatDateToUTCEndOfDay(moment()).unix(),
    //   }),
    //   type: 'Trailing',
    //   daysCount: 7,
    // },
  ]
  datesObj.Trailing = trailingDates
  trailingDates.forEach((date) => {
    datesObj.allDates.push({ ...date, type: 'Trailing' })
  })
  Object.keys(datesObj).forEach((key) => {
    datesObj[key].reverse()
  })
  return datesObj
}

// useful function for converting a filter object to a URL search string that
// can be used to load a page view. deprecated because it can produce a string
// longer than cloudflare supports, see getBookmarkUrlPost uses for alternative
export const getFilterURIString = (filters: Filters) => {
  const obj: Record<string, any> = {}
  filters.values.forEach((filter) => {
    if ((filter as FilterOptionRange).max) {
      // @ts-ignore
      obj[filter.field + '_0'] = JSON.stringify(filter)
    } else {
      // @ts-ignore
      obj[filter.field + '_0'] = (filter as AppliedFilterOptionSelect).values
    }
  })
  const searchObj: Record<string, any> = {}
  if (filters.searchQuery.length) {
    searchObj['search_query_0'] = filters.searchQuery
  }
  if (filters.searchCondition.length) {
    searchObj['search_condition_0'] = filters.searchCondition
  }
  return `filter_values=${encodeURIComponent(
    JSON.stringify(obj)
  )}&search_query_0=${encodeURIComponent(
    JSON.stringify(filters.searchQuery)
  )}&search_condition_0=${encodeURIComponent(
    JSON.stringify(filters.searchCondition)
  )}`
}

export const getInitialFilters = (dateWindows: DateWindows) => {
  const initialFilters: Filters = JSON.parse(
    JSON.stringify(initialState.filters[0])
  )
  const dateFilter = initialFilters.values.find(
    (el) => el.field === 'create_time'
  )
  if (
    !dateFilter ||
    !dateWindows ||
    !dateWindows.Trailing ||
    !dateWindows.Trailing.length ||
    !dateWindows?.Trailing.find((el) => el.daysCount === 30)?.value
  )
    return initialFilters
  const trailing30 = JSON.parse(
    dateWindows.Trailing.find((el) => el.daysCount === 30)?.value ?? '{}'
  )

  if (!trailing30.min || !trailing30.max) return initialFilters
  ;(dateFilter as AppliedFilterOptionRange).min = trailing30.min
  ;(dateFilter as AppliedFilterOptionRange).max = trailing30.max

  return initialFilters
}
