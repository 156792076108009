import React, { useContext } from 'react'
import { Form, Button, Select } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { AuthContext } from 'features/auth'
import { FormItem } from 'shared/components'
import { shareProjectWithUser } from '../model'
import { YogiButton } from '../../../../../components/UI/YogiButton'

type Props = {
  email: string | null
  projectId: string
  isUserAdmin: boolean
  onSuccess: () => void
}

const validationSchema = Yup.object().shape({
  shared_email: Yup.string()
    .email('Invalid email')
    .required('Field is required'),
  first_name: Yup.string().required('Field is required'),
  last_name: Yup.string().required('Field is required'),
  org: Yup.string().required('Field is required'),
  team: Yup.string().required('Field is required'),
})

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

export const ShareForm: React.FC<Props> = ({
  email,
  onSuccess,
  projectId,
  isUserAdmin,
}) => {
  const { userDetails }: any = useContext(AuthContext)
  const formik = useFormik({
    validationSchema,
    validateOnChange: true,
    initialValues: {
      role: 'viewer',
      last_name: '',
      first_name: '',
      shared_email: email || '',
      team: userDetails?.team || '',
      org: userDetails?.org || '',
    },
    onSubmit: async ({ role, ...values }, { setFieldError }) => {
      const { shared_email } = values
      const currentUserEmail = userDetails.email

      if (
        shared_email.split('@')[1].toLowerCase() !==
        currentUserEmail.split('@')[1].toLowerCase()
      ) {
        if (currentUserEmail.split('@')[1].toLowerCase() !== 'meetyogi.com') {
          setFieldError(
            'shared_email',
            'You are not allowed to invite this user'
          )
          return Promise.resolve(false)
        }
      }

      try {
        await shareProjectWithUser(projectId, {
          ...values,
          admin: role === 'admin',
          create_user: true,
        })
        toast.success('User has been invited successfully')
        onSuccess()
      } catch (e: any) {
        toast.error(e.message)
      }
    },
  })

  return (
    <Form className="share-form" onFinish={formik.handleSubmit} {...layout}>
      <FormItem label="Email address" field="shared_email" formik={formik} />
      <Form.Item label="Role">
        <Select
          defaultValue={formik.values.role}
          style={{ width: '100%' }}
          onChange={(v: string) => {
            formik.setFieldValue('role', v)
          }}
        >
          <Select.Option value="viewer">Viewer</Select.Option>
          {isUserAdmin && <Select.Option value="admin">Admin</Select.Option>}
        </Select>
      </Form.Item>

      <FormItem label="First name" field="first_name" formik={formik} />
      <FormItem label="Last name" field="last_name" formik={formik} />
      <FormItem label="Organization" field="org" formik={formik} />
      <FormItem label="Team" field="team" formik={formik} />

      <YogiButton
        style={{ margin: '0 auto', display: 'block' }}
        type="primary"
        htmlType="submit"
        disabled={!formik.isValid || !formik.dirty}
        loading={formik.isSubmitting}
      >
        Create User & Share Yogi Dashboard
      </YogiButton>
    </Form>
  )
}
