import { useState, useEffect } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { getOutlierAnalysisStream, OutlierType } from '../model'
import { constructThemeOutlierPrompt } from '../utils'
import untruncateJson from 'untruncate-json'
import { useAuthContext } from '../../../../auth'

interface AnalysisItem {
  title: string
  explanation: string
  evidence: string
  implication: string
}

interface Analysis {
  header: string
  body: AnalysisItem[]
}

interface OutlierAnalysisResult {
  analysis: Analysis | null
  completeAnalysis: Analysis | null
  isStreaming: boolean
  error: string | null
  refetch: () => void
}

export const useAnalysisStream = (
  projectId: string,
  outlier: OutlierType,
  model: string
): OutlierAnalysisResult => {
  const [streamContent, setStreamContent] = useState<string>('')
  const [completeContent, setCompleteContent] = useState<string>('')
  const [isStreaming, setIsStreaming] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const authContext = useAuthContext()

  const fetchStreamedData = async (): Promise<void> => {
    try {
      setStreamContent('')
      setError(null)
      setIsStreaming(true)

      const prompt = constructThemeOutlierPrompt(outlier)
      const reviews = outlier.data.map((el) => el.body).filter(Boolean)

      const response = await getOutlierAnalysisStream(
        projectId,
        reviews,
        prompt,
        model,
        authContext?.user?.signInUserSession?.idToken?.jwtToken ?? ''
      )
      const reader = response?.getReader()
      if (!reader) {
        throw new Error('ReadableStream not supported')
      }

      const decoder = new TextDecoder()
      let buffer = ''

      while (true) {
        const { done, value } = await reader.read()
        if (done) break

        const chunk = decoder.decode(value, { stream: true })
        buffer += chunk

        while (buffer.includes('\n')) {
          const lineEndIndex = buffer.indexOf('\n')
          const line = buffer.slice(0, lineEndIndex)
          buffer = buffer.slice(lineEndIndex + 1)

          if (line.startsWith('data:')) {
            try {
              const jsonStr = line.slice(5).trim()
              if (jsonStr === '[DONE]') {
                setIsStreaming(false)
                setCompleteContent(streamContent)
                await reader.cancel()
                return
              }
              const data = JSON.parse(jsonStr)
              if (data.chunk) {
                setStreamContent((prev) => prev + data.chunk)
              } else if (data.header) {
                console.log('Received final summary:', data)
              }
            } catch (e) {
              console.error('Error parsing JSON:', e)
            }
          }
        }
      }

      setIsStreaming(false)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
      setIsStreaming(false)
    }
  }

  const { data, refetch }: UseQueryResult<void, unknown> = useQuery(
    ['outlierAnalysis', projectId, outlier.theme, model],
    fetchStreamedData,
    {
      enabled: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    refetch()
  }, [projectId, outlier, model, refetch])

  // const parsedAnalysis: Analysis | null = streamContent
  //   ? JSON.parse(untruncateJson(streamContent))
  //   : null

  let parsedAnalysis = {
    header: '',
    body: [],
  }
  try {
    parsedAnalysis = JSON.parse(untruncateJson(streamContent || '{}'))
  } catch (e) {
    console.error('Error parsing JSON:', e)
  }

  const parsedCompleteAnalysis: Analysis | null = completeContent
    ? JSON.parse(untruncateJson(completeContent))
    : null

  return {
    analysis: parsedAnalysis,
    completeAnalysis: parsedCompleteAnalysis,
    isStreaming,
    error,
    refetch,
  }
}
