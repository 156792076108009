import React, { useEffect } from 'react'
import { ProjectContainer } from 'features/project/containers/project-container'
import { ProjectLayout } from '../partials/project-layout'
import { initialState, useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { Loader } from '../../../shared/components'
import { useProject } from '../hooks/useProject'
import { useCountries } from '../hooks/useCountries'
import _ from 'lodash'
import { useQuery } from 'react-query'
import { fetchProjects } from '../model'
import { Access } from '../../../shared/components/access'
import { fetchFilterOptions } from '../react-query-functions'
import { emptyFilters } from '../utils'
import { generateDates } from '../features/dashboard/utils'

type Props = { projectId: string }

export const ProjectRoot: React.FC<Props> = React.memo(({ projectId }) => {
  const setDetails = useProjectStore((state: ProjectState) => state.setDetails)
  const setCountries = useProjectStore(
    (state: ProjectState) => state.setCountries
  )
  const setDefaultFilterList = useProjectStore(
    (state: ProjectState) => state.setDefaultFilterList
  )
  const setDateWindows = useProjectStore((state) => state.setDateWindows)

  // TODO put at project level and store in project store
  const { data: filterList } = useQuery(
    ['filter-list-data', projectId, [], emptyFilters],
    async () => {
      const data = await fetchFilterOptions(projectId, [], emptyFilters)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )
  useEffect(() => {
    if (filterList) {
      setDefaultFilterList(filterList)
      setDateWindows(generateDates(filterList))
    }
  }, [filterList])

  const { data: details } = useProject(projectId)
  // this is technically server data but i'd rather keep it in zustand, we'll see tho
  useEffect(() => {
    if (details) {
      setDetails(details)
    }
  }, [details])

  // TODO we probably want to bring this back if project select doesnt trigger page reload
  // in a single dash proj, when toggling between projects, we need to reset the filters and page configs
  // useEffect(() => {
  //   if (projectId && details) {
  //     resetFilters(projectId, details)
  //   }
  // }, [projectId])

  const { data: projectCountriesData, isError, error } = useCountries()
  // this is technically server data but i'd rather keep it in zustand, we'll see tho
  useEffect(() => {
    if (projectCountriesData) {
      setCountries(projectCountriesData.data)
    }
  }, [projectCountriesData])

  const { data: projectsData } = useQuery(
    ['project-search'],
    () => fetchProjects(),
    { refetchOnWindowFocus: false }
  )

  if (isError) {
    return <Access />
  }

  // all we need here is project name and project path for layout to be happy
  if (!details || !projectCountriesData || !projectsData)
    // return <LogoSpinner>Loading</LogoSpinner>
    return <Loader />
  return (
    <ProjectLayout>
      <ProjectContainer />
    </ProjectLayout>
  )
})
