import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import useResizeObserver from 'use-resize-observer'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Loader } from 'shared/components'
import { numberFormatter } from 'utils/numberFormat'
import { HeaderFilterPanel } from 'features/project/features/filters'
import { ClusterProvider, useClusterContext } from '../features/cluster'
import { GraphProvider, useGraphContext } from '../features/graph'
import { highlightSelected } from '../features/graph/utils'
import {
  NetworkDiagram,
  ControlPanel,
  ContextMenu,
  AssignCluster,
  NewCluster,
  NodataOverlay,
  NoDataModalTypes,
} from '../components'
import { assignClusters, customCluster } from '../model'
import { tooltip } from 'utils/tooltip-data'
import { isInitialFlow } from 'features/home/utils'
import { PostDetailModal } from '../../feedback/post-detail-modal'
import { NetworkProvider } from '../features/network/network-provider'
import mixpanel from 'features/trackers/mixpanel'
import _ from 'lodash'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { useGlobalFilterList } from '../../../hooks/useGlobalFilterList'
import { Fetcher } from '../../../../../shared/components/fetcher'

type Props = { comparativeIndex: any }
const NetworkContent: React.FC<Props> = ({ comparativeIndex }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const filterList = useProjectStore(
    (state: ProjectState) => state.filterList[comparativeIndex]
  )
  const {
    error,
    groups,
    totalNodes,
    clusters,
    fetchClusters,
    selectedDomain,
    isFetching,
    isLoading: clusterLoading,
  } = useClusterContext()
  const {
    setup,
    graph,
    selectedNode,
    setSelectedNode,
    contextPosition,
    selectedNodeList,
    setSelectedNodeList,
    setContextPosition,
  } = useGraphContext()
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )
  const setFilterListByIndex = useProjectStore(
    (state: ProjectState) => state.setFilterListByIndex
  )
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions
  )

  const [operationLoading, setOperationLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [isControlPanelHidden, setIsControlPanelHidden] = useState(false)
  const [rerenderLoading, setRerenderLoading] = useState(false)
  const [modal, setModal] = useState<string | NoDataModalTypes | null>(null)
  const clusterAndGroupsLength = clusters.length + groups.length

  useEffect(() => {
    mixpanel.track('network page')
  }, [])

  // useEffect(() => {
  //   if (comparativeIndex !== undefined) {
  //     const newContexts = [...globalDataContexts.current]
  //
  //     newContexts[comparativeIndex] = {
  //       error,
  //       groups,
  //       totalNodes,
  //       clusters,
  //       isLoading: clusterLoading,
  //       isThemeDomain,
  //       selectedNodeList,
  //     }
  //     globalDataContexts.current = newContexts
  //   }
  // }, [error, groups, totalNodes, clusters, isThemeDomain, selectedNodeList])

  // TODO
  // https://linear.app/yo-yo-gi/issue/EFE-104/manual-refetch-filterlist-in-network-container
  const refetchData = async () => {
    // await Promise.all([fetchClusters(), filterList.refetch()])
    await Promise.all([fetchClusters()])
  }

  // Whenever a filter is applied, opened options should be reset
  useEffect(() => {
    clearOpenedOptions(comparativeIndex)
  }, [filterValues])

  useEffect(() => {
    // The graphs render strangely when adding and removing panels, so we refresh the view each time
    setRerenderLoading(true)
    setTimeout(() => {
      setRerenderLoading(false)
    }, 100)
    setIsControlPanelHidden(false)
    setTimeout(() => {
      setIsControlPanelHidden(isComparative)
    }, 2000)
  }, [comparativePanelsNumber])

  useEffect(() => {
    if (!clusterLoading) {
      const dataLength = clusters.length + groups.length
      if (error) {
        setModal(NoDataModalTypes.OUT_OF_LIMIT)
      } else if (!dataLength) {
        setModal(NoDataModalTypes.NO_DATA)
      } else {
        setModal(null)
      }
    }
  }, [groups, clusters, clusterLoading, error])

  useEffect(() => {
    if (!isFetching && (clusterAndGroupsLength || modal)) {
      setInitialLoading(false)
    }
  }, [isFetching, clusterAndGroupsLength])

  const onAssign = useCallback(
    async (cluster_uuid: string) => {
      setOperationLoading(true)
      await assignClusters(
        selectedNodeList,
        cluster_uuid ? cluster_uuid : null,
        projectId,
        !Boolean(cluster_uuid)
      )
      refetchData()
      setOperationLoading(false)
    },
    [selectedNodeList]
  )

  const onCreate = useCallback(
    async (clusterName: string) => {
      if (selectedDomain && details?.cluster_event_uuid) {
        setOperationLoading(true)
        await customCluster(
          selectedNodeList,
          clusterName,
          details?.cluster_event_uuid
        )
        refetchData()
        setOperationLoading(false)
      }
    },
    [selectedNodeList, details?.cluster_event_uuid]
  )

  const onClosePostDetailModal = useCallback(() => {
    setSelectedNode(null)
  }, [])

  // get filter list data
  const { data: filterListData } = useGlobalFilterList(comparativeIndex)

  // TODO network getting stuck here for some reason, check on tuesday

  if (
    isInitialFlow(details?.state) ||
    initialLoading ||
    rerenderLoading ||
    !filterList ||
    !hasLoadedFilters
  ) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading ${details?.clientSettings.project.pages.network.alias} Page`}</LoaderTitle>
      </>
    )
  }

  // const onPrintChart = async (type: string) => {
  //   getReport({
  //     filter_context: {
  //       filterValues,
  //       comparativeIndex,
  //       selectedDomain
  //     },
  //     comparative_context: {
  //       comparativePanelsNumber,
  //       isComparative,
  //     },
  //     is_network: true,
  //     type,
  //   }, projectId)
  // }

  return (
    <>
      <HeaderFilterPanel
        filterValues={filterValues}
        comparativeIndex={comparativeIndex}
        // downloadSettings={{ loading, onPrint: onPrintChart }}
      />
      <NetworkWrapper id="network-container">
        <TotalNodes id="total-nodes-title">
          Total Nodes: <b>{numberFormatter(totalNodes)}</b>
          <Tooltip title={tooltip['total-nodes']}>
            <InfoCircleOutlined style={{ marginLeft: '8px' }} />
          </Tooltip>
        </TotalNodes>
        <SelectedNodes id="selected-nodes-title">
          Selected Nodes: <b>{numberFormatter(selectedNodeList.length)}</b>
          <Tooltip title={tooltip['selected-nodes']} placement="right">
            <InfoCircleOutlined style={{ marginLeft: '8px' }} />
          </Tooltip>
        </SelectedNodes>
        {!!clusterAndGroupsLength && (
          <ControlPanel
            comparativeIndex={comparativeIndex}
            hidden={isControlPanelHidden}
            setHidden={setIsControlPanelHidden}
          />
        )}

        <NetworkDiagram
          setup={setup}
          onClick={() => {
            setContextPosition(null)
            if (selectedNodeList.length) {
              highlightSelected(graph, [])
              setSelectedNodeList([])
            }
          }}
        >
          {(NoDataModalTypes.NO_DATA === modal ||
            NoDataModalTypes.OUT_OF_LIMIT === modal) && (
            <NodataOverlay modal={modal} />
          )}
        </NetworkDiagram>
        <Layer />
        {(operationLoading || clusterLoading || isFetching) && (
          <LoaderLayer>
            <Fetcher />
          </LoaderLayer>
        )}
        {contextPosition &&
          details?.role === 'admin' &&
          !!selectedNodeList.length && (
            <ContextMenu
              x={contextPosition.x}
              y={contextPosition.y}
              onCreateCluster={() => {
                setContextPosition(null)
                setModal('create')
              }}
              onAssignCluster={() => {
                setContextPosition(null)
                setModal('assign')
              }}
            />
          )}

        <AssignCluster
          isVisible={modal === 'assign'}
          onCreate={onAssign}
          onClose={() => setModal(null)}
        />

        <NewCluster
          modal={modal === 'create'}
          onCreate={onCreate}
          onClose={() => setModal(null)}
        />
      </NetworkWrapper>
      <PostDetailModal
        postUuid={selectedNode || undefined}
        fragment
        onClose={onClosePostDetailModal}
      />
    </>
  )
}

export const NetworkContainer: React.FC = () => {
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>()
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const networkControls = useProjectStore(
    (state: ProjectState) => state.networkControls
  )
  const filters = useProjectStore((state: ProjectState) => state.filters)
  if (
    networkControls.length < comparativePanelsNumber ||
    filters.length < comparativePanelsNumber
  ) {
    return <Container />
  }

  return (
    <Container>
      <NetworkProvider>
        <Wrapper ref={ref}>
          {_.times(comparativePanelsNumber, (index) => (
            <Panel
              key={index}
              style={{
                // Breaks without this /shrug
                minWidth: !width
                  ? 'none'
                  : width / comparativePanelsNumber - 12.5 > 420
                  ? width / comparativePanelsNumber - 12.5 + 'px'
                  : '420px',
                width: '100%',
              }}
            >
              <ClusterProvider comparativeIndex={index}>
                <GraphProvider>
                  <NetworkContent comparativeIndex={index} />
                </GraphProvider>
              </ClusterProvider>
            </Panel>
          ))}
        </Wrapper>
      </NetworkProvider>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  //min-height: calc(100vh - 40px);
  //max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  //overflow: hidden;
  padding: 0px 16px;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  //min-height: calc(100vh - 40px);
  //max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  //overflow: hidden;
  gap: var(--default-padding-half);
  margin: 16px;
`
const Panel = styled.div`
  flex: 1;
  overflow: hidden;
  height: 100%;
  border-radius: var(--border-radius);
  //box-shadow: var(--light-box-shadow);
`
const NetworkWrapper = styled.div`
  position: relative;
  height: calc(100% - 40px);
  border-radius: var(--border-radius);
  overflow-x: hidden;
`
const Layer = styled.div`
  background: #ccc;
  width: 100vw;
  position: absolute;
  left: 0;
  z-index: -1;
`
const LoaderLayer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: calc(100vh - 85px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
`
const TotalNodes = styled.div`
  position: absolute;
  left: 20px;
  top: 50px;
  z-index: 1;
`
const SelectedNodes = styled.div`
  position: absolute;
  left: 20px;
  top: 74px;
  z-index: 1;
`
const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`
