import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  NumberOutlined,
  PieChartOutlined,
  BarChartOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
} from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import { ChartId } from '../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { secondaryNavy } from '../../../../../assets/styles/variables'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'

type CardProps = { selected?: boolean; localSelected?: boolean }
const icons: any = {
  NumberOutlined,
  PieChartOutlined,
  BarChartOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
}

type Props = { headerHeight?: number; data: any }

export const ChartCards: React.FC<Props> = ({ headerHeight, data }: Props) => {
  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls
  )

  const cardFilter = data?.tier2
  // TODO I don't know why this is set like this
  // https://linear.app/yo-yo-gi/issue/EFE-101/chart-cards-cardfilter-not-sure-what-this-does
  const chartFilter = {
    options: [],
    value: {
      chart_ids: [{ chart_id: data?.charts[0]?.chart_id }],
      tier3_chart_ids: [],
    },
  }

  const [events, setEvents] = useState<any>(null)
  const eventTimeout = useRef<any>(null)
  const [selectedCategory, setSelectedCategory] = useState(
    cardFilter?.options[cardFilter.default].name
  )

  useEffect(() => {
    setSelectedCategory(cardFilter?.options[cardFilter.default].name)
  }, [cardFilter])

  return (
    <Wrapper>
      <Text>Charts</Text>
      {cardFilter?.options.map((option: any, optionIndex: any) => {
        const AntdIcon = icons[option.icon] as any
        const selected = selectedCategory === option.name
        return (
          <CardWrapper key={option.name}>
            <Card
              disabled={option.disabled}
              selected={selected}
              onMouseOver={() => {
                if (!option.disabled) {
                  clearTimeout(eventTimeout.current)
                  setEvents({ [`${optionIndex}`]: true })
                }
              }}
              onMouseLeave={() => {
                if (!option.disabled) {
                  eventTimeout.current = setTimeout(() => setEvents(null), 500)
                }
              }}
            >
              <Icon>
                <AntdIcon />
              </Icon>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Name>{option.name}</Name>
                {/*{selected && <Name>Chart Name</Name>}*/}
              </div>

              <Charts
                style={{
                  gridTemplateColumns:
                    option.tier3.length < 5
                      ? '1fr'
                      : option.tier3.length < 9
                      ? '1fr 1fr'
                      : '1fr 1fr 1fr',
                  transform:
                    optionIndex === 4 && headerHeight && headerHeight > 100
                      ? 'translateX(-84%)'
                      : 'translateX(-50%)',
                  visibility:
                    events && events[`${optionIndex}`] ? 'visible' : 'hidden',
                }}
              >
                {option.tier3.map((chart: any, index: any) => {
                  const chartSelected =
                    chartFilter.value &&
                    (getGraphName(chartFilter.value.chart_ids) ===
                      getGraphName(chart.chart_ids) ||
                      getGraphName(chartFilter.value.tier3_chart_ids) ===
                        getGraphName(chart.chart_ids))
                  return (
                    <Chart
                      key={`${chart.name} ${index}`}
                      onClick={() => {
                        mixpanel.track('chart type', {
                          value: chart.chart_ids?.[0]?.chart_id,
                          name: chart.name,
                        })
                        updateDashboardControls(
                          0,
                          'chartId',
                          chart.chart_ids[0].chart_id
                        )
                        setSelectedCategory(option.name)
                        setEvents(null)
                      }}
                      selected={chartSelected}
                    >
                      <ChartImage>
                        <img
                          src={`${window.location.origin}/images/${chart.image}`}
                          alt={chart.name}
                        />
                      </ChartImage>
                      <ChartTitle selected={chartSelected}>
                        {chart.name}
                      </ChartTitle>
                    </Chart>
                  )
                })}
              </Charts>
            </Card>
          </CardWrapper>
        )
      })}
    </Wrapper>
  )
}

const getGraphName = (items?: ChartId[]) => {
  return items
    ?.reduce((s, c) => s + c.chart_id, '')
    .split('_')
    .slice(0, -1)
    .join('_')
}

const Wrapper = styled.div`
  position: absolute;
  flex: 0;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  gap: 20px;
  background: var(--page-background-grey);

  border-top: 2px solid rgba(159, 183, 195, 0.15);
  border-bottom: 2px solid rgba(159, 183, 195, 0.15);
  @media (max-width: 1450px) {
    position: relative;
    justify-content: flex-start;
  }
  @media (max-width: 1400px) {
    gap: 10px;
  }
  @media (max-width: 1300px) {
    position: absolute;
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: 1100px) {
    position: relative;
    justify-content: flex-start;
    gap: 0px;
  }
`

const Text = styled.div`
  margin: 0px 4px;
  @media (max-width: 1300px) {
    display: none;
    margin: 0px;
  }
`

const CardWrapper = styled.div`
  position: relative;
`

const Card = styled.button`
  @media (min-width: 1400px) {
    min-width: 120px;
  }
  @media (max-width: 1400px) {
    min-width: 110px;
  }
  @media (max-width: 1300px) {
    min-width: 0px;
  }
  justify-content: center;
  //box-shadow: var(--default-box-shadow);
  //border: none;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s;
  background: white;
  border-radius: 6px;
  border: 2px solid rgba(159, 183, 195, 0.15);

  &:hover {
    border: 2px solid #4070c0;
  }

  ${({ selected }: CardProps) =>
    selected &&
    'background: linear-gradient(#f4f5ff, #f4f5ff) padding-box, linear-gradient(180deg, #3378ea 0%, #5933ab 100%) border-box; border-radius: 6px; border: 2px solid transparent;'};

  @media (max-width: 1300px) {
    margin: 0 calc(var(--default-padding-half) / 6);
  }

  :disabled {
    background: white;
    color: rgba(0, 0, 0, 0.16);
    cursor: not-allowed;

    &:hover {
      border: 2px solid rgba(159, 183, 195, 0.15);
    }
  }
`
const Icon = styled.span`
  font-size: 24px;
`
const Name = styled.span`
  white-space: nowrap;
  font-size: var(--font-size-md);
  margin-left: 10px;
  line-height: 1.25;
  text-align: left;
  @media (max-width: 1300px) {
    display: none;
  }
`
const Charts = styled.div`
  position: absolute;
  z-index: 101;
  top: 100%;
  left: 50%;
  /* transform: translateX(-50%); */
  background: white;
  box-shadow: var(--default-box-shadow);
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid rgba(0, 0, 0, 0.16);

  @media (max-width: 1600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`
const ChartTitle = styled.span`
  font-size: var(--font-size-md);
  text-align: center;
  margin-top: calc(var(--default-padding-half) / 2);
  color: black;
  ${({ selected }: CardProps) => selected && 'color: white;'};
`
const Chart = styled.div`
  width: 180px;
  padding: calc(var(--default-padding-half) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  border: none;
  cursor: pointer;
  z-index: 5;

  &:hover {
    background-color: #9fb7c3;
    color: white !important;
  }

  ${({ selected }: CardProps) =>
    selected && 'background-color: ' + secondaryNavy + ' !important;'}
  &:hover ${ChartTitle} {
    color: white;
  }
`

const ChartImage = styled.span`
  & > img {
    max-width: 100%;
  }
`
