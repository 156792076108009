import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Input, Modal } from 'antd'
import { EditOutlined, UndoOutlined } from '@ant-design/icons'
import { cardBorderGrey } from '../../../../../../assets/styles/variables'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import { Close } from '@mui/icons-material'
import { VerticalDivider } from '../../../../../../components/UI/VerticalDivider'
import { useHistory } from 'react-router'
import mixpanel from '../../../../../trackers/mixpanel'
import { DashboardResponse } from '../../types'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'
import useResizeObserver from 'use-resize-observer'

export interface HeaderProps {
  viewDashboard?: () => void
  saveDashboard: () => void
  exportDashboard: () => void
  resetDashboard?: () => void
  showResetDashboard: boolean
  setDashboardName: (name: string) => void
  setView: (name: 'list' | 'view' | 'edit') => void
  dashboardName: string
  isEdit?: boolean
  setSharingDashboard?: () => void
  dashboard?: DashboardResponse
  isExporting: boolean
  isOwner?: boolean
  isPublic?: boolean
  setHeaderHeight?: (height: number) => void
  isSmart?: boolean
}

export const Header: FC<HeaderProps> = ({
  saveDashboard,
  exportDashboard,
  showResetDashboard,
  setDashboardName,
  dashboardName,
  setView,
  dashboard,
  resetDashboard,
  setSharingDashboard,
  isEdit,
  isExporting,
  isOwner,
  isPublic,
  setHeaderHeight,
  isSmart,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const history = useHistory()
  const dashboardId = dashboard?.dashboard?.id
  const { ref, height } = useResizeObserver()

  useEffect(() => {
    if (height && setHeaderHeight) {
      setHeaderHeight(height + 20)
    }
  }, [height])

  return (
    <Wrapper ref={ref}>
      <Left>
        {!isPublic && (
          <>
            <CloseButton
              type={'ghost'}
              onClick={() => {
                mixpanel.track('custom dashboard', {
                  action: 'close',
                  value: dashboardId,
                  proj_uuid: projectId,
                })
                if (isEdit && (showResetDashboard || !dashboardId)) {
                  Modal.confirm({
                    title: 'Make sure to save your dashboard before closing',
                    content: 'Any unsaved changes will be lost',
                    onOk() {
                      setView('list')
                      history.push(`${window.location.pathname}`)
                    },
                  })
                } else {
                  setView('list')
                  history.push(`${window.location.pathname}`)
                }
              }}
              icon={
                <Close style={{ width: 16, height: 16, margin: '0 auto' }} />
              }
              style={{ boxShadow: 'none' }}
            />
            <VerticalDivider style={{ height: 20, borderWidth: 1 }} />
          </>
        )}

        {!isEdit ? (
          <h2 style={{ margin: 0 }}>
            {dashboardName}
            {/*{!dashboard?.dashboard.author && ' (Template)'}*/}
          </h2>
        ) : (
          <Input
            placeholder="New Dashboard"
            suffix={<EditOutlined style={{ color: '#9FB8C3' }} />}
            style={{
              border: '2px solid ' + cardBorderGrey,
              borderRadius: '6px',
              minWidth: '300px',
            }}
            value={dashboardName}
            onChange={(event) => setDashboardName(event.target.value)}
          />
        )}
      </Left>
      <Right>
        {showResetDashboard && (
          <YogiButton
            onClick={() => {
              mixpanel.track('custom dashboard', {
                action: 'reset',
                value: dashboardId,
                proj_uuid: projectId,
              })
              resetDashboard && resetDashboard()
            }}
            icon={<UndoOutlined />}
          >
            Reset
          </YogiButton>
        )}
        <YogiButton onClick={exportDashboard} loading={isExporting}>
          Export Dashboard
        </YogiButton>
        {(!dashboardId || dashboard?.dashboard?.author) && (
          <>
            {!isEdit && !isSmart ? (
              <>
                {!isPublic && (
                  <YogiButton
                    onClick={() => {
                      setSharingDashboard && setSharingDashboard()
                    }}
                  >
                    Share Dashboard
                  </YogiButton>
                )}
                {isOwner && (
                  <YogiButton
                    onClick={() => {
                      mixpanel.track('custom dashboard', {
                        action: 'edit',
                        value: dashboardId,
                      })
                      setView('edit')
                    }}
                  >
                    Edit Dashboard
                  </YogiButton>
                )}
              </>
            ) : (
              <>
                {/*<YogiButton onClick={viewDashboard}>View Dashboard</YogiButton>*/}
                <YogiButton
                  type={'primary'}
                  onClick={saveDashboard}
                  // disabled={!showResetDashboard && !dashboard.id}
                  id={'save-dashboard'}
                >
                  Save Dashboard
                </YogiButton>
              </>
            )}
          </>
        )}
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 10px 20px;
  flex: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: white;
  position: relative;
  border-bottom: 2px solid rgba(159, 183, 195, 0.15);
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.1);
  gap: 20px;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 20px;
`

const Right = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`

const CloseButton = styled(Button)`
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 24px !important;
    width: 24px !important;
  }
  ::after {
    all: unset;
  }
`
