import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Form, Input, Button } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Loader } from 'shared/components'
import { home } from 'utils/links'
import { AuthContext } from 'features/auth'
import { AuthWrapper } from 'shared/wrappers'
import { freeSignUp } from '../model'

const layout = {
  labelCol: { span: 6 },
}

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Enter valid '),
  first_name: Yup.string().required('Enter valid first name'),
  last_name: Yup.string().required('Enter valid last name'),
  business: Yup.string().required('Enter valid business name'),
  position: Yup.string().required('Enter valid position'),
})

export const SignUpTrialForm = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [isSuccesfullyRegistered, setSuccesfullyRegistered] = useState(false)
  const authContext = useContext(AuthContext)
  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      business: '',
      position: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: async ({ email, first_name, last_name, business, position }) => {
      try {
        setLoading(true)

        window.localStorage.clear()
        await freeSignUp(
          email.toLowerCase(),
          first_name,
          last_name,
          business,
          position
        )

        setSuccesfullyRegistered(true)
        toast.success(`Welcome, ${first_name}!`)
      } catch (e) {
        toast.error(e?.response?.data?.detail?.msg || e.message)
      } finally {
        setLoading(false)
      }
    },
  })

  window.$crisp.push(['do', 'chat:show'])
  window.$crisp.push([
    'on',
    'chat:closed',
    () => {
      window.$crisp.push(['do', 'chat:show'])
    },
  ])

  useEffect(() => {
    if (authContext.isAuthenticated) {
      history.push(home)
    }
  }, [authContext.isAuthenticated])

  const emailError = formik.touched.email && formik.errors.email
  const firstNameError = formik.touched.first_name && formik.errors.first_name
  const lastNameError = formik.touched.last_name && formik.errors.last_name
  const businessError = formik.touched.business && formik.errors.business
  const positionError = formik.touched.position && formik.errors.position

  return (
    <AuthWrapper>
      <Wrapper onFinish={formik.submitForm} {...layout}>
        {!isSuccesfullyRegistered ? (
          <div>
            <FormTitle>
              <Typography.Title level={4}>Start using Yogi</Typography.Title>
            </FormTitle>
            <Form.Item
              label="Business Email"
              validateStatus={emailError && 'error'}
              help={formik.touched.email && formik.errors.email}
            >
              <Input
                name="email"
                autoComplete="email"
                onChange={formik.handleChange}
                value={formik.values.username}
              />
            </Form.Item>
            <Form.Item
              label="First name"
              validateStatus={firstNameError && 'error'}
              help={formik.touched.first_name && formik.errors.first_name}
            >
              <Input
                name="first_name"
                autoComplete="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
              />
            </Form.Item>
            <Form.Item
              label="Last name"
              validateStatus={lastNameError && 'error'}
              help={formik.touched.last_name && formik.errors.last_name}
            >
              <Input
                name="last_name"
                autoComplete="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
              />
            </Form.Item>
            <Form.Item
              label="Company"
              validateStatus={businessError && 'error'}
              help={formik.touched.business && formik.errors.business}
            >
              <Input
                name="business"
                autoComplete="business"
                onChange={formik.handleChange}
                value={formik.values.business}
              />
            </Form.Item>
            <Form.Item
              label="Your position"
              validateStatus={positionError && 'error'}
              help={formik.touched.position && formik.errors.position}
            >
              <Input
                name="position"
                autoComplete="position"
                onChange={formik.handleChange}
                value={formik.values.position}
              />
            </Form.Item>
            {loading && (
              <Overlay>
                <Loader />
              </Overlay>
            )}
            <Form.Item style={{ marginBottom: 0 }}>
              <SubmitButton type="primary" htmlType="submit">
                Submit
              </SubmitButton>
            </Form.Item>
            <Line />
            <div style={{ textAlign: 'center' }}>
              <Typography.Text style={{ fontSize: 16 }}>
                {"Don't have deep insight into your reviews & ratings? "} <br />
                <a href="https://meetyogi.com/book-a-demo">
                  <b>Sign up for a demo!</b>
                </a>
              </Typography.Text>
            </div>
          </div>
        ) : (
          <div>
            <h2>Waiting for Email confirmation</h2>
            Please, check your email specified during registration. Soon you
            will receive mail with confirmation registration.
          </div>
        )}
      </Wrapper>
    </AuthWrapper>
  )
}

const Wrapper = styled(Form)`
  max-width: 500px;
  margin: 0 auto;
  background: #fff;
  box-shadow: var(--default-box-shadow);
  padding: var(--default-padding);
  position: relative;

  @media (max-width: 768px) {
    max-width: none;
    margin: var(--mobile-margin);
  }
`
const FormTitle = styled.div`
  margin-bottom: var(--default-padding);
  text-align: center;
`
const SubmitButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--default-padding);
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.6);
  margin: 30px 0;
`

const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
