import { useEffect, useState } from 'react'
import { Cluster } from '../types'

type RestrictedOperations = { [key: string]: boolean }

export const useModalRestrictedOperations = (
  clusters: Cluster[],
  selectedClusterUUID: string[] | null
) => {
  const [restrictedOperations, setRestrictedOperations] =
    useState<RestrictedOperations>({})
  useEffect(() => {
    const filteredClusters: Cluster[] = clusters.filter((c) =>
      selectedClusterUUID?.find((uuid) => uuid === c.cluster_uuid)
    )
    let restrictedOperations: RestrictedOperations = {}
    if (
      filteredClusters.filter((c) => c?.hidden).length ||
      (filteredClusters.filter((c) => c?.visible).length &&
        filteredClusters.filter((c) => !c?.visible).length)
    ) {
      restrictedOperations = {
        ...restrictedOperations,
        hide: true,
        unhide: true,
        simple_feedback: true,
      }
    } else if (
      filteredClusters.filter((c) => c?.hidden).length ||
      !filteredClusters.filter((c) => c?.visible).length
    ) {
      restrictedOperations = {
        ...restrictedOperations,
        hide: true,
        unhide: false,
        simple_feedback: true,
      }
    } else {
      restrictedOperations = {
        ...restrictedOperations,
        hide: false,
        unhide: true,
        simple_feedback: false,
      }
    }

    if (
      filteredClusters.filter((c) => c?.is_assignable).length ===
      filteredClusters.length
    ) {
      restrictedOperations = {
        ...restrictedOperations,
        merge: true,
      }
    } else {
      restrictedOperations = {
        ...restrictedOperations,
        merge: false,
      }
    }

    if (
      filteredClusters.filter((c) => typeof c?.group_id === 'number').length ===
      filteredClusters.length
    ) {
      restrictedOperations = {
        ...restrictedOperations,
        group: false,
        ungroup: false,
      }
    } else {
      restrictedOperations = {
        ...restrictedOperations,
        group: false,
        ungroup: true,
      }
    }
    setRestrictedOperations(restrictedOperations)
  }, [selectedClusterUUID])

  return restrictedOperations
}
