import React, { useEffect, useMemo, useState } from 'react'
import { Select } from 'antd'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { getPostSample, ReviewCardType } from '../model'
import { Loader } from '../../../../../shared/components'
import { FilterOptionSelect } from '../../filters/types'
import { InnerTitle } from '../../../../../components/InnerTitle'
import { YogiButton } from 'components/UI/YogiButton'
import mixpanel from '../../../../trackers/mixpanel'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ReviewCard from './ReviewCard'

const ReviewComponent: React.FC = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const datasets = useMemo(() => {
    return (
      (
        defaultFilterList?.find(
          (el) => el.field === 'proj_uuid'
        ) as FilterOptionSelect
      )?.values ?? []
    )
  }, [defaultFilterList])

  const datasetOptions = useMemo(() => {
    return (
      (
        defaultFilterList?.find(
          (el) => el.field === 'proj_uuid'
        ) as FilterOptionSelect
      )?.values?.map((el) => ({ label: el.value, value: el.cluster_uuid })) ??
      []
    )
  }, [defaultFilterList])

  const [dataset, setDataset] = useState<string | undefined>(
    datasets?.length ? datasets[0].cluster_uuid : undefined
  )
  const [reviewIndex, setReviewIndex] = useState<number>(0)

  useEffect(() => {
    setReviewIndex(0)
  }, [dataset])

  const { data, isLoading } = useQuery(
    ['post-sample', projectId, dataset],
    () => {
      return getPostSample(projectId, dataset)
    },
    { refetchOnWindowFocus: false, enabled: !!projectId }
  )

  const dedupedData = useMemo(() => {
    if (!data) return []
    const seenSentences = new Set<string>()
    const dedupedObjects: ReviewCardType[] = []

    for (const obj of data) {
      // Create a sorted array of sentence texts and convert it to a unique key
      const sortedSentencesKey = obj.sentences
        .map((el) => el.text)
        .sort()
        .join('')

      if (!seenSentences.has(sortedSentencesKey)) {
        seenSentences.add(sortedSentencesKey)
        dedupedObjects.push(obj)
      }
    }

    return dedupedObjects
  }, [data])

  return (
    <Container>
      <Greeting
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', gap: 10 }}>
            <InnerTitle
              style={{
                whiteSpace: 'nowrap',
                fontWeight: '400',
                fontSize: '20px',
              }}
            >
              Here are some {/*<Tooltip*/}
              {/*  title={'Reviews with 3+ sentences that include multiple themes'}*/}
              {/*>*/}
              {/*  <a href="#" onClick={() => false}>*/}
              notable
              {/*  </a>*/}
              {/*</Tooltip>*/} recent reviews
              {datasets ? ' in the dataset' : ':'}
            </InnerTitle>
            {datasets && (
              <Dropdown style={{ flexBasis: '100%' }}>
                <StyledSelect
                  style={{ width: '100%' }}
                  value={dataset}
                  onChange={(value) => {
                    mixpanel.track('expanded reviews', {
                      action: 'dataset',
                      value: value,
                      proj_uuid: projectId,
                    })
                    // por que señor
                    // @ts-ignore
                    setDataset(value)
                  }}
                >
                  {datasetOptions?.map((el) => (
                    <Select.Option value={el.value}>{el.label}</Select.Option>
                  ))}
                </StyledSelect>
              </Dropdown>
            )}
          </div>
        </div>
      </Greeting>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <div>
          {dedupedData && dedupedData.length > 0 ? (
            <ReviewCardWrapper>
              <div style={{ marginTop: 200 }}>
                <YogiButton
                  type={'primary'}
                  disabled={reviewIndex === 0}
                  onClick={() => {
                    mixpanel.track('expanded reviews', {
                      action: 'previous',
                      proj_uuid: projectId,
                    })
                    setReviewIndex(reviewIndex - 1)
                  }}
                >
                  <LeftOutlined />
                  {' Previous'}
                </YogiButton>
              </div>
              {dedupedData[reviewIndex] && (
                <ReviewCard key={reviewIndex} {...dedupedData[reviewIndex]} />
              )}
              <div style={{ marginTop: 200 }}>
                <YogiButton
                  type={'primary'}
                  disabled={reviewIndex === dedupedData.length - 1}
                  onClick={() => {
                    mixpanel.track('expanded reviews', {
                      action: 'next',
                      proj_uuid: projectId,
                    })
                    setReviewIndex(reviewIndex + 1)
                  }}
                >
                  {'Next '}
                  <RightOutlined />
                </YogiButton>
              </div>
            </ReviewCardWrapper>
          ) : (
            <div>No data</div>
          )}
        </div>
      )}
    </Container>
  )
}

export default ReviewComponent

const Container = styled.div`
  padding: 10px 20px;
  width: 100%;
  max-width: 1400px;
  background: var(--card-background-white);
`

const Greeting = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`

const Dropdown = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: start;
`

const LoaderWrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
`

const ReviewCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`
const StyledSelect = styled(Select)`
  .ant-select-selector {
    background: transparent !important;
    border: none !important;

    color: var(--color-text-dark-grey);
    font-size: 20px;
    padding: 0 !important;
    padding-right: 10px !important;
  }
  .ant-select-selection-item {
    text-decoration: underline;
    text-decoration-color: var(--color-text-dark-grey);
  }
  .ant-select-arrow {
    color: var(--color-text-dark-grey) !important;
  }
`
