import { useState, useCallback, useEffect, useRef } from 'react'
import axios from 'axios'
import Graph from 'graphology'
// import enhanceWithSelectionTool from '@rkolpakov/sigma-experiments-selection-tool/selection-tool'
import Sigma from 'sigma'
import { getPostText } from 'features/project/model'
import { highlightSelected, setGraphColors } from './utils'
import { GraphContext, ContextPosition, GraphType } from './types'
import { useClusterContext } from '../cluster'
import { processLabel } from 'utils/processLabel'
import _ from 'lodash'

const defaultContextPosition: ContextPosition = null
let cancelToken = axios.CancelToken.source()

export const useGraph = (): GraphContext => {
  const { groups, clusters } = useClusterContext()
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement>()
  const [graph, setGraph] = useState<GraphType | null>(null)
  const [renderer, setRenderer] = useState<any | null>(null)
  const [selectedNode, setSelectedNode] = useState<string | null>(null)
  const [selectedNodeList, setSelectedNodeList] = useState<Array<string>>([])
  const [contextPosition, setContextPosition] = useState<ContextPosition>(
    defaultContextPosition
  )
  const highlightedNode = useRef()

  function initRenderer() {
    if (renderer) {
      renderer.kill()
    }

    if (!canvasElement) return

    const newRenderer = new Sigma(graph, canvasElement, {
      zIndex: true,
      labelSize: 14,
      // TODO this used to not be commented but TS was yelling about it, keep an eye
      // @ts-ignore
      // labelGrid: {
      //   cell: {
      //     width: 250,
      //     height: 50,
      //   },
      //   renderedSizeThreshold: 100,
      // },
      hoverRenderer: (context, data, settings) => {
        let textWidth = 0
        data.hoverLabel?.split('\n').forEach((item: string) => {
          textWidth = Math.max(textWidth, context.measureText(item).width)
        })

        var size = settings.labelSize,
          font = settings.labelFont,
          weight = settings.labelWeight
        context.font = weight + ' ' + size + 'px ' + font

        context.beginPath()
        context.fillStyle = '#fff'
        context.shadowOffsetX = 0
        context.shadowOffsetY = 0
        context.shadowBlur = 8
        context.shadowColor = '#000'
        data.size = 16
        var x = Math.round(data.x - size / 2 - 2),
          y = Math.round(data.y - size / 2 - 2),
          w = Math.round(textWidth + size / 2 + data.size + 9) + 10,
          h =
            Math.round(
              (data.hoverLabel?.split('\n').length || 0) * (size + 1.5)
            ) + 12,
          e = Math.round(size / 2 + 2)
        context.moveTo(x, y + e)
        context.moveTo(x, y + e)
        context.arcTo(x, y, x + e, y, e)
        context.lineTo(x + w, y)
        context.lineTo(x + w, y + h)
        context.lineTo(x + e, y + h)
        context.arcTo(x, y + h, x, y + h - e, e)
        context.lineTo(x, y + e)
        context.closePath()
        context.fill()
        context.shadowOffsetX = 0
        context.shadowOffsetY = 0
        context.shadowBlur = 0

        context.fillStyle = data.color
        context.beginPath()
        context.arc(data.x, data.y, data.size, 0, Math.PI * 2, true)
        context.closePath()
        context.fill()

        var labelSize = settings.labelSize,
          labelFont = settings.labelFont,
          labelWeight = settings.labelWeight
        context.fillStyle = '#000'
        context.font = labelWeight + ' ' + labelSize + 'px ' + labelFont
        data.hoverLabel?.split('\n').forEach((line: string, index: number) => {
          context.fillText(
            line,
            data.x + data.size + 3,
            data.y + labelSize / 3 + (labelSize + 1.5) * index + 5
          )
        })
      },
    })

    // TODO could be an issue - selectNodes is not a provided action
    // @ts-ignore
    newRenderer.on('selectNodes', ({ nodes }: any) => {
      // Takes care of case where "hidden" nodes may show up
      // under a selected section since their size is 0.001
      const subNodeList: any[] = []
      nodes.forEach(function (node: any) {
        const currentSize = graph.getNodeAttribute(node, 'size')
        if (currentSize >= 1) {
          subNodeList.push(node)
        }
      })

      setSelectedNodeList(subNodeList)
      highlightSelected(graph, subNodeList)
    })

    newRenderer.on('clickNode', ({ node }: any) => {
      setSelectedNode(highlightedNode.current ?? node)
    })

    newRenderer.on('rightClickNode', ({ event }: any) => {
      setContextPosition({ x: event.x, y: event.y })
    })

    newRenderer.on('rightClickStage', ({ event }: any) => {
      setContextPosition({ x: event.x, y: event.y })
    })

    newRenderer.on(
      'enterNode',
      _.debounce(async ({ node }) => {
        highlightedNode.current = node
        const text = graph.getNodeAttribute(node, 'label')
        if (!text) {
          getPostText(node, cancelToken)
            .then(({ data }) => {
              graph.mergeNodeAttributes(node, {
                hoverLabel: processLabel(20, data),
              })
            })
            .catch(() => null)
        }
      }, 120)
    )

    // enhanceWithSelectionTool(newRenderer, {
    //   borderStyle: '2px dashed red',
    // })

    setRenderer(newRenderer)
  }

  const setup = useCallback((canvasElement: HTMLCanvasElement) => {
    try {
      // const graph = new Graph({ type: 'mixed' })
      const graph = new Graph()
      setCanvasElement(canvasElement)
      setGraph(graph)

      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }, [])

  useEffect(() => {
    const dataLength = clusters.length + groups.length
    if (graph && dataLength) {
      setGraphColors(graph, clusters, groups)
      initRenderer()
    }
  }, [clusters, groups, graph])

  const resetCameraPosition = () => {
    if (renderer) {
      renderer.camera.x = 0.5
      renderer.camera.y = 0.5
      renderer.camera.ratio = 1
    }
  }

  return {
    graph,
    setup,
    renderer,
    resetCameraPosition,
    selectedNode,
    selectedNodeList,
    contextPosition,
    setSelectedNode,
    setSelectedNodeList,
    setContextPosition: (p) => setContextPosition(p || defaultContextPosition),
  }
}
