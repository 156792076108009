import { useQuery } from 'react-query'
import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { getCountries } from '../features/charts/model'

export const useCountries = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  return useQuery(
    ['project-countries', projectId],
    () => getCountries({ proj_uuid: projectId }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      enabled: !!projectId,
    }
  )
}
