import React, { useContext } from 'react'
import styled from 'styled-components'
import { AuthContext } from '../../../../auth'
import {
  CampaignOutlined,
  ChatOutlined,
  FavoriteBorderOutlined,
  StarOutlineOutlined,
  ThumbUpOutlined,
} from '@mui/icons-material'
import {
  secondaryBlue,
  secondaryGreen,
  secondaryOrange,
  starYellow,
} from '../../../../../assets/styles/variables'

const pluralize = require('pluralize')

const infoPanelObj = {
  '# Reviews & Ratings': {
    title: '# Reviews & Ratings',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  '# Sentences': {
    title: '# Sentences',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  '# Reviews': {
    title: '# Reviews',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  '# Ratings Only': {
    title: '# Ratings Only',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  'Average Rating': {
    title: 'Average Rating',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  'Average Sentiment': {
    title: 'Average Sentiment',
    icon: <FavoriteBorderOutlined style={{ padding: '1px' }} />,
    color: secondaryGreen,
  },
  'Promotional Reviews': {
    title: 'Promotional Reviews',
    icon: <CampaignOutlined />,
    color: secondaryOrange,
  },
  'Product Recommendations': {
    title: 'Product Recommendations',
    icon: <ThumbUpOutlined />,
    color: secondaryBlue,
  },
}

type Props = {}
export const Header: React.FC<Props> = ({}) => {
  const authContext = useContext(AuthContext)

  return (
    <Container>
      <h1 style={{ marginBottom: 0 }}>Automated Insights</h1>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  margin: 0 auto 5px;
`
