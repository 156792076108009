import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  BarcodeOutlined,
} from '@ant-design/icons'
import { Button, Select } from 'antd'
import styled from 'styled-components'
import {
  buttonBlue,
  cardBorderGrey,
  lightText,
  mainText,
  pageBackgroundGrey,
  secondaryNavy,
} from '../../../../assets/styles/variables'
import { YogiSearch } from '../../../../components/UI/YogiSearch'
import mixpanel from '../../../trackers/mixpanel'
import { useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { useQuery } from 'react-query'
import { getProductHierarchiesForProject } from '../products/model'
import { Loader } from '../../../../shared/components'
import _ from 'lodash'
import { emptyFilters, PDP_URL } from '../../utils'
import { LogoSpinner } from '../../../../components/LogoSpinner'
import { InnerTitle } from '../../../../components/InnerTitle'
import { ProductHierarchyInfo } from '../products/types'
import { useLocalFilterList } from '../../hooks/useLocalFilterList'
import { FilterOption, FilterOptionSelect } from '../filters/types'
import { YogiSelect } from '../../../../components/UI/YogiSelect'
import { Fetcher } from '../../../../shared/components/fetcher'

export interface FocusListOption {
  value: string
  n_posts: number
  identifiers: string[]
  product_count?: number
  image_url?: string
  cluster_uuid?: string
}

export interface FocusData {
  title: string
  reviewCount: number
  productCount?: number
  imageUrl?: string
}

interface FocusProps extends FocusData {
  onSelect: (value: string) => void
  disabled?: boolean
}

const PDPItem: React.FC<FocusProps> = ({
  title,
  reviewCount,
  imageUrl,
  productCount,
  onSelect,
  disabled,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  return (
    <a
      style={{
        textDecoration: 'none',
        color: mainText,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      href={`${window.location.origin}/project/${projectId}/${PDP_URL}?product=${title}`}
      onClick={(e) => {
        e.preventDefault()
        if (disabled) return
        mixpanel.track('pdp insights', {
          action: 'select product',
          value: title,
        })
      }}
    >
      <PDPItemContainer
        disabled={disabled}
        onClick={() => {
          if (disabled) return
          onSelect(title)
        }}
      >
        <ColorBar color={disabled ? lightText : secondaryNavy} />
        <PDPItemHeader>
          <h3
            style={{
              color: disabled ? lightText : mainText,
            }}
          >
            {title.length > 100 ? `${title.slice(0, 100)}...` : title}
            {disabled && (
              <div style={{ color: lightText, fontSize: 12 }}>
                No Amazon sources for this product
              </div>
            )}
          </h3>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 5,
            }}
          >
            <div>
              {productCount && productCount > 1 && (
                <>
                  Items: {productCount.toLocaleString()}
                  <br />
                </>
              )}
              Reviews: {reviewCount.toLocaleString()}
            </div>
          </div>
        </PDPItemHeader>
        {imageUrl && (
          <ImageWrapper>
            {imageUrl ? (
              <img
                src={imageUrl}
                alt=""
                style={{
                  height: 'auto',
                  width: 'auto',
                  maxHeight: 80,
                  opacity: disabled ? 0.5 : 1,
                }}
              />
            ) : (
              <BarcodeOutlined style={{ fontSize: '60px' }} />
            )}
          </ImageWrapper>
        )}
        {/*<p>Last Updated: {new Date(date).toLocaleDateString()}</p>*/}
      </PDPItemContainer>
    </a>
  )
}

interface FocusListProps {
  selectedLens: string | null
  setSelectedProduct: (title: string) => void
  focusListData: ProductHierarchyInfo[]
  setFocusListData: (value: ProductHierarchyInfo[]) => void
}

const PDPList: React.FC<FocusListProps> = ({
  selectedLens,
  setSelectedProduct,
  focusListData,
  setFocusListData,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const { Option } = Select

  const [compareSet, setCompareSet] = useState<string[]>([])

  const {
    data: hierarchyData,
    isLoading,
    // isError,
    // error,
  } = useQuery(
    ['getProductHierarchiesForProject', projectId],
    () =>
      getProductHierarchiesForProject(
        'getProductHierarchiesForProject',
        projectId
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && selectedLens === 'product_hierarchy',
    }
  )

  const [selectedDataset, setSelectedDataset] = useState<string>('')
  const [selectedBrand, setSelectedBrand] = useState<string>('')

  const [filterList, setFilterList] = useState<FilterOption[]>([])

  // useEffect(() => {
  //   if (defaultFilterList.length) {
  //     setFilterList(defaultFilterList)
  //   }
  // }, [defaultFilterList])

  const [activeFilters, setActiveFilters] = useState<Filters>(
    _.cloneDeep(emptyFilters)
  )

  useEffect(() => {
    const tempFilters = _.cloneDeep(emptyFilters)
    if (selectedBrand) {
      tempFilters.values.push({ field: 'brand', values: [selectedBrand] })
    }
    if (selectedDataset) {
      tempFilters.values.push({ field: 'proj_uuid', values: [selectedDataset] })
    }
    setActiveFilters(tempFilters)
  }, [selectedBrand, selectedDataset])

  const {
    data: filterListData,
    isLoading: filterListLoading,
    isError,
    error,
  } = useLocalFilterList(
    activeFilters,
    'product_hierarchy',
    ['product_hierarchy'],
    filterList,
    setFilterList,
    !(selectedBrand || selectedDataset)
  )

  useEffect(() => {
    if (filterListData) {
      setFilterList(filterListData)
    }
  }, [filterListData])
  //
  // const data: FocusListOption[] = useMemo(() => {
  //   return (
  //     (
  //       filterList.find(
  //         (el) => el.field === 'product_hierarchy'
  //       ) as FilterOptionSelect
  //     )?.values ?? []
  //   )
  // }, [filterList, selectedLens])

  const [sortField, setSortField] = useState<'title' | 'reviews'>('reviews')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  const [searchTerm, setSearchTerm] = useState('')
  const [category, setCategory] = useState('All')

  const onSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const dedupedValues: any = {}

  useEffect(() => {
    if (!hierarchyData) return

    let sortData = _.cloneDeep(hierarchyData)

    // do filtering
    if (selectedBrand || selectedDataset) {
      sortData = sortData.filter((item) => {
        return !!(
          filterList.find(
            (el) => el.field === 'product_hierarchy'
          ) as FilterOptionSelect
        )?.values?.find((el) => el.value === item.hierarchy)?.n_posts
      })
    }
    // sortData.forEach((item) => {
    //   if (!dedupedValues[item.hierarchy]) {
    //     dedupedValues[item.hierarchy] = item
    //     dedupedValues[item.hierarchy].identifiers = []
    //   } else {
    //     dedupedValues[item.hierarchy].n_posts += item.review_count
    //     if (item.product_count) {
    //       dedupedValues[item.hierarchy].product_count += item.product_count
    //     }
    //   }
    //   dedupedValues[item.hierarchy].identifiers.push(item.hierarchy)
    // })
    //
    // sortData = Object.keys(dedupedValues).map((key) => ({
    //   ...dedupedValues[key],
    //   hierarchy:key
    // }))

    sortData.sort((a, b) => {
      let result

      if (sortField === 'title') {
        result = a.hierarchy.localeCompare(b.hierarchy)
      }
      // else if (sortField === 'date') {
      //   result =
      //     new Date(a.last_review).getTime() - new Date(b.last_review).getTime()
      // }
      else {
        result = b.review_count - a.review_count
      }

      return sortOrder === 'desc' ? result : -result
    })

    if (searchTerm) {
      sortData = sortData.filter((item) =>
        item.hierarchy.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
    setFocusListData(sortData)
  }, [hierarchyData, sortField, sortOrder, searchTerm, category, filterList])

  // if (isLoading) return <p>Loading...</p>
  // if (isError) return <p>Error: {error.message}</p>

  return (
    <Container>
      <Header>
        <h1 style={{ margin: 0 }}>PDP Insights</h1>
        <div style={{ color: lightText }}>
          *Note - PDP Analysis is currently only available for Amazon PDPs.
        </div>
        {/*<YogiButton*/}
        {/*  type="primary"*/}
        {/*  onClick={() => {*/}
        {/*    setSelectedFocus(null)*/}
        {/*    setIsDrawerOpen(true)*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Create Catalog*/}
        {/*</YogiButton>*/}
        {/*<div style={{ flexBasis: '50%' }}>*/}
        {/*  <Omnibar size={'small'} />*/}
        {/*</div>*/}
      </Header>
      <Controls>
        <FilterSearchControls>
          <FilterControls>
            <SortControls>
              <label>Filter:&nbsp;</label>
              {!!(
                defaultFilterList?.find(
                  (el) => el.field === 'proj_uuid'
                ) as FilterOptionSelect
              )?.values && (
                <Select
                  style={{ minWidth: 200 }}
                  value={selectedDataset}
                  // defaultValue={'All Brands'}
                  onChange={(value: string) => setSelectedDataset(value)}
                  options={[
                    { value: '', label: 'All Datasets' },
                    ...((
                      defaultFilterList.find(
                        (el) => el.field === 'proj_uuid'
                      ) as FilterOptionSelect
                    )?.values?.map((el) => ({
                      value: el.cluster_uuid,
                      label: el.value,
                    })) ?? []),
                  ]}
                />
              )}
              <Select
                style={{ minWidth: 200 }}
                value={selectedBrand}
                // defaultValue={'All Brands'}
                onChange={(value: string) => setSelectedBrand(value)}
                options={[
                  { value: '', label: 'All Brands' },
                  ...((
                    defaultFilterList.find(
                      (el) => el.field === 'brand'
                    ) as FilterOptionSelect
                  )?.values?.map((el) => ({
                    value: el.value,
                    label: el.value,
                  })) ?? []),
                ]}
              />
            </SortControls>
            {/*<StyledSelect*/}
            {/*  showSearch*/}
            {/*  optionFilterProp="children"*/}
            {/*  filterOption={(input, option) =>*/}
            {/*    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
            {/*  }*/}
            {/*  style={{ width: 250 }}*/}
            {/*  value={selectedLens}*/}
            {/*  onChange={(v: any) => {*/}
            {/*    mixpanel.track('catalog', { action: 'lens', value: v })*/}
            {/*    setSelectedProduct(v)*/}
            {/*  }}*/}
            {/*  placeholder="Catalog Lens"*/}
            {/*  options={lensList}*/}
            {/*/>*/}

            <SortControls>
              <label>
                Sort by:&nbsp;
                <Select
                  value={sortField}
                  onChange={(value) => setSortField(value)}
                  style={{ minWidth: 150 }}
                >
                  <Option value="title">Name</Option>
                  <Option value="reviews">Review Count</Option>
                  {/*<Option value="date">Date</Option>*/}
                  {/*<Option value="active">Active</Option>*/}
                </Select>
              </label>
              <StyledButton
                onClick={() =>
                  setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                }
              >
                {sortOrder === 'asc' ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )}
              </StyledButton>
            </SortControls>
          </FilterControls>
          <SearchContainer>
            <YogiSearch onChange={onSearch} />
          </SearchContainer>
          {/*<PillContainer>*/}
          {/*  {categories.map((el, index) => (*/}
          {/*    <>*/}
          {/*      <Pill*/}
          {/*        key={index}*/}
          {/*        value={el}*/}
          {/*        selected={el === category}*/}
          {/*        onClick={() => {*/}
          {/*          setCategory(el)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {el}*/}
          {/*      </Pill>*/}
          {/*      {index === 0 && <VerticalDivider />}*/}
          {/*    </>*/}
          {/*  ))}*/}
          {/*</PillContainer>*/}
        </FilterSearchControls>
      </Controls>
      {isLoading ? (
        <LoadingWrapper>
          <LogoSpinner>
            <InnerTitle>Loading Products</InnerTitle>
          </LogoSpinner>
        </LoadingWrapper>
      ) : (
        <Content>
          {selectedLens === 'product_hierarchy' && isLoading ? (
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Loader style={{ height: '50%', maxHeight: 300 }} />
              <LoaderTitle>{`Loading Options`}</LoaderTitle>
            </div>
          ) : (
            <Grid container spacing={2} style={{ padding: 20, marginTop: -30 }}>
              {focusListData?.map((focus, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} spacing={2}>
                  <PDPItem
                    title={focus.hierarchy}
                    reviewCount={focus.review_count}
                    productCount={focus.product_count}
                    imageUrl={focus.image_url}
                    onSelect={setSelectedProduct}
                    disabled={
                      focus.source_types &&
                      !focus.source_types.includes('Amazon')
                    }
                  />
                </Grid>
              ))}
              {focusListData.length === 0 && (
                <div
                  style={{ textAlign: 'center', width: '100%', marginTop: 40 }}
                >
                  <InnerTitle>No Products Found</InnerTitle>
                </div>
              )}
            </Grid>
          )}
        </Content>
      )}
      {/*<AlertDrawer isOpen={!!compareSet.length}>*/}
      {/*  <div>*/}
      {/*    {compareSet.map((item, index) => (*/}
      {/*      <div>{item}</div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <YogiButton*/}
      {/*      type={'primary'}*/}
      {/*      onClick={() => setSelectedFocus(compareSet)}*/}
      {/*    >*/}
      {/*      Compare*/}
      {/*    </YogiButton>*/}
      {/*  </div>*/}
      {/*</AlertDrawer>*/}
      {filterListLoading && (
        <Overlay>
          <Fetcher />
        </Overlay>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Header = styled.div`
  background: white;
  padding: var(--default-padding-half) var(--default-padding);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  //display: flex;
  justify-content: space-between;
  align-items: center;
`

const Controls = styled.div`
  padding: var(--default-padding-half) var(--default-padding)
    var(--default-padding-quarter);
`

const FilterSearchControls = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`
const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
`

const SortControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  //width: 100%;
  align-items: center;
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //flex-basis: 30%;
  min-width: 300px;
  margin-bottom: 10px;
`

const Content = styled.div`
  //padding: var(--default-padding-half) var(--default-padding);
  //height: 100%;
  overflow: auto;
`

const PDPItemContainer = styled.div<{ disabled?: boolean }>`
  padding: var(--default-padding-quarter) var(--default-padding-half);
  padding-left: calc(var(--default-padding-quarter) + 12px);
  background: ${(props) => (props.disabled ? '#eaeaea' : '#FFF')};
  color: ${(props) => (props.disabled ? lightText : mainText)};
  box-shadow: var(--default-box-shadow);
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: var(--border-radius);
  height: 100%;
  display: flex;
  //flex-direction: column;
  justify-content: space-between;
  &:hover {
    background: ${(props) => (props.disabled ? '#eaeaea' : pageBackgroundGrey)};
  }
`

const PDPItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
`

const StyledSelect = styled(Select)`
  height: 36px;

  .ant-select {
    height: 100%;
  }

  .ant-select-selector {
    height: 100% !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
  }

  //.ant-switch-inner {
  //  font-size: 12px;
  //}
`

const Drawer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background: white;
  height: 200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  transform: ${(props) =>
    props.isOpen ? 'translateY(0px)' : 'translateY(200px)'};
  transition: 0.3s ease-in-out;
`

export default PDPList

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  max-width: 50%;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const StyledButton = styled(Button)`
  border-radius: 6px;
  border: 2px solid ${cardBorderGrey};
  color: ${mainText};
  &:hover {
    border-color: ${buttonBlue};
  }
`

const ColorBar = styled.div<{ color?: string }>`
  width: 8px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
`

const LoadingWrapper = styled.div`
  height: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  //width: 100%;
  //height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`
