import { useQuery } from 'react-query'
import React from 'react'
import { useProjectStore } from '../../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../../types'
import { getBookmarkUrlPost } from '../../../../../../../url-params/model'
import { CHARTS_URL } from '../../../../../../utils'
import { Link } from 'react-router-dom'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { mainText } from '../../../../../../../../assets/styles/variables'
import { YogiButton } from '../../../../../../../../components/UI/YogiButton'
import { OpenInNewOutlined } from '@mui/icons-material'
import { LoadingOutlined } from '@ant-design/icons'
import { Chart } from '../../../../../dashboard/components/Dashboard/Charts/chart'
import { ChartTitle, Container } from './InsightChart.styles'

type Props = {
  chartItem: any
  style?: React.CSSProperties
}

const InsightChart: React.FC<Props> = ({ chartItem, style }) => {
  // props
  const { filterValues } = chartItem

  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // api
  const { data: linkData } = useQuery(
    ['getFilterHash', projectId, filterValues, chartItem.dashboardControls],
    () =>
      getBookmarkUrlPost(projectId, {
        page: `${CHARTS_URL}`,
        filterString: JSON.stringify({
          filters: [filterValues],
          pageControls: [chartItem.dashboardControls],
        }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!chartItem?.dashboardControls,
    }
  )

  return (
    <Container>
      <ChartTitle>
        {chartItem.title}
        {linkData ? (
          <Link
            target={'_blank'}
            to={`${CHARTS_URL}?filters=${linkData}`}
            onClick={() => {
              mixpanel.track('catalog', {
                action: 'open chart link',
                value: chartItem.dashboardControls.chartId,
              })
            }}
            style={{
              color: mainText,
              marginTop: -5,
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              marginRight: 10,
            }}
          >
            <YogiButton
              type={'ghost'}
              style={{ display: 'flex', alignItems: 'center', gap: 5 }}
            >
              <OpenInNewOutlined fontSize={'inherit'} />
            </YogiButton>
          </Link>
        ) : (
          <LoadingOutlined />
        )}
      </ChartTitle>
      <Chart
        key={JSON.stringify(filterValues)}
        filters={filterValues}
        chartId={chartItem.dashboardControls.chartId}
        tier1={chartItem.dashboardControls.tier1}
        comparativeIndex={0}
        isComparative={false}
        dashboardControls={chartItem.dashboardControls}
        item={chartItem}
        hideHeader
      />
    </Container>
  )
}

export default InsightChart
