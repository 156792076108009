import * as Yup from 'yup'
import { UploadType } from 'utils/enums'
import { customSources } from './components'
import { amazonDomains } from './components/sources/amazon-utils'

export const SourceSchema = Yup.object().shape({
  sourceType: Yup.string().required('A source type is required'),
  sourceTitle: Yup.string()
    .required('A source title is required')
    .max(100, '100 Character Max for Title'),
  subtype: Yup.array<string>().test(
    'have-data-types',
    'You must select at least one data type',
    function (value) {
      const {
        parent: { sourceType },
      } = this
      if (sourceType !== 'Amazon') return true
      if (value.length) return true
      return this.createError({
        message: 'You must select at least one data type',
      })
    }
  ),
  sourceUrl: Yup.string()
    .when('sourceType', (sourceType: string, schema: any, { value }: any) => {
      if (
        sourceType === UploadType.File ||
        customSources.includes(sourceType)
      ) {
        return schema
      }

      return schema.required('You must add the URL')
    })
    .test('supports-amazon', 'You must add the URL', function (value) {
      const {
        parent: { sourceType },
      } = this

      if (sourceType === 'Amazon' && value) {
        if (amazonDomains.indexOf(value) !== -1) {
          return this.createError({
            message: 'You must add the URL',
          })
        }
      }
      return true
    }),
  sourceDate: Yup.date().when('sourceType', (sourceType: string, schema: any) =>
    sourceType !== UploadType.File
      ? schema.required('You must select the Date')
      : schema
  ),
  sourceFile: Yup.mixed().when(
    'sourceType',
    (sourceType: string, schema: any) =>
      sourceType === UploadType.File
        ? schema.required('You must upload the file')
        : schema
  ),
  product_id: Yup.string().required('You have to specify product'),

  // META OBJECT

  title: Yup.number().when(
    ['body', 'sourceType'],
    (metaBody: number, sourceType: any, schema: any) => {
      return sourceType === UploadType.File && !metaBody
        ? schema.required('You must select the title or body')
        : schema
    }
  ),
  body: Yup.number().nullable(),
  input_id: Yup.number().nullable(),
  username: Yup.number().nullable(),
  userid: Yup.number().nullable(),
  location: Yup.number().nullable(),
  create_time: Yup.number().nullable(),
  score: Yup.number().nullable(),
  url: Yup.number().nullable(),
  custom: Yup.object().nullable(),

  // EXTRA OBJECT
  access_token: Yup.string().when(
    'sourceType',
    (sourceType: string, schema: any) =>
      customSources.includes(sourceType)
        ? schema.required(`You need to connect your ${sourceType} account`)
        : schema
  ),
  page_id: Yup.string().nullable(),
  brand: Yup.string().nullable(),
  email: Yup.string().nullable(),
  client_id: Yup.string().when(
    'sourceType',
    (sourceType: string, schema: any) =>
      sourceType === 'Powerreviews'
        ? schema.required(`You need to connect your ${sourceType} account`)
        : schema
  ),
  locale: Yup.string().nullable(),
  extra_product_id: Yup.string().when(
    'sourceType',
    (sourceType: string, schema: any) =>
      customSources.includes(sourceType)
        ? schema.required(`Select the ${sourceType} product`)
        : schema
  ),
})
