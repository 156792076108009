import { YogiButton } from '../../../../components/UI/YogiButton'
import mixpanel from '../../../trackers/mixpanel'
import { LoadingOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Badge, InputProps } from 'antd'
import React from 'react'
import { useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { getBadgeLabels, reducerToComputeFiltersCount } from './helpers'
import { FilterOption } from './types'

type Props = {
  filterValues: Filters
  filterList: FilterOption[]
  isFilterPanelVisible: boolean
  setFilterPanelVisible: any
  filterOptionsLoading: boolean
  comparativePanelsNumber?: number
  isSmall?: boolean
  disabled?: boolean
}
export const FilterButton: React.FC<Props> = ({
  filterValues,
  filterList,
  isFilterPanelVisible,
  setFilterPanelVisible,
  filterOptionsLoading,
  comparativePanelsNumber,
  isSmall,
  disabled,
}) => {
  const { isDashboard, isNetwork, isFeedback, isKeywords } = useProjectStore(
    (state: ProjectState) => state.route
  )
  const countries = useProjectStore((state: ProjectState) => state.countries)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const globalAppliedFiltersCount = filterValues.values
    .filter(
      (el) =>
        !el.field.startsWith('search_query') &&
        !el.field.startsWith('search_condition') &&
        (countries?.enabled ? !el.field.startsWith('country') : true)
    )
    .map((el) => ({
      field: el.field,
      // @ts-ignore
      values: [...new Set(getBadgeLabels(el, defaultFilterList))],
    }))
    .reduce(reducerToComputeFiltersCount, 0)
  return (
    <Badge count={globalAppliedFiltersCount} size="small" style={{ zIndex: 1 }}>
      <YogiButton
        disabled={disabled}
        id="filters-button"
        // disabled={filterOptionsLoading}
        type={isFilterPanelVisible ? 'default' : 'primary'}
        onClick={() => {
          setFilterPanelVisible(!isFilterPanelVisible)
          mixpanel.track('filter panel open', {
            isDashboard,
            isNetwork,
            isFeedback,
            isKeywords,
          })
        }}
        style={{ padding: isSmall ? '0 10px' : '' }}
      >
        {comparativePanelsNumber === 3 || isSmall ? (
          <span style={{ pointerEvents: 'none' }}>
            {filterOptionsLoading ? (
              <LoadingOutlined style={{ pointerEvents: 'none' }} />
            ) : (
              <UnorderedListOutlined style={{ pointerEvents: 'none' }} />
            )}
          </span>
        ) : (
          <span style={{ pointerEvents: 'none' }}>
            {filterOptionsLoading ? (
              <LoadingOutlined
                style={{
                  pointerEvents: 'none',
                  marginLeft: -3,
                  marginRight: 8,
                }}
              />
            ) : (
              <UnorderedListOutlined
                style={{
                  pointerEvents: 'none',
                  marginLeft: -3,
                  marginRight: 8,
                }}
              />
            )}
            {''}
            Filters
          </span>
        )}
      </YogiButton>
    </Badge>
  )
}
