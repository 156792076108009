import { FilterPreset } from 'features/project/types'

export enum FilterTypes {
  SELECT = 'select',
  WINDOW = 'window',
  DATE = 'date',
}

export type FilterOptionValue = {
  value: string
  n_posts: number
  cluster_uuid?: string
  identifiers: string[]
}

interface FilterOptionBasis {
  alias: string
  field: string
  description?: string
}

export interface FilterOptionSelect extends FilterOptionBasis {
  filter_type: FilterTypes.SELECT
  values: Array<FilterOptionValue>
}

export interface FilterOptionDate extends FilterOptionBasis {
  Null: number
  filter_type: FilterTypes.DATE
  min: number
  max: number
  value_type: number
  cur_max: number
  cur_min: number
}

export interface FilterOptionRange extends FilterOptionBasis {
  Null: number
  cur_max: number
  cur_min: number
  filter_type: FilterTypes.WINDOW
  min: number
  max: number
  value_type: number
  step: number
}

export type FilterOption =
  | FilterOptionSelect
  | FilterOptionDate
  | FilterOptionRange

export type AppliedFilterOptionSelect = {
  field: string
  values: Array<string>
}

export type AppliedFilterOptionRange = {
  field: string
  max: number
  min: number
  value_type: number
}

export type LeftPanelFilters = {
  filterValues: AppliedFilterOption[]
  searchQuery: string[]
  searchCondition: string[]
}

export type AppliedFilterOption =
  | AppliedFilterOptionSelect
  | AppliedFilterOptionRange

export type ProjectOptionsResponse = {
  filter_options: Array<FilterOption>
}
export type FilterList = {
  isLoading: boolean
  isPageDataLoading: boolean
  setPageDataLoading: (b: boolean) => void
  changeThemeOrGroupNameInFilter: (
    entityType: string,
    entityId: string,
    newName: string
  ) => void
  updateSelectFilterGroupValue: (
    filterField: string,
    oldName: string,
    newName: string
  ) => void
  options: Array<FilterOption>
  openedOptions: Array<string>
  setOpenedOptions: (s: Array<string>) => void
  refetch: () => void
}
export type FilterValues = {
  values: Array<AppliedFilterOption>
  setValues: (
    list: Array<AppliedFilterOption>,
    comparativeIndex?: number
  ) => void
  clearFilter: (fieldName: string) => void
  searchQuery: Array<AppliedFilterOption>
  setSearchQueryAndCondition: (
    query: Array<string>,
    condition: Array<string>,
    comparativeIndex?: number,
    isComparativeModeChanged?: boolean,
    isAlignFilters?: boolean
  ) => void
  searchCondition: Array<AppliedFilterOption>

  pageCriteria: AppliedFilterOption[]
  getPageCriteriaFilter: (
    field: string,
    comparativeIndex?: number
  ) => AppliedFilterOption | undefined
  getPageCriteriaFilterAndRemoveIdxFromName: (
    field: string,
    comparativeIndex?: number
  ) => AppliedFilterOption | undefined
  getPageCriteriaValue: (
    field: string,
    comparativeIndex?: number
  ) => string | undefined
  getPageCriteriaValues: (
    field: string,
    comparativeIndex?: number
  ) => string[] | []
  appendOrReplacePageCriteriaValues: (
    filter: AppliedFilterOption,
    comparativeIndex?: number
  ) => void
  unsetPageCriteriaValues: (field: string, comparativeIndex?: number) => void
  clearPageCriteria: () => void
  setPageCriteriaAsync: (newPageCriteria: AppliedFilterOption[]) => void
}
export type FiltersContext = {
  filterList: FilterList
  filterValues: FilterValues
  addComparativeIndexToFilterName: (
    fName: string,
    comparativeIndex: number | undefined
  ) => string
  comparativeIndex?: number
  filterOptions?: FilterOption[]
  selectedCountry?: string
}

export type FiltersPresetContext = {
  filterPresets: FilterPreset[]
  setFilterPresets: (list: FilterPreset[]) => void
  relativeDateFilterIdx: Record<number, number>
  setRelativeDateFilterIdx: (idxMap: Record<number, number> | Function) => void
}
export type FilterPayload = {
  criteria: Array<AppliedFilterOption>
  search_criteria: Array<string>
  search_terms: Array<string>
  limit?: number
  stop_words?: Array<string>
  search_by_keywords?: boolean
}
export type ApplyFilterPayload = {
  field: FilterOption['field']
  value: string | Pick<FilterOptionDate, 'min' | 'max' | 'value_type'>
}
export type ApplyGroupFilterPayload = {
  field: FilterOption['field']
  value: string[]
}

export type FilterPresetResponse = {
  filter_presets: FilterPreset[]
}

export type SortableFilterOption = FilterOption & {
  id: string
  disabled?: boolean
}

export enum RelativeDateFilterIdx {
  Unset = -1,
  Last30Days,
  Last3Months,
  Last6Months,
  CurrentYear,
}
