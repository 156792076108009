import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import * as serviceWorker from './serviceWorker'
import 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min'
import 'chart-js-treemap/src/index'

import 'normalize.css/normalize.css'
import 'antd/dist/antd.css'
import 'rc-color-picker/assets/index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'assets/styles/base.css'
import 'assets/styles/variables.css'
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import './config'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default
    ReactDOM.render(<NextApp />, document.getElementById('root'))
  })
}
