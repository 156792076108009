import React from 'react'
import { Collapse, Typography } from 'antd'
import styled from 'styled-components'
import mixpanel from 'features/trackers/mixpanel'
import { ProductHierarchyInfo } from '../types'
import { ProductCollapse } from '../components'
import { TagOutlined } from '@ant-design/icons'

type Props = {
  data: ProductHierarchyInfo[]
  projectId: string
}

export const ProductPanel: React.FC<Props> = ({
  data,
  projectId: proj_uuid,
}) => {
  return (
    <>
      <Collapse
        accordion
        defaultActiveKey={['1']}
        onChange={(key) => {
          mixpanel.track('product data', {
            action: 'product contents',
            value: data?.find((item) => item.hierarchy === key)?.hierarchy,
          })
        }}
      >
        {data &&
          data.map((info) => (
            <Collapse.Panel
              style={{ fontSize: 18 }}
              header={
                <>
                  <ImageWrapper>
                    {info.image_url ? (
                      <img
                        src={info.image_url}
                        width="auto"
                        height="70px"
                        alt=""
                        style={{
                          maxHeight: '70px',
                          maxWidth: '70px',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <TagOutlined style={{ fontSize: '40px' }} />
                    )}
                  </ImageWrapper>
                  <h2>
                    {info.hierarchy} ({info.product_count} Item
                    {info.product_count !== 1 ? 's' : ''})
                  </h2>
                  <TextWrapper>
                    <Typography.Text>
                      {info.review_count + ' Reviews'}
                    </Typography.Text>
                    <Typography.Text>
                      {'  Last Review Date: ' + info.last_review}
                    </Typography.Text>
                  </TextWrapper>
                </>
              }
              key={info.hierarchy}
            >
              <ProductCollapse
                projectId={proj_uuid}
                hierarchy={info.hierarchy}
              />
            </Collapse.Panel>
          ))}
      </Collapse>
    </>
  )
}

const ImageWrapper = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  margin-left: 2%;
  margin-right: 5%;
`
const TextWrapper = styled.div`
  vertical-align: top;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
