import React, { useState, useEffect } from 'react'
import logo from 'assets/images/logos/Icon-Only-Peach.png'
import { ArrowRight } from '@mui/icons-material'
import { YogiButton } from '../../../../components/UI/YogiButton'
import { ArrowRightOutlined } from '@ant-design/icons'
import mixpanel from '../../../trackers/mixpanel'
import { useSavedViews } from '../saved-vews/utils'
import Markdown from 'marked-react'
import { CHARTS_URL } from '../../utils'
import { mainText } from '../../../../assets/styles/variables'
import { Link } from 'react-router-dom'
import { Input } from 'antd'
import { YogiInput } from '../../../../components/UI/YogiInput'
import { useQuery } from 'react-query'
import { getBookmarkUrlPost } from '../../../url-params/model'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import styled from 'styled-components'

interface Message {
  sender: string
  content: string
}

interface NLInputProps {
  userMessage: string
  aiResponse: string
  typingSpeed?: number
  chartId?: any
  isValid: boolean
}

const NLInput: React.FC<NLInputProps> = ({
  userMessage,
  aiResponse,
  typingSpeed = 70,
  chartId = '6_4_16_rating',
  isValid,
}) => {
  const [messages, setMessages] = useState<Message[]>([])
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const { updateFilters } = useSavedViews()

  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const comparativeIndex = 0
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )

  useEffect(() => {
    setMessages([{ sender: 'User', content: userMessage }])
  }, [userMessage])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentWordIndex < aiResponse.split(' ').length) {
        setCurrentWordIndex(currentWordIndex + 1)
      }
    }, typingSpeed)

    return () => clearTimeout(timer)
  }, [currentWordIndex, aiResponse, typingSpeed])

  useEffect(() => {
    const aiWords = aiResponse.split(' ').slice(0, currentWordIndex).join(' ')
    setMessages((prevMessages) => [
      ...prevMessages.slice(0, 1),
      { sender: 'AI', content: aiWords },
    ])
  }, [currentWordIndex, aiResponse])

  const chartItem = {
    type: 'chart',
    content: {
      name: 'Rating',
      image: '4_16.png',
      chart_ids: [
        {
          chart_id: chartId ? chartId.chart_id : '6_4_16_rating',
          model: null,
          field: null,
        },
      ],
      tier1: 'Theme',
    },
    dashboardControls: {
      chartId: chartId ? chartId.chart_id : '6_4_16_rating',
      tier1: 'Theme',
      tier2: null,
      tier3: null,
      legend: true,
      fullAxis: true,
      hasLabels: true,
      hasOverlay: false,
      treeView: true,
      origin: true,
      labels: false,
      trendlines: false,
      shadedZones: true,
      sortedByCategory: false,
      isAlignScales: true,
      isAlignOrder: true,
      multiChart: true,
      fontSize: 'medium',
      timeInterval: null,
      reviewsCount: 0,
      postType: 'post',
      interval: null,
      isExpandChart: false,
      order: null,
      isDelta: false,
      showCompetitors: false,
      showMyBrands: false,
      showPercentChange: true,
      wrapLabels: false,
      maxAxesLength: 30,
    },
    filterValues: filterValues,
  }

  const { data: linkData } = useQuery(
    ['getFilterHash', projectId, filterValues, chartId],
    () =>
      getBookmarkUrlPost(projectId, {
        page: `${CHARTS_URL}`,
        filterString: JSON.stringify({
          filters: [filterValues],
          pageControls: [chartItem.dashboardControls],
          // pageControls: [chartItem.dashboardControls].map((el) => ({
          //   ...el,
          //   chart_id: chartItem.dashboardControls.chartId,
          //   tier1: tierName,
          //   multiChart: true,
          // })),
        }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!chartItem?.dashboardControls,
    }
  )

  return (
    <div style={{ fontFamily: 'Arial', fontSize: '16px' }}>
      {messages.map((message, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: message.sender === 'AI' ? 'row-reverse' : 'row',
            alignItems: message.sender === 'AI' ? 'center' : 'end',
            margin: '10px 0',
            gap: '10px',
          }}
        >
          {message.sender === 'AI' ? (
            <div
              style={{
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                backgroundColor:
                  message.sender === 'AI' ? '#00A7E1' : '#4CAF50',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                marginRight: message.sender === 'AI' ? '10px' : '',
                marginLeft: message.sender === 'AI' ? '' : '10px',
                flexShrink: 0,
              }}
            >
              U
            </div>
          ) : (
            <img
              src={logo}
              style={{
                width: '40px',
                height: '40px',
                flexShrink: 0,
              }}
            />
          )}
          <MessageContainer
            style={{
              backgroundColor: message.sender === 'AI' ? '#E8F5E9' : '#E1F5FE',
              width: message.sender === 'AI' ? '100%' : 'auto',
            }}
          >
            {message.sender !== 'AI' ? (
              <Markdown>{message.content}</Markdown>
            ) : (
              <div style={{ display: 'flex', gap: 10 }}>
                <YogiInput placeholder={'Message Yogi'} disabled />
                <YogiButton type={'primary'}>
                  <ArrowRightOutlined />
                </YogiButton>
              </div>
            )}
            {message.sender !== 'AI' &&
              currentWordIndex === aiResponse.split(' ').length &&
              isValid && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px',
                    paddingBottom: '5px',
                  }}
                >
                  <Link
                    target={'_blank'}
                    to={`${CHARTS_URL}?filters=${linkData}`}
                    onClick={() => {
                      // mixpanel.track('catalog', {
                      //   action: 'open chart link',
                      //   value: chartItem.dashboardControls.chartId,
                      // })
                    }}
                    style={{
                      color: mainText,
                      marginTop: -5,
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                    }}
                  >
                    <YogiButton
                      onClick={(e: any) => {
                        // e.preventDefault()
                      }}
                      loading={!chartId}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          alignItems: 'center',
                        }}
                      >
                        View in Charts <ArrowRightOutlined />
                      </div>
                    </YogiButton>
                  </Link>
                </div>
              )}
          </MessageContainer>
        </div>
      ))}
    </div>
  )
}

export default NLInput

const MessageContainer = styled.div`
  border-radius: 10px;
  padding: 8px 12px;
  white-space: pre-wrap;
  p {
    margin: 0;
  }
`
