import React from 'react'
import styled from 'styled-components'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { ClusterPanel } from '../features/cluster'

type Props = {
  comparativeIndex: number
  hidden: boolean
  setHidden: (v: boolean) => void
}

type WrapperProps = { custom: any }

export const ControlPanel: React.FC<Props> = ({
  comparativeIndex,
  hidden,
  setHidden,
}) => {
  return (
    <Wrapper id="control-panel" custom={{ hidden }}>
      <Container>
        <ClusterPanel comparativeIndex={comparativeIndex} />
      </Container>

      <HideButton onClick={() => setHidden(!hidden)}>
        {hidden ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
      </HideButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 300px;
  position: absolute;
  height: calc(100vh - 133px);
  transform: translateX(0);
  transition: all ease 0.3s;

  ${(props: WrapperProps) =>
    props.custom.hidden && 'transform: translateX(300px)'};

  right: 0;
  top: 0;
  z-index: 1;

  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
`
const Container = styled.div`
  width: 300px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #fff;
  position: relative;
  box-shadow: var(--default-box-shadow);
  border-left: 1px solid var(--color-grey);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
`
const HideButton = styled.div`
  background: #fff;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: calc(100% - 1px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--default-box-shadow);
  z-index: -1;
  border: 1px solid var(--color-grey);

  svg {
    transform: rotate(180deg);
  }
`
