import React, { useContext, useEffect, useState } from 'react'
import { DashboardView } from './DashboardView'
import { DashboardResponse } from '../../types'
import { DashboardEditor } from './DashboardEditor'
import {
  initialState,
  useProjectStore,
} from '../../../../projectStore/projectStore'
import { Filters } from '../../../../types'
import { AuthContext } from '../../../../../auth'
import { SmartDashboardView } from './SmartDashboard/SmartDashboardView'
import { useDashboardStore } from '../../dashboardStore'
import { AppliedFilterOptionRange } from '../../../filters/types'
import { getInitialFilters } from '../../utils'

type Props = {
  view: 'list' | 'view' | 'edit'
  setView: (view: 'list' | 'view' | 'edit') => void
  selectedDashboard: DashboardResponse | undefined
  setSharingDashboard: (sharingDashboard: DashboardResponse | undefined) => void
  refetch: () => void
  isPublic?: boolean
  setShowFetcher: (show: boolean) => void
}

export const Dashboard: React.FC<Props> = ({
  view,
  setView,
  selectedDashboard,
  setSharingDashboard,
  refetch,
  isPublic,
  setShowFetcher,
}) => {
  const isSmartDashboard = useDashboardStore((state) => state.isSmartDashboard)
  const dateWindows = useProjectStore((state) => state.dateWindows)
  const [dashboard, setDashboard] = useState<DashboardResponse | undefined>(
    selectedDashboard
  )

  const [filters, setFilters] = useState<Filters>(
    selectedDashboard?.dashboard.dashboard_settings.filters ??
      getInitialFilters(dateWindows)
  )

  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email
  const isOwner = !!selectedDashboard?.owners?.find(
    (el: { email: string }) =>
      el.email?.toLowerCase() === currentUserEmail?.toLowerCase()
  )

  useEffect(() => {
    if (selectedDashboard) setDashboard(selectedDashboard)
  }, [selectedDashboard])

  return (
    <>
      {view === 'view' ? (
        <>
          {isSmartDashboard ? (
            <SmartDashboardView
              filterValues={filters}
              setFilters={setFilters}
              uneditedDashboard={selectedDashboard}
              selectedDashboard={dashboard}
              setView={setView}
              setSelectedDashboard={setDashboard}
              setSharingDashboard={setSharingDashboard}
              isOwner={isOwner}
              isPublic={isPublic}
            />
          ) : (
            <DashboardView
              filterValues={filters}
              setFilters={setFilters}
              uneditedDashboard={selectedDashboard}
              selectedDashboard={dashboard}
              setView={setView}
              setSelectedDashboard={setDashboard}
              setSharingDashboard={setSharingDashboard}
              isOwner={isOwner}
              isPublic={isPublic}
            />
          )}
        </>
      ) : (
        <DashboardEditor
          filterValues={filters}
          setFilters={setFilters}
          uneditedDashboard={selectedDashboard}
          selectedDashboard={dashboard}
          setView={setView}
          setSelectedDashboard={setDashboard}
          setShowFetcher={setShowFetcher}
          refetch={refetch}
        />
      )}
    </>
  )
}
