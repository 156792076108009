import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { Grid } from '@mui/material'
import Icon, { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Popconfirm, Select } from 'antd'
import styled from 'styled-components'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from '../../../../types'
import { DashboardResponse } from '../../types'
import { AuthContext } from '../../../../../auth'
import { deleteDashboard, getDashboards, saveDashboard } from '../../model'
import { toast } from 'react-toastify'
import useResizeObserver from 'use-resize-observer'
import { LogoSpinner } from '../../../../../../components/LogoSpinner'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import { YogiSearch } from '../../../../../../components/UI/YogiSearch'
import { PostsEmpty } from '../../../../../../components/PostsEmpty'
import Divider from '../../../../../../components/UI/YogiDivider'
import { mainText } from '../../../../../../assets/styles/variables'
import DashboardCard from './DashboardCard'
import { useHistory } from 'react-router'
import { DASHBOARD_URL } from '../../../../utils'
import mixpanel from '../../../../../trackers/mixpanel'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import AIcon from 'assets/images/icons/AIcon_180x180.png'
import { useDashboardStore } from '../../dashboardStore'
import useCustomDashboards from '../../../../hooks/useCustomDashboards'

const { Option } = Select
interface DashboardActionMenuProps {
  onDuplicate: (id: number) => void
  onDelete: (id: number, isOwner: boolean) => void
  onShare: (id: number) => void
  dashboardId: number
  onEdit: (id: number) => void
  isOwner: boolean
  isTemplate: boolean
}

const DashboardActionMenu: React.FC<DashboardActionMenuProps> = ({
  onDuplicate,
  onDelete,
  onShare,
  dashboardId,
  onEdit,
  isOwner,
  isTemplate,
}) => {
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleClick = ({ key }: { key: string }) => {
    switch (key) {
      case 'edit':
        onEdit(dashboardId)
        break
      case 'duplicate':
        onDuplicate(dashboardId)
        break
      case 'share':
        onShare(dashboardId)
        break
      case 'delete':
        // todo show spinner
        onDelete(dashboardId, isOwner)
        break
      default:
        break
    }
    setVisible(false)
  }

  const menu = (
    <Menu
      onClick={({ key, domEvent }) => {
        domEvent.stopPropagation()
        handleClick({ key })
      }}
    >
      {isOwner && <Menu.Item key="edit">Edit</Menu.Item>}
      {!isTemplate && <Menu.Item key="share">Share</Menu.Item>}
      <Menu.Item key="duplicate">Duplicate</Menu.Item>
      <span onClick={(e) => e?.stopPropagation()}>
        <Popconfirm
          title={`Are you sure you want to ${
            isOwner ? 'delete' : 'unfollow'
          } this dashboard?`}
          okText="Yes"
          cancelText="No"
          onConfirm={(e) => {
            e?.stopPropagation()
            handleClick({ key: 'delete' })
          }}
          onCancel={(e) => {
            e?.stopPropagation()
          }}
        >
          <Menu.Item key="delete">{isOwner ? 'Delete' : 'Unfollow'}</Menu.Item>
        </Popconfirm>
      </span>
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      // @ts-ignore
      onClick={(e) => e.stopPropagation()}
    >
      <DashboardActionIcon />
    </Dropdown>
  )
}

type DashboardProps = {
  dashboardData: DashboardResponse
  onDuplicate: (id: number) => void
  onShare: (id: number) => void
  onDelete: (id: number, isOwner: boolean) => void
  onSelect: (dashboard: DashboardResponse) => void
  onEdit: (id: number) => void
  listType: 'list' | 'grid'
  notifications?: any //NotificationsResponse[] | undefined
}

const Dashboard: React.FC<DashboardProps> = ({
  dashboardData,
  onDuplicate,
  onShare,
  onDelete,
  onSelect,
  onEdit,
  listType,
  notifications,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const setIsSmartDashboard = useDashboardStore(
    (state) => state.setIsSmartDashboard
  )
  const { dashboard } = dashboardData
  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email
  const isOwner = !!dashboardData?.owners?.find(
    (el: { email: string }) =>
      el.email?.toLowerCase() === currentUserEmail?.toLowerCase()
  )

  return (
    <a
      style={{ textDecoration: 'none', color: mainText }}
      href={`${window.location.origin}/project/${projectId}/${DASHBOARD_URL}?dashboard_id=${dashboard.id}`}
      onClick={(e) => {
        e.preventDefault()
        setIsSmartDashboard(false)
      }}
    >
      <DashboardCard
        dashboardData={dashboardData}
        onSelect={onSelect}
        listType={listType}
        notifications={notifications}
        actions={
          <DashboardActionMenu
            {...{ onDuplicate, onDelete, onShare }}
            dashboardId={dashboard.id}
            onEdit={onEdit}
            isOwner={isOwner}
            isTemplate={!dashboard?.author}
          />
        }
      />
    </a>
  )
}

type Props = {
  setView: (view: 'list' | 'view' | 'edit') => void
  setSelectedDashboard: (
    selectedDashboard: DashboardResponse | undefined
  ) => void
  setSharingDashboard: (sharingDashboard: DashboardResponse | undefined) => void
  setDrawerIsOpen: (isOpen: boolean) => void
  setShowFetcher: (show: boolean) => void
}
const DashboardList: React.FC<Props> = ({
  setView,
  setSelectedDashboard,
  setSharingDashboard,
  setDrawerIsOpen,
  setShowFetcher,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const setIsSmartDashboard = useDashboardStore(
    (state) => state.setIsSmartDashboard
  )

  const isSampleDash = details?.title?.includes('[Sample]')

  const history = useHistory()

  const [sortField, setSortField] = useState<'title' | 'date' | 'active'>(
    'date'
  )
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  const [searchTerm, setSearchTerm] = useState('')
  const [category, setCategory] = useState('')
  const [listType, setListType] = useState<'list' | 'grid'>('grid')

  const { data: feature_flags } = useFeatureFlags()
  const onSearch = (e: any) => {
    // TODO debounce
    mixpanel.track('custom dashboard', {
      action: 'search',
      proj_uuid: projectId,
    })
    setSearchTerm(e.target.value)
  }
  const { data, refetch, isLoading } = useCustomDashboards()

  const sortedData = React.useMemo(() => {
    let sortData = data ? [...data] : []

    // filter out outlier bookmarks TODO find a more permanent solution
    sortData = sortData.filter(
      ({ dashboard }) => !dashboard.dashboard_settings.extra_settings?.outlier
    )

    sortData.sort(({ dashboard: dashboardA }, { dashboard: dashboardB }) => {
      let result

      if (sortField === 'title') {
        result = dashboardB.name.localeCompare(dashboardA.name)
      } else {
        result =
          new Date(dashboardA.created_dt).getTime() -
          new Date(dashboardB.created_dt).getTime()
      }
      return sortOrder === 'asc' ? result : -result
    })

    if (searchTerm) {
      sortData = sortData.filter(({ dashboard }) =>
        dashboard.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    return sortData
  }, [data, sortField, sortOrder, searchTerm, category])

  const handleShare = (id: number) => {
    mixpanel.track('custom dashboard', {
      action: 'share',
      type: 'open',
      proj_uuid: projectId,
    })
    setSharingDashboard(data?.find(({ dashboard }) => dashboard.id === id))
    setDrawerIsOpen(true)
  }

  const handleDuplicate = async (id: number) => {
    const { dashboard } =
      data?.find(({ dashboard }) => dashboard.id === id) ?? {}
    mixpanel.track('custom dashboard', {
      action: 'duplicate',
      value: id,
      proj_uuid: projectId,
    })
    if (dashboard) {
      try {
        setShowFetcher(true)
        const dashboardCopy = JSON.parse(JSON.stringify(dashboard))
        delete dashboardCopy.id
        dashboardCopy.name = `${dashboardCopy.name} (Copy)`
        await saveDashboard(projectId, dashboardCopy)
        await refetch()
        toast.success(`Dashboard duplicated`)
        setShowFetcher(false)
      } catch (e) {
        toast.error(`Error duplicating dashboard`)
        setShowFetcher(false)
      }
    }
  }

  const handleDelete = async (id: number, isOwner: boolean) => {
    mixpanel.track('custom dashboard', {
      action: 'delete',
      value: id,
      proj_uuid: projectId,
    })
    setShowFetcher(true)
    const { dashboard } =
      data?.find(({ dashboard }) => dashboard.id === id) ?? {}
    if (dashboard) {
      await deleteDashboard(projectId, dashboard.id)
      await refetch()
      setShowFetcher(false)
    }
  }

  const handleSelect = async (dashboardData: DashboardResponse) => {
    const { dashboard } = dashboardData
    mixpanel.track('custom dashboard', {
      action: 'open',
      value: dashboard.id,
      proj_uuid: projectId,
    })
    history.push(`${window.location.pathname}?dashboard_id=${dashboard.id}`)
  }

  const handleEdit = async (id: number) => {
    mixpanel.track('custom dashboard', {
      action: 'edit',
      value: id,
      proj_uuid: projectId,
    })
    setSelectedDashboard(data?.find(({ dashboard }) => dashboard.id === id))
    setView('edit')
  }

  const { ref: headerRef, height: headerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()

  if (isLoading) {
    return <LogoSpinner>{`Loading Dashboards...`}</LogoSpinner>
  }

  return (
    <Container>
      <Header ref={headerRef}>
        <h1 style={{ margin: 0 }}>Dashboards</h1>
        <ButtonWrapper>
          <YogiButton
            type="primary"
            onClick={() => {
              mixpanel.track('custom dashboard', {
                action: 'create',
                type: 'open',
                proj_uuid: projectId,
              })
              setSelectedDashboard(undefined)
              setView('edit')
            }}
          >
            Create Dashboard
          </YogiButton>
          {(feature_flags?.dashboard_builder || isSampleDash) && (
            <YogiButton
              type="primary"
              onClick={() => {
                mixpanel.track('custom dashboard', {
                  action: 'create',
                  type: 'open',
                  proj_uuid: projectId,
                })
                setSelectedDashboard(undefined)
                setView('view')
                setIsSmartDashboard(true)
              }}
            >
              <img
                style={{
                  height: '120%',
                  marginTop: -3,
                  marginLeft: -10,
                  marginRight: 3,
                }}
                src={AIcon}
              />
              Create Smart Dashboard
            </YogiButton>
          )}
        </ButtonWrapper>
      </Header>
      <Body headerHeight={headerHeight}>
        <BodyHeader>
          <Controls>
            <FilterControls>
              <SearchContainer>
                <YogiSearch onChange={onSearch} style={{ width: '100%' }} />
              </SearchContainer>
            </FilterControls>
            <SortControls>
              <label>
                Sort by:&nbsp;
                <Select
                  value={sortField}
                  onChange={(value) => {
                    mixpanel.track('custom dashboard', {
                      action: 'sort type',
                      value: value,
                      proj_uuid: projectId,
                    })
                    setSortField(value)
                  }}
                >
                  <Option value="title">Title</Option>
                  <Option value="date">Date</Option>
                </Select>
              </label>
              {sortOrder === 'asc' ? (
                <StyledButton
                  id="feedback-sort-button"
                  type="ghost"
                  onClick={() => {
                    mixpanel.track('custom dashboard', {
                      action: 'sort direction',
                      value: 'desc',
                      proj_uuid: projectId,
                    })
                    setSortOrder('desc')
                  }}
                >
                  ↑
                </StyledButton>
              ) : (
                <StyledButton
                  id="feedback-sort-button"
                  type="ghost"
                  onClick={() => {
                    mixpanel.track('custom dashboard', {
                      action: 'sort direction',
                      value: 'asc',
                      proj_uuid: projectId,
                    })
                    setSortOrder('asc')
                  }}
                >
                  ↓
                </StyledButton>
              )}
            </SortControls>
          </Controls>
          <Controls>
            {/*{feature_flags?.dashboard_builder && (*/}
            {/*  <DashboardBuilder setView={setView} refetch={refetch} />*/}
            {/*)}*/}

            <div></div>
          </Controls>
        </BodyHeader>

        <Content>
          {!data?.length ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <PostsEmpty>No Dashboards...yet!</PostsEmpty>

              <YogiButton
                type="primary"
                onClick={() => {
                  mixpanel.track('custom dashboard', {
                    action: 'create',
                    type: 'open',
                    proj_uuid: projectId,
                  })
                  setSelectedDashboard(undefined)
                  setView('edit')
                }}
                id={'create-dashboard-button'}
              >
                Create Dashboard
              </YogiButton>
            </div>
          ) : (
            <Grid container spacing={3}>
              {listType === 'list' && (
                <Divider style={{ marginLeft: 24, marginTop: 5 }} />
              )}
              {sortedData?.map((dashboardData) => (
                <Grid
                  item
                  xs={12}
                  sm={listType === 'list' ? 12 : 12}
                  md={listType === 'list' ? 12 : 6}
                  lg={listType === 'list' ? 12 : 4}
                  xl={listType === 'list' ? 12 : 3}
                  key={dashboardData.dashboard.id}
                >
                  <Dashboard
                    dashboardData={dashboardData}
                    onDuplicate={handleDuplicate}
                    onShare={handleShare}
                    onDelete={handleDelete}
                    onSelect={handleSelect}
                    onEdit={handleEdit}
                    listType={listType}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Content>
      </Body>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 40px);
`

const Header = styled.div`
  background: white;
  padding: var(--default-padding-half) var(--default-padding);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const Body = styled.div<{ headerHeight?: number }>`
  height: calc(100% - ${(props) => props.headerHeight}px - 40px);
  overflow: auto;
`

const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const Controls = styled.div`
  margin: 10px 0px;
  padding: var(--default-padding-half) var(--default-padding);
  width: 50%;
`

const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 40px;
  width: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: space-between;
`

const SortControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Content = styled.div`
  padding: var(--default-padding-half) var(--default-padding);
`

const DashboardActionIcon = styled(MoreOutlined)`
  font-size: 24px;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  background: white !important;
  color: var(--main-text);
  border: 2px solid var(--card-border-grey);
  border-radius: 8px;
  &:focus {
    border: 2px solid var(--card-border-grey);
    background: white;
    color: var(--main-text);
  }
  &:hover {
    color: var(--button-blue);
    border: 2px solid var(--button-blue);
    background: white;
  }
  box-shadow: none;
  height: 32px;
`

export default DashboardList
