import React from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

type Props = {
  input: string
  updateInput: (e: any) => void
}

export const ProductSearch: React.FC<Props> = ({ input, updateInput }) => {
  return (
    <Input
      id="product-search"
      style={{ margin: '20px 10px 20px 0 ', width: '500px' }}
      prefix={<SearchOutlined />}
      allowClear
      key="product-search"
      value={input}
      placeholder={'Search Products'}
      onChange={updateInput}
    />
  )
}
