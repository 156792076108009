import React, { useEffect, useState } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import styled from 'styled-components'
import { mainText, secondaryNavy } from '../../../../assets/styles/variables'
import { FocusListOption } from './PDPList'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { LogoSpinner } from '../../../../components/LogoSpinner'
import { InnerTitle } from '../../../../components/InnerTitle'
import PDPDetail from './PDPDetail'
import PDPList from './PDPList'
import mixpanel from '../../../trackers/mixpanel'
import { ProductHierarchyInfo } from '../products/types'

export const PDP: React.FC = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const location = useLocation()
  const history = useHistory()

  const [focusListData, setFocusListData] = useState<ProductHierarchyInfo[]>([])

  const selectedLens = 'product_hierarchy'

  const [selectedProduct, setSelectedProduct] = useState<string | null>(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSelectedProduct(
      !!urlParams.get('product') ? urlParams.get('product') : null
    )
  }, [location.search])

  const updateProduct = (product: string | null) => {
    // update url params to reflect lens
    const urlParams = new URLSearchParams(window.location.search)
    if (!product) {
      urlParams.delete('product')
      urlParams.delete('item')
    } else {
      urlParams.set('product', product)
    }
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  const focusItem = focusListData.find(
    (item) => item.hierarchy === selectedProduct
  )

  if (!defaultFilterList.length) {
    return (
      <Container>
        <LogoSpinner>
          <InnerTitle>Loading PDP Insights</InnerTitle>
        </LogoSpinner>
      </Container>
    )
  }

  return (
    <Container>
      {!focusItem ? (
        <PDPList
          selectedLens={selectedLens}
          setSelectedProduct={updateProduct}
          focusListData={focusListData}
          setFocusListData={setFocusListData}
        />
      ) : (
        <>
          {selectedProduct && (
            <PDPDetail
              item={focusItem}
              lens={selectedLens}
              setValue={updateProduct}
            />
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: calc(100vh - 40px);
`

const Header = styled.div`
  background: white;
  padding: var(--default-padding-half) var(--default-padding);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Controls = styled.div`
  margin: 10px 0px;
  padding: var(--default-padding-half) var(--default-padding);
`

const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 40px;
  width: 100%;
`

const SortControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  width: 100%;
`

const PillContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  min-height: 50px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`

const Pill = styled.button<{ selected: boolean }>`
  display: inline-block;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 2px 15px;
  border-radius: 20px;
  background: ${(props) => (props.selected ? secondaryNavy : 'white')};
  color: ${(props) => (props.selected ? 'white' : mainText)};
  border: ${(props) =>
    props.selected ? '1px solid ' + secondaryNavy : '1px solid #CDD3DD'};
  cursor: pointer;
  transform: translate(0%);
  transition: 0.15s ease-out;

  &:hover {
    background: ${() => secondaryNavy};
    color: white;
    border: ${() => '1px solid ' + secondaryNavy};
  }
`

const VerticalDivider = styled.div`
  height: 100%;
  border: 2px solid rgba(159, 183, 195, 0.15);
`

const Content = styled.div`
  padding: var(--default-padding-half) var(--default-padding);
`

const FocusContainer = styled.div`
  padding: var(--default-padding-half);
  background: #fff;
  box-shadow: var(--default-box-shadow);
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  cursor: pointer;
  border-radius: var(--border-radius);
`

const FocusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SharedWith = styled.div`
  position: relative;
`

const UserList = styled.div`
  position: absolute;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
`

const FocusActionIcon = styled(EllipsisOutlined)`
  font-size: 24px;
  cursor: pointer;
`

const StyledSwitch = styled(Switch)`
  background-color: ${(props) =>
    props.checked ? secondaryNavy : 'rgba(0, 0, 0, 0.25)'};

  //.ant-switch-inner {
  //  font-size: 12px;
  //}
`
