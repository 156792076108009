import React, { useState, useCallback, useEffect } from 'react'
import { Card, Typography } from 'antd'
import { BarcodeOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Product } from '../types'
import { ProductModal } from './product-modal'
import { SourceIcon } from './product-source-icon'
import { Emoji } from 'shared/components'
import { useQuery } from 'react-query'
import { Loader } from 'shared/components'
import { getProductsForHierarchy } from '../model'
import mixpanel from 'features/trackers/mixpanel'

type Props = {
  hierarchy: string
  projectId: string
}

export const ProductCollapse: React.FC<Props> = ({
  hierarchy,
  projectId: proj_uuid,
}) => {
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['getProductsForHierarchy', proj_uuid, hierarchy],
    () =>
      getProductsForHierarchy('getProductsForHierarchy', proj_uuid, hierarchy),
    { enabled: false }
  )

  useEffect(() => {
    refetch()
  }, [])

  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [modalProduct, setModalProduct] = useState<Product>()

  const openModal = (product: Product) => {
    setModalProduct(product)
    setModalOpened(true)
  }
  const handleOk = useCallback((e) => {
    e.stopPropagation()
    setModalOpened(false)
  }, [])

  const handleCancel = useCallback((e) => {
    e.stopPropagation()
    setModalOpened(false)
  }, [])

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  if (isError) {
    return <span> Error: {error}</span>
  }

  return (
    <>
      {data &&
        data.map((product) => (
          <div
            onClick={() => {
              mixpanel.track('product data', {
                action: 'item contents',
                value: product.name,
              })
              openModal(product)
            }}
            key={product.uuid}
          >
            <Card
              hoverable
              title={product.name}
              style={{ marginTop: 16 }}
              extra={
                product.generic_listing ? (
                  <>
                    <Emoji symbol="⭐" />
                    {' General Item Listing'}
                  </>
                ) : null
              }
            >
              <ImageWrapper>
                {product.image_url ? (
                  <img
                    src={product.image_url}
                    width="auto"
                    height="80px"
                    alt=""
                  />
                ) : (
                  <BarcodeOutlined style={{ fontSize: '60px' }} />
                )}
              </ImageWrapper>
              <CardContentWrapper>
                <TextWrapper>
                  <Typography.Text>
                    {product.review_count + ' Reviews \n'}
                  </Typography.Text>
                  <br></br>
                  <Typography.Text>
                    {'Last Review Date: ' + product.last_review}
                  </Typography.Text>
                </TextWrapper>
                <SourceWrapper>
                  {product.sources &&
                    product.sources.map((product_source) => (
                      <SourceIcon source={product_source}></SourceIcon>
                    ))}
                </SourceWrapper>
              </CardContentWrapper>
            </Card>
          </div>
        ))}

      {modalOpened ? (
        <ProductModal
          product={modalProduct!}
          handleCancel={handleCancel}
          handleOk={handleOk}
        ></ProductModal>
      ) : null}
    </>
  )
}

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: top;
  float: left;
  margin-right: 5%;
  margin-left: 2%;
`
const TextWrapper = styled.div`
  float: left;
  vertical-align: top;
  font-size: 16px;
  font-weight: bold;
`
const SourceWrapper = styled.div`
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: 10%;
`
const CardContentWrapper = styled.div`
  align-items: center;
  justify-content: center;
`
