import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { THEME_DOMAIN } from '../utils'
import { Domain } from '../types'
import { fetchDomains } from '../model'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'

export const useClusterDomain = (
  projectId: string,
  comparativeIndex: number
) => {
  const networkControls = useProjectStore(
    (state: ProjectState) => state.networkControls
  )

  const [selectedDomain, setSelectedDomain] = useState<Domain>()
  const preSelectedDomain =
    networkControls[comparativeIndex].clusterField || THEME_DOMAIN

  const { data, isLoading, isFetching } = useQuery(
    [`domains-${comparativeIndex}`, projectId],
    () => fetchDomains(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  const domains = data?.data.fields || []

  useEffect(() => {
    if (domains.length) {
      let domain =
        domains.find((field) => field.name === preSelectedDomain) ||
        domains.find((field) => field.name === THEME_DOMAIN)
      setSelectedDomain(domain)
    }
  }, [domains])

  return {
    selectedDomain,
    setSelectedDomain,
    isThemeDomain: selectedDomain?.name === THEME_DOMAIN,
    domains: data?.data.fields || [],
    isLoading: isLoading || isFetching,
  }
}
