import React, { useContext, useEffect, useState } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from 'features/auth'
import mixpanel from 'features/trackers/mixpanel'
import { Charts } from '../features/charts/charts'
import { FeedbackContainer } from '../features/feedback'
import { Product } from '../features/products'
import { SourceContainer } from '../features/sourcepage'
import { Keywords } from '../features/keywords'
import { Settings } from '../features/settings'
import { Recommendations } from '../features/recommendations'
import { NetworkContainer } from '../features/network'

import {
  NETWORK_URL,
  FEEDBACK_URL,
  PRODUCTS_URL,
  SOURCES_URL,
  SETTINGS_URL,
  KEYWORDS_URL,
  RECOMMENDATIONS_URL,
  CHARTS_URL,
  SAVED_VIEWS_URL,
  detectCurrentRoute,
  getCurrentRouteName,
  hydrateSavedViewURL,
  parseUriParams,
  ALERTS_URL,
  DASHBOARD_URL,
  CATALOG_URL,
  PDP_URL,
  emptyFilters,
  GEMINI_URL,
  AUTOMATED_URL,
  FINDINGS_URL,
  AUTO_INSIGHTS_URL,
} from '../utils'
import { initialState, useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { SavedViews } from '../features/saved-vews/containers/saved-views'
import { isInitialFlow } from '../../home/utils'
import { customAlphabet } from 'nanoid'
import { useQuery } from 'react-query'
import { getBookmarkUrlPost, getOriginalUrl } from '../../url-params/model'
import { FilterOptionSelect } from '../features/filters/types'
import { useComparativeNavigation } from '../hooks/useComparativeNavigation'
import _ from 'lodash'
import { Home } from '../features/home/home'
import { Summary } from '../features/summary'
import { getCompassDetails } from '../model'
import Alerts from '../features/alerts/Alerts'
import { Notifications } from '../features/notifications/Notifications'
import useNotifications from '../hooks/useNotifications'
import { Dashboards } from '../features/dashboard/dashboards'
import { Catalog } from '../features/catalog/Catalog'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { PDP } from '../features/PDP/PDP'
import Gemini from '../features/gemini/gemini'
import Automated from '../features/automated/automated'
import Findings from '../features/findings/findings'
import { AlertDrawer } from '../features/alerts/Components/AlertDrawer'
import AutoInsights from '../features/auto-insights/AutoInsights'

// I apologize for this component
export const ProjectContainer = React.memo(() => {
  let hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters
  )
  const setHasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.setHasLoadedFilters
  )
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const setRoute = useProjectStore((state: ProjectState) => state.setRoute)
  const filters = useProjectStore((state: ProjectState) => state.filters)
  const setFilters = useProjectStore((state: ProjectState) => state.setFilters)
  const updateFilters = useProjectStore(
    (state: ProjectState) => state.updateFilters
  )
  const setSelectedCountry = useProjectStore(
    (state: ProjectState) => state.setSelectedCountry
  )
  const dashboardControls = useProjectStore(
    (state: ProjectState) => state.dashboardControls
  )
  const setDashboardControls = useProjectStore(
    (state: ProjectState) => state.setDashboardControls
  )
  const feedbackControls = useProjectStore(
    (state: ProjectState) => state.feedbackControls
  )
  const setFeedbackControls = useProjectStore(
    (state: ProjectState) => state.setFeedbackControls
  )
  const keywordsControls = useProjectStore(
    (state: ProjectState) => state.keywordsControls
  )
  const setKeywordsControls = useProjectStore(
    (state: ProjectState) => state.setKeywordsControls
  )
  const currentChartList = useProjectStore(
    (state: ProjectState) => state.currentChartList
  )
  const setCurrentChartList = useProjectStore(
    (state: ProjectState) => state.setCurrentChartList
  )
  const networkControls = useProjectStore(
    (state: ProjectState) => state.networkControls
  )
  const setNetworkControls = useProjectStore(
    (state: ProjectState) => state.setNetworkControls
  )
  const openedOptions = useProjectStore(
    (state: ProjectState) => state.openedOptions
  )
  const setOpenedOptions = useProjectStore(
    (state: ProjectState) => state.setOpenedOptions
  )
  const setIsComparative = useProjectStore(
    (state: ProjectState) => state.setIsComparative
  )
  const setComparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.setComparativePanelsNumber
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen
  )
  const setIsAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsAlertDrawerOpen
  )

  const user = useContext(AuthContext).user
  const { path, isExact } = useRouteMatch()
  const { addPanel, removePanel } = useComparativeNavigation()
  const history = useHistory()

  useEffect(() => {
    if (
      isInitialFlow(details.state) &&
      window.location.pathname.indexOf(SOURCES_URL) === -1
    ) {
      history.push(`${window.location.pathname}/${SOURCES_URL}`)
      return
    }
    if (window.location.pathname.includes('dashboard-new')) {
      history.push(
        window.location.pathname.replace('dashboard-new', 'dashboard')
      )
    }
    if (isExact) {
      const settings = details.clientSettings.project.pages
      if (!settings?.compass?.hidden) {
        history.replace(`${window.location.pathname}`)
      } else if (!settings?.dashboard?.hidden) {
        history.replace(`${window.location.pathname}/${CHARTS_URL}`)
      } else {
        const validRoute = Object.keys(settings).find(
          (key: string) => !(settings as any)[key].hidden
        )
        history.push(`${window.location.pathname}/${validRoute}`)
      }
    }

    // todo is this what is freaaking out the axios public viewer error?
    if (!user) {
      const savedId = localStorage.getItem('public_id')
      if (savedId) {
        mixpanel.alias(savedId)
      } else {
        const id = customAlphabet('1234567890', 16)()
        localStorage.setItem('public_id', id)
        mixpanel.identify(id)
      }
    }
  }, [projectId])

  useEffect(() => {
    setRoute(detectCurrentRoute(window.location.pathname))
  }, [window.location.pathname])

  // this closes the drawers when navigating away from charts
  useEffect(() => {
    // if (!route.isCharts) {
    setSummaryIsOpen(false)
    setIsAlertDrawerOpen(false)
    // }
  }, [route])

  // these two useEffects allow backwards/forwards browser controls to work.
  const searchParams = new URLSearchParams(window.location.search)
  useEffect(() => {
    if (
      // !!searchParams.get('filters') &&
      urlHashData !== searchParams.get('filters')
    ) {
      hasLoadedFilters = false
      setHasLoadedFilters(false)
      refetch()
    }
  }, [searchParams.get('filters')])

  const { data: urlHashData } = useQuery(
    [
      'url',
      projectId,
      filters,
      detectCurrentRoute(window.location.pathname),
      dashboardControls,
      feedbackControls,
      keywordsControls,
      networkControls,
    ],
    async () => {
      const curRoute = detectCurrentRoute(window.location.pathname)
      // if (
      //   route.isSource ||
      //   route.isProduct ||
      //   route.isSettings ||
      //   route.isSavedViews ||
      //   route.isCompass
      // ) {
      //   // remove url params on pages where it doesnt do anything
      //   window.history.replaceState({}, '', `${window.location.pathname}`)
      //   return
      // }
      //
      // if (curRoute.isRecommendations) {
      //   // remove url params on pages where it doesnt do anything
      //   let searchParams = new URLSearchParams(window.location.search)
      //   searchParams.delete('filters')
      //   const search = !!searchParams.toString()?.length
      //     ? `?${searchParams.toString()}`
      //     : ''
      //   const url = `${window.location.pathname}` + search
      //   window.history.replaceState({}, '', url)
      //   return
      // }

      if (curRoute.isDashboard) {
        // remove url params on pages where it doesnt do anything
        // let searchParams = new URLSearchParams(window.location.search)
        // searchParams.delete('filters')
        // const search = !!searchParams.toString()?.length
        //   ? `?${searchParams.toString()}`
        //   : ''
        // const url = `${window.location.pathname}` + search
        // window.history.replaceState({}, '', url)
        return
      }

      let pageControls: any = dashboardControls
      if (curRoute.isCharts) {
        pageControls = dashboardControls
        // if (
        //   _.isEqual(filters, initialState.filters) &&
        //   _.isEqual(dashboardControls, initialState.dashboardControls)
        // ) {
        //   console.log('in here')
        //   // this prevents a double routing when vals in page config that depend on default chart returning aren't set yet
        //   window.history.replaceState({}, '', `${window.location.pathname}`)
        //   return
        // }
      }
      if (curRoute.isFeedback) {
        pageControls = feedbackControls
      }
      if (curRoute.isKeywords) {
        pageControls = keywordsControls
      }
      if (curRoute.isNetwork) {
        pageControls = networkControls
      }
      let body = {
        filters,
        pageControls,
      }
      if (
        originalUrlData &&
        _.isEqual(JSON.parse(originalUrlData)?.filters, body.filters) &&
        _.isEqual(
          JSON.parse(originalUrlData)?.pageControls,
          body.pageControls
        ) &&
        JSON.parse(originalUrlData)?.text_panel
      ) {
        // @ts-ignore
        // body = {
        //   // @ts-ignore
        //   text_panel: JSON.parse(originalUrlData)?.text_panel,
        //   filters,
        //   pageControls,
        // }
        // // body.text_panel = JSON.parse(originalUrlData)?.text_panel
        return
      }
      return getBookmarkUrlPost(projectId, {
        page: getCurrentRouteName(window.location.pathname),
        filterString: JSON.stringify(body),
      })
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    }
  )

  let [visitedHashes, setVisitedHashes] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState('')

  // TODO idk about this
  useEffect(() => {
    if (urlHashData && !route.isFocus && !route.isPDP) {
      if (!visitedHashes.includes(urlHashData)) {
        if (currentPage !== getCurrentRouteName(window.location.pathname)) {
          // because when switching page, the urlhashdata will cycle to include that page's page params, and we dont want that cycle in the history
          history.replace(window.location.pathname + '?filters=' + urlHashData)
          setCurrentPage(getCurrentRouteName(window.location.pathname))
          visitedHashes = []
        } else {
          if (
            window.location.pathname + '?filters=' + urlHashData !==
            window.location.pathname + window.location.search
          ) {
            history.push(window.location.pathname + '?filters=' + urlHashData)
          }
        }
        visitedHashes.push(urlHashData)
        setVisitedHashes(visitedHashes)
      } else {
        history.replace(window.location.pathname + '?filters=' + urlHashData)
      }
    }
  }, [urlHashData])

  const location = useLocation()

  const {
    data: originalUrlData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['filter', projectId, location.search],
    async () => {
      const curRoute = detectCurrentRoute(window.location.pathname)
      const searchParams = new URLSearchParams(window.location.search)
      let originalData = ''
      if (searchParams.get('filters')) {
        originalData = await getOriginalUrl(
          projectId,
          searchParams.get('filters') || ''
        )
      } else if (searchParams.get('FilterHash')) {
        const data = await getOriginalUrl(
          projectId,
          searchParams.get('FilterHash') || ''
        )
        const hydrated = await hydrate(data)
        setHasLoadedFilters(true)
        return hydrated
      } else {
        const hydrated = await hydrate(location.search)
        setHasLoadedFilters(true)
        return hydrated
      }
      if (originalData) {
        const filterObj = JSON.parse(originalData)
        setFilters(filterObj.filters)
        const comparativePanelsNumber = filterObj.filters.length
        setComparativePanelsNumber(comparativePanelsNumber)
        setIsComparative(comparativePanelsNumber > 1)

        // set country
        if (
          filterObj?.filters?.length &&
          filterObj.filters[0]?.values.find(
            (el: FilterOptionSelect) => el.field === 'country'
          )
        ) {
          setSelectedCountry(
            filterObj.filters[0].values.find(
              (el: FilterOptionSelect) => el.field === 'country'
            ).values[0]
          )
        } else {
          // @ts-ignore
          setSelectedCountry([])
        }

        // TODO - set page controls with {...initialState[pageControlType], ...filterObj.pageControls} to fill any missing vals - can open up saving only modified values as well if we want
        // https://linear.app/yo-yo-gi/issue/EFE-99/loading-page-controls-object-spreading-to-include-all-default-values
        // set page controls
        if (curRoute.isCharts) {
          const mergedDashboardControls = filterObj.pageControls.map(
            (el: any) => ({ ...initialState.dashboardControls[0], ...el })
          )
          if (mergedDashboardControls.length < comparativePanelsNumber) {
            let arr = new Array(comparativePanelsNumber - 1)
            arr.fill(initialState.dashboardControls[0])
            setDashboardControls([...mergedDashboardControls, ...arr])
          } else {
            setDashboardControls(mergedDashboardControls)
          }
        }
        if (curRoute.isFeedback) {
          setFeedbackControls(filterObj.pageControls)
        }
        if (curRoute.isKeywords) {
          setKeywordsControls(filterObj.pageControls)
        }
        if (curRoute.isNetwork) {
          setNetworkControls(filterObj.pageControls)
        }

        // fill empty comparative slots of other page controls
        if (!curRoute.isCharts) {
          if (currentChartList.length < comparativePanelsNumber) {
            let arr = new Array(comparativePanelsNumber - 1)
            arr.fill(currentChartList[0])
            setCurrentChartList([...currentChartList, ...arr])
          }
          if (dashboardControls.length < comparativePanelsNumber) {
            let arr = new Array(comparativePanelsNumber - 1)
            arr.fill(initialState.dashboardControls[0])
            setDashboardControls([...dashboardControls, ...arr])
          }
        }
        if (!curRoute.isFeedback) {
          if (feedbackControls.length < comparativePanelsNumber) {
            let arr = new Array(comparativePanelsNumber - 1)
            arr.fill(feedbackControls[0])
            setFeedbackControls([...feedbackControls, ...arr])
          }
        }
        if (!curRoute.isKeywords) {
          if (keywordsControls.length < comparativePanelsNumber) {
            let arr = new Array(comparativePanelsNumber - 1)
            arr.fill(keywordsControls[0])
            setKeywordsControls([...keywordsControls, ...arr])
          }
        }
        if (!curRoute.isNetwork) {
          if (networkControls.length < comparativePanelsNumber) {
            let arr = new Array(comparativePanelsNumber - 1)
            arr.fill(networkControls[0])
            setNetworkControls([...networkControls, ...arr])
          }
        }
        let arr = new Array(comparativePanelsNumber - 1)
        arr.fill([])
        setOpenedOptions([...openedOptions, ...arr])
        setHasLoadedFilters(true)
        return originalData
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !hasLoadedFilters && !!location.search,
    }
  )
  // console.log(originalUrlData)

  // useEffect(() => {
  //   if (!hasLoadedFilters && !isLoading) {
  //     if (originalUrlData) {
  //       const filterObj = JSON.parse(originalUrlData)
  //       setFilters(filterObj.filters)
  //       const comparativePanelsNumber = filterObj.filters.length
  //       setComparativePanelsNumber(comparativePanelsNumber)
  //       setIsComparative(comparativePanelsNumber > 1)
  //
  //       // set country
  //       if (
  //         filterObj?.filters?.length &&
  //         filterObj.filters[0]?.values.find(
  //           (el: FilterOptionSelect) => el.field === 'country'
  //         )
  //       ) {
  //         setSelectedCountry(
  //           filterObj.filters[0].values.find(
  //             (el: FilterOptionSelect) => el.field === 'country'
  //           ).values[0]
  //         )
  //       } else {
  //         // @ts-ignore
  //         setSelectedCountry([])
  //       }
  //
  //       // TODO - set page controls with {...initialState[pageControlType], ...filterObj.pageControls} to fill any missing vals - can open up saving only modified values as well if we want
  //       // https://linear.app/yo-yo-gi/issue/EFE-99/loading-page-controls-object-spreading-to-include-all-default-values
  //       // set page controls
  //       if (route.isDashboard) {
  //         console.log(filterObj)
  //         setDashboardControls(filterObj.pageControls)
  //       }
  //       if (route.isFeedback) {
  //         setFeedbackControls(filterObj.pageControls)
  //       }
  //       if (route.isKeywords) {
  //         setKeywordsControls(filterObj.pageControls)
  //       }
  //       if (route.isNetwork) {
  //         setNetworkControls(filterObj.pageControls)
  //       }
  //
  //       // fill empty comparative slots of other page controls
  //       if (!route.isDashboard) {
  //         if (currentChartList.length < comparativePanelsNumber) {
  //           let arr = new Array(comparativePanelsNumber - 1)
  //           arr.fill(currentChartList[0])
  //           setCurrentChartList([...currentChartList, ...arr])
  //         }
  //       }
  //       if (!route.isFeedback) {
  //         if (feedbackControls.length < comparativePanelsNumber) {
  //           let arr = new Array(comparativePanelsNumber - 1)
  //           arr.fill(feedbackControls[0])
  //           setFeedbackControls([...feedbackControls, ...arr])
  //         }
  //       }
  //       if (!route.isKeywords) {
  //         if (keywordsControls.length < comparativePanelsNumber) {
  //           let arr = new Array(comparativePanelsNumber - 1)
  //           arr.fill(keywordsControls[0])
  //           setKeywordsControls([...keywordsControls, ...arr])
  //         }
  //       }
  //       if (!route.isNetwork) {
  //         if (networkControls.length < comparativePanelsNumber) {
  //           let arr = new Array(comparativePanelsNumber - 1)
  //           arr.fill(networkControls[0])
  //           setNetworkControls([...networkControls, ...arr])
  //         }
  //       }
  //       let arr = new Array(comparativePanelsNumber - 1)
  //       arr.fill([])
  //       setOpenedOptions([...openedOptions, ...arr])
  //       setHasLoadedFilters(true)
  //     } else {
  //       if (!isFetching) {
  //         setHasLoadedFilters(true)
  //       }
  //     }
  //   }
  // }, [originalUrlData, hasLoadedFilters, isLoading, isFetching])

  const hydrate = async (queryString: string) => {
    let panelNumber =
      parseUriParams(queryString).find(
        (el) => el.field === 'comparative_panels_number'
        // @ts-ignore
      )?.values || 1
    // this is a little inefficient but my brain isnt working rn and this works
    for (let i = 1; i < panelNumber; i++) {
      if (comparativePanelsNumber < panelNumber) {
        addPanel()
      }
    }
    for (let i = comparativePanelsNumber; i > 0; i--) {
      if (comparativePanelsNumber > panelNumber) {
        removePanel()
      }
    }
    hydrateSavedViewURL(
      queryString,
      updateFilters,
      setDashboardControls,
      setFeedbackControls,
      setKeywordsControls,
      setNetworkControls
    )
  }

  // TODO this might be useful for keeping rq state when changing pages - but on secondthought it might just cause a bunch of requeries for the complicated pages
  // but maybe for the more static pages something like this would be good
  // const [hasVisitedCompass, setHasVisitedCompass] = useState(false)
  //
  // useEffect(() => {
  //   if (route.isCompass) {
  //     setHasVisitedCompass(true)
  //   }
  // }, [route.isCompass])

  const { data: compassData } = useQuery(
    ['home', projectId],
    () => getCompassDetails(projectId, emptyFilters),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!projectId,
    }
  )

  // if (!hasLoadedFilters) {
  //   return <div></div>
  // }

  // const [isModalOpen, setIsModalOpen] = useState(true)

  // const handleOk = () => {
  //   setIsModalOpen(false)
  // }

  // const handleCancel = () => {
  //   setIsModalOpen(false)
  // }

  const { data: feature_flags } = useFeatureFlags()
  // TODO lazy loading??
  //https://linear.app/yo-yo-gi/issue/EFE-100/lazy-loading
  return (
    <Wrapper>
      {/* { (projectId === "some_uuid") && (
          <Modal title="Maintenance Notice" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <p>Hello! This is a notice that this dashboard is undergoing scheduled maintenance through the end of day June 20, 2023. Some metrics may appear different during this window. We   apologize for any inconvenience this may cause during this time.</p>
          </Modal>
        )
      } */}
      <Switch>
        <Route exact path={`${path}`}>
          <Home />
        </Route>
        <Route exact path={`${path}/${CHARTS_URL}`}>
          <Charts />
        </Route>
        <Route exact path={`${path}/${DASHBOARD_URL}`}>
          <Dashboards useNotifications={useNotifications} />
        </Route>
        <Route path={`${path}/${NETWORK_URL}`}>
          <NetworkContainer />
        </Route>
        <Route path={`${path}/${FEEDBACK_URL}`}>
          <FeedbackContainer />
        </Route>
        <Route path={`${path}/${KEYWORDS_URL}`}>
          <Keywords />
        </Route>
        <Route path={`${path}/${CATALOG_URL}`}>
          <Catalog />
        </Route>
        {feature_flags?.pdp_tool && (
          <Route path={`${path}/${PDP_URL}`}>
            <PDP />
          </Route>
        )}
        {feature_flags?.gemini && (
          <Route path={`${path}/${GEMINI_URL}`}>
            <Gemini />
          </Route>
        )}
        {feature_flags?.automated_insights && (
          <Route path={`${path}/${FINDINGS_URL}`}>
            <Findings />
          </Route>
        )}
        {feature_flags?.automated_insights && (
          <Route path={`${path}/${AUTOMATED_URL}`}>
            <Automated />
          </Route>
        )}
        {feature_flags?.automated_insights && (
          <Route path={`${path}/${AUTO_INSIGHTS_URL}`}>
            <AutoInsights />
          </Route>
        )}
        <Route path={`${path}/${SOURCES_URL}`}>
          <SourceContainer projectId={projectId} />
        </Route>
        <Route path={`${path}/${PRODUCTS_URL}`}>
          <Product projectId={projectId} />
        </Route>
        <Route path={`${path}/${SETTINGS_URL}`}>
          <Settings />
        </Route>
        <Route path={`${path}/${RECOMMENDATIONS_URL}`}>
          <Recommendations />
        </Route>
        <Route
          path={[
            `${path}/${SAVED_VIEWS_URL}/:viewName`,
            `${path}/${SAVED_VIEWS_URL}`,
          ]}
        >
          <SavedViews />
        </Route>
        <Route
          path={[`${path}/${ALERTS_URL}/:alertName`, `${path}/${ALERTS_URL}`]}
        >
          <Alerts useNotifications={useNotifications} />
        </Route>
      </Switch>
      {(route.isCharts || route.isFeedback || route.isKeywords) && (
        <>
          <Summary />
          <AlertDrawer />
        </>
      )}
      <Notifications useNotifications={useNotifications} />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`
