import { ChartType } from 'chart.js'
import { PieChart, DataForChart } from '../types'
import { numberFormatter } from 'utils/numberFormat'

export const configureTreemapChart = (
  currentChart: PieChart,
  expandedCategories?: string[],
  disableGrouping?: boolean
): DataForChart => {
  const oldValues = currentChart.values.map((v) => ({
    value: v.volume,
    title: v[currentChart.legend_key],
    color: v.color,
  })) as any
  const oldLegend = currentChart.values.map((v) => ({
    label: v.category,
    color: v.color,
    disabled: false,
  }))

  // for each expanded category, push the child labels into a list
  let openChildLabels: string[] = []
  if (currentChart.category_mapping && expandedCategories?.length) {
    expandedCategories?.forEach((category) => {
      openChildLabels = openChildLabels.concat(
        currentChart.category_mapping[category] ?? []
      )
      openChildLabels = openChildLabels.concat(
        currentChart.category_mapping[category].map(
          (item) => item + ' Volume'
        ) ?? []
      )
    })
  }

  let childValues = currentChart.values.filter((el) =>
    openChildLabels.includes(el.category)
  )

  // TODO this should happen dynamically above, this solution only works for if a single child is filtered for
  // TODO this will not work if 1 child of Category 1 and 2 children of Category 2 are selected
  let maxChildrenCount = 0
  if (currentChart.category_mapping) {
    Object.values(currentChart.category_mapping).forEach((value) => {
      maxChildrenCount = Math.max(maxChildrenCount, value.length)
    })
  }

  let values =
    maxChildrenCount > 1 && currentChart.parent_values
      ? [...childValues, ...(currentChart.parent_values ?? [])]
      : currentChart.values

  if (disableGrouping) {
    values = currentChart.values
  }

  const legend = values.map((v) => ({
    color: v.color,
    label: v.category,
    disabled: expandedCategories?.includes(v.category) ?? false,
    children:
      currentChart?.category_mapping &&
      currentChart?.category_mapping[v.category]?.map((item) => ({
        color: values.find((el) => el.category === item)?.color ?? v.color,
        label: item.split(': ')[1] ?? item,
        disabled: false,
      })),
  }))

  const labels = values.map((v) => v[currentChart.legend_key])

  const chartValues = values.map((v) => ({
    value: v.volume,
    title: v[currentChart.legend_key],
    color: v.color,
  })) as any

  const options: any = {
    animation: {
      colors: {
        type: 'color',
        duration: 300,
      },
    },
    responsive: true,
    resizeDelay: 0,
    maintainAspectRatio: false,
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    plugins: {
      datalabels: false,
      legend: false,
      tooltip: {
        callbacks: {
          title: function (data: any) {
            return `${data[0].dataset.data[data[0].dataIndex].g}`
          },
          label: function (data: any) {
            return `${numberFormatter(data.dataset.data[data.dataIndex].v)}`
          },
        },
      },
    },
  }

  const getColor = (ctx: any) => {
    // const localValues = currentChart.values.map((v) => ({
    //   value: v.volume,
    //   title: v[currentChart.legend_key],
    //   color: v.color,
    // })) as any
    // console.log(localValues)
    const foundItem = chartValues.find(
      (item: any) => ctx.raw?.g.toString() === item.title.toString()
    )
    return foundItem?.color
  }

  const data: any = {
    datasets: [
      {
        data: chartValues,
        key: 'value',
        tree: chartValues,
        groups: ['title'],
        backgroundColor: getColor,
        color: '#fff',
        font: { family: 'Helvetica' },
        labels: {
          align: 'center',
          display: true,
          formatter(ctx: any) {
            const hover = Object.entries(
              // @ts-ignore
              values.find(
                (item) => item.category.toString() === ctx.raw.g.toString()
              )?.hover
            ).map(([key, value]) => {
              return key + ': ' + numberFormatter(value as any)
            })
            return ctx.raw.g + '\n' + hover.join('\n')
          },
          color: '#ffffff',
          position: 'center',
        },
      },
    ],
    labels: labels,
  }

  return { data, options, type: 'treemap' as ChartType, height: 500, legend }
}
