import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Loader } from 'shared/components'
import { Badge } from 'antd'

export const Profile: React.FC<any> = React.memo(function Profile({
  loading,
  data,
  is_trial,
}) {
  const history = useHistory()

  if (loading || !data) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  return (
    <>
      <ProfileWrapper>
        <ProfileAvatar count={is_trial ? "Trial" : undefined} color="orange" style={{fontSize: ".9rem",}}>
          {data.first_name[0]}
          {data.last_name[0]}
        </ProfileAvatar>
        
        <ProfileName>
          {data.first_name} {data.last_name}
        </ProfileName>
        <ProfileInfo>
          <b>Email Address:</b> {data.email}
        </ProfileInfo>
        <ProfileInfo>
          <b>Company:</b> {data.org}
        </ProfileInfo>
        <ContactInfo>
          <ContactTitle>Contact us</ContactTitle>
          <div>
            To make any changes, deactivate your account, or resolve any other
            issues, please send us an email at{' '}
            <a href="mailto:support@meetyogi.com">support@meetyogi.com</a>.
          </div>
        </ContactInfo>
        <GoBack onClick={() => history.goBack()}>
          <ArrowLeftOutlined />
          Return
        </GoBack>
      </ProfileWrapper>
    </>
  )
})

const LoaderWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
`
const ProfileWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  position: relative;
`
const ProfileAvatar = styled(Badge)`
  height: 96px;
  width: 96px;
  border: 2px solid black;
  border-radius: 100%;
  font-size: var(--font-size-xxl);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--default-padding-double) auto;
`
const ProfileName = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 600;
  margin-bottom: var(--default-padding-double);
`
const ProfileInfo = styled.div`
  margin-bottom: var(--default-padding-half);
`
const ContactInfo = styled.div`
  margin-top: var(--default-padding-double);
  text-align: center;
  box-shadow: var(--default-box-shadow);
  padding: var(--default-padding);
`
const ContactTitle = styled.div`
  font-size: var(--font-size-xl);
  font-weight: 600;
  margin-bottom: var(--default-padding-half);
`
const GoBack = styled.a`
  width: 220px;
  position: absolute;
  top: 0;
  right: 100%;
  color: black;

  svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`
