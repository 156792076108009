import React, { useState } from 'react'
import { Collapse, List, Input } from 'antd'
import styled from 'styled-components'
import { getSourceGroupInfo } from '../model'
import { useQuery } from 'react-query'
import { Loader } from 'shared/components'
import { SourceCustomFields } from './source-custom-fields'
import { SourceTableDescription } from './source-table-description'
import { numberFormatter } from 'utils/numberFormat'
import { SearchOutlined } from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'

type Props = {
  sourcename: string
  projectId: string
  syndicated: boolean
  role: string
}

export const SourceModal: React.FC<Props> = ({
  projectId,
  sourcename,
  syndicated,
  role,
}) => {
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['source_name', sourcename, syndicated, projectId],
    () => getSourceGroupInfo('source_name', sourcename, syndicated, projectId)
  )

  const [search, setSearch] = useState('')

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  if (isError) {
    return <span> Error: {error}</span>
  }

  const onSearch = (e: any) => {
    setSearch(e.target.value)
  }

  return (
    <>
      {!syndicated && (
        <div>
          <Input
            className="source-modal-search"
            prefix={<SearchOutlined />}
            placeholder="Search for Source"
            allowClear
            value={search}
            onChange={onSearch}
            style={{ margin: '20px', width: '500px' }}
          />
          <Collapse
            defaultActiveKey={['1']}
            onChange={(key) => {
              mixpanel.track('source data', {
                action: 'source info',
                value: data?.sources.find((item) => item.source_uuid === key[1])
                  ?.title,
              })
            }}
          >
            {data &&
              data.sources
                .filter((data) => {
                  return (
                    data.title.toLowerCase().indexOf(search.toLowerCase()) !==
                    -1
                  )
                })
                .map((source) => (
                  <Collapse.Panel
                    header={source.title}
                    key={source.source_uuid}
                    extra={numberFormatter(source.reviews) + ' Review(s)'}
                  >
                    <SourceTableDescription
                      role={role}
                      source_uuid={source.source_uuid}
                      title={source.title}
                      description={source.description}
                      item={source.product.name}
                      last_update_time={source.last_update_time}
                      state={source.state}
                      hierarchy={source.product.hierarchy}
                      refetch_data={refetch}
                      url={source.source_url}
                    ></SourceTableDescription>
                    <br></br>
                    <SourceCustomFields
                      uuid={source.source_uuid}
                      data={source.custom_fields}
                      role={role}
                    ></SourceCustomFields>
                  </Collapse.Panel>
                ))}
          </Collapse>
        </div>
      )}
      {syndicated && (
        <List
          bordered
          header={
            <div>
              <b>Items</b>
            </div>
          }
        >
          {data &&
            data.sources.map((source) => (
              <List.Item>{source.product.name}</List.Item>
            ))}
        </List>
      )}
    </>
  )
}

const LoaderWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
