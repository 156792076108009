import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
  dangerouslySetInnerHTML?: { __html: string }
}

export const InnerTitle: React.FC<Props> = ({ children, style }) => {
  return <HeaderText style={style}>{children}</HeaderText>
}
const HeaderText = styled.h2`
  color: var(--color-text-dark-grey);
`
