import React, { useState } from 'react'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import styled from 'styled-components'

type SelectType = {
  value: string
  n_posts: number
  identifiers: string[]
}

type NestedThemeInputProps = {
  theme: String
  subthemes: SelectType[]
  selectItemContent: Function
}

const NestedThemeInput: React.FC<NestedThemeInputProps> = ({
  theme,
  subthemes,
  selectItemContent,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {selectItemContent(
          {
            value: subthemes.length === 1 ? subthemes[0].value : theme,
            n_posts: subthemes.reduce(
              (acc: number, el: SelectType) => acc + el.n_posts,
              0
            ),
            identifiers: subthemes
              .map((el: SelectType) => el.identifiers)
              .flat(),
          },
          true
        )}

        {subthemes.length > 1 && (
          <Caret onClick={() => setIsOpen((prevState) => !prevState)}>
            {isOpen ? <CaretUpFilled /> : <CaretDownFilled />}
          </Caret>
        )}
      </div>
      {/*{subthemes.length > 1 && theme !== subthemes[0].value && (*/}
      {subthemes.length > 1 && (
        <CollapsedContent
          style={{ maxHeight: isOpen ? 30 * subthemes.length : 0 }}
        >
          {subthemes.map((subtheme: SelectType) => (
            <div>{selectItemContent(subtheme)}</div>
          ))}
        </CollapsedContent>
      )}
    </div>
  )
}

type NestedThemes = {
  themes: SelectType[]
  selectItemContent: Function
  floatValues: boolean
}

const NestedThemes: React.FC<NestedThemes> = ({
  themes,
  selectItemContent,
  floatValues,
}) => {
  const topLevelThemes = new Set()
  const subThemeMap: Record<string, SelectType[]> = {}

  themes.forEach((el) => {
    const splitName = el.value.split(': ')
    if (splitName.length === 1) {
      topLevelThemes.add(splitName[0])
      subThemeMap[splitName[0]] = [el]
    } else if (splitName.length === 2) {
      topLevelThemes.add(splitName[0])
      if (!subThemeMap[splitName[0]]) {
        if (
          splitName[0] === 'Fans / Attractors' ||
          splitName[0] === 'Critics / Detractors'
        ) {
          subThemeMap[splitName[0]] = [{ ...el, value: splitName[0] }]
        } else {
          subThemeMap[splitName[0]] = [{ ...el, value: splitName[1] }]
        }
      } else {
        if (
          splitName[0] === 'Fans / Attractors' ||
          splitName[0] === 'Critics / Detractors'
        ) {
          subThemeMap[splitName[0]][0].n_posts =
            subThemeMap[splitName[0]][0].n_posts + el.n_posts
          subThemeMap[splitName[0]][0].identifiers = [
            ...subThemeMap[splitName[0]][0].identifiers,
            ...el.identifiers,
          ]
        } else {
          subThemeMap[splitName[0]].push({ ...el, value: splitName[1] })
        }
      }
    } else {
      // bad theme name do nothing
    }
  })

  // TODO maybe split this into two sets - with reviews / without reviews to mimic original behavior
  const orderedKeys = floatValues
    ? Object.keys(subThemeMap).sort((a, b) => {
        return (
          subThemeMap[b].reduce(
            (acc: number, el: SelectType) => acc + el.n_posts,
            0
          ) -
          subThemeMap[a].reduce(
            (acc: number, el: SelectType) => acc + el.n_posts,
            0
          )
        )
      })
    : Object.keys(subThemeMap)

  return (
    <div>
      {orderedKeys.map((theme) => (
        <NestedThemeInput
          key={theme}
          theme={theme}
          subthemes={subThemeMap[theme]}
          selectItemContent={selectItemContent}
        />
      ))}
    </div>
  )
}

export default NestedThemes

const CollapsedContent = styled.div`
  margin-left: 20px;
  transition: max-height 0.2s ease-out, opacity 0.3s ease-out;
  max-height: 0;
  overflow: hidden;
`

const Caret = styled.div`
  padding: 0px 5px;
  margin-left: -15px;
  cursor: pointer;
`
