import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
}

export const Title: React.FC<Props> = ({ children, style }) => {
  return <HeaderText style={style}>{children}</HeaderText>
}
const HeaderText = styled.h1`
  margin-bottom: 0px;
  font-weight: bold;
  color: var(--color-text-dark-grey);
  margin-bottom: 0px;
`
