import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Checkbox, Input, Radio, Slider, Spin, Tooltip } from 'antd'
import {
  AppliedFilterOption,
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
  ApplyFilterPayload,
  ApplyGroupFilterPayload,
  FilterOption,
  FilterOptionSelect,
  FilterOptionValue,
  FilterTypes,
} from 'features/project/features/filters/types'
import { InfoCircleOutlined } from '@ant-design/icons'
import { generateSliderMarks, isAppliedFilterOptionSelect } from '../../helpers'
import './../../styles.css'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import Divider from '../../../../../../components/UI/YogiDivider'
import { secondaryNavy } from '../../../../../../assets/styles/variables'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import ratingsAndReviews from 'assets/svg/RatingFilter/ReviewsAndRatings.svg'
import reviewOnly from 'assets/svg/RatingFilter/ReviewOnly.svg'
import ratingOnly from 'assets/svg/RatingFilter/RatingOnly.svg'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import NestedThemes from './components/NestedThemes'

type Props = {
  children?: React.ReactNode
  option?: FilterOption
  appliedFilters: Array<AppliedFilterOption>
  applyFilter: (p: ApplyFilterPayload) => void
  applyGroupFilter: (p: ApplyGroupFilterPayload) => void
  applyEveryFilter: (search: string) => void
  clearFilters: (search: string) => void
  comparativeIndex: number
  filterList: FilterOption[]
  filterListData: FilterOption[] | undefined
  filterOptionsLoading?: boolean
  parentFilters?: Filters
  isUnlinked?: boolean
  filterValues: Filters
}

const NULL_FIELD = 'Null'
const numberFormatter = new Intl.NumberFormat()

export const FilterPanelSelector: React.FC<Props> = ({
  option: propOption,
  applyFilter,
  applyGroupFilter,
  appliedFilters,
  clearFilters,
  applyEveryFilter,
  comparativeIndex,
  filterList,
  filterListData,
  filterOptionsLoading,
  parentFilters,
  isUnlinked,
  filterValues,
}) => {
  const [search, setSearch] = useState('')
  const [checkAll, setCheckAll] = useState(false)
  const [option, setOption] = useState(propOption)
  const details = useProjectStore((state: ProjectState) => state.details)
  const ref = useRef<any>()
  const [buttonDisplayed, setButtonDisplayed] = useState(false)
  const [floatValues, setFloatValues] = useState(false)

  const isSampleDash = details?.title?.includes('[Sample]')
  const isLLMDash = !!details.clientSettings['llm-dash']

  useEffect(() => {
    if (!!propOption?.filter_type) {
      setFloatValues(false)
    }
  }, [propOption])

  // useEffect(() => {
  //   // including filterList in this means the display updates when the panel is open and filter options returns with new data
  //   if (propOption && propOption?.filter_type) {
  //     if (propOption.filter_type === FilterTypes.WINDOW) {
  //       if (!propOption.min || !propOption.max) {
  //         setOption(
  //           defaultFilterList.find(
  //             //@ts-ignore
  //             (filter) => filter.field === propOption?.field
  //           ) ?? propOption
  //         )
  //         return
  //       }
  //     }
  //     setOption(
  //       (filterList || defaultFilterList).find(
  //         (filter) => filter.field === propOption?.field
  //       ) ?? propOption
  //     )
  //   } else {
  //     //@ts-ignore
  //     const defaultOption = defaultFilterList.find(
  //       //@ts-ignore
  //       (filter) => filter.field === propOption?.field
  //     )
  //     if (defaultOption?.filter_type === FilterTypes.SELECT) {
  //       //@ts-ignore
  //       if (!defaultOption?.values) {
  //         setOption(propOption)
  //       }
  //       const tempOption = _.cloneDeep(defaultOption)
  //       //@ts-ignore
  //       tempOption.values = defaultOption.values.map((value: any) => ({
  //         value: value.value,
  //       }))
  //       setOption(tempOption)
  //     } else {
  //       setOption(defaultOption)
  //     }
  //   }
  // }, [propOption, filterList])

  useEffect(() => {
    setOption(
      // filterListData?.find((filter) => filter.field === propOption?.field) ||
      (!!filterListData?.find((filter) => filter.field === propOption?.field)
        ?.filter_type &&
        filterListData?.find((filter) => filter.field === propOption?.field)) ||
        (!!filterList?.find((filter) => filter.field === propOption?.field)
          ?.filter_type &&
          filterList?.find((filter) => filter.field === propOption?.field)) ||
        propOption
    )
  }, [filterListData, filterList, propOption])

  useEffect(() => {
    setSearch('')
    setButtonDisplayed(
      ref?.current?.parentElement?.clientHeight < ref?.current?.clientHeight
    )
  }, [option])

  if (!option) {
    return null
  }

  const currentAppliedFilter = appliedFilters.find(
    (filter) => filter.field === option.field
  )

  const apply = (value: ApplyFilterPayload['value']) => {
    return applyFilter({ field: option.field, value: value })
  }
  const applySelect = (value: ApplyGroupFilterPayload['value']) => {
    return applyGroupFilter({ field: option.field, value: value })
  }

  const filterByReviews = (option: FilterOptionSelect) => {
    setOption({
      ...option,
      values: option?.values
        ?.filter((item) => item.n_posts !== 0)
        .sort((a: any, b: any) => {
          return b.value - a.value
        })
        .concat(option?.values?.filter((item) => item.n_posts === 0)),
    })
  }

  const dedupedValues: any = {}

  ;(option as FilterOptionSelect)?.values?.forEach((item: any) => {
    if (!dedupedValues[item.value]) {
      dedupedValues[item.value] = { n_posts: 0, identifiers: [] }
    }
    dedupedValues[item.value].n_posts += item.n_posts
    dedupedValues[item.value].identifiers.push(item.cluster_uuid ?? item.value)
  })

  const dedupedList = Object.keys(dedupedValues)
    .map((key) => ({
      value: key,
      n_posts: dedupedValues[key].n_posts,
      identifiers: dedupedValues[key].identifiers,
    }))
    // this is for the case where we have a parent filter selected and we want to limit the child filter to those options
    .filter((el) => {
      if (isUnlinked || !parentFilters) return true
      const parentFilter = parentFilters?.values.find(
        (parentFilter) => parentFilter.field === propOption?.field
      ) as AppliedFilterOptionSelect | undefined
      // TODO any changes needed for window / non select filters?
      if (
        // if parent filter not set (and as such not applying a limit to filter options)
        !parentFilter?.values ||
        // or parent filter includes this option
        parentFilter?.values?.includes(el?.value) ||
        // or current filter includes this option (useful for when adding link to filter that is outside parent, so you can see it to remove it)
        (currentAppliedFilter as AppliedFilterOptionSelect)?.values?.includes(
          el?.value
        )
      )
        return true
      return false
    })

  const checkedItems = dedupedList?.filter((item) =>
    checkIfGroupFilterSelected.checkbox(
      currentAppliedFilter as AppliedFilterOptionSelect,
      item.identifiers
    )
  )

  const uncheckedItems = dedupedList?.filter(
    (item) =>
      !checkIfGroupFilterSelected.checkbox(
        currentAppliedFilter as AppliedFilterOptionSelect,
        item.identifiers
      )
  )

  const filteredItems = uncheckedItems
    ?.filter((item) => item.n_posts !== 0)
    .sort((a, b) => b.n_posts - a.n_posts)

  const unfilteredItems = uncheckedItems?.filter((item) => item.n_posts === 0)

  const storedValues = filterList.find(
    (el) => el.field === option.field
    // @ts-ignore
  )?.values

  const selectItemContent = (value: FilterOptionValue, isParent?: boolean) => {
    // this checks to see if we have already calculated the values for this filter
    const numberOfPosts = numberFormatter.format(value.n_posts)
    const clusterId = value.cluster_uuid || value.value
    let displayValue = value.value
    const checked = checkIfGroupFilterSelected.checkbox(
      currentAppliedFilter as AppliedFilterOptionSelect,
      value.identifiers
    )

    let indeterminate = false
    if (isParent) {
      const intersection = value.identifiers.filter((value) =>
        (currentAppliedFilter as AppliedFilterOptionSelect)?.values.includes(
          value
        )
      )
      indeterminate = !!(
        intersection.length && intersection.length !== value.identifiers.length
      )
    }

    return (
      <StyledCheckbox
        key={value.cluster_uuid || value.value}
        checked={checked}
        indeterminate={indeterminate}
        onChange={() => {
          applySelect(value.identifiers)
        }}
      >
        {displayValue}{' '}
        {!!value.n_posts && !!storedValues && (
          <>{numberOfPosts !== '0' && <b>({numberOfPosts})</b>}</>
        )}
      </StyledCheckbox>
    )
  }

  // Sometimes there can be filter values in a filter that were renamed or deleted. This function checks if the filter values in the filter are not in the filter options list
  const hasDeprecatedFilterValue = (
    filterValues: Filters,
    filterOption: FilterOptionSelect
  ) => {
    return (
      filterValues.values.find(
        (el) => el.field === option.field
      ) as AppliedFilterOptionSelect
    )?.values.some(
      (el) =>
        !filterOption.values
          .map((el) => el.cluster_uuid ?? el.value)
          .includes(el)
    )
  }

  const hasDeletedFilter =
    option.filter_type === FilterTypes.SELECT &&
    hasDeprecatedFilterValue(filterValues, option as FilterOptionSelect)

  return (
    <Wrapper ref={ref}>
      {/*<TitleWrapper>*/}
      {/*  <Title>{option.alias}</Title>*/}
      {/*  {filterValues.values.filter((item: any) => item.field).length !== 0 &&*/}
      {/*    buttonDisplayed && (*/}
      {/*      <Button*/}
      {/*        type="text"*/}
      {/*        onClick={() => filterByReviews(option as FilterOptionSelect)}*/}
      {/*      >*/}
      {/*        {'Show Filtered'}*/}
      {/*      </Button>*/}
      {/*    )}*/}
      {/*</TitleWrapper>*/}
      {option?.description && (
        <FilterOptionDescription>{option?.description}</FilterOptionDescription>
      )}
      {option.field !== 'score' && option.field !== 'sentiment' ? (
        <>
          <Description>
            {option.filter_type === FilterTypes.SELECT &&
            option.values.length === 0
              ? 'Too many values to display.'
              : 'Select one or more options to filter.'}

            {option.filter_type === FilterTypes.SELECT &&
              option.values.length > 10 && (
                <StyledInput
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search for option..."
                />
              )}
          </Description>
          <Divider />
        </>
      ) : (
        <>
          <Description>
            Select an option
            {option.field === 'score' ? (
              <Tooltip
                title={
                  'Choose Reviews Only for reviews with text, Ratings Only for ratings without text, or Reviews & Ratings for all reviews and ratings.'
                }
              >
                <InfoCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  'Choose Review Sentiment to filter for reviews with a particular overall sentiment value, or Sentence Sentiment to filter for sentences with a particular sentiment value. A review can have a mix of positive, negative, and neutral sentiment sentences. Note that the numbers next to the options are the counts of reviews not sentences.'
                }
              >
                <InfoCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            )}
          </Description>
          <Divider />
        </>
      )}
      <Option>
        {!option.filter_type && (
          <SpinWrapper>
            <StyledSpin />
          </SpinWrapper>
        )}
        {option.filter_type === FilterTypes.SELECT && (
          <>
            {/*{option.values.length > 10 && (*/}
            {/*  <Input*/}
            {/*    value={search}*/}
            {/*    onChange={(e) => setSearch(e.target.value)}*/}
            {/*    placeholder="Search for option..."*/}
            {/*  />*/}
            {/*)}*/}
            <Option>
              {option.field === `sentiment` && (
                <span style={{ marginBottom: '10px' }}>
                  <Radio.Group
                    value={((currentAppliedFilter as AppliedFilterOptionSelect)
                      ?.values
                      ? (
                          currentAppliedFilter as AppliedFilterOptionSelect
                        ).values.includes('0')
                        ? '0'
                        : '1'
                      : '1'
                    ).toString()}
                    onChange={function (e: any) {
                      if (
                        !(
                          currentAppliedFilter as AppliedFilterOptionSelect
                        )?.values.includes('0') ||
                        e.target.value === '1'
                      ) {
                        apply('0')
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <RadioOption
                      selected={
                        ((currentAppliedFilter as AppliedFilterOptionSelect)
                          ?.values
                          ? (
                              currentAppliedFilter as AppliedFilterOptionSelect
                            ).values.includes('0')
                            ? '0'
                            : '1'
                          : '1'
                        ).toString() === '1'
                      }
                      value="1"
                    >
                      Sentence Sentiment
                    </RadioOption>
                    <RadioOption
                      selected={
                        ((currentAppliedFilter as AppliedFilterOptionSelect)
                          ?.values
                          ? (
                              currentAppliedFilter as AppliedFilterOptionSelect
                            ).values.includes('0')
                            ? '0'
                            : '1'
                          : '1'
                        ).toString() === '0'
                      }
                      value="0"
                    >
                      Review Sentiment
                    </RadioOption>
                    {/*<Radio.Button value="0">Review Sentiment</Radio.Button>*/}
                    {/*<Radio.Button value="1">Sentence Sentiment</Radio.Button>*/}
                  </Radio.Group>
                </span>
              )}
              {/*{option.values && (*/}
              {/*  <>*/}
              {/*    <StyledCheckbox*/}
              {/*      checked={checkAll}*/}
              {/*      onChange={() => {*/}
              {/*        setCheckAll(!checkAll)*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      All {option.alias}*/}
              {/*      {'s'}*/}
              {/*    </StyledCheckbox>*/}
              {/*    <Divider />*/}
              {/*  </>*/}
              {/*)}*/}
              {option.field === 'theme' && (isSampleDash || isLLMDash) ? (
                <div style={{ marginTop: '10px' }}>
                  <NestedThemes
                    themes={dedupedList.filter(
                      ({ value }) =>
                        value.toLowerCase().indexOf(search.toLowerCase()) !== -1
                    )}
                    selectItemContent={selectItemContent}
                    floatValues={floatValues}
                  />
                </div>
              ) : (
                <div style={{ marginTop: '10px' }}>
                  {(!!checkedItems.length || hasDeletedFilter) && (
                    <>
                      <OptionSelectLabel>
                        Applied ({checkedItems.length})
                      </OptionSelectLabel>
                      {checkedItems
                        .filter(
                          ({ value }) =>
                            value
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) !== -1
                        )
                        .map((value) => selectItemContent(value))}
                      {/* How we can visualize items in the filter that are not in the filter list (deprecated values on saved views etc)*/}
                      {hasDeletedFilter && (
                        <>
                          {(
                            filterValues.values.find(
                              (el) => el.field === option.field
                            ) as AppliedFilterOptionSelect
                          )?.values
                            .filter(
                              (el) =>
                                !option.values
                                  .map((el) => el.cluster_uuid ?? el.value)
                                  .includes(el)
                            )
                            .map((value) =>
                              selectItemContent({
                                value: '[Deleted Value]',
                                n_posts: 0,
                                identifiers: [value],
                              })
                            )}
                          <div style={{ padding: '0px 20px' }}>
                            * Deleted Values occur from project updates and may
                            cause issues creating charts. Removing the Deleted
                            Value is recommended.
                          </div>
                        </>
                      )}
                      <Divider />
                    </>
                  )}

                  <OptionSelectLabel style={{ paddingTop: '10px' }}>
                    Unselected ({option?.values.length - checkedItems.length})
                  </OptionSelectLabel>
                  {floatValues ? (
                    <>
                      {filteredItems
                        .filter(
                          ({ value }) =>
                            value
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) !== -1
                        )
                        .map((value) => selectItemContent(value))}
                      {unfilteredItems
                        .filter(
                          ({ value }) =>
                            value
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) !== -1
                        )
                        .map((value) => selectItemContent(value))}
                    </>
                  ) : (
                    <>
                      {uncheckedItems
                        .filter(
                          ({ value }) =>
                            value
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) !== -1
                        )
                        .map((value) => selectItemContent(value))}
                    </>
                  )}
                </div>
              )}
            </Option>
          </>
        )}
        {option.filter_type === FilterTypes.WINDOW && (
          <Option>
            {option.min === undefined ? (
              <SpinWrapper>
                <Spin />
              </SpinWrapper>
            ) : (
              <>
                {option.field === `score` && (
                  <span>
                    <Radio.Group
                      disabled={
                        checkIfFilterSelected.checkbox(
                          currentAppliedFilter as AppliedFilterOptionSelect,
                          NULL_FIELD
                        ) || option.min === undefined
                      }
                      value={((currentAppliedFilter as AppliedFilterOptionRange)
                        ?.value_type
                        ? (currentAppliedFilter as AppliedFilterOptionRange)
                            .value_type
                        : 0
                      ).toString()}
                      onChange={function (e: any) {
                        apply({
                          min: (
                            currentAppliedFilter as AppliedFilterOptionRange
                          )?.min
                            ? (currentAppliedFilter as AppliedFilterOptionRange)
                                ?.min
                            : option?.cur_min || option.min,
                          max: (
                            currentAppliedFilter as AppliedFilterOptionRange
                          )?.max
                            ? (currentAppliedFilter as AppliedFilterOptionRange)
                                ?.max
                            : option?.cur_max || option.max,
                          value_type: parseInt(e.target.value),
                        })
                      }}
                      style={{ width: '100%' }}
                    >
                      <RadioOption
                        selected={
                          ((currentAppliedFilter as AppliedFilterOptionRange)
                            ?.value_type
                            ? (currentAppliedFilter as AppliedFilterOptionRange)
                                .value_type
                            : 0
                          ).toString() === '0'
                        }
                        value="0"
                      >
                        Reviews & Ratings
                        {((currentAppliedFilter as AppliedFilterOptionRange)
                          ?.value_type
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              .value_type
                          : 0
                        ).toString() === '0' && (
                          <div>
                            <p style={{ fontWeight: 400 }}>
                              We’ll show you reviews with ratings, reviews
                              without ratings, and ratings without reviews.
                            </p>
                            <div style={{ display: 'flex', gap: 10 }}>
                              <img src={ratingsAndReviews} />
                              <img src={reviewOnly} />
                              <img src={ratingOnly} />
                            </div>
                          </div>
                        )}
                      </RadioOption>
                      <RadioOption
                        selected={
                          ((currentAppliedFilter as AppliedFilterOptionRange)
                            ?.value_type
                            ? (currentAppliedFilter as AppliedFilterOptionRange)
                                .value_type
                            : 0
                          ).toString() === '1'
                        }
                        value="1"
                      >
                        Reviews Only
                        {((currentAppliedFilter as AppliedFilterOptionRange)
                          ?.value_type
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              .value_type
                          : 0
                        ).toString() === '1' && (
                          <div>
                            <p style={{ fontWeight: 400 }}>
                              We’ll show you reviews with ratings and reviews
                              without ratings, but won’t show ratings without
                              reviews.
                            </p>
                            <div style={{ display: 'flex', gap: 10 }}>
                              <img src={ratingsAndReviews} />
                              <img src={reviewOnly} />
                            </div>
                          </div>
                        )}
                      </RadioOption>
                      <RadioOption
                        selected={
                          ((currentAppliedFilter as AppliedFilterOptionRange)
                            ?.value_type
                            ? (currentAppliedFilter as AppliedFilterOptionRange)
                                .value_type
                            : 0
                          ).toString() === '2'
                        }
                        value="2"
                      >
                        Ratings Only
                        {((currentAppliedFilter as AppliedFilterOptionRange)
                          ?.value_type
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              .value_type
                          : 0
                        ).toString() === '2' && (
                          <div>
                            <p style={{ fontWeight: 400 }}>
                              We’ll show you ratings without reviews.
                            </p>
                            <div style={{ display: 'flex', gap: 10 }}>
                              {/*<img src={ratingsAndReviews} />*/}
                              <img src={ratingOnly} />
                            </div>
                          </div>
                        )}
                      </RadioOption>
                    </Radio.Group>{' '}
                  </span>
                )}
                <StyledSlider
                  max={option.max}
                  min={option.min}
                  step={null}
                  included={true}
                  marks={generateSliderMarks(
                    option.min,
                    option.max,
                    option.step
                  )}
                  value={
                    checkIfFilterSelected.slider(
                      currentAppliedFilter as AppliedFilterOptionRange
                    ) ||
                    (option.cur_min && option.cur_max
                      ? [option.cur_min, option.cur_max]
                      : [1, 5])
                  }
                  onChange={(values: any) => {
                    if (
                      !checkIfFilterSelected.checkbox(
                        currentAppliedFilter as AppliedFilterOptionSelect,
                        NULL_FIELD
                      )
                    ) {
                      apply({
                        min: values[0],
                        max: values[1],
                        value_type: (
                          currentAppliedFilter as AppliedFilterOptionRange
                        )?.value_type
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              ?.value_type
                          : 0,
                      })
                    }
                  }}
                  range
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0px 40px',
                  }}
                >
                  <span>{option.min}</span> <span>{option.max}</span>
                </div>
                {option.Null > 0 && (
                  <StyledCheckbox
                    key="Null"
                    checked={checkIfFilterSelected.checkbox(
                      currentAppliedFilter as AppliedFilterOptionSelect,
                      NULL_FIELD
                    )}
                    onChange={() => apply(NULL_FIELD)}
                  >
                    {option.field === 'score'
                      ? 'Reviews with no star rating'
                      : 'No Value'}{' '}
                    ({option.Null})
                  </StyledCheckbox>
                )}
              </>
            )}
          </Option>
        )}
      </Option>

      {option.filter_type === FilterTypes.SELECT &&
        option.values.length > 0 && (
          <>
            <Divider />
            <ButtonWrapper>
              <ButtonInnerWrapper>
                <YogiButton
                  type={'ghost'}
                  onClick={() => clearFilters(search)}
                  disabled={
                    !(currentAppliedFilter as AppliedFilterOptionSelect)?.values
                      ?.length ||
                    (option.field === 'sentiment' &&
                      !!(currentAppliedFilter as AppliedFilterOptionSelect)
                        ?.values.length &&
                      (currentAppliedFilter as AppliedFilterOptionSelect)
                        ?.values[0] === '0')
                  }
                >
                  Deselect All
                </YogiButton>
                <YogiButton
                  type={'ghost'}
                  onClick={() => applyEveryFilter(search)}
                >
                  Select All
                </YogiButton>
              </ButtonInnerWrapper>
              {/* TODO - probably check if stable filter list has entry and && with filteroptionsloading*/}
              <ButtonInnerWrapper>
                {!propOption?.filter_type || !storedValues ? (
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <StyledSpin />
                    <LoadingCounts>Loading Review Counts</LoadingCounts>
                  </div>
                ) : (
                  <YogiButton
                    onClick={() => setFloatValues(!floatValues)}
                    // disabled={
                    //   propOption?.filter_type &&
                    //   (floatValues ||
                    //     filteredItems.length === uncheckedItems.length)
                    // }
                  >
                    {floatValues
                      ? 'Sort Alphabetically'
                      : 'Sort by Review Count'}
                  </YogiButton>
                )}
              </ButtonInnerWrapper>
            </ButtonWrapper>
          </>
        )}
    </Wrapper>
  )
}

const checkIfFilterSelected = {
  checkbox: (
    filter: AppliedFilterOptionSelect | AppliedFilterOptionRange | undefined,
    value: string
  ) => {
    if (!filter) return false

    return isAppliedFilterOptionSelect(filter)
      ? filter.values.includes(value)
      : false
  },
  slider: (
    filter: AppliedFilterOptionRange | undefined
  ): [number, number] | undefined => {
    if (filter?.max && filter?.min) return [filter.min, filter.max]
  },
}

const checkIfGroupFilterSelected = {
  checkbox: (
    filter: AppliedFilterOptionSelect | AppliedFilterOptionRange | undefined,
    value: string[]
  ) => {
    if (!filter) return false

    return isAppliedFilterOptionSelect(filter)
      ? value.every((v) => filter.values.includes(v))
      : false
  },
  slider: (
    filter: AppliedFilterOptionRange | undefined
  ): [number, number] | undefined => {
    if (filter?.max && filter?.min) return [filter.min, filter.max]
  },
}

const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  height: 100%;
`
const Title = styled.div`
  font-size: var(--font-size-xl);
  font-weight: 500;
`
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const FilterOptionDescription = styled.div`
  font-weight: 300;
  color: #0443ae;
  font-style: italic;
  word-wrap: normal;
  max-width: 600px;
`
const Description = styled.div`
  font-weight: 300;
  margin: 20px 20px 10px;
`
const Option = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //margin-bottom: var(--default-padding-half);
  overflow: auto;

  //&:first-child {
  //  padding-top: calc(var(--default-padding-half) / 2);
  //}
  //
  //&:last-child {
  //  padding-bottom: calc(var(--default-padding-half) / 2);
  //}
`
const StyledCheckbox = styled(Checkbox)`
  margin: 0 !important;
  display: flex;
  padding: 3px 20px;
  --antd-wave-shadow-color: var(--secondary-navy);
`
const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: calc(var(--default-padding-double) * 2);
`
const ButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px;
`
const ButtonInnerWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`
const StyledSlider = styled(Slider)`
  margin: 20px 40px;

  .ant-slider-rail {
    height: 3px;
  }

  .ant-slider-track {
    height: 3px;
    background-color: ${secondaryNavy};
  }

  .ant-slider:hover .ant-slider-track {
    background-color: ${secondaryNavy};
  }

  .ant-slider:hover .ant-slider-handle {
    border-color: ${secondaryNavy};
  }

  .ant-slider-handle {
    width: 22px;
    height: 22px;
    margin-top: -9px;
    border-width: 1px;
    border-color: ${secondaryNavy};

    &::before {
      content: '|||';
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 18px;
      text-align: center;
      font-size: 10px;
      transition: color 0.1s linear;
      color: ${secondaryNavy};
      margin-top: 1px;
      margin-left: 0.5px;

      &:hover {
        background-color: #91d5ff;
      }
    }
  }

  &:hover {
    .ant-slider-handle::before {
      color: ${secondaryNavy};
    }
  }
`
const RadioOption = styled(Radio)<{ selected: boolean }>`
  cursor: pointer;
  user-select: none;
  padding: 12px 20px 12px 24px;
  background: ${(props) => (props.selected ? '#e7edf0' : '#fff')};
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  font-size: 14px;
  border-bottom: 1px solid #e7edf0;
  display: flex;

  &:hover {
    background: #e7edf0;
  }
`
const StyledInput = styled(Input)`
  border-radius: 6px;

  &:placeholder-shown {
    font-style: italic;
  }
`
const OptionSelectLabel = styled.div`
  padding: 0px 20px 5px;
  /* Small Italic */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  /* Dark Gray */

  color: #8a8c8f;
`

const StyledSpin = styled(Spin)`
  .ant-spin-dot {
    font-size: 20px !important;
  }
`

const LoadingCounts = styled.div`
  margin-top: -5px;
  //:after {
  //  overflow: hidden;
  //  display: inline-block;
  //  vertical-align: bottom;
  //  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  //  animation: ellipsis steps(4, end) 900ms infinite;
  //  content: '\\2026'; /* ascii code for the ellipsis character */
  //  width: 0px;
  //}
  //
  //@keyframes ellipsis {
  //  to {
  //    width: 1.25em;
  //  }
  //}
  //
  //@-webkit-keyframes ellipsis {
  //  to {
  //    width: 1.25em;
  //  }
  //}
`
