import React, { useEffect, useState } from 'react'
import { Col, Collapse, Modal, Row, Select } from 'antd'
import styled from 'styled-components'
import { fetchSentencesByClusters } from './model'
import { Loader } from 'shared/components'
import {
  Cluster,
  ClustersWithSentences,
  ClusterWithSentence,
  GroupSettings,
} from './types'
import { useModalRestrictedOperations } from './hooks/use-modal-restricted-operations'
import { ActionButtonsPopover } from './cluster-list/action-buttons-popover'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'

type Props = {
  handleOk: (e: any) => void
  handleCancel: (e: any) => void
  selectedClusters: string[]
  clusters: Cluster[]
  clusterOps: any
  fetchClusters: () => void
  confirmationWrapper: (callback: () => void) => void
}

type GroupedData = {
  [key: string]: Theme[]
}

type Theme = {
  sentences: string[]
  color: string
  group: string
  theme: string
  uuid: string
}

const selectedClusterSentencesModalTitle = 'Theme Sentences'

export const SelectedClusterSentencesModal: React.FC<Props> = ({
  handleOk,
  handleCancel,
  selectedClusters,
  clusters,
  clusterOps,
  fetchClusters,
  confirmationWrapper,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const [data, setData] = useState<ClustersWithSentences>({})
  const [groupedData, setGroupedData] = useState<GroupedData>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [maxCountPerGroup, setMaxCountPerGroup] = useState<number>(10)
  const [error, setError] = useState<any>(null)
  const [selectedClusterUUID, setSelectedClusterUUID] = useState<
    string[] | null
  >(null)
  const availableActionsForCluster = useModalRestrictedOperations(
    clusters,
    selectedClusterUUID
  )
  const hiddenButtons = {
    preview: true,
    clear: true,
    merge: true,
    done: true,
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const res = await fetchSentencesByClusters({
          selectedClusters,
          maxCountPerGroup,
          projectId,
        })
        setData(res.data)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [maxCountPerGroup])

  useEffect(() => {
    setGroupedData(
      Object.keys(data).reduce((acc: GroupedData, el) => {
        if (el === 'groups_settings') return acc
        ;(acc[(data[el] as ClusterWithSentence)['group']] =
          acc[(data[el] as ClusterWithSentence)['group']] || []).push({
          ...(data[el] as ClusterWithSentence),
          theme: el,
        })
        return acc
      }, {})
    )
  }, [data])

  if (!Object.keys(data) || loading)
    return (
      <Modal
        title={selectedClusterSentencesModalTitle}
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={modalBodyStyle}
        width={'45vw'}
      >
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      </Modal>
    )

  if (error)
    return (
      <Modal
        title={selectedClusterSentencesModalTitle}
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={modalBodyStyle}
        width={'45vw'}
      >
        <ErrorWrapper>Error while loading data</ErrorWrapper>
      </Modal>
    )

  return (
    <Modal
      title={selectedClusterSentencesModalTitle}
      visible={true}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={modalBodyStyle}
      width={'45vw'}
    >
      <Row style={modalSelectRowStyle}>
        <Col span={6}>Number of sentences per theme</Col>
        <Col span={2}>
          <Select
            defaultValue={10}
            value={maxCountPerGroup}
            onChange={(val) => setMaxCountPerGroup(val)}
            size="small"
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </Col>
      </Row>
      {Object.keys(groupedData)
        .filter((i) => !i.includes('ungrouped'))
        .map((key) => (
          <ul style={{ width: '100%' }}>
            <Collapse style={collapseStyle}>
              <Collapse.Panel
                header={key}
                key={key}
                style={{
                  borderLeft: `8px solid ${
                    //@ts-ignore
                    (data.groups_settings as GroupSettings)?.color
                  }`,
                }}
                extra={
                  <ActionButtonsPopover
                    fetchClusters={fetchClusters}
                    selectedClusterUUID={selectedClusterUUID || []}
                    clusterOps={clusterOps}
                    availableActionsForCluster={availableActionsForCluster}
                    hiddenButtons={hiddenButtons}
                    clusters={clusters}
                    visibilityCondition={
                      !!groupedData[key].map((theme) => theme.uuid)
                    }
                    setSelectedStateCallback={() =>
                      setSelectedClusterUUID(
                        groupedData[key].map((theme) => theme.uuid)
                      )
                    }
                    confirmationWrapper={confirmationWrapper}
                  />
                }
              >
                {groupedData[key].map((theme, idx) => (
                  <Collapse style={collapseStyle}>
                    <Collapse.Panel
                      style={{ borderLeft: `8px solid ${theme.color}` }}
                      header={theme.theme}
                      key={idx}
                      extra={
                        <ActionButtonsPopover
                          fetchClusters={fetchClusters}
                          selectedClusterUUID={selectedClusterUUID || []}
                          clusterOps={clusterOps}
                          availableActionsForCluster={
                            availableActionsForCluster
                          }
                          hiddenButtons={hiddenButtons}
                          clusters={clusters}
                          visibilityCondition={
                            !!selectedClusterUUID?.find(
                              (uuid) => uuid === theme.uuid
                            )
                          }
                          setSelectedStateCallback={() =>
                            setSelectedClusterUUID([theme.uuid])
                          }
                          confirmationWrapper={confirmationWrapper}
                        />
                      }
                    >
                      <div style={collapseContentStyle}>
                        <ul style={{ width: '100%' }}>
                          {theme.sentences.length &&
                            theme.sentences.map((sentence) => (
                              <li>{sentence}</li>
                            ))}
                        </ul>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                ))}
              </Collapse.Panel>
            </Collapse>
          </ul>
        ))}
      {Object.keys(groupedData)
        .filter((i) => i.includes('ungrouped'))
        .map((key) => (
          <ul style={{ width: '100%' }}>
            {groupedData[key].map((theme, idx) => (
              <Collapse style={collapseStyle}>
                <Collapse.Panel
                  style={{ borderLeft: `8px solid ${theme.color}` }}
                  header={theme.theme}
                  key={idx}
                  extra={
                    <ActionButtonsPopover
                      fetchClusters={fetchClusters}
                      selectedClusterUUID={selectedClusterUUID || []}
                      clusterOps={clusterOps}
                      availableActionsForCluster={availableActionsForCluster}
                      hiddenButtons={hiddenButtons}
                      clusters={clusters}
                      visibilityCondition={
                        !!selectedClusterUUID?.find(
                          (uuid) => uuid === theme.uuid
                        )
                      }
                      setSelectedStateCallback={() =>
                        setSelectedClusterUUID([theme.uuid])
                      }
                      confirmationWrapper={confirmationWrapper}
                    />
                  }
                >
                  <div style={collapseContentStyle}>
                    <ul style={{ width: '100%' }}>
                      {theme.sentences.length &&
                        theme.sentences.map((sentence) => <li>{sentence}</li>)}
                    </ul>
                  </div>
                </Collapse.Panel>
              </Collapse>
            ))}
          </ul>
        ))}
    </Modal>
  )
}

const LoaderWrapper = styled.div`
  margin-bottom: auto;
`
const collapseStyle = {
  marginTop: '16px',
  width: '100%',
}

const modalSelectRowStyle = {
  alignItems: 'center',
  justifyContent: 'center',
}

const modalBodyStyle = {
  overflow: 'auto',
  height: '60vh',
  width: '45vw',
}

const collapseContentStyle = {
  maxHeight: '30vh',
  overflow: 'auto',
}

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
