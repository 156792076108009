import { useCallback, useState } from 'react'
import { Cluster } from '../types'
import {
  applySimpleFeedback,
  changeClusterVisibility,
  groupClusters,
  mergeClusters,
  ungroupClusters,
} from '../model'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'

export enum ModalType {
  Merge = 'Merge',
  Group = 'Group',
  AssignToGroup = 'AssignToGroup',
}

export const useClustersOperations = (
  clusters: Cluster[],
  fetchClusters: () => void
) => {
  const [editMode, setEditMode] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [selectedClusters, setSelectedClusters] = useState<string[]>([])
  const [needToUpdateSettings, setNeedToUpdateSettings] = useState(false)
  // TODO
  // https://linear.app/yo-yo-gi/issue/EFE-104/manual-refetch-filterlist-in-network-container
  // const refetchData = () => Promise.all([filterList.refetch(), fetchClusters()])
  const refetchData = () => Promise.all([fetchClusters()])

  const onClusterClick = (clusterId: string) => {
    setSelectedClusters(
      selectedClusters.includes(clusterId)
        ? selectedClusters.filter((v) => v !== clusterId)
        : [...selectedClusters, clusterId]
    )
  }

  const checkIfGroupIsSelected = (group_id: number) => {
    const groupClusterIds = getClusterIdsByGroupId(group_id)
    return (
      groupClusterIds.length > 0 &&
      groupClusterIds.every((e) => selectedClusters.includes(e))
    )
  }

  const selectGroup = (group_id: number) => {
    const groupClusterIds = getClusterIdsByGroupId(group_id)
    setSelectedClusters([
      ...selectedClusters,
      ...groupClusterIds.filter((e) => !selectedClusters.includes(e)),
    ])
  }

  const unselectGroup = (group_id: number) => {
    const groupClusterIds = getClusterIdsByGroupId(group_id)
    setSelectedClusters([
      ...selectedClusters.filter((e) => !groupClusterIds.includes(e)),
    ])
  }

  const getClusterIdsByGroupId = (group_id: number) => {
    return clusters
      .filter((c) => c.group_id === group_id)
      .map((c) => c.cluster_uuid)
  }

  const withLoading = async (f: Function) => {
    setLoading(true)
    try {
      await f()
    } finally {
      setLoading(false)
    }
  }

  const withUnselectClustersAfter = async (f: Function) => {
    await f()
    setSelectedClusters([])
  }

  const withRefetchDataAfter = async (f: Function) => {
    await f()
    await refetchData()
  }

  const withLoadingAndRefresh = async (f: Function) =>
    await withLoading(() =>
      withUnselectClustersAfter(() =>
        withRefetchDataAfter(f).then(() => setNeedToUpdateSettings(true))
      )
    )

  const onChangeVisibility = useCallback(
    async (show: boolean, customClusters?: string[]) => {
      await withLoadingAndRefresh(async () => {
        await changeClusterVisibility(customClusters || selectedClusters, show)
      })
    },
    [selectedClusters]
  )

  const onApplySimpleFeedback = useCallback(
    async (customClusters?: string[]) => {
      await withLoadingAndRefresh(async () => {
        await applySimpleFeedback(customClusters || selectedClusters)
      })
    },
    [selectedClusters]
  )

  const onMerge = useCallback(
    async (clusterName: string, customClusters?: string[]) =>
      await mergeClusters(customClusters || selectedClusters, clusterName),
    [selectedClusters]
  )

  const onGroup = useCallback(
    async (clusterName: string, customClusters?: string[]) =>
      await groupClusters(customClusters || selectedClusters, clusterName),
    [selectedClusters]
  )

  const clusterOperations = {
    [ModalType.Merge]: onMerge,
    [ModalType.Group]: onGroup,
    [ModalType.AssignToGroup]: onGroup,
  }

  const onClusterOperation = useCallback(
    async (
      clusterName: string,
      operation: ModalType,
      customClusters?: string[]
    ) => {
      await withLoadingAndRefresh(async () => {
        await clusterOperations[operation](
          clusterName,
          customClusters || selectedClusters
        )
      })
    },
    [selectedClusters]
  )

  const onUngroup = useCallback(
    async (customClusters?) => {
      await withLoadingAndRefresh(
        async () => await ungroupClusters(customClusters || selectedClusters)
      )
    },
    [selectedClusters]
  )

  const onFinish = () => {
    setSelectedClusters([])
    setEditMode(false)
  }

  const clearClusterSelection = () => {
    setSelectedClusters([])
  }

  const selectedClustersObjects = selectedClusters
    .map((clusterId) =>
      clusters.find(({ cluster_uuid }) => cluster_uuid === clusterId)
    )
    .filter(Boolean) as Cluster[]
  const selectedClustersOutsideGroups = selectedClustersObjects.filter(
    ({ group_id }) => group_id === undefined
  )
  const selectedClustersInsideGroups = selectedClustersObjects.filter(
    ({ group_id }) => group_id !== undefined
  )

  const noClustersSelected =
    selectedClustersOutsideGroups.length + selectedClustersInsideGroups.length <
    1
  const lessThenTwoClustersSelected =
    selectedClustersOutsideGroups.length + selectedClustersInsideGroups.length <
    2
  const visibleClustersSelected = selectedClustersObjects.filter(
    ({ visible }) => visible
  ).length
  const hiddenClustersSelected = selectedClustersObjects.filter(
    ({ visible }) => !visible
  ).length
  const clustersOutsideGroupSelected = !!selectedClustersOutsideGroups.length
  const isHideDisabled =
    noClustersSelected || hiddenClustersSelected || isLoading
  const isUnhideDisabled =
    noClustersSelected || visibleClustersSelected || isLoading
  const isGroupDisabled = noClustersSelected || isLoading
  const isMoveDisabled = noClustersSelected || isLoading
  const isMergeDisabled = lessThenTwoClustersSelected || isLoading
  const isUngroupDisabled =
    noClustersSelected || clustersOutsideGroupSelected || isLoading
  const isSimpleFeedbackDisabled =
    isHideDisabled ||
    !!selectedClustersObjects.filter(({ is_assignable }) => !is_assignable)
      .length

  return {
    editMode,
    needToUpdateSettings,
    isLoading,
    setNeedToUpdateSettings,
    setLoading,
    setEditMode,
    onClusterClick,
    checkIfGroupIsSelected,
    selectGroup,
    unselectGroup,
    selectedClusters,
    restrictedOperations: {
      simple_feedback: !!isSimpleFeedbackDisabled,
      hide: !!isHideDisabled,
      unhide: !!isUnhideDisabled,
      ungroup: isUngroupDisabled,
      merge: isMergeDisabled,
      group: isGroupDisabled,
      move: isMoveDisabled,
      preview: !selectedClusters.length,
    },
    operations: {
      onFinish,
      onUngroup,
      onClusterOperation,
      onChangeVisibility,
      onApplySimpleFeedback,
      clearClusterSelection,
    },
  }
}
