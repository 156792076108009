import React from 'react'
import { Button, ButtonProps } from 'antd'
import { ButtonType } from 'antd/es/button'
import styled from 'styled-components'
import {
  buttonBlue,
  buttonHover,
  mainText,
  secondaryBlue,
  secondaryNavy,
  secondaryRed,
} from '../../assets/styles/variables'

export const YogiButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  type,
  icon,
  id,
  disabled,
  style,
  htmlType,
  shape,
  danger,
  loading,
}) => {
  return (
    <StyledButton
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      style={style}
      htmlType={htmlType}
      shape={shape}
      loading={loading}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button).attrs((props) => {
  let additionalStyles = {
    background: 'white',
    hover: buttonBlue,
    color: buttonBlue,
    border: secondaryNavy,
  }
  if (props.type === 'primary') {
    additionalStyles = {
      background: buttonBlue,
      hover: buttonHover,
      color: 'white',
      border: buttonBlue,
    }
  }
  if (props.type === 'ghost') {
    additionalStyles = {
      background: 'white',
      hover: 'white',
      color: '',
      border: '',
    }
  }
  if (props.danger) {
    additionalStyles = {
      background: secondaryRed,
      hover: '',
      color: 'white',
      border: secondaryRed,
    }
  }
  return additionalStyles
})`
  border-radius: 8px;
  border-width: 2px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-color: ${(props) => props.border};

  &:hover {
    border-color: ${(props) =>
      props.type === 'ghost' ? buttonBlue : props.hover};
    color: ${(props) => (props.type === 'ghost' ? buttonBlue : 'white')};
    background: ${(props) => props.hover};
    box-shadow: 0px 12px 24px 0px #1d4ed852;
    box-shadow: 0px 2px 4px 0px #3b82f61f;
  }

  &:focus {
    border-color: ${buttonBlue};
    color: ${(props) => (props.type === 'ghost' ? buttonBlue : 'white')};
    background: ${(props) => props.hover};
  }
`
