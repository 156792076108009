// User URLs
export const USER_VISIT_URL = '/user/visit'
export const USER_DETAILS_URL = '/user'
export const USER_REQUEST_ACCESS_URL = '/user/request-access'
export const USER_TRIAL_SIGNUP_URL = '/user/trial-signup'

// Project URLs
export const PROJECT_CREATE_URL = '/project'
export const PROJECT_LIST_URL = '/project/list'
export const GROUP_PROJECT_LIST_URL = '/project/groups'
export const PROJECT_META = '/project/meta'
export const PROJECT_DETAILS_URL = '/project'
export const PROJECT_DELETE_URL = '/project'
export const PROJECT_ID_URL = '/project/uuid'
export const PROJECT_FIELDS_URL = '/post/color/fields'
export const PROJECT_FIELD_URL = '/post/color'
export const PROJECT_FIELD_THEME_URL = '/post/color/themes'
export const PROJECT_FIELD_DETAILS = '/post/field/details'
export const PROJECT_SOURCE_GROUP_URL = '/project/sources/group'
export const PROJECT_SOURCE_GROUP_INFO_URL = '/project/sources/group/info'
export const PROJECT_SETTINGS_FILTER_PRESET = '/project/filter-preset'
export const PROJECT_SETTINGS_FILTER_ORDER = '/project/filter-order'
export const PROJECT_FILTER_PARAMS_URL = '/project/get-filter'
export const PROJECT_SETTINGS = '/project/client-settings'
export const PROJECT_KEYWORDS = '/post/phrases'
export const PROJECT_KEYWORDS_DOWNLOAD = '/post/download-keywords'
export const PROJECT_COUNTRIES = '/project/country'
export const SETTINGS_UPDATE = '/project/settings-update'
export const PROJECT_COMPASS = '/project/compass'

// Source URLs
export const SOURCE_CONNECT_URL = '/source/connect'
export const SOURCE_UPLOAD_PATH_URL = '/source/upload-path'
export const SOURCE_UPDATE_URL = '/source/update'
export const SOURCE_CUSTOMFIELD_DELETE_URL = '/source/field'

// Network URLs
export const NETWORK_URL = '/network'
export const CLUSTER_VISIBILITY = '/network/cluster/visibility'
export const CLUSTER_SIMPLE_FEEDBACK = '/network/cluster/simple-feedback'
export const CLUSTER_ASSIGN = '/network/cluster/assign'
export const CLUSTER_CUSTOM = '/network/cluster/custom'
export const CLUSTER_MERGE = '/network/cluster/merge'
export const CLUSTER_GROUP = '/network/cluster/group'
export const CLUSTERS_SENTENSES = '/network/cluster/group/preview'

// Charts Old URLs
export const DASHBOARD_LIST_URL = '/charts/options'
export const CHARTS_URL = '/charts'
export const DASHBOARD_INTERFACE_URL = '/charts/new-charts-interface'

// Feedback URLs
export const FILTER_URL = '/post/filter'
export const PROJECT_OPTIONS = '/post/filter/options'
export const PROJECT_SUMMARY_URL = '/post/summary'

// Post URLs
export const POST_DETAILS_URL = '/post'
export const POST_TEXT_URL = '/post/text'
export const STARRED_POSTS_URL = '/post/stars'
export const POST_FEEDBACK_URL = '/post/feedback'
export const POST_DOWNLOAD_URL = '/post/download'
export const POST_ADD_CUSTOM_FIELD = '/post/field/value'
export const POST_SUMMARY_URL = '/post/posts-summary-for-chart-entity'
export const POST_SENTENCES_SUMMARY_URL =
  '/post/reviews-related-to-chart-entity'
export const POST_SAMPLE = '/post/sample'

// Alerts
export const ALERT_URL = '/alert'

// Product URLs
export const PRODUCT_HIERARCHY_INFO = '/product/hierarchy/info'
export const PRODUCT_PROJECT_INFO = '/product/project/info'
export const PRODUCT_HIERARCHY = '/product/hierarchy'
export const PRODUCT_PROJECT_HIERARCHIES = '/product/project/hierarchies'
export const PRODUCT_LIST = '/product/hierarchy/productlist'
export const PRODUCT_GROUPS = '/product/group'
export const PRODUCT_GROUP_PRODUCTS = '/product/group/products'
export const PRODUCT_HIERARCHY_PRODUCTS = '/product/hierarchy/products'
export const PRODUCT_LIST_PROJECT_FORM = '/product/list'

// URL Bookmarks
export const HASH_FILTER_URL = '/project/hash-filter'
export const ORIGINAL_URL = '/project/get-filter'

// GPT
export const THEME_SUMMARY = '/project/gpt-analysis'

// PDP
export const PDP_ANALYSIS_URL = '/custom-dashboards/pdp-optimization'

// GEMINI
export const GEMINI_ANALYSIS_URL = '/custom-prompt/custom-reviews-prompt'
export const GEMINI_CHART = '/custom-prompt/get-chart'

// AUTOMATED
export const OUTLIERS_URL = '/insights/outliers'
export const TIMESERIES_OUTLIERS_URL = '/insights/timeseries-outliers'
export const OUTLIER_ANALYSIS_URL = '/insights/analysis'
export const OUTLIER_ANALYSIS_STREAM_URL = '/insights/analysis-stream'
export const OUTLIER_BETA_ANALYSIS_URL = '/insights/analysis-beta'
export const SEMANTIC_QUERY = '/insights/search-with-query'
