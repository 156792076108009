import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import {
  SignInForm,
  ResetPasswordForm,
  NewPasswordForm,
  AuthContainer,
  SetupPasswordForm,
  VerifyForm,
  SignInCallback,
} from 'features/auth'
import { ProfileContainer } from 'features/profile'
import { Project, SharePage } from 'features/project'
import { HomeContainer } from 'features/home'
import {
  home,
  signinURL,
  verifyURL,
  profileURL,
  projectURL,
  newPasswordURL,
  shareProjectURL,
  resetPasswordURL,
  setupPasswordURL,
  signinCallbackURL,
  trialSignupURL,
  trialURL,
  requestAccessURL,
  shareCustomDashboardURL,
} from 'utils/links'
import GA from 'utils/GoogleAnalytics'
import { runTrackers } from './trackers'
import { Auth } from 'aws-amplify'
import awsConfig from 'aws-exports'
import { SignUpTrialForm } from './features/auth/forms/signup_trial_form'
import { TrialContainer } from 'features/trial/containers/trial-page-container'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { env } from 'utils/axios'
import RequestAccessPage from './features/auth/components/RequestAccessPage'
import { DashboardSharePage } from './features/project/features/shared-dashboard'

const queryClient = new QueryClient()

let cognitoDomain: string
let redirectSignIn: string
let redirectSignOut: string

if (env === 'prod') {
  cognitoDomain = 'meetyogi-prod.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app.meetyogi.com/sign-in'
} else if (env === 'staging') {
  cognitoDomain = 'meetyogi-staging.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app-staging.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app-staging.meetyogi.com/sign-in'
} else if (env === 'dev') {
  cognitoDomain = 'meetyogi-dev.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app-dev.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app-dev.meetyogi.com/sign-in'
} else if (env === 'sandbox') {
  cognitoDomain = 'meetyogi-sandbox.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app-sandbox.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app-sandbox.meetyogi.com/sign-in'
} else if (env === 'local') {
  cognitoDomain = 'meetyogi-dev.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'http://localhost:3000/sign-in/callback'
  redirectSignOut = 'http://localhost:3000/sign-in'
} else {
  cognitoDomain = 'meetyogi-dev.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'http://localhost:3000/sign-in/callback'
  redirectSignOut = 'http://localhost:3000/sign-in'
}

export const App = () => {
  Auth.configure({
    ...awsConfig,
    oauth: {
      domain: cognitoDomain,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: redirectSignIn,
      redirectSignOut: redirectSignOut,
      responseType: 'code',
    },
  })

  // without this, its possible mixpanel.register is called before mixpanel is initialized
  const [mixpanelLoaded, setMixpanelLoaded] = useState(false)

  useEffect(() => {
    runTrackers()
    setMixpanelLoaded(true)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          {GA.init() && <GA.RouteTracker />}
          {mixpanelLoaded && (
            <Switch>
              <Route path={shareProjectURL} component={SharePage} />
              <Route
                path={shareCustomDashboardURL}
                component={DashboardSharePage}
              />
              <AuthContainer>
                <Switch>
                  <Route path={home} component={HomeContainer} exact />
                  <Route path={signinURL} component={SignInForm} exact />
                  <Route
                    path={trialSignupURL}
                    component={SignUpTrialForm}
                    exact
                  />
                  <Route
                    path={signinCallbackURL}
                    component={SignInCallback}
                    exact
                  />
                  <Route path={verifyURL} component={VerifyForm} exact />
                  <Route
                    path={resetPasswordURL}
                    component={ResetPasswordForm}
                    exact
                  />
                  <Route
                    path={newPasswordURL}
                    component={NewPasswordForm}
                    exact
                  />
                  <Route
                    path={setupPasswordURL}
                    component={SetupPasswordForm}
                    exact
                  />
                  <Route path={profileURL} component={ProfileContainer} exact />
                  <Route path={projectURL()} component={Project} />
                  <Route path={trialURL} component={TrialContainer} />
                  <Route
                    path={requestAccessURL}
                    component={RequestAccessPage}
                  />
                </Switch>
              </AuthContainer>
            </Switch>
          )}
        </BrowserRouter>
        <ToastContainer hideProgressBar={true} position="bottom-right" />
        {/*<ReactQueryDevtools initialIsOpen={true} />*/}
      </DndProvider>
    </QueryClientProvider>
  )
}

export default App
