import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Typography, Form, Input, Button } from 'antd'
import { toast } from 'react-toastify'
import { AuthContext } from 'features/auth'
import { AuthWrapper } from 'shared/wrappers'
import { Loader } from 'shared/components'
import { signinURL } from 'utils/links'
import { verifyUser, sendVerificationCode } from '../model'

const layout = {
  labelCol: { span: 4 },
}

const VerifySchema = Yup.object().shape({
  code: Yup.string().required('Required'),
})

export const VerifyForm = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const authContext = useContext(AuthContext)

  const formik = useFormik({
    initialValues: { code: '' },
    validationSchema: VerifySchema,
    onSubmit: async ({ code }) => {
      try {
        setLoading(true)
        await verifyUser(code)
        toast.info('Success')
        authContext.signOut()
        authContext.setUser(null)
        history.push(signinURL)
      } catch (e) {
        setLoading(false)
        toast.error(e.message)
      }
    },
  })

  useEffect(() => {
    sendVerificationCode()
  }, [])

  const codeError = formik.touched.code && formik.errors.code
  return (
    <AuthWrapper>
      <Wrapper onFinish={formik.submitForm} {...layout}>
        <FormTitle>
          <Typography.Title level={4}>Verify your email</Typography.Title>
        </FormTitle>
        <Form.Item
          label="Code"
          validateStatus={codeError && 'error'}
          help={codeError}
        >
          <Input
            name="code"
            onChange={formik.handleChange}
            value={formik.values.code}
          />
        </Form.Item>
        {loading && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <Form.Item style={{ marginBottom: 0 }}>
          <SubmitButton type="primary" htmlType="submit">
            Submit
          </SubmitButton>
        </Form.Item>
      </Wrapper>
    </AuthWrapper>
  )
}

const Wrapper = styled(Form)`
  max-width: 500px;
  margin: 0 auto;
  background: #fff;
  box-shadow: var(--default-box-shadow);
  padding: var(--default-padding);
  position: relative;

  @media (max-width: 768px) {
    max-width: none;
    margin: var(--mobile-margin);
  }
`
const FormTitle = styled.div`
  margin-bottom: var(--default-padding);
  text-align: center;
`
const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
const SubmitButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-top: var(--default-padding);
`
