import React from 'react'
import { OutlierType } from '../../../../model'
import { Container, Header, Info, Title } from './InsightListItem.styles'
import { getOutlierTitle } from '../../../../utils'
import FavoriteInsightButton from '../../../FavoriteInsightButton/FavoriteInsightButton'
import { Filters } from '../../../../../../types'
import useThemes from '../../../../../../hooks/useThemes'

interface Props {
  outlier: OutlierType
  filterValues: Filters
  isSelected: boolean
  setSelectedOutlier: (outlier: OutlierType) => void
}

const InsightListItem: React.FC<Props> = ({
  outlier,
  filterValues,
  isSelected,
  setSelectedOutlier,
}) => {
  const { getNormalizedThemeName } = useThemes()

  return (
    <Container
      isSelected={isSelected}
      onClick={() => {
        setSelectedOutlier(outlier)
      }}
    >
      <Header isSelected={isSelected}>
        <Title>{outlier.analysis?.stinger ?? getOutlierTitle(outlier)}</Title>
        <FavoriteInsightButton outlier={outlier} filterValues={filterValues} />
      </Header>
      <Info isSelected={isSelected}>
        <div>{outlier.product}</div>
        <div>{outlier.theme ? getNormalizedThemeName(outlier.theme) : ''}</div>
      </Info>
    </Container>
  )
}

export default InsightListItem
