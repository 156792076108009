import _ from 'lodash'
import { sortBy } from 'lodash'
import { Cluster, Group } from './types'

export const THEME_DOMAIN = 'theme'
export const THEME_GROUP_DOMAIN = 'theme_group'

export const prepareClusterList = (
  clusters: Cluster[],
  groups: Group[],
  editMode: boolean,
  isThemeCluster: boolean
) => {
  const invisibleOrNotGroupedClusters = clusters.filter(
    ({ visible, group_id }: Cluster) =>
      group_id === undefined && (editMode || (isThemeCluster ? visible : true))
  )
  const sortedNestedClusters = sortBy(
    clusters.filter(({ group_id }) => editMode && group_id !== undefined),
    (c) => c.value.toLowerCase()
  )

  const sortedClusterAndGroupList: Array<Cluster | Group> = []

  sortBy([...groups, ...invisibleOrNotGroupedClusters], (c) => {
    if ('group' in c) {
      return c.group.toLowerCase()
    }
    if ('value' in c) {
      return typeof c.value === 'string' ? c.value.toLowerCase() : c.value
    }
  }).forEach((clusterOrGroup) => {
    sortedClusterAndGroupList.push(clusterOrGroup)
    if ('id' in clusterOrGroup) {
      const foundNestedClusters = sortedNestedClusters.filter(
        (c) => c.group_id === clusterOrGroup.id
      )
      if (foundNestedClusters.length) {
        sortedClusterAndGroupList.push(...foundNestedClusters)
      }
    }
  })

  return {
    hasLength: !!(clusters.length + groups.length),
    data: sortedClusterAndGroupList,
  }
}

export const arraysAreEqual = (array1: any[], array2: any[]) => {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  )
}

export const arraysContentIsEqual = (array1: any[], array2: any[]) => {
  array1 = array1.sort()
  array2 = array2.sort()
  return objectsAreEqual(array1, array2)
}

export const objectsAreEqual = (obj1: any, obj2: any): boolean => {
  return _.isEqual(obj1, obj2)
}
