import React from 'react'
import styled from 'styled-components'
import { DomainSelector } from './domain-selector'
import { ClusterList } from './cluster-list/cluster-list'

type Props = {
  comparativeIndex: number
}
export const ClusterPanel: React.FC<Props> = ({ comparativeIndex }) => {
  return (
    <Wrapper>
      <DomainSelector comparativeIndex={comparativeIndex} />
      <ClusterList comparativeIndex={comparativeIndex} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
`
