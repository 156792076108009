// @ts-nocheck
import mixpanel from '../../../../trackers/mixpanel'
import { BarcodeOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { ProjectState } from '../../../types'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { getProductsForHierarchy } from '../../products/model'
import { useProjectStore } from '../../../projectStore/projectStore'
import {
  buttonBlue,
  cardBorderGrey,
  pageBackgroundGrey,
} from '../../../../../assets/styles/variables'
import { Typography } from 'antd'
import { SourceIcon } from '../../products/components/product-source-icon'
import { Product, Source } from '../../products/types'
import { ProductModal } from '../../products/components/product-modal'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { Emoji, Loader } from '../../../../../shared/components'
import { InnerTitle } from 'components/InnerTitle'
type Props = {
  value: string
  activeValues: string[]
  setActiveValues: (value: string[]) => void
  setActiveTier1: (
    value: 'theme' | 'brand' | 'source_type' | 'product_hierarchy'
  ) => void
  childSelected: boolean
  isPDP?: boolean
  setSelectedProduct?: (url: Product) => void
}
const ProductSelector: React.FC<Props> = ({
  value,
  activeValues,
  setActiveValues,
  setActiveTier1,
  childSelected,
  isPDP,
  setSelectedProduct,
}) => {
  const validUrl = 'www.amazon.com/'
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)

  const [selectedItem, setSelectedItem] = React.useState<Product>()
  const [modalOpened, setModalOpened] = useState<boolean>(false)

  const handleOk = useCallback((e) => {
    e.stopPropagation()
    setModalOpened(false)
  }, [])

  const handleCancel = useCallback((e) => {
    e.stopPropagation()
    setModalOpened(false)
  }, [])

  const { data: hierachyProductsData, isLoading } = useQuery(
    ['getProductsForHierarchy', projectId, value],
    () => getProductsForHierarchy('getProductsForHierarchy', projectId, value),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
    }
  )

  // if we click into a view with item already selected, we want to scroll to it if necessary
  useEffect(() => {
    if (activeValues?.length && hierachyProductsData?.length) {
      const uuid = hierachyProductsData?.find(
        (el) => el.name === activeValues[0]
      )?.uuid
      if (uuid) {
        const element = document.querySelector('#uuid-' + uuid) ?? null
        element?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [hierachyProductsData])

  // todo maybe apply disabled in the mapping below so all products are shown
  const validProducts = (
    hierachyProductsData?.filter((el) =>
      isPDP ? !!el.sources.find((src) => src.source_type === 'Amazon') : true
    ) ?? []
  ).sort((a, b) => b.review_count - a.review_count)

  const [hasValidProducts, setHasValidProducts] = useState(true)

  useEffect(() => {
    if (isPDP) {
      if (!activeValues?.length) {
        if (validProducts?.[0]) {
          setSelectedItem(validProducts?.[0])
          setActiveValues([validProducts?.[0]?.name])
          setSelectedProduct && setSelectedProduct(validProducts?.[0])
          setActiveTier1('product')
          setHasValidProducts(true)
        } else {
          // TODO if validproducts empty, show a message
          setHasValidProducts(false)
        }
      } else {
        const item = validProducts.find((el) => el.name === activeValues[0])
        setSelectedItem(item)
        setSelectedProduct && setSelectedProduct(item)
      }
    }
  }, [validProducts])

  return (
    <Container>
      {!hierachyProductsData ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 150,
          }}
        >
          <Loader style={{ height: '50%', maxHeight: 300 }} />
          <InnerTitle
            style={{ textAlign: 'center' }}
          >{`Loading Product Variants`}</InnerTitle>
        </div>
      ) : (
        <>
          <ProductItemContainer>
            {!hasValidProducts && (
              <div style={{ marginTop: 10, width: '100%' }}>
                <InnerTitle style={{ textAlign: 'center' }}>
                  No Amazon Listings for this Product
                </InnerTitle>
              </div>
            )}
            {hierachyProductsData &&
              validProducts.map((product) => (
                <ProductItem
                  key={product.uuid}
                  id={'uuid-' + product.uuid}
                  onClick={() => {
                    // TODO this should be moved out to the respective component
                    if (!route.isPDP) {
                      mixpanel.track('catalog', {
                        action: 'product selector',
                        value: product.name,
                      })
                    }
                    if (childSelected && activeValues.includes(product.name)) {
                      if (!isPDP) {
                        setSelectedItem(undefined)
                        setActiveValues([value])
                        setSelectedProduct && setSelectedProduct(null)
                        setActiveTier1('product_hierarchy')
                      }
                    } else {
                      setSelectedItem(product)
                      setActiveValues([product.name])
                      setSelectedProduct && setSelectedProduct(product)
                      setActiveTier1('product')
                    }
                  }}
                  active={childSelected && activeValues.includes(product.name)}
                >
                  <ImageWrapper>
                    {product.image_url ? (
                      <img
                        src={product.image_url}
                        width="auto"
                        height="80px"
                        alt=""
                      />
                    ) : (
                      <BarcodeOutlined style={{ fontSize: '60px' }} />
                    )}
                  </ImageWrapper>
                  <div
                    style={{
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        lineClamp: product.generic_listing ? 3 : 4,
                        WebkitLineClamp: product.generic_listing ? 3 : 4,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {product.name}
                    </div>
                    <div>
                      {product.generic_listing ? (
                        <>
                          <Emoji symbol="⭐" />
                          {' General Item Listing'}
                        </>
                      ) : null}
                    </div>
                    <div style={{ marginTop: 5 }}>
                      {product.review_count} Reviews
                    </div>
                    {/*  TODO maybe url link button here*/}
                  </div>
                </ProductItem>
              ))}
          </ProductItemContainer>

          {selectedItem && !isPDP && (
            <CardContentWrapper>
              <div style={{ display: 'flex' }}>
                <TextWrapper>
                  <Typography.Text>
                    <span style={{ fontWeight: 600 }}>
                      {selectedItem.rating_count}
                    </span>{' '}
                    Ratings (All Time)
                  </Typography.Text>
                  <Typography.Text>
                    <span style={{ fontWeight: 600 }}>
                      {selectedItem.review_count}
                    </span>{' '}
                    Reviews with Text (All Time)
                  </Typography.Text>
                  <YogiButton
                    type={'default'}
                    onClick={() => setModalOpened(true)}
                  >
                    View Full Item Details
                  </YogiButton>
                </TextWrapper>
                <SourceWrapper>
                  {selectedItem.sources &&
                    selectedItem.sources.map((product_source) => (
                      <SourceIcon source={product_source}></SourceIcon>
                    ))}
                </SourceWrapper>
              </div>
            </CardContentWrapper>
          )}
        </>
      )}

      {modalOpened && selectedItem && (
        <ProductModal
          product={selectedItem}
          handleCancel={handleCancel}
          handleOk={handleOk}
        ></ProductModal>
      )}
    </Container>
  )
}

export default ProductSelector

const Container = styled.div`
  //height: 100%;
`

const ProductItemContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  height: 150px;
  overflow: auto;
  padding: 10px;
`

const ProductItem = styled.div<{ active: boolean }>`
  flex-grow: 1;
  padding: 10px;
  cursor: pointer;
  background: ${({ active }) => (active ? pageBackgroundGrey : 'transparent')};
  border-radius: 5px;
  border: 1px solid ${({ active }) => (active ? buttonBlue : cardBorderGrey)};
  box-shadow: var(--default-box-shadow);
  height: 100%;
  min-width: 300px;
  &:hover {
    background: ${pageBackgroundGrey};
  }
  overflow: hidden;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: top;
  float: left;
  margin-right: 5%;
  margin-left: 2%;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  //font-weight: bold;
`
const SourceWrapper = styled.div`
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: 10%;
`
const CardContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
`
