import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Select, Typography } from 'antd'
import styled from 'styled-components'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import { LinkOutlined } from '@ant-design/icons'
import {
  mainText,
  pageBackgroundGrey,
} from '../../../../../../assets/styles/variables'
import { ProjectState } from '../../../../types'
import { useProjectStore } from '../../../../projectStore/projectStore'
import useResizeObserver from 'use-resize-observer'
import { useQuery } from 'react-query'
import { fetchSharedUsers } from '../../../share/model'
import { AuthContext } from '../../../../../auth'
import { toast } from 'react-toastify'
import { GroupsOutlined } from '@mui/icons-material'
import { Fetcher } from '../../../../../../shared/components/fetcher'
import { getPublicUrl, shareDashboard, unshareDashboard } from '../../model'
import { DashboardResponse } from '../../types'
import mixpanel from '../../../../../trackers/mixpanel'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import TextArea from 'antd/lib/input/TextArea'
import { InnerTitle } from '../../../../../../components/InnerTitle'
const { Option } = Select
const { Title } = Typography
const pluralize = require('pluralize')

type Props = {
  dashboardData: DashboardResponse
  setIsOpen: (isOpen: boolean) => void
  refetch: () => Promise<any>
}
const ShareDashboard: React.FC<Props> = ({
  dashboardData,
  setIsOpen,
  refetch,
}) => {
  const { owners, viewers } = dashboardData || {}
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email
  const isOwner = !!dashboardData?.owners?.find(
    (el: { email: string }) =>
      el.email?.toLowerCase() === currentUserEmail?.toLowerCase()
  )
  const isAuthor = dashboardData.dashboard.author.email === currentUserEmail

  const { data: feature_flags } = useFeatureFlags()

  const { data } = useQuery(
    ['shared-users', projectId],
    () => fetchSharedUsers(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  )

  const allUsers = useMemo(() => {
    if (!data) return []
    return [...data.admins, ...data.shared_users, ...data.yogi_employees].sort()
  }, [data?.admins, data?.shared_users])

  const [recipients, setRecipients] = useState<string[]>([])
  const [newViewers, setNewViewers] = useState<string[]>([])
  const [newOwners, setNewOwners] = useState<string[]>([])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSharingUrlLoading, setIsSharingUrlLoading] = useState(false)
  const [message, setMessage] = useState('false')

  useEffect(() => {
    setNewViewers([])
    setNewOwners([])
    setMessage('')
  }, [dashboardData])

  const manualSubmit = async () => {
    mixpanel.track('alerts', {
      action: 'share',
      value: dashboardData?.dashboard.id,
    })
    setIsSubmitting(true)
    const promises = []

    if (newOwners.length > 0) {
      const body = {
        id: dashboardData?.dashboard.id,
        as_owners: true,
        email_addresses: newOwners,
        message,
      }
      promises.push(shareDashboard(projectId, body))
    }
    if (newViewers.length > 0) {
      const body = {
        id: dashboardData?.dashboard.id,
        as_owners: false,
        email_addresses: newViewers,
        message,
      }
      promises.push(shareDashboard(projectId, body))
    }
    try {
      await Promise.all(promises)
      await refetch()
      setNewOwners([])
      setNewViewers([])
      setMessage('')
      setIsOpen(false)
      setIsSubmitting(false)
      toast.success('Invite sent successfully')
    } catch (e) {
      setIsSubmitting(false)
      toast.error('Error inviting users')
    }
  }

  const handleUnshare = async (email: string) => {
    setIsSubmitting(true)
    const body = {
      dashboard_id: dashboardData?.dashboard.id,
      email_addresses: [email],
    }
    try {
      await unshareDashboard(projectId, body)
      refetch().then(() => {
        setIsSubmitting(false)
        toast.success('User removed successfully')
      })
    } catch (e) {
      setIsSubmitting(false)
      toast.error('Error removing user')
    }
  }

  const { ref: headerRef, height: headerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()
  const { ref: footerRef, height: footerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()

  return (
    <Container>
      <Header ref={headerRef}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingBottom: 10,
          }}
        >
          <Title level={4} style={{ margin: 0 }}>
            Sharing "{dashboardData?.dashboard.name}"
          </Title>
          <Title
            level={4}
            onClick={() => setIsOpen(false)}
            style={{ fontWeight: 600, cursor: 'pointer', marginTop: -20 }}
          >
            X
          </Title>
        </div>
      </Header>
      <Body headerHeight={headerHeight} footerHeight={footerHeight}>
        <Section style={{ background: pageBackgroundGrey }}>
          <div>
            <Title level={5} style={{ display: 'flex', alignItems: 'center' }}>
              <GroupsOutlined style={{ marginRight: 5 }} />{' '}
              {owners?.length > 1 && owners?.length}{' '}
              {pluralize('Owner', owners?.length ?? 0)}
            </Title>
            <div>
              {owners?.map((owner: { email: string }) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 15,
                    alignItems: 'center',
                    marginBottom: 5,
                  }}
                >
                  <div>
                    {owner.email}
                    {owner.email === currentUserEmail ? ' (you)' : ''}
                  </div>
                  {isAuthor && owner.email !== currentUserEmail && (
                    <YogiButton
                      type={'ghost'}
                      onClick={() => {
                        handleUnshare(owner.email)
                      }}
                      style={{ height: 24, padding: '1px 6px' }}
                    >
                      X
                    </YogiButton>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Section>
        {isOwner && (
          <Section style={{ border: 'none' }}>
            <div style={{ marginTop: -3, width: '100%' }}>
              <div>Add Owners</div>
              <Select
                mode="multiple"
                allowClear
                value={newOwners}
                style={{ width: '100%', marginTop: 5 }}
                placeholder="Choose users to share view"
                onChange={(value) => {
                  setNewOwners(value)
                }}
              >
                {allUsers
                  .filter(
                    (user) =>
                      user !== currentUserEmail &&
                      !owners.find((el) => el.email === user)
                  )
                  .map((user) => (
                    <Option key={user} value={user}>
                      {user}
                    </Option>
                  ))}
              </Select>
            </div>
          </Section>
        )}
        <Section style={{ background: pageBackgroundGrey }}>
          <div>
            <Title level={5} style={{ display: 'flex', alignItems: 'center' }}>
              <GroupsOutlined style={{ marginRight: 5 }} /> {viewers?.length}{' '}
              {pluralize('Viewer', viewers?.length ?? 0)}
            </Title>
            <div>
              {viewers?.map((viewer) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 15,
                    alignItems: 'center',
                    marginBottom: 5,
                  }}
                >
                  {viewer.full_name === 'Public Viewer' ? (
                    <div>Shared via Public Link</div>
                  ) : (
                    <div>
                      {viewer.email}
                      {viewer.email === currentUserEmail ? ' (you)' : ''}
                    </div>
                  )}
                  {isOwner && viewer.email !== currentUserEmail && (
                    <YogiButton
                      type={'ghost'}
                      onClick={() => {
                        handleUnshare(viewer.email)
                      }}
                      style={{ height: 24, padding: '1px 6px' }}
                    >
                      X
                    </YogiButton>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Section>
        <Section style={{ border: 'none' }}>
          <div style={{ marginTop: -3, width: '100%' }}>
            <div>Add Viewers</div>
            <Select
              mode="multiple"
              allowClear
              value={newViewers}
              style={{ width: '100%', marginTop: 5 }}
              placeholder="Choose users to share view"
              onChange={(value) => {
                setNewViewers(value)
              }}
            >
              {allUsers
                .filter(
                  (user) =>
                    user !== currentUserEmail && !recipients.includes(user)
                )
                .map((user) => (
                  <Option key={user} value={user}>
                    {user}
                  </Option>
                ))}
            </Select>
          </div>
        </Section>
        <Section>
          <InnerTitle style={{ color: mainText }}>
            Add a Message: (Optional)
          </InnerTitle>
          <TextArea
            rows={4}
            onChange={(e) => setMessage(e.target.value)}
          ></TextArea>
        </Section>
        {feature_flags?.dashboard_public_link && (
          <Section style={{ background: pageBackgroundGrey }}>
            <div style={{ marginTop: -3, width: '100%' }}>
              <Title
                level={5}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <LinkOutlined style={{ marginRight: 5 }} /> Create Public Link
              </Title>
              <YogiButton
                type={'primary'}
                loading={isSharingUrlLoading}
                onClick={() => {
                  mixpanel.track('custom dashboard', {
                    action: 'share',
                    type: 'public link',
                    value: dashboardData?.dashboard.id,
                  })
                  setIsSharingUrlLoading(true)
                  getPublicUrl(projectId, dashboardData.dashboard.id)
                    .then((res) => {
                      setIsSharingUrlLoading(false)
                      navigator.clipboard
                        .writeText(res)
                        .then(() =>
                          toast.info(
                            'Public Dashboard Link copied to clipboard'
                          )
                        )
                    })
                    .catch((e) => {
                      setIsSharingUrlLoading(false)
                      toast.error('Error creating public link')
                    })
                }}
              >
                Copy Link
              </YogiButton>
            </div>
          </Section>
        )}
      </Body>
      <Footer ref={footerRef}>
        <YogiButton type="ghost" onClick={() => setIsOpen(false)}>
          Cancel
        </YogiButton>

        <YogiButton type="primary" onClick={() => manualSubmit()}>
          Send
        </YogiButton>
      </Footer>

      {isSubmitting && (
        <Overlay>
          <Fetcher />
        </Overlay>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: var(--default-padding) var(--default-padding) 5px;
  border-bottom: 1px solid #f2f2f2;
`

const Body = styled.div<{ headerHeight: number; footerHeight: number }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(
    100vh - ${(props) => props.headerHeight}px -
      ${(props) => props.footerHeight}px - 50px
  );
  padding-bottom: var(--default-padding);
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--default-padding-half) var(--default-padding);
  border-bottom: 1px solid #f2f2f2;
`

const Footer = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  margin: 0 var(--default-padding-half);
`

const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default ShareDashboard
