import React from 'react'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'

export const ProjectCardNew = () => {
  return (
    <ProjectWrapper>
      <Icon>
        <PlusOutlined />
      </Icon>
      <Text>Add Project</Text>
    </ProjectWrapper>
  )
}

const ProjectWrapper = styled.div`
  width: 100%;
  padding: var(--default-padding);
  background: #fff;
  box-shadow: var(--default-box-shadow);
  color: black;
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--border-radius);

  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
`
const Icon = styled.div`
  width: 48px;
  height: 48px;
  font-size: var(--font-size-l);
  border: 2px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: var(--color-grey);
  margin-bottom: var(--default-padding-half);
`
const Text = styled.div`
  font-size: var(--font-size-l);
  text-align: center;
  font-weight: 500;
`
