import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Filters, ProjectState } from '../../../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { useQuery } from 'react-query'
import { getSummary } from '../../charts/model'
import { InnerTitle } from '../../../../../components/InnerTitle'
import { CloseOutlined, HighlightOutlined } from '@ant-design/icons'
import { Loader } from '../../../../../shared/components'
import { SEARCH_HIGHLIGHT_COLOR } from '../../../utils'
import mixpanel from '../../../../trackers/mixpanel'
import {
  buttonBlue,
  cardBorderGrey,
  lightText,
  mainText,
  pageBackgroundGrey,
  secondaryNavy,
  secondaryRed,
} from '../../../../../assets/styles/variables'
import { Stats } from '../../summary/components/Stats'
import _ from 'lodash'
import { OpenInNewOutlined } from '@mui/icons-material'
import { Divider } from '@mui/material'
import YogiDivider from '../../../../../components/UI/YogiDivider'
import { getItemPDPAnalysis } from '../model'
import { useHistory } from 'react-router'
import CopyButton from './CopyButton'
import HighlightedText from './HighlightedText'
import { Switch, Tooltip } from 'antd'
import LoadingText from '../../../../../components/Loading/LoadingText'
import { Link } from 'react-router-dom'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { YogiInput } from '../../../../../components/UI/YogiInput'
import Temperature from './Temperature'
import { Product, ProductHierarchyInfo } from '../../products/types'
import { YogiSelect } from '../../../../../components/UI/YogiSelect'
import useThemes from '../../../hooks/useThemes'
import ProductSelector from './ProductSelector'
import usePDP from '../../../hooks/usePDP'

type Props = {
  item: ProductHierarchyInfo
  filterValues: Filters
  tier1: string
  chartId: string
  disabled?: boolean
}

const Analysis: React.FC<Props> = ({ item, filterValues, tier1, chartId }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const history = useHistory()
  const { getNormalizedThemeName } = useThemes()

  const [enableHighlights, setEnableHighlights] = React.useState<boolean>(true)
  const [temperature, setTemperature] = React.useState<number>(1)
  const [selectedItem, setSelectedItem] = React.useState<Product | undefined>()
  const [selectedUrl, setSelectedUrl] = React.useState<string | null>(null)
  const [userInput, setUserInput] = React.useState<string>('')
  const [userKeywords, setUserKeywords] = React.useState<string[]>([])
  const [committedKeywords, setCommittedKeywords] = React.useState<string[]>([])

  // if an item has been selected, grab a URL to use for the PDP
  useEffect(() => {
    if (selectedItem) {
      setSelectedUrl(
        selectedItem.sources
          .sort((a, b) => a.source_url?.localeCompare(b.source_url))
          .sort((a, b) => {
            const containsStringA = a.source_url?.includes('www.amazon.com/')
            const containsStringB = b.source_url?.includes('www.amazon.com/')

            if (containsStringA && !containsStringB) {
              return -1 // a should come before b
            } else if (!containsStringA && containsStringB) {
              return 1 // b should come before a
            } else {
              return 0 // no change in order
            }
          })
          .find((el) => el.source_type === 'Amazon')?.source_url ?? null
      )
    }
  }, [selectedItem])

  // update url params to reflect item
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('item') !== selectedItem?.uuid) {
      if (selectedItem?.uuid) {
        urlParams.set('item', selectedItem.uuid)
        history.push(`${window.location.pathname}?${urlParams.toString()}`)
      }
      setCommittedKeywords([...userKeywords])

      if (selectedItem?.uuid) {
        mixpanel.track('pdp insights', {
          action: 'view',
          product: item.hierarchy,
          item: selectedItem?.uuid,
        })
      }
    }
  }, [selectedItem])

  const summaryRequest = {
    headers: {
      proj_uuid: projectId,
    },
    body: {
      chart_id: '5_5_7_rating_sentiment',
      tier1: 'Product',
      tier1_value: '',
      tier2_value: null,
      tier3_value: null,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
      criteria: filterValues.values,
    },
  }

  const { data, isLoading: pdpLoading } = useQuery(
    ['summary', summaryRequest],
    () => getSummary(summaryRequest.headers, summaryRequest.body),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!summaryRequest,
    }
  )

  const toggleSwitch = (checked: boolean) => {
    setEnableHighlights(checked)
  }

  const { data: analysisResponseObj, isLoading: analysisLoading } = useQuery(
    ['pdpAnalysis', selectedItem, committedKeywords, selectedUrl],
    () =>
      getItemPDPAnalysis(
        projectId,
        selectedItem!.name,
        committedKeywords,
        selectedUrl
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!selectedItem && !!selectedUrl,
    }
  )

  const analysisResponse =
    analysisResponseObj?.[
      temperature === 1 ? 'temp1' : temperature === 2 ? 'temp2' : 'temp3'
    ]

  const { handleKeywordClick, handleThemeClick } = usePDP()

  return (
    <OverviewContainer>
      <ProductSelectorWrapper className={'product-selector'}>
        <ProductSelector
          hierarchy={item.hierarchy}
          isPDP
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </ProductSelectorWrapper>
      {selectedUrl && (
        <>
          {pdpLoading || !analysisResponse ? (
            <LoadingWrapper
              style={{
                height: '100%',
                width: '100%',
                padding: 8,
              }}
            >
              <div
                style={{
                  top: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <Loader
                  style={{ height: '100%', maxHeight: 300, marginTop: 30 }}
                />
                <LoaderTitle>
                  {!!selectedItem?.uuid ? (
                    <LoadingText
                      key={selectedItem?.uuid}
                      strings={[
                        'Fetching PDP from Amazon',
                        'Calculating Themes and Keywords',
                        'Modifying PDP Content',
                        'Making A List, Checking It Twice',
                        'Wrapping Things Up',
                      ]}
                      interval={8000}
                    />
                  ) : (
                    <LoadingText
                      key={selectedItem?.uuid}
                      strings={['Getting Product Data']}
                      speed={5}
                    />
                  )}
                </LoaderTitle>
              </div>
            </LoadingWrapper>
          ) : (
            <>
              <KeywordWrapper>
                <Stats
                  data={data}
                  summaryRequest={summaryRequest}
                  filterValues={filterValues}
                  filterList={defaultFilterList}
                  omittedOptions={[
                    'n_fragments',
                    'n_rating_only',
                    'n_recommended_posts',
                    'n_promo_posts',
                    'n_themes_total',
                    'n_themes_provided',
                  ]}
                  columns={4}
                />
              </KeywordWrapper>
              <KeywordWrapper>
                {/*<ColorBar color={secondaryNavy} />*/}
                <div style={{ maxWidth: 400 }}>
                  <p style={{ fontSize: 16, fontWeight: 500 }}>
                    Selected Keywords and Themes:
                  </p>
                  <p>
                    Keywords and Themes are selected by identifying high-volume,
                    high-sentiment instances from user reviews across all
                    product urls.
                  </p>
                  <p>
                    Keyword and Theme bubbles can be hovered to view the number
                    of sentences in which the item occurs, and the average
                    sentiment of those sentences.
                  </p>
                  <p>
                    Click on Keywords and Themes to view them in more detail.
                  </p>
                  <p>*Note: Themes are only used at higher Temperatures</p>
                  {/*<p>*/}
                  {/*  Themes are selected by identifying topics that are high-volume*/}
                  {/*  and over-performing relative to competitors*/}
                  {/*</p>*/}
                </div>
                <Divider
                  // variant={'middle'}
                  orientation={'vertical'}
                  style={{ margin: '0px 10px' }}
                />
                <div style={{ flexGrow: 1 }}>
                  <h3 style={{ marginBottom: 0 }}>Keywords</h3>
                  <PillContainer
                    style={{
                      overflow: 'visible',
                      width: '100%',
                      flexGrow: 1,
                      flexWrap: 'wrap',
                      minHeight: 0,
                    }}
                  >
                    {analysisResponse.keywords
                      .filter((el) =>
                        analysisResponse.explanation.keywords_used.includes(
                          el.keyword
                        )
                      )
                      .sort((a, b) => b.sentiment - a.sentiment)
                      .map((el) => (
                        // todo make these separate components -
                        //  can precalc the hashcode so theres no so much delay
                        <Tooltip
                          title={
                            <div>
                              <div>Mentions: {el.sentences}</div>
                              <div>Sentiment: {el.sentiment}</div>
                            </div>
                          }
                        >
                          <KeywordPill
                            color={el.color}
                            onClick={() => handleKeywordClick(el.keyword)}
                          >
                            <ColorBar color={el.color} />
                            <div>{el.keyword}</div>
                            {/*<Divider style={{ borderColor: '#c2c2c2' }} />*/}
                            {/*<div*/}
                            {/*  style={{*/}
                            {/*    display: 'flex',*/}
                            {/*    margin: '5px 0px 3px',*/}
                            {/*    fontSize: 14,*/}
                            {/*    fontWeight: 400,*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  <div style={{ minWidth: 25 }}>{el.sentences}</div>*/}
                            {/*  <div>*/}
                            {/*    <Divider*/}
                            {/*      orientation={'vertical'}*/}
                            {/*      style={{*/}
                            {/*        borderColor: '#c2c2c2',*/}
                            {/*        margin: '0px 5px',*/}
                            {/*      }}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*  <div style={{ marginLeft: 2 }}>*/}
                            {/*    {el.sentiment > 0 && '+'}*/}
                            {/*    {el.sentiment}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                          </KeywordPill>
                        </Tooltip>
                      ))}

                    {!!userKeywords.length && (
                      <>
                        {userKeywords.map((el) => (
                          <KeywordPill
                            color={secondaryNavy}
                            onClick={() => handleKeywordClick(el)}
                            style={{ position: 'relative' }}
                          >
                            <ColorBar color={secondaryNavy} />
                            <div>{el}</div>
                            <CloseButton
                              className={'close-button'}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setUserKeywords(
                                  userKeywords.filter(
                                    (keyword) => keyword !== el
                                  )
                                )
                              }}
                            >
                              <CloseOutlined />
                            </CloseButton>
                          </KeywordPill>
                        ))}
                      </>
                    )}
                  </PillContainer>
                  <div
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                    }}
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        if (userInput) {
                          setUserKeywords([...userKeywords, userInput.trim()])
                          setUserInput('')
                        }
                      }}
                      style={{ flexGrow: 1 }}
                    >
                      <YogiInput
                        placeholder={'Add Keywords'}
                        value={userInput}
                        onChange={(event) => {
                          setUserInput(event.target.value)
                        }}
                      />
                    </form>
                    <YogiButton
                      disabled={!userInput}
                      onClick={() => {
                        if (userInput) {
                          setUserKeywords([...userKeywords, userInput.trim()])
                          setUserInput('')
                        }
                      }}
                    >
                      Add
                    </YogiButton>
                    <YogiButton
                      type={'ghost'}
                      onClick={() => {
                        setUserKeywords([])
                      }}
                    >
                      Clear
                    </YogiButton>
                    <Divider orientation={'vertical'} />
                    <YogiButton
                      type={'primary'}
                      onClick={() => {
                        setCommittedKeywords([...userKeywords])
                      }}
                      disabled={_.isEqual(userKeywords, committedKeywords)}
                    >
                      Regenerate PDP
                    </YogiButton>
                  </div>
                  {temperature > 1 && (
                    <>
                      <Divider
                        style={{
                          borderColor: '#c2c2c2',
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                      />
                      <h3 style={{ marginBottom: 0 }}>Themes</h3>
                      <PillContainer
                        style={{
                          width: '100%',
                          flexGrow: 1,
                          flexWrap: 'wrap',
                          minHeight: 0,
                        }}
                      >
                        {analysisResponse.themes
                          .filter(
                            (el) => el.sentences > 5 && el.sentiment >= 0.3
                          )
                          .sort((a, b) => b.sentiment - a.sentiment)
                          .map((el, index) => (
                            <Tooltip
                              title={
                                <div>
                                  <div>Sentences: {el.sentences}</div>
                                  <div>Sentiment: {el.sentiment}</div>
                                </div>
                              }
                            >
                              <KeywordPill
                                color={el.color}
                                onClick={() => handleThemeClick(el.theme)}
                              >
                                <ColorBar color={el.color} />
                                {getNormalizedThemeName(el.theme)
                                  .split(': ')
                                  .map((part, index) => (
                                    <div>
                                      {part}
                                      {!index &&
                                        getNormalizedThemeName(el.theme).split(
                                          ': '
                                        ).length > 1 &&
                                        ':'}
                                    </div>
                                  ))}
                              </KeywordPill>
                            </Tooltip>
                          ))}
                      </PillContainer>
                    </>
                  )}

                  <div style={{ marginTop: 10 }}>
                    <YogiDivider style={{ marginBottom: 5 }} />
                    <div style={{ color: lightText }}>
                      * Reviews from all Product listings are used to identify
                      Keywords and Themes
                    </div>
                  </div>
                </div>
                <Divider
                  // variant={'middle'}
                  orientation={'vertical'}
                  style={{ margin: '0px 10px' }}
                />
                <Temperature
                  temperature={temperature}
                  setTemperature={setTemperature}
                />
              </KeywordWrapper>

              <AnalysisWrapper>
                <WidgetWrapper>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <InnerTitle style={{ marginBottom: 5 }}>Current</InnerTitle>
                    {selectedItem &&
                      selectedItem.sources.filter(
                        (el) => el.source_type === 'Amazon'
                      ).length > 1 && (
                        <YogiSelect
                          style={{ width: 300 }}
                          value={selectedUrl}
                          defaultValue={selectedUrl}
                          onChange={(value: string) => setSelectedUrl(value)}
                          options={selectedItem?.sources
                            .filter((el) => el.source_type === 'Amazon')
                            .map((el) => ({
                              // label: new URL(el.source_url).hostname,
                              label: el.source_url?.split('//')[1],
                              value: el.source_url,
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label))}
                        />
                      )}
                    {selectedUrl && (
                      <a
                        href={selectedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <YogiButton
                          type={'ghost'}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                            // padding: '2px 5px',
                            marginTop: -3,
                          }}
                        >
                          Open PDP <OpenInNewOutlined fontSize={'small'} />
                        </YogiButton>
                      </a>
                    )}
                  </div>
                  <YogiDivider style={{ marginBottom: 5 }} />
                  <InnerTitle>
                    {analysisResponse.before.listing_title}
                  </InnerTitle>
                  <p>{analysisResponse.before.description}</p>
                  {typeof analysisResponse.before.features === 'object' ? (
                    <ul>
                      {analysisResponse.before.features.map((el) => (
                        <li>{el}</li>
                      ))}
                    </ul>
                  ) : (
                    <div>
                      <p>{analysisResponse.before.features}</p>
                    </div>
                  )}
                  {!analysisResponse.before.description && (
                    <div>
                      <YogiDivider style={{ marginBottom: 5 }} />
                      <div style={{ color: lightText }}>
                        * No description provided on current PDP
                      </div>
                    </div>
                  )}
                </WidgetWrapper>
                <WidgetWrapper>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <InnerTitle
                      style={{ marginBottom: 5, color: secondaryNavy }}
                    >
                      Updated
                    </InnerTitle>
                    <StyledSwitch
                      defaultChecked
                      checked={enableHighlights}
                      onChange={toggleSwitch}
                      checkedChildren={<HighlightOutlined />}
                      unCheckedChildren={<HighlightOutlined />}
                      style={{ marginTop: 5 }}
                    />
                  </div>

                  <YogiDivider style={{ marginBottom: 5 }} />
                  {/*<AfterTitle*/}
                  {/*  dangerouslySetInnerHTML={{*/}
                  {/*    __html: highlightKeywords(*/}
                  {/*      analysisResponse.before.listing_title,*/}
                  {/*      analysisResponse.after.listing_title,*/}
                  {/*      analysisResponse.keywords.map((el) => el.keyword)*/}
                  {/*    ),*/}
                  {/*  }}*/}
                  {/*></AfterTitle>*/}
                  <AfterTitle
                    className={'copy-container'}
                    style={{ position: 'relative' }}
                  >
                    {/*<CopyButton textToCopy={analysisResponse.after.listing_title} />*/}
                    {/*<HighlightedText*/}
                    {/*  text={analysisResponse.after.listing_title}*/}
                    {/*  keywordsToHighlight={*/}
                    {/*    analysisResponse.explanation.keywords_used*/}
                    {/*  }*/}
                    {/*  keywords={analysisResponse.keywords}*/}
                    {/*  disabled={!enableHighlights}*/}
                    {/*/>*/}
                    <div>{analysisResponse.before.listing_title}</div>
                  </AfterTitle>
                  <div
                    className={'copy-container'}
                    style={{ position: 'relative' }}
                  >
                    <CopyButton
                      textToCopy={analysisResponse.after.description}
                    />

                    <Description>
                      <HighlightedText
                        key={temperature}
                        text={analysisResponse.after.description}
                        keywordsToHighlight={
                          analysisResponse.explanation.keywords_used
                        }
                        userKeywords={committedKeywords}
                        keywords={analysisResponse.keywords}
                        disabled={!enableHighlights}
                        filters={filterValues}
                      />
                    </Description>
                  </div>
                  <div
                    className={'copy-container'}
                    style={{ position: 'relative' }}
                  >
                    <CopyButton
                      textToCopy={analysisResponse.after.features
                        .map((str) => `• ${str}`)
                        .join('\n')}
                    />
                    <ul>
                      {analysisResponse.after.features.map((el, index) => (
                        <StyledListItem
                          className={'copy-container'}
                          style={{ position: 'relative' }}
                        >
                          <HighlightedText
                            text={el}
                            keywordsToHighlight={
                              analysisResponse.explanation.keywords_used
                            }
                            userKeywords={committedKeywords}
                            keywords={analysisResponse.keywords}
                            disabled={!enableHighlights}
                            filters={filterValues}
                          />
                        </StyledListItem>
                      ))}
                    </ul>
                  </div>
                  <YogiDivider style={{ marginBottom: 10 }} />
                  <div style={{ color: lightText }}>
                    * Generative tools may create unexpected results - make sure
                    output conforms to your PDP guidelines
                  </div>
                  {analysisResponse.after.description.length > 1000 && (
                    <div style={{ color: secondaryRed, marginTop: 10 }}>
                      * Warning - Description is longer than 1000 characters. A
                      Description of less than 1000 characters is recommended
                    </div>
                  )}
                  {analysisResponse.after.features.join().length > 1000 && (
                    <div style={{ color: secondaryRed, marginTop: 10 }}>
                      * Warning - Feature Bullets are longer than 1000
                      characters. A Feature Bullet section of less than 1000
                      characters (200 characters each) is recommended
                    </div>
                  )}
                </WidgetWrapper>
              </AnalysisWrapper>

              {/*<AnalysisWrapper>*/}
              {/*  <WidgetWrapper>*/}
              {/*    /!*<ColorBar color={secondaryNavy} />*!/*/}
              {/*    {analysisResponse.explanation && (*/}
              {/*      <>*/}
              {/*        <InnerTitle style={{ marginBottom: 5 }}>*/}
              {/*          Explanation*/}
              {/*        </InnerTitle>*/}
              {/*        <YogiDivider style={{ marginBottom: 5 }} />*/}
              {/*        <div>*/}
              {/*          <ul style={{ marginBottom: 0 }}>*/}
              {/*            <li>*/}
              {/*              {*/}
              {/*                analysisResponse.explanation*/}
              {/*                  .description_optimization_explanation*/}
              {/*              }*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*              {*/}
              {/*                analysisResponse.explanation*/}
              {/*                  .features_optimization_explanation*/}
              {/*              }*/}
              {/*            </li>*/}
              {/*            /!*<li>*!/*/}
              {/*            /!*  {*!/*/}
              {/*            /!*    analysisResponse.explanation*!/*/}
              {/*            /!*      .title_optimization_explanation*!/*/}
              {/*            /!*  }*!/*/}
              {/*            /!*</li>*!/*/}
              {/*          </ul>*/}
              {/*        </div>*/}
              {/*      </>*/}
              {/*    )}*/}
              {/*  </WidgetWrapper>*/}
              {/*</AnalysisWrapper>*/}
            </>
          )}
        </>
      )}
    </OverviewContainer>
  )
}
export default Analysis

const OverviewContainer = styled.div`
  height: 100%;
  width: 100%;
  //max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  //justify-content: center;
  overflow: auto;
  padding: var(--default-padding-quarter);
`

const TitleWrapper = styled.div`
  background: white;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  margin-bottom: -5px;
`

const ChartContainer = styled.div`
  height: 250px;
`

const LoadingWrapper = styled.div`
  //height: 125px;

  height: 100%;
  width: 100%;
  padding: 8px;
  background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
`

const WidgetWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
  position: relative;
`

const ProductSelectorWrapper = styled.div`
  //height: 125px;
  background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
`

const KeywordWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
  display: flex;
  gap: 10px;
  //align-items: center;
  position: relative;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const OpenInNewOutlinedStyled = styled(OpenInNewOutlined)`
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 0.2s ease !important;

  &:hover {
    color: ${buttonBlue};
  }
`

const AnalysisWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const StyledListItem = styled.li`
  .highlight {
    background-color: ${SEARCH_HIGHLIGHT_COLOR};
    //color: white;
  }
`

const AfterTitle = styled.h2`
  color: var(--color-text-dark-grey);
  .highlight {
    background-color: ${SEARCH_HIGHLIGHT_COLOR};
    //color: white;
  }
`
const Description = styled.p`
  .highlight {
    background-color: ${SEARCH_HIGHLIGHT_COLOR};
    //color: white;
  }
`

const PillContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  min-height: 50px;
  width: 100%;
  overflow: visible;
  white-space: nowrap;
`

const KeywordPill = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  //flex-direction: row;
  padding: 2px 15px;
  //border: 2px solid ${(props) => props.color ?? secondaryNavy};
  border: 1px solid #eff0f6;
  border-radius: 8px;
  background: ${pageBackgroundGrey};
  font-weight: 500;
  font-size: 16px;
  //cursor: pointer;
  transform: translate(0%);
  transition: 0.15s ease-out;
  cursor: pointer;
  box-shadow: var(--default-box-shadow);

  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.05);
    .close-button {
      display: flex;
    }
  }
`

const ColorBar = styled.div<{ color?: string }>`
  width: 5px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
`
const StyledSwitch = styled(Switch)`
  background-color: ${(props) =>
    props.checked ? secondaryNavy : 'rgba(0, 0, 0, 0.25)'};

  .ant-switch-inner {
    font-size: 12px;
  }
`

const StyledLink = styled(Link)`
  //  display: flex;
  //  align-items: center;
  //  width: 100%;
  //  justify-content: space-between;
  color: var(--main-text);
  &:hover {
    text-decoration: none;
    color: var(--main-text);
  }
`
const CloseButton = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  right: -7px;
  top: -7px;
  font-size: 12px;
  cursor: pointer;
  color: ${mainText};
  background: ${pageBackgroundGrey};
  border: 1px solid ${mainText};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  &:hover {
    background: ${cardBorderGrey};
  }
`
