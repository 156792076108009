import { Button, Form, Input } from 'antd'
import React from 'react'
import { FormItem } from 'shared/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { SavedViewPayload } from '../types'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import mixpanel from 'features/trackers/mixpanel'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import styled from 'styled-components'

type Props = {
  saveFunction: (view: SavedViewPayload) => Promise<void>
}

const validationSchema = Yup.object().shape({
  presetName: Yup.string().required('Field is required'),
})

export const SaveViewForm: React.FC<Props> = ({ saveFunction }) => {
  const location = useLocation()
  const locationParts = location.pathname.split('/')
  const viewUrl = locationParts[locationParts.length - 1]
  const formik = useFormik({
    validationSchema,
    initialValues: { presetName: '', saveRelativeDate: false },
    onSubmit: async ({ presetName }, { resetForm }) => {
      mixpanel.track('saved view', { action: 'save', value: presetName })
      saveFunction({
        name: presetName,
        page: viewUrl,
        search_url: window.location.search,
      })
        .then(() => {
          resetForm()
        })
        .catch((e) => {
          toast.error(e.message)
        })
    },
  })

  // const relativeDateIsSet = relativeDateFilterIdx != null && relativeDateFilterIdx !== RelativeDateFilterIdx.Unset

  return (
    <div>
      <Form onFinish={formik.handleSubmit}>
        {/*<FormItem field="presetName" formik={formik}/>*/}

        <Form.Item>
          <StyledInput
            name={'presetName'}
            onChange={formik.handleChange}
            value={formik.values['presetName']}
            placeholder={'View Name'}
          />
          {/*<Error>{formik.errors?.['presetName']}</Error>*/}
        </Form.Item>
        {/* <Form.Item label="Save relative date" wrapperCol={{ span: 16 }}>
        <Checkbox
          disabled={!relativeDateIsSet}
          value={relativeDateIsSet && formik.values.saveRelativeDate}
          name="saveRelativeDate"
          checked={relativeDateIsSet && formik.values.saveRelativeDate}
          onChange={formik.handleChange}
        />
      </Form.Item> */}
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <YogiButton type="primary" htmlType="submit">
            Save
          </YogiButton>
        </div>
      </Form>
    </div>
  )
}

const StyledInput = styled(Input)`
  border-radius: 6px;

  &:placeholder-shown {
    font-style: italic;
  }
`

const Error = styled.div`
  text-align: center;
  color: #ff4d4f;
  position: absolute;
  top: 100%;
  font-size: var(--font-size-sm);
`
