import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  Button,
  Radio,
  Checkbox,
  Input,
  Slider,
  Spin,
  Tooltip,
  Popover,
} from 'antd'
import {
  AppliedFilterOption,
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
  ApplyFilterPayload,
  FilterOption,
  FilterOptionSelect,
  FilterTypes,
} from 'features/project/features/filters/types'
import {
  DeleteOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { generateSliderMarks, isAppliedFilterOptionSelect } from '../../helpers'
import './../../styles.css'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import Divider from '../../../../../../components/UI/YogiDivider'
import { secondaryNavy } from '../../../../../../assets/styles/variables'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import { deleteFilterPreset, loadFilterPresets } from '../../model'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { fetchProjects } from '../../../../../home/model'
import emptySavedFilters from 'assets/images/EmptySavedFilters.png'
import { PanelBadges } from '../badge-panel/panel-badges'

type Props = {
  children?: React.ReactNode
  option?: FilterOption
  appliedFilters?: Array<AppliedFilterOption>
  applyFilter?: (p: ApplyFilterPayload) => void
  applyEveryFilter?: (search: string) => void
  clearFilters?: (search: string) => void
  comparativeIndex: number
  onClose?: () => void
  updateLocalFilterValues?: (filterValues: Filters) => void
}

const NULL_FIELD = 'Null'
const numberFormatter = new Intl.NumberFormat()

export const SavedFilterSelector: React.FC<Props> = ({
  option: propOption,
  applyFilter,
  appliedFilters,
  clearFilters,
  applyEveryFilter,
  comparativeIndex,
  onClose,
  updateLocalFilterValues,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const updateFilters = useProjectStore(
    (state: ProjectState) => state.updateFilters
  )
  const filterPresets = useProjectStore(
    (state: ProjectState) => state.filterPresets
  )
  const setFilterPresets = useProjectStore(
    (state: ProjectState) => state.setFilterPresets
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField
  )
  const updateOpenedOptions = useProjectStore(
    (state: ProjectState) => state.updateOpenedOptions
  )
  const ref = useRef<any>()
  const [selectedFilter, setSelectedFilter] = useState()

  // const reloadFilterPresets = () =>
  //   loadFilterPresets(projectId)
  //     .then((response) => setFilterPresets(response.filterPresets))
  //     .catch((error) =>
  //       toast.error(error.response.data.detail.msg || 'Error getting filters.')
  //     )

  const { data, isLoading, refetch } = useQuery(
    ['filter-presets', projectId],
    () => loadFilterPresets(projectId),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
    }
  )

  const deletePreset = (preset_name: string) =>
    deleteFilterPreset(projectId, preset_name)
      .then(() => {
        // toast.success('Filter deleted')
        refetch()
      })
      .catch((error) => {
        toast.error(error.response.data.detail.msg || 'Error deleting filter.')
      })

  useEffect(() => {
    if (data) {
      setFilterPresets(data.filter_presets)
    }
  }, [data])

  return (
    <Wrapper ref={ref}>
      <Description>Select one option</Description>
      <Divider />
      <Option>
        {!data || isLoading ? (
          <SpinWrapper>
            <Spin />
          </SpinWrapper>
        ) : (
          <>
            {filterPresets?.length ? (
              <div>
                {filterPresets.map((el) => (
                  <RadioOption
                    selected={el.name === selectedFilter}
                    onClick={() => {
                      const filterVals = {
                        values: el.criteria,
                        searchQuery: el.search_terms,
                        searchCondition: el.search_criteria,
                      }
                      updateLocalFilterValues
                        ? updateLocalFilterValues(filterVals)
                        : updateFilters(comparativeIndex, filterVals)
                      onClose && onClose()
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <div>{el.name}</div>
                      <PanelBadges
                        filterValues={{
                          values: el.criteria,
                          searchQuery: el.search_terms,
                          searchCondition: el.search_criteria,
                        }}
                        comparativeIndex={comparativeIndex}
                        infoOnly
                        savedFilter
                        openFilterPanel={(field) => {
                          updateSelectedOptionField(comparativeIndex, field)
                          updateOpenedOptions(comparativeIndex, field)
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      {/*<YogiButton*/}
                      {/*  type={selectedFilter === el.name ? 'ghost' : 'default'}*/}
                      {/*  style={{ padding: '0px 8px', height: 28 }}*/}
                      {/*  onClick={(event: any) => {*/}
                      {/*    event.stopPropagation()*/}
                      {/*    if (!el.default) {*/}
                      {/*      console.log('update')*/}
                      {/*    }*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {el.default ? 'Default' : 'Make Default'}*/}
                      {/*</YogiButton>*/}
                      <YogiButton
                        type="ghost"
                        style={{ padding: '0px 4px', height: 28 }}
                        icon={<DeleteOutlined />}
                        onClick={(event: any) => {
                          event.stopPropagation()
                          deletePreset(el.name)
                        }}
                      ></YogiButton>
                      <RightOutlined />
                    </div>
                  </RadioOption>
                ))}
              </div>
            ) : (
              <EmptyWrapper>
                <EmptyImage src={emptySavedFilters} />
                <EmptyMessage>No Saved Filters Yet...</EmptyMessage>
              </EmptyWrapper>
            )}
          </>
        )}
      </Option>
    </Wrapper>
  )
}

const checkIfFilterSelected = {
  checkbox: (
    filter: AppliedFilterOptionSelect | AppliedFilterOptionRange | undefined,
    value: string
  ) => {
    if (!filter) return false

    return isAppliedFilterOptionSelect(filter)
      ? filter.values.includes(value)
      : false
  },
  slider: (
    filter: AppliedFilterOptionRange | undefined
  ): [number, number] | undefined => {
    if (filter?.max && filter?.min) return [filter.min, filter.max]
  },
}

const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  height: 100%;
`
const Description = styled.div`
  font-weight: 300;
  margin: 20px 20px 10px;
`
const Option = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //margin-bottom: var(--default-padding-half);
  overflow: auto;

  //&:first-child {
  //  padding-top: calc(var(--default-padding-half) / 2);
  //}
  //
  //&:last-child {
  //  padding-bottom: calc(var(--default-padding-half) / 2);
  //}
`
const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: calc(var(--default-padding-double) * 2);
`
const RadioOption = styled.div<{ selected: boolean }>`
  cursor: pointer;
  user-select: none;
  padding: 12px 20px 12px 24px;
  background: ${(props) => (props.selected ? '#e7edf0' : '#fff')};
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  font-size: 14px;
  border-bottom: 1px solid #e7edf0;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: #e7edf0;
  }

  span.ant-radio + * {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
    gap: 10px;
  }
`
const EmptyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const EmptyImage = styled.img`
  max-width: 30%;
  margin-bottom: 20px;
`
const EmptyMessage = styled.div`
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #9fb7c3;
`
