import React, { useRef } from 'react'
import styled from 'styled-components'
import ShareDashboard from './ShareDashboard'
import { DashboardResponse } from '../../types'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  dashboardData: DashboardResponse | undefined
  refetch: () => Promise<any>
}
export const Drawer: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  dashboardData,
  refetch,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <DrawerContainer ref={ref} isOpen={isOpen}>
      <Wrapper>
        {dashboardData && (
          <ShareDashboard
            dashboardData={dashboardData}
            setIsOpen={setIsOpen}
            refetch={refetch}
          />
        )}
      </Wrapper>
    </DrawerContainer>
  )
}

const DrawerContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 100;
  height: 100vh;
  width: 50%;
  background: white;
  right: 0px;
  top: 0px;
  margin-top: -40px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: ${(props) =>
    props.isOpen
      ? '0px 0px 100px -20px rgba(50, 50, 93, 0.25), -10px 0px 60px -30px rgba(0, 0, 0, 0.3)'
      : 'none'};
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.25s ease-in-out;
`

const Wrapper = styled.div`
  position: relative;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`
