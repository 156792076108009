import { AxiosResponse } from 'axios'
import {
  ProjectThemesResponse,
  ProjectFieldResponse,
  ProjectDomainResponse,
  SetFieldValueOpts,
  SetGroupValueOpts,
  ClustersWithSentences,
} from './types'
import { axiosInstance } from 'utils/axios'
import {
  CLUSTER_GROUP,
  CLUSTER_MERGE,
  CLUSTER_VISIBILITY,
  CLUSTER_SIMPLE_FEEDBACK,
  PROJECT_FIELD_DETAILS,
  PROJECT_FIELD_URL,
  PROJECT_FIELDS_URL,
  PROJECT_FIELD_THEME_URL,
  CLUSTERS_SENTENSES,
} from 'utils/apiLinks'
import { FilterPayload } from 'features/project/features/filters/types'

/**
 * Retrieves fields for color tab
 * @param {string} proj_uuid - Project UUID
 * @returns {Promise<ProjectDomainResponse>} Array of available fields
 */

export const fetchDomains = (
  proj_uuid: string
): Promise<AxiosResponse<ProjectDomainResponse>> =>
  axiosInstance.get(PROJECT_FIELDS_URL, { params: { proj_uuid } })
/**
 * Retrieves values for color tab
 * @param {string} proj_uuid - Project UUID
 * @param {string} field - Field name
 * @param body
 * @returns {Promise<ProjectFieldResponse>} Array of available values
 */
export const fetchClusters = (
  proj_uuid: string,
  field: string,
  body: FilterPayload,
  customParams?: any
): Promise<AxiosResponse<ProjectFieldResponse>> => {
  let config = { params: { proj_uuid, field } }
  if (customParams) config = { ...config, ...customParams }

  return axiosInstance.post(PROJECT_FIELD_URL, body, config)
}

/**
 * Retrieves theme values
 * @param {string} proj_uuid - Project UUID
 * @returns {Promise<ProjectThemesResponse>} Array of available themes
 */
export const fetchThemes = (
  proj_uuid: string
): Promise<AxiosResponse<ProjectThemesResponse>> =>
  axiosInstance.get(PROJECT_FIELD_THEME_URL, { params: { proj_uuid } })

/**
 * Updates the cluster state
 * @param {string} proj_uuid - Project UUID
 * @param {string} field - Field name
 * @param {string} value - Value name
 * @param {string} color - Color (rgb)
 * @param {string} theme - Theme
 * @returns nothing
 */

export const setClusterValue = ({
  proj_uuid,
  field,
  value,
  theme,
  color,
  selected_dataset,
}: SetFieldValueOpts): Promise<AxiosResponse<void>> =>
  axiosInstance.put(
    PROJECT_FIELD_DETAILS,
    { theme, color, selected_dataset },
    { params: { field, value, proj_uuid } }
  )

export const setGroupValue = ({
  proj_uuid,
  field,
  value,
  color,
  theme_group,
  selected_dataset,
}: SetGroupValueOpts): Promise<AxiosResponse<void>> =>
  axiosInstance.put(
    PROJECT_FIELD_DETAILS,
    { theme_group, color, selected_dataset },
    { params: { field, value, proj_uuid } }
  )

export const changeClusterVisibility = (
  cluster_uuids: string[],
  show: boolean
): Promise<AxiosResponse<void>> =>
  axiosInstance.post(CLUSTER_VISIBILITY, { cluster_uuids, show })

export const applySimpleFeedback = (
  cluster_uuids: string[]
): Promise<AxiosResponse<void>> =>
  axiosInstance.post(CLUSTER_SIMPLE_FEEDBACK, { cluster_uuids })

export const mergeClusters = (
  cluster_uuids: string[],
  theme: string,
  color?: string
): Promise<AxiosResponse<void>> =>
  axiosInstance.post(CLUSTER_MERGE, { cluster_uuids, theme, color })

export const groupClusters = (
  cluster_uuids: string[],
  group: string
): Promise<AxiosResponse<void>> =>
  axiosInstance.post(CLUSTER_GROUP, { cluster_uuids, group })

export const ungroupClusters = (
  cluster_uuids: string[]
): Promise<AxiosResponse<void>> =>
  axiosInstance.delete(CLUSTER_GROUP, {
    data: { cluster_uuids },
  })

export const fetchSentencesByClusters = ({
  selectedClusters,
  maxCountPerGroup,
  projectId,
}: {
  selectedClusters: string[]
  maxCountPerGroup: number
  projectId: string
}): Promise<AxiosResponse<ClustersWithSentences>> =>
  axiosInstance.post(
    CLUSTERS_SENTENSES,
    {
      cluster_uuids: selectedClusters,
      max_count_per_group: maxCountPerGroup,
    },
    { params: { proj_uuid: projectId } }
  )
