import { Filters } from '../types'
import { matrixChartIds } from '../features/charts/utils'
import useThemes from './useThemes'
import { AppliedFilterOption } from '../features/filters/types'

const useSummary = () => {
  const { getClusterUUIDsFromThemeName } = useThemes()

  const getFilterFromChartClick = (
    summaryRequest: any,
    themeName?: string
  ): Filters => {
    const isMatrixChart = matrixChartIds.includes(
      summaryRequest?.body?.chart_id
    )

    if (isMatrixChart) {
      return {
        values: summaryRequest?.body?.criteria ?? [],
        searchQuery: summaryRequest?.body?.search_terms ?? [],
        searchCondition: summaryRequest?.body?.search_criteria ?? [],
      }
    }

    let criteria: AppliedFilterOption[] = summaryRequest?.body?.criteria ?? []

    if (themeName) {
      criteria = criteria.filter((el) => !['theme', 'Theme'].includes(el.field))
      criteria.push({
        field: 'theme',
        values: getClusterUUIDsFromThemeName(
          themeName.split(': ')[0],
          themeName.split(': ')[1]
        ),
      })
    }

    return {
      values: criteria,
      searchQuery: summaryRequest?.body?.search_terms ?? [],
      searchCondition: summaryRequest?.body?.search_criteria ?? [],
    }
  }

  return { getFilterFromChartClick }
}

export default useSummary
