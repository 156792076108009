import React, { useState, useEffect, Fragment, useRef } from 'react'
import styled from 'styled-components'
import { Select, Button, Dropdown, Menu } from 'antd'
import {
  DownOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { ChartListItem, PostType } from '../types'
import { ChartCards } from './chart-cards'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { useQuery } from 'react-query'
import { fetchChartsForSearching, getReviewsCount } from '../model'
import { secondaryNavy } from '../../../../../assets/styles/variables'
import { useClickOutside } from '../../../../../shared/hooks'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { ChartSettings } from './ChartSettings'

type Props = {
  resizeRef: (instance: HTMLDivElement | null) => void
  headerHeight?: number
  // dashboardData: any
}

export const fontSizeOptions = [
  { label: 'Small Font', value: 'small' },
  { label: 'Medium Font', value: 'medium' },
  { label: 'Large Font', value: 'large' },
  { label: 'Extra Large Font', value: 'extra_large' },
]

export const postTypeOptions = [
  { label: 'Reviews', value: 'post' as PostType },
  { label: 'Sentences', value: 'sentence' as PostType },
]

export const Header = ({ resizeRef, headerHeight }: Props) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls
  )
  const chartData = useProjectStore((state: ProjectState) => state.chartData)

  const dashboardControls = useProjectStore(
    (state: ProjectState) => state.dashboardControls[0]
  )

  const { tier1 } = dashboardControls

  const [goToChartValue, setGoToChartValue] = useState<string | undefined>(
    undefined
  )
  const [showJump, setShowJump] = useState<boolean>(false)

  const [dashboardData, setDashboardData] = useState(chartData[0])
  const [data, setData] = useState(chartData[0]?.charts[0])

  useEffect(() => {
    if (chartData.length) {
      setDashboardData(chartData[0])
      if (chartData[0]?.charts.length) {
        setData(chartData[0].charts[0])
      }
    }
  }, [chartData])

  const { data: chartList } = useQuery(
    ['dashboard-new-charts-search', projectId],
    () => fetchChartsForSearching(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!projectId,
    }
  )

  useEffect(() => {
    // @ts-ignore
    if (!data?.tier3?.value?.explicit) {
      setGoToChartValue(undefined)
    }
    // @ts-ignore
  }, [data?.tier3?.value])

  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, () => {
    setShowJump(false)
  })

  const handleDashboardControls = (key: string, value: any) => {
    updateDashboardControls(0, key, value)
  }

  // if (!chartList || !reviewsCountData || !dashboardData)
  //   return <Wrapper ref={resizeRef} />

  const contexts = (
    <Menu
      style={{
        maxHeight: '320px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '1px solid rgba(0,0,0,0.16)',
        marginTop: '-2px',
      }}
      onClick={(data) => {
        mixpanel.track('chart lens', { value: data?.key.toString() })
        updateDashboardControls(0, 'tier1', data?.key.toString())
      }}
      selectedKeys={tier1 ? [tier1] : []}
    >
      {dashboardData?.tier1.options
        .slice(0, -1)
        .map((option: any, index: any) => {
          const isBordered = dashboardData.tier1.options
            .find((el: any) => el.name === 'bar')
            ?.value?.includes(index)
          return (
            <Fragment key={option.name}>
              <Menu.Item
                className={option.name === 'Product' ? 'product_item' : ''}
                key={option.name}
                style={{
                  color: 'black',
                }}
              >
                {option.name}
              </Menu.Item>
              {isBordered && (
                <Menu.Item
                  disabled
                  key={'bar' + option.name}
                  style={{
                    color: 'black',
                    border: '0.5px solid rgba(0,0,0,0.16)',
                    padding: 0,
                    height: '1px',
                    margin: '4px',
                    cursor: 'unset',
                  }}
                ></Menu.Item>
              )}
            </Fragment>
          )
        })}
    </Menu>
  )

  return (
    <Wrapper ref={resizeRef}>
      <Dropdown overlay={contexts} trigger={['click']}>
        <Left>
          <LensText>
            Breakdown by:
            <div
              style={{
                color: secondaryNavy,
                height: '20px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 150,
              }}
            >
              {tier1 ?? <LoadingOutlined />}
            </div>
          </LensText>
          <DownOutlined />
        </Left>
      </Dropdown>
      <ChartCards headerHeight={headerHeight} data={dashboardData} />
      <Right>
        <span ref={ref}>
          <ChartSelect
            value={goToChartValue}
            showSearch
            focused={showJump}
            onMouseEnter={() => {
              setShowJump(true)
            }}
            onChange={(value: any) => {
              const item: (string | null)[] = value.toString().split('/')
              item[1] = item[1] === 'null' ? null : item[1]
              item[2] = item[2] === 'null' ? null : item[2]
              const foundChart = chartList?.chart_ids?.find(
                (chart: ChartListItem) => {
                  return (
                    chart.chart_id === item[0] &&
                    chart.field === item[1] &&
                    chart.model === item[2]
                  )
                }
              )

              mixpanel.track('chart search', { value: foundChart?.chart_id })
              const tier1 = foundChart?.field as string
              updateDashboardControls(0, 'tier1', tier1)
              updateDashboardControls(0, 'chartId', item[0] as string)
            }}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={
              showJump ? (
                <>
                  <SearchOutlined />
                  &nbsp;
                  {'Jump to a chart'}
                </>
              ) : (
                <SearchOutlined />
              )
            }
          >
            {chartList?.chart_ids?.map((chart: any, index: any) => (
              <Select.Option
                value={chart.chart_id + '/' + chart.field + '/' + chart.model}
                key={chart.chart_id + chart.field + chart.model}
              >
                {chart.display_text}
              </Select.Option>
            ))}
          </ChartSelect>
          <ChartSettings
            dashboardData={dashboardData}
            dashboardControls={dashboardControls}
            updateDashboardControls={handleDashboardControls}
          />
        </span>
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: white;
  position: relative;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.1);
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 20px;
  background: var(--page-background-grey);
  z-index: 10;
  gap: 20px;
  cursor: pointer;
  border-right: 2px solid rgba(159, 183, 195, 0.15);

  background: linear-gradient(#f4f5ff, #f4f5ff) padding-box,
    linear-gradient(180deg, #3378ea 0%, #5933ab 100%) border-box;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 2px solid transparent;
  border-left: 0px;

  &:hover {
    background: linear-gradient(#e3e5ff, #f4f5ff) padding-box,
      linear-gradient(180deg, #3378ea 0%, #5933ab 100%) border-box;
  }

  transition: background 1s ease-in-out;

  svg {
    width: 12px;
    height: 12px;
  }
`
const LensText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: 500;
`

const Right = styled.div`
  height: 100%;
  background: var(--page-background-grey);
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-left: 2px solid rgba(159, 183, 195, 0.15);
  position: absolute;
  right: 0px;
  border-top: 2px solid rgba(159, 183, 195, 0.15);
  border-bottom: 2px solid rgba(159, 183, 195, 0.15);
`

const ChartSelect = styled(Select)<{ focused: boolean }>`
  margin-right: 10px;
  transition: 0.2s ease-in-out;
  width: ${(props) => (props.focused ? '250px!important' : '60px')};
`
