import React from 'react'
import styled from 'styled-components'
import { Node } from '../types'

type Props = {
  nodes: Node[]
}

export const CommentList: React.FC<Props> = ({ nodes }) => {
  return (
    <Wrapper>
      {nodes.map((node: Node) => (
        <Item key={node.uuid}>{node.text}</Item>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 20px;
`
const Item = styled.div`
  border-bottom: 1px solid var(--color-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0;
`
