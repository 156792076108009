import { AxiosResponse } from 'axios'
import { StarredPosts } from './types'
import { axiosInstance } from 'utils/axios'
import {
  CLUSTER_ASSIGN,
  CLUSTER_CUSTOM,
  STARRED_POSTS_URL,
} from 'utils/apiLinks'

/**
 * Fetches list of favorite nodes
 * @param {string} proj_uuid - The project UUID
 * @returns {Promise<StarredPosts>} List of favorite nodes
 */
export const getStarredPosts = (
  proj_uuid: string
): Promise<AxiosResponse<StarredPosts>> =>
  axiosInstance.get(STARRED_POSTS_URL, { params: { proj_uuid } })

export const assignClusters = (
  post_uuids: string[],
  cluster_uuid: string | null,
  project_uuid?: string,
  fans_critics_mode: boolean = false
): Promise<AxiosResponse<void>> =>
  axiosInstance.post(CLUSTER_ASSIGN, { cluster_uuid, post_uuids, fans_critics_mode, project_uuid})

export const customCluster = (
  post_uuids: string[],
  theme: string,
  cluster_event_uuid: string,
  color?: string
): Promise<AxiosResponse<void>> =>
  axiosInstance.post(CLUSTER_CUSTOM, {
    post_uuids,
    theme,
    color,
    cluster_event_uuid,
  })
