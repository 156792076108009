import { Divider, Slider } from '@mui/material'
import React from 'react'

type Props = {
  temperature: number
  setTemperature: (temperature: number) => void
}

const Temperature: React.FC<Props> = ({ temperature, setTemperature }) => {
  return (
    <div style={{ maxWidth: 250, minWidth: 250, marginRight: 20, flexGrow: 0 }}>
      <div style={{ fontSize: 16, fontWeight: 500 }}>
        Temperature of PDP Changes:
      </div>
      <div>
        <Slider
          aria-label="Temperature"
          defaultValue={1}
          valueLabelDisplay="auto"
          step={1}
          marks={[
            {
              value: 1,
              label: 'Low',
            },
            {
              value: 2,
              label: 'Medium',
            },
            {
              value: 3,
              label: 'High',
            },
          ]}
          min={1}
          max={3}
          value={temperature}
          // @ts-ignore
          onChange={(e) => setTemperature(e.target?.value ?? 1)}
          style={{ maxWidth: 400, marginLeft: 10 }}
        />
      </div>
      <Divider orientation={'horizontal'} style={{ margin: '10px 0px' }} />
      <p>
        <span style={{ fontWeight: 500 }}>Low</span>: Introduces select keywords
        into existing copy
      </p>
      <p>
        <span style={{ fontWeight: 500 }}>Medium</span>: Adds/removes feature
        bullets based on Yogi Theme data
      </p>
      <p>
        <span style={{ fontWeight: 500 }}>High</span>: Rewrites entire copy
        based on Yogi Theme data
      </p>
    </div>
  )
}

export default Temperature
