import React, { useState } from 'react'
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { YogiButton } from '../../../../components/UI/YogiButton'
import { Summary } from '../summary'
import { initialState, useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { lightText } from '../../../../assets/styles/variables'
import useResizeObserver from 'use-resize-observer'
import FocusDetailContent from './FocusDetailContent'
import { DatePanel } from '../filters/features/date-panel/date-panel'
import { FocusListOption } from './FocusList'
import { emptyFilters } from '../../utils'
import { Tooltip } from 'antd'
import { tooltip } from '../../../../utils/tooltip-data'
import { useHistory } from 'react-router'

const pluralize = require('pluralize')

interface FocusDetailProps {
  lens: string
  item: FocusListOption
  setValue: (value: string | null) => void
}

const FocusDetail: React.FC<FocusDetailProps> = ({ lens, item, setValue }) => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const [activeTab, setActiveTab] = useState('0')
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>()
  const history = useHistory()

  const [localFilters, setLocalFilters] = useState<Filters>(
    initialState.filters[0]
  )

  const alias = defaultFilterList?.find((item) => item.field === lens)?.alias

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Header ref={ref}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <YogiButton
            type={'ghost'}
            onClick={() => {
              setValue(null)

              const urlParams = new URLSearchParams(window.location.search)
              if (urlParams.get('subtheme')) {
                urlParams.delete('subtheme')
                history.push(
                  `${window.location.pathname}?${urlParams.toString()}`
                )
              }
            }}
            icon={<LeftOutlined />}
            style={{
              border: 'none',
              boxShadow: 'none',
              color: lightText,
              paddingLeft: 0,
            }}
          >
            Back to {!!alias ? `all ${pluralize(alias)}` : 'Catalog'}
          </YogiButton>
          <div>
            <DatePanel
              comparativeIndex={0}
              filterList={defaultFilterList}
              filterValues={localFilters}
              updateLocalFilterValues={setLocalFilters}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <h1 style={{ marginBottom: 10 }}>{item.value}</h1>
          <Tooltip
            title={
              'Catalog Data is only filtered to selected ' +
              alias +
              ' and Date Range'
            }
            placement="right"
          >
            <InfoCircleOutlined
              style={{ fontSize: '20px', marginBottom: 10, cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      </Header>
      <Content headerHeight={height}>
        <FocusDetailContent
          item={item}
          isComparative={false}
          lens={lens}
          activeTab={activeTab}
          headerHeight={height}
          filterValues={localFilters}
        />
      </Content>
      <Summary />
    </div>
  )
}

const Header = styled.div`
  background: white;
  padding: var(--default-padding-quarter) var(--default-padding) 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`
const Content = styled.div<{ headerHeight?: number }>`
  height: calc(100% - ${(props) => props.headerHeight}px);
  overflow: auto;
  display: flex;
`

export default FocusDetail
