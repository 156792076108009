import React, { useState, useEffect } from 'react'
import { InnerTitle } from '../../../../../components/InnerTitle'
import { FileSearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { CHARTS_URL } from '../../../utils'
import mixpanel from '../../../../trackers/mixpanel'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { OpenInNewOutlined } from '@mui/icons-material'
import { Filters, ProjectState } from '../../../types'
import { useQuery } from 'react-query'
import { getBookmarkUrlPost } from '../../../../url-params/model'
import { useProjectStore } from '../../../projectStore/projectStore'

type Props = {
  title: string
  filters: Filters
  dashboardControls: any
  page: string // page url path
}

const NextStepsButton: React.FC<Props> = ({
  title,
  filters,
  dashboardControls,
  page,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const { data: linkData } = useQuery(
    ['getFilterHash', title, projectId, filters, dashboardControls],
    () =>
      getBookmarkUrlPost(projectId, {
        page: page,
        filterString: JSON.stringify({
          filters: [filters],
          pageControls: [dashboardControls],
        }),
      }),
    {
      enabled: !!projectId,
    }
  )

  return (
    <>
      {linkData ? (
        <Link
          target={'_blank'}
          to={`${page}?filters=${linkData}`}
          onClick={() => {
            mixpanel.track('catalog', {
              action: 'open chart link',
              type: 'next steps',
              page: page,
              value: dashboardControls.chartId,
            })
          }}
          style={{ textDecoration: 'none' }}
        >
          <YogiButton
            type={'ghost'}
            style={{ display: 'flex', alignItems: 'center', gap: 5 }}
          >
            {title}
            <OpenInNewOutlined fontSize={'inherit'} />
          </YogiButton>
        </Link>
      ) : (
        <YogiButton
          type={'ghost'}
          style={{ display: 'flex', alignItems: 'center', gap: 5 }}
        >
          {title}
          <OpenInNewOutlined fontSize={'inherit'} />
        </YogiButton>
      )}
    </>
  )
}
export default NextStepsButton
