import React, { useState } from 'react'
import { Button, message } from 'antd'
import { CheckOutlined, CopyOutlined, LoadingOutlined } from '@ant-design/icons'
import './CopyButton.css'
import styled from 'styled-components'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import mixpanel from '../../../../trackers/mixpanel'
import { toast } from 'react-toastify' // Make sure to create a CSS file for styling

interface CopyButtonProps {
  textToCopy: string
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy)
      toast.success('Copied to clipboard!')
    } catch (err) {
      toast.error('Failed to copy!')
    }
  }

  const [isCopying, setIsCopying] = useState(false)
  const [showCheck, setShowCheck] = useState(false)

  return (
    <Copy
      data-html2canvas-ignore={'true'}
      onClick={copyToClipboard}
      className="copyButton"
    >
      {/*TODO show spinner when copying, and disable. also - use yogibutton?*/}

      <YogiButton
        type={'primary'}
        style={{
          boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
          // background: 'rgba(255, 255, 255, 0.4)',
          // opacity: copyHovered ? '1' : '.4',
          padding: '4px 8px',
        }}
      >
        {isCopying ? (
          <LoadingOutlined />
        ) : showCheck ? (
          <CheckOutlined />
        ) : (
          <CopyOutlined />
        )}
      </YogiButton>
    </Copy>
  )
}

export default CopyButton

export const Copy = styled.div`
  position: absolute;
  //top: 4px;
  right: 0px;
  z-index: 100;
  background: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`
