import React, { useState, useEffect } from 'react'
import { Modal, Select } from 'antd'
import { useQuery } from 'react-query'
import { Cluster } from '../features/cluster/types'
import { fetchThemes } from '../features/cluster/model'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'

type Props = {
  onCreate: (s: string) => void
  onClose: () => void
  isVisible: boolean
}

const { Option } = Select

export const AssignCluster: React.FC<Props> = ({
  isVisible,
  onCreate,
  onClose: onDefaultClose,
}) => {
  const [isAvailableForFetching, setAvailableForFetching] = useState(true)
  const [selectedCluster, setSelectedCluster] = useState<string | undefined>(
    undefined
  )
  const [id, setId] = useState<string | null>(null)
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const { data, isLoading, refetch } = useQuery(
    ['themes'],
    () => fetchThemes(projectId as string),
    { cacheTime: 0, enabled: false, refetchOnWindowFocus: false }
  )

  const onClose = () => {
    onDefaultClose()
    setAvailableForFetching(true)
  }

  useEffect(() => {
    if (isVisible && isAvailableForFetching) {
      refetch()
      setAvailableForFetching(false)
    }
  }, [isVisible])

  if (isLoading) {
    return null
  }
  const clusters = data?.data.values || []
  const filteredClusters = [
    {
      cluster_uuid: '',
      color: '',
      value: 'Simple Feedback: Fans or Critics',
      nodes: [],
      visible: true,
      percent: 0,
    },
    ...clusters.filter(
      ({ group_id, is_assignable }) =>
        group_id === undefined && is_assignable === true
    ),
  ]

  return (
    <Modal
      visible={isVisible}
      title="Assign to Theme"
      onOk={() => {
        onCreate(id ? id : '')
        onClose()
        // if (id) {
        //   onCreate(id ? id : '')
        //   onClose()
        // }
      }}
      onCancel={onClose}
      okText="Assign"
    >
      <Select
        defaultValue="Select a theme"
        style={{ width: '100%' }}
        value={selectedCluster}
        onChange={(v) => {
          setSelectedCluster(v as string)
          setId(v as string)
        }}
      >
        {filteredClusters.map((cluster: Cluster) => (
          <Option key={cluster.cluster_uuid} value={cluster.cluster_uuid}>
            {cluster.value}
          </Option>
        ))}
      </Select>
    </Modal>
  )
}
