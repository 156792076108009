import { Checkbox, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BarChart, ChartLegend as ChartLegendType, UnionChart } from '../types'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import _ from 'lodash'
import mixpanel from '../../../../trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import YogiDivider from '../../../../../components/UI/YogiDivider'
import { ChartTypes } from '../utils'

type ChartLegendProps = {
  legend: Array<ChartLegendType>
  setHighlightedLegendItems?: (v: any) => void
  disabledLegendItems?: string[]
  setDisabledLegendItems?: (v: string[]) => void
  change?: (items: Array<ChartLegendType>) => void
  isHorizontal?: boolean
  expandedCategories: string[]
  currentChart: UnionChart
  updateDashboardControls: (field: string, value: any) => void
  tier1: string
}

export const ChartLegend: React.FC<ChartLegendProps> = React.memo(
  ({
    legend,
    change,
    isHorizontal,
    setHighlightedLegendItems,
    disabledLegendItems,
    setDisabledLegendItems,
    expandedCategories,
    currentChart,
    updateDashboardControls,
    tier1,
  }) => {
    const route = useProjectStore((state: ProjectState) => state.route)

    const isExpandCategoryType =
      currentChart.chart_type === ChartTypes.BAR ||
      currentChart.chart_type === ChartTypes.STACK_BAR ||
      currentChart.chart_type === ChartTypes.MATRIX

    const blockedValues = ['Fans / Attractors', 'Critics / Detractors']

    const onChange = (changedItem: ChartLegendType) => {
      // if (change) {
      //   change(
      //     legend.map((item) => {
      //       return item.label === changedItem.label
      //         ? { ...item, disabled: !item.disabled }
      //         : item
      //     })
      //   )
      // }
      if (setDisabledLegendItems) {
        setDisabledLegendItems(
          disabledLegendItems?.includes(changedItem.label)
            ? [...disabledLegendItems?.filter((i) => i !== changedItem.label)]
            : [...(disabledLegendItems || []), changedItem.label]
        )
      }
    }

    const [highlightData, setHighlightData] = useState<ChartLegendType[]>([])

    const addSelected = (item: ChartLegendType) => {
      const match = highlightData.find((el) => el.label === item.label)
      if (!match) {
        setHighlightData([...highlightData, item])
      } else if (!match.selected) {
        setHighlightData(highlightData.filter((i) => i.label !== item.label))
      }
    }
    const removeSelected = (item: ChartLegendType) => {
      const match = highlightData.find((el) => el.label === item.label)
      if (item.selected) {
        return
      }
      if (match) {
        setHighlightData(highlightData.filter((i) => i.label !== item.label))
      }
    }

    useEffect(() => {
      if (setHighlightedLegendItems) {
        setHighlightedLegendItems(highlightData)
      }
    }, [highlightData.length])

    const parentsOnly = legend.filter((el) => el.children)

    // its fine
    const legendObj: any = {}
    parentsOnly.forEach((el) => {
      legendObj[el.label] = legend
        .filter(
          (item) =>
            //@ts-ignore
            currentChart.category_mapping?.[el.label]?.includes(item.label) ||
            item.label === el.label ||
            // item.label.split('Volume')[0].trim() === el.label ||
            //@ts-ignore
            (currentChart.category_mapping?.[el.label]?.includes(
              item.label.split('Volume')[0].trim()
            ) &&
              item.label.includes('Volume'))
        )
        .sort((a, b) => (expandedCategories.includes(a.label) ? -1 : 1))
        .concat(legend.filter((item) => item.label === el.label + ' Volume'))

      // .sort((a, b) => (a.label === el.label + ' Volume' ? -1 : 1))
      // legendObj[el.label + ' Volume'] = legend.filter(
      //   (item) =>
      //     //@ts-ignore
      //     currentChart.category_mapping[el.label]?.includes(
      //       item.label.split('Volume')[0].trim()
      //     ) && item.label.includes('Volume')
      // )
    })

    let resortedLegend: any[] = legend
    if (expandedCategories?.length && parentsOnly?.length) {
      resortedLegend = []
      Object.keys(legendObj).forEach((key) => {
        resortedLegend = resortedLegend.concat(legendObj[key])
      })
    }

    // Add top-level volumes back in at the bottom when items are expanded
    // if (expandedCategories.length) {
    //   const parentVolumeLabels = parentsOnly.map(
    //     (parent) => parent.label + ' Volume'
    //   )
    //   parentVolumeLabels.forEach((label) => {
    //     const foundItem = legend.find((el) => el.label === label)
    //     if (foundItem) {
    //       resortedLegend.push(foundItem)
    //     }
    //   })
    // }

    let expandableCategories: string[] = []
    if ((currentChart as BarChart).category_mapping) {
      Object.keys((currentChart as BarChart).category_mapping).forEach(
        (key) => {
          if (
            (((currentChart as BarChart).category_mapping[key].length > 0 &&
              tier1 !== 'Theme') ||
              ((currentChart as BarChart).category_mapping[key].length > 1 &&
                tier1 === 'Theme')) &&
            key !== 'Fans / Attractors' &&
            key !== 'Critics / Detractors'
            // !expandedCategories.includes(key)
          ) {
            if (currentChart.chart_type === ChartTypes.MATRIX) {
              expandableCategories.push(key.split(' - ')[0].trim())
            } else {
              expandableCategories.push(key)
            }
          }
        }
      )
    }
    expandableCategories = _.uniq(expandableCategories)

    return (
      <Wrapper>
        {resortedLegend.map((item, index) => (
          <Tooltip
            title={
              !blockedValues.includes(item.label) && item.children?.length > 1
                ? item.children?.map(({ label }: any) => (
                    <div>- {label.split(':')[1]?.trim() ?? label}</div>
                  ))
                : ''
            }
            placement={'left'}
          >
            <ItemWrapper
              key={index}
              onClick={() => onChange(item)}
              onMouseOver={() => {
                if (!item.selected) {
                  item.hovered = true
                  addSelected(item)
                }
              }}
              onMouseOut={() => {
                if (!item.selected) {
                  item.hovered = false
                  removeSelected(item)
                }
              }}
              style={{
                width: !isHorizontal ? '100%' : '',
                paddingLeft:
                  !isExpandCategoryType &&
                  // @ts-ignore
                  currentChart.category_mapping &&
                  // @ts-ignore
                  !currentChart.category_mapping[item.label] &&
                  // @ts-ignore
                  !currentChart.category_mapping[
                    item.label.split('Volume')[0].trim()
                  ]
                    ? '15px'
                    : '',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '5px',
                  gap: 5,
                }}
              >
                <StyledCheckbox
                  color={item.color}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  onChange={(e) => {
                    item.hovered = e.target.checked
                    item.selected = e.target.checked
                    addSelected(item)
                  }}
                />
                <Text
                  style={{
                    textDecoration: item.disabled ? 'line-through' : 'none',
                    fontWeight: item.hovered ? 'bold' : 'normal',
                  }}
                >
                  {item.label}
                </Text>
              </div>
              {!blockedValues.includes(item.label) &&
                item.children &&
                ((item.children.length > 0 && tier1 !== 'Theme') ||
                  (item.children.length > 1 && tier1 === 'Theme')) && (
                  <div>
                    <Caret
                      onClick={(e) => {
                        updateDashboardControls(
                          'expandedCategories',
                          expandedCategories?.includes(item.label)
                            ? expandedCategories?.filter(
                                (el) => el !== item.label
                              )
                            : [...(expandedCategories ?? []), item.label]
                        )
                        // TODO fix this
                        setTimeout(() => {
                          setHighlightData([])
                        }, 10)
                        // setHighlightData([])
                        // e.stopPropagation()
                        // e.preventDefault()
                      }}
                    >
                      {expandedCategories?.includes(item.label) ? (
                        <CaretUpFilled />
                      ) : (
                        <CaretDownFilled />
                      )}
                    </Caret>
                  </div>
                )}
              {/*{expandedCategories.includes(item.label) && (*/}
              {/*  <ChildWrapper>*/}
              {/*    {item.children?.map((item, index) => (*/}
              {/*      <ItemWrapper*/}
              {/*        key={index}*/}
              {/*        onClick={() => onChange(item)}*/}
              {/*        onMouseOver={() => {*/}
              {/*          if (!item.selected) {*/}
              {/*            item.hovered = true*/}
              {/*            addSelected(item)*/}
              {/*          }*/}
              {/*        }}*/}
              {/*        onMouseOut={() => {*/}
              {/*          if (!item.selected) {*/}
              {/*            item.hovered = false*/}
              {/*            removeSelected(item)*/}
              {/*          }*/}
              {/*        }}*/}
              {/*        style={{*/}
              {/*          width: !isHorizontal ? '100%' : '',*/}
              {/*          marginLeft:*/}
              {/*            expandedCategories.includes(*/}
              {/*              item.label.split(': ')[0]*/}
              {/*            ) && item.label.split(': ').length > 1*/}
              {/*              ? '15px'*/}
              {/*              : '',*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <div*/}
              {/*          style={{*/}
              {/*            display: 'flex',*/}
              {/*            flexDirection: 'column',*/}
              {/*            alignItems: 'center',*/}
              {/*            marginRight: '5px',*/}
              {/*            // gap: 5,*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          /!*<Color style={{ backgroundColor: item.color }} />*!/*/}
              {/*          <StyledCheckbox*/}
              {/*            color={item.color}*/}
              {/*            onClick={(e) => {*/}
              {/*              e.stopPropagation()*/}
              {/*            }}*/}
              {/*            onChange={(e) => {*/}
              {/*              item.hovered = e.target.checked*/}
              {/*              item.selected = e.target.checked*/}
              {/*              addSelected(item)*/}
              {/*            }}*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*        <Text*/}
              {/*          style={{*/}
              {/*            textDecoration: item.disabled ? 'line-through' : 'none',*/}
              {/*            fontWeight: item.hovered ? 'bold' : 'normal',*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          {item.label}*/}
              {/*        </Text>*/}
              {/*      </ItemWrapper>*/}
              {/*    ))}*/}
              {/*  </ChildWrapper>*/}
              {/*)}*/}
            </ItemWrapper>
          </Tooltip>
        ))}
        {isExpandCategoryType && !!expandableCategories.length && (
          <Content>
            <YogiDivider style={{ marginBottom: 5 }} />
            <ReviewsControlTitle>
              Expand {(currentChart as BarChart).x_title} Groups:
            </ReviewsControlTitle>
            <Checkbox
              checked={
                expandedCategories?.length === expandableCategories.length
              }
              indeterminate={
                expandedCategories?.length < expandableCategories.length &&
                expandedCategories?.length > 0
              }
              onChange={(event) => {
                mixpanel.track('chart config', {
                  action: 'openCategory',
                  value: event.target.checked,
                  ...route,
                })
                if (event.target.checked) {
                  updateDashboardControls(
                    'expandedCategories',
                    expandableCategories
                  )
                } else {
                  updateDashboardControls('expandedCategories', [])
                }
              }}
            >
              Select All
            </Checkbox>
            <YogiDivider />
            {expandableCategories.sort().map((item: any) => (
              <Tooltip
                title={(currentChart as BarChart).category_mapping[item]?.map(
                  (el) => (
                    <div>- {el.split(':')[1]?.trim() ?? el}</div>
                  )
                )}
                placement={'left'}
              >
                <Checkbox
                  checked={expandedCategories?.includes(item)}
                  onChange={(event) => {
                    mixpanel.track('chart config', {
                      action: 'openCategory',
                      value: event.target.checked,
                      ...route,
                    })
                    updateDashboardControls(
                      'expandedCategories',
                      expandedCategories?.includes(item)
                        ? expandedCategories?.filter((i: any) => i !== item)
                        : [...(expandedCategories || []), item]
                    )
                  }}
                >
                  {item}
                </Checkbox>
              </Tooltip>
            ))}
          </Content>
        )}
      </Wrapper>
    )
  }
)

ChartLegend.displayName = 'ChartLegend'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  height: fit-content;
  width: fit-content;
  max-width: fit-content;
  max-width: 100%;
`
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  //margin-right: 10px;
  cursor: pointer;
  margin-bottom: 8px;
`
const Color = styled.div`
  //min-width: 20px;
  width: 15px;
  height: 15px;
`

const Text = styled.div``

const StyledCheckbox = styled(Checkbox)<{ color: string }>`
  .ant-checkbox-inner {
    background-color: ${(props) => props.color};
  }
`

const Caret = styled.div`
  display: flex;
  padding-right: 2px;
  cursor: pointer;
`
const ChildWrapper = styled.div`
  width: 100%;
  margin-left: 15px;
`
const ReviewsControlTitle = styled.div`
  margin-bottom: 6px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-select {
    .ant-select-selector {
      height: 40px;
      padding: 5px 10px;
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`
