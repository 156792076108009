import React from 'react'
import styled from 'styled-components'
import { Header as HeaderComponent } from 'shared/components'
import { Tooltip } from 'antd'
import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'

export const Header = () => {
  // const { details, route } = useProjectContext()
  const route = useProjectStore((state: ProjectState) => state.route)
  const details = useProjectStore((state: ProjectState) => state.details)
  // const { details, route } = useProjectContext()
  const settings = details?.clientSettings?.project?.pages

  if (!details) return null
  return (
    <Wrapper>
      <HeaderComponent>
        <ProjectInfo>
          <Title>
            {(details.title_override ?? details.title) || 'Loading...'}
          </Title>
          <div style={{ marginBottom: '-2px' }}>
            {route.isFeedback && (
              <>
                <Tooltip
                  title={`Use the ${settings?.feedback?.alias} Page to view individual reviews from your data sources.`}
                >
                  {settings?.feedback?.alias}
                </Tooltip>
              </>
            )}
            {route.isNetwork && (
              <>
                <Tooltip
                  title={`Use the ${settings?.network?.alias} Page to easily understand the thematic landscape of 
                customer feedback at the sentence level. This is an easy way to visualize
                patterns across thousands of datapoints and to dive deeper into interesting sections.`}
                >
                  {settings?.network?.alias}
                </Tooltip>
              </>
            )}
            {route.isCharts && (
              <>
                <Tooltip
                  title={`Use the ${settings?.dashboard?.alias} Page to obtain a high-level understanding of your data, 
                track trends, find outliers, and answer questions.`}
                >
                  {settings?.dashboard?.alias}
                </Tooltip>
              </>
            )}
            {route.isKeywords && (
              <>
                <Tooltip
                  title={`Use the ${settings?.keywords?.alias} Page to see how certain words and phrases are 
                used in the reviews. Click on a bar to view the reviews.`}
                >
                  {settings?.keywords?.alias}
                </Tooltip>
              </>
            )}
            {route.isSource && (
              <>
                <Tooltip
                  title={`Use the ${settings?.sources?.alias} Page to see the organization and 
                metadata of the data sources for organic and syndicated reviews.`}
                >
                  {settings?.sources?.alias}
                </Tooltip>
              </>
            )}
            {route.isProduct && (
              <>
                <Tooltip
                  title={`Use the ${settings?.products?.alias} Page to see the organization 
                and metadata of the products and items or variants.`}
                >
                  {settings?.products?.alias}
                </Tooltip>
              </>
            )}
            {route.isSettings && (
              <>
                <Tooltip
                  title={`Use the ${settings?.recommendations?.alias} Page to view general information of the project.`}
                >
                  {settings?.recommendations?.alias}
                </Tooltip>
              </>
            )}
          </div>
        </ProjectInfo>
      </HeaderComponent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  box-shadow: var(--header-box-shadow);
  position: relative;
`
const ProjectInfo = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-text-black);
`
const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-blue);
  position: relative;
  margin-bottom: -2px;
  padding-right: var(--default-padding-half);
  margin-right: var(--default-padding-half);

  &:after {
    height: 100%;
    content: ' ';
    font-weight: 300;
    position: absolute;
    border-right: 1px solid var(--color-grey);
    right: 0;
    top: 0;
  }
`
