import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Form, Input, InputNumber, Select, Tooltip } from 'antd'
import {
  CopyOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import { toast } from 'react-toastify'
import { HeaderFilterPanel } from 'features/project/features/filters'
import {
  downloadKeywords,
  downloadPosts,
} from 'features/project/features/feedback/model'
import mixpanel from 'features/trackers/mixpanel'
import { Loader } from 'shared/components'
import { PhraseList } from './phrase-list'
import { Chart } from './components/chart'
import { searchKeywords } from './model'
import type { WindowSize } from './types'
import useResizeObserver from 'use-resize-observer'
import { isInitialFlow } from 'features/home/utils'
import _ from 'lodash'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useQuery } from 'react-query'
import { useGlobalFilterList } from '../../hooks/useGlobalFilterList'
import { YogiButton } from '../../../../components/UI/YogiButton'
import { Fetcher } from '../../../../shared/components/fetcher'
import { cardBorderGrey, lightText } from '../../../../assets/styles/variables'
import Divider from '../../../../components/UI/YogiDivider'
import html2canvas from 'html2canvas'
import logo from '../../../../assets/images/logos/Peach_Horizontal.png'
import { tooltip } from '../../../../utils/tooltip-data'
import AIcon from '../../../../assets/images/icons/AIcon_180x180.png'

type FormikValues = {
  windowSize: WindowSize
  searchPhrase: string
  stopWords: string[]
  currentPhrase: string
}

const MAX_RESULTS = 50
const validationSchema = Yup.object().shape({
  windowSize: Yup.number().min(0).max(4),
  searchPhrase: Yup.string(),
})

type Props = { comparativeIndex: number }

const KeywordsContent: React.FC<Props> = ({ comparativeIndex }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const filterList = useProjectStore(
    (state: ProjectState) => state.filterList[comparativeIndex]
  )
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )
  const setFilterListByIndex = useProjectStore(
    (state: ProjectState) => state.setFilterListByIndex
  )
  const keywordsControls = useProjectStore(
    (state: ProjectState) => state.keywordsControls
  )
  const updateKeywordsControls = useProjectStore(
    (state: ProjectState) => state.updateKeywordsControls
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen
  )
  const setSummaryRequest = useProjectStore(
    (state: ProjectState) => state.setSummaryRequest
  )

  const wrapperRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { searchTerm, windowSize, stopWords, currentPhrase } =
    keywordsControls[comparativeIndex]
  const [selectedPhrase, setSelectedPhrase] = useState<null | string>(null)
  const [showHighlights, setShowHighlights] = useState(true)
  const [sortBySentiment, setSortBySentiment] = useState<boolean>(false)

  const [loading, setLoading] = useState(true)

  const initialValues: FormikValues = {
    windowSize: windowSize ?? 1,
    searchPhrase: searchTerm ?? '',
    stopWords: stopWords ?? [],
    currentPhrase: currentPhrase ?? '',
  }

  const [downloadLoading, setDownloadLoading] = useState(false)
  const isPublicViewer = details.role === 'public_viewer'

  const { data: searchKeywordsResults, isFetching } = useQuery(
    [
      `keywords-${comparativeIndex || 0}`,
      projectId,
      filterValues.values,
      filterValues.searchQuery,
      filterValues.searchCondition,
      searchTerm,
      windowSize,
      stopWords,
    ],
    async () => {
      const { data } = await searchKeywords(
        projectId,
        {
          search_term: searchTerm || '',
          window_size: windowSize,
          max_results: MAX_RESULTS,
        },
        {
          criteria: filterValues.values,
          search_terms: [],
          search_criteria: [],
          stop_words: stopWords as string[],
        }
      )
      return data
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    }
  )

  useEffect(() => {
    if (searchKeywordsResults) {
      setSelectedPhrase(null)
    }
  }, [searchKeywordsResults])

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async ({ windowSize, searchPhrase, stopWords }) => {
      updateKeywordsControls(comparativeIndex, 'windowSize', windowSize)
      updateKeywordsControls(comparativeIndex, 'searchTerm', searchPhrase)
      updateKeywordsControls(comparativeIndex, 'stopWords', stopWords)
      return
    },
  })

  useEffect(() => {
    mixpanel.track('keywords page')
  }, [])

  useEffect(() => {
    formik.submitForm()
  }, [filterValues.values])

  const mixpanelTrack = useCallback(
    (event) => {
      mixpanel.track(event, {
        phrase: formik.values.searchPhrase,
        window: Number(formik.values.windowSize),
        stop_words: formik.values.stopWords.join(','),
      })
    },
    [formik.values]
  )

  // Whenever a filter is applied, opened options should be reset
  useEffect(() => {
    clearOpenedOptions(comparativeIndex)
  }, [filterValues])

  const submitSearchForm = useCallback(() => {
    // let phrase = formik.values.searchPhrase
    // console.log(phrase)
    // formik.setFieldValue('currentPhrase', phrase)
    //
    // mixpanelTrack('keyword search')
  }, [filterValues, formik.values])

  useEffect(() => {
    loadSearchParametersFromLocalViewFilters()
  }, [keywordsControls[comparativeIndex]])

  const loadSearchParametersFromLocalViewFilters = useCallback(() => {
    formik.setFieldValue(
      'searchPhrase',
      initialValues.searchPhrase
      // filterValues.searchQuery || initialValues.searchPhrase
    )
    formik.setFieldValue(
      'currentPhrase',
      //filterValues.searchQuery ||
      initialValues.searchPhrase
    )
    formik.setFieldValue(
      'windowSize',
      keywordsControls[comparativeIndex].windowSize || initialValues.windowSize
    )
    const stopWords =
      keywordsControls[comparativeIndex].stopWords || initialValues.stopWords
    formik.setFieldValue(
      'stopWords',
      (stopWords?.value?.length && stopWords) || initialValues.stopWords
    )
  }, [filterValues, keywordsControls[comparativeIndex]])

  const handleDownloadFiltered = async () => {
    if (!projectId || !selectedPhrase) return
    try {
      setDownloadLoading(true)
      await downloadPosts(projectId, {
        criteria: filterValues.values,
        search_terms: [selectedPhrase],
        search_criteria: [],
        post_text_table_search: true,
        search_by_keywords: formik.values.currentPhrase === '',
      })
      toast.success('Success')
      setDownloadLoading(false)
    } catch (e) {
      setDownloadLoading(false)
      toast.error((e as any).message)
    }
  }

  const download = async () => {
    if (!projectId) {
      toast.error('Undefined project')
      return
    }
    setDownloadLoading(true)
    mixpanelTrack('keywords download')
    await downloadKeywords(
      projectId,
      formik.values.currentPhrase,
      formik.values.windowSize,
      MAX_RESULTS,
      {
        criteria: filterValues.values,
        stop_words: formik.values.stopWords,
      }
    )
    toast.success('Success')
    setDownloadLoading(false)
  }

  // get filter list data
  const { data: filterListData } = useGlobalFilterList(comparativeIndex)

  const settings = (
    <div>
      {searchKeywordsResults && !isPublicViewer && selectedPhrase && (
        <YogiButton
          type={'ghost'}
          onClick={() => {
            mixpanel.track('posts download', {
              proj_uuid: projectId,
              action: 'all',
              type: 'keywords',
            })
            handleDownloadFiltered()
          }}
        >
          Download Posts
        </YogiButton>
      )}
      {!isPublicViewer && !selectedPhrase && (
        <YogiButton
          type={'ghost'}
          onClick={() => {
            mixpanel.track('keywords download', {
              proj_uuid: projectId,
              action: 'all',
            })
            download()
          }}
        >
          Download Keywords
        </YogiButton>
      )}
    </div>
  )

  // const onPrintChart = async (type: string) => {
  //   getReport({
  //     filter_context: {
  //       filterValues,
  //       comparativeIndex,
  //       search_phrase: formik.values.searchPhrase,
  //       windowSize: formik.values.windowSize,
  //       stop_words: formik.values.stopWords
  //     },
  //     comparative_context: {
  //       comparativePanelsNumber,
  //     },
  //     is_keywords: true,
  //     type,
  //   }, projectId)
  // }

  const onCopyKeywords = async () => {
    if (wrapperRef.current) {
      const parentElement = wrapperRef.current.parentElement
      if (parentElement) {
        setTimeout(async () => {
          // if (chartWrapperRef.current && wrapperRef.current) {
          if (canvasRef.current) {
            let canvas = await html2canvas(canvasRef.current)
            var tempCanvas = document.createElement('canvas')
            var tempCtx = tempCanvas.getContext('2d')
            const text = 'Yogi'

            if (tempCtx) {
              // console.log(tempCtx)
              let cw: number, ch: number
              cw = tempCanvas.width = canvas.width
              ch = tempCanvas.height = canvas.height
              tempCtx.drawImage(canvas, 0, 0)
              tempCtx.font = '24px verdana'
              let textWidth = tempCtx.measureText(text).width
              tempCtx.globalAlpha = 0.5

              // TODO enable logo - has new styling in it
              const logoImg = document.createElement('img')
              logoImg.height = 50
              logoImg.width = 50
              logoImg.style.position = 'absolute'
              logoImg.style.left = '50%'
              logoImg.style.top = '0px'
              // logoImg.style.opacity = '.5'

              logoImg.onload = function () {
                // tempCtx!.drawImage(logoImg, cw / 2 - 174, -10, 174, 75)

                tempCanvas.toBlob(function (blob) {
                  const item = new ClipboardItem({ 'image/png': blob ?? '' })
                  navigator.clipboard.write([item])
                })
              }
              logoImg.src = logo
            }
            toast.info('Chart copied to clipboard')
          }
        }, 10)
      }
    }
  }
  const onPrintChart = async () => {
    if (wrapperRef.current) {
      const parentElement = wrapperRef.current.parentElement
      if (parentElement) {
        setTimeout(async () => {
          if (canvasRef.current) {
            let canvas = await html2canvas(canvasRef.current)
            const link = document.createElement('a')
            link.href = canvas.toDataURL('image/jpg')
            link.download = 'keywords.png' ?? 'screenshot.jpg'
            link.click()
          }
        }, 10)
      }
    }
  }

  useEffect(() => {
    if (searchKeywordsResults && filterListData) {
      setLoading(false)
    }
  }, [searchKeywordsResults, filterListData])

  if (isInitialFlow(details?.state) || loading) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading ${details?.clientSettings.project.pages.keywords.alias} Page`}</LoaderTitle>
      </>
    )
  }

  return (
    <>
      <HeaderFilterPanel
        download={settings}
        filterValues={filterValues}
        comparativeIndex={comparativeIndex}
        // downloadSettings={{ loading: printLoading, onPrint: onPrintChart }}
      />
      <ContentWrapper>
        <WindowSizeControl
          onSubmit={(event) => {
            event.preventDefault()
            let phrase = formik.values.searchPhrase
            formik.setFieldValue('currentPhrase', phrase)
            formik.submitForm()
          }}
          style={{ display: 'flex', alignItems: 'flex-start' }}
        >
          <Controls>
            <PhraseWrapper>
              <Form.Item
                validateStatus={formik.errors.searchPhrase && 'error'}
                help={formik.errors.searchPhrase}
                style={{ margin: 0, width: '100%' }}
              >
                <StyledInput
                  name="searchPhrase"
                  value={formik.values.searchPhrase}
                  onChange={formik.handleChange}
                  placeholder="Phrase Search:"
                />
              </Form.Item>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ whiteSpace: 'nowrap', lineHeight: 1 }}>
                  Window Size:&nbsp;
                </div>
              </div>
              <StyledInputNumber
                min={1}
                max={4}
                disabled={!formik.values.searchPhrase}
                value={formik.values.windowSize}
                onChange={(n) =>
                  formik.setFieldValue('windowSize', n as WindowSize)
                }
              />
              <Tooltip
                title={'Number of words on either side of the keyword phrase'}
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </PhraseWrapper>
            <Form.Item style={{ margin: '10px 0 0 0', width: '100%' }}>
              <ExcludedWrapper>
                <Select
                  style={{ width: '100%', maxHeight: 200, overflow: 'auto' }}
                  mode="tags"
                  placeholder="Excluded Words:"
                  value={formik.values.stopWords || []}
                  onChange={(s: string[]) =>
                    formik.setFieldValue(
                      'stopWords',
                      s.filter((word) => word.trim().length > 0)
                    )
                  }
                  onInputKeyDown={(event) => {
                    if (event.metaKey) {
                      const value = (formik.values.stopWords || []).join(',')
                      if (event.key === 'c') {
                        navigator.clipboard.writeText(value)
                      }
                      if (event.key === 'x') {
                        navigator.clipboard.writeText(value)
                        formik.setFieldValue('stopWords', [])
                      }
                      if (event.key === 'v') {
                        navigator.clipboard
                          .readText()
                          .then((result) =>
                            (formik as any).setFieldValue(
                              'stopWords',
                              Array.from(
                                new Set(
                                  formik.values.stopWords.concat(
                                    result.split(',')
                                  )
                                )
                              )
                            )
                          )
                      }
                    }
                  }}
                  tokenSeparators={[',']}
                />
                <YogiButton
                  type="primary"
                  shape="circle"
                  icon={
                    <Tooltip title={'Copy Excluded Words to Clipboard'}>
                      <CopyOutlined />
                    </Tooltip>
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(
                      (formik.values.stopWords || []).join(',')
                    )
                    toast.info('Copied to clipboard')
                  }}
                />
              </ExcludedWrapper>
            </Form.Item>
          </Controls>
          <ButtonWrapper>
            {selectedPhrase && (
              <YogiButton
                type={'ghost'}
                style={{ marginLeft: 0 }}
                onClick={() => setSelectedPhrase(null)}
              >
                Back
              </YogiButton>
            )}

            {searchKeywordsResults && (
              <YogiButton
                type={'ghost'}
                onClick={() => {
                  formik.setFieldValue('windowSize', 1)
                  updateKeywordsControls(comparativeIndex, 'windowSize', 1)
                  formik.setFieldValue('searchPhrase', '')
                  updateKeywordsControls(comparativeIndex, 'searchTerm', '')
                  formik.setFieldValue('currentPhrase', '')
                  updateKeywordsControls(comparativeIndex, 'currentPhrase', '')
                  updateKeywordsControls(comparativeIndex, 'stopWords', [])
                  formik.submitForm()
                }}
                style={{ marginLeft: 0 }}
              >
                Clear
              </YogiButton>
            )}
            <YogiButton
              htmlType="submit"
              type="primary"
              onClick={submitSearchForm}
              style={{ marginLeft: 0 }}
            >
              Search
            </YogiButton>
            {comparativePanelsNumber === 1 && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <i style={{ color: lightText, fontSize: '12px' }}>
                  Click on chart data to view Reviews that include the selected
                  phrase
                </i>
              </div>
            )}
            {selectedPhrase && (
              <HighlightsLink
                onClick={() => {
                  setShowHighlights(!showHighlights)
                }}
              >
                Toggle Highlights
              </HighlightsLink>
            )}
            {!selectedPhrase && (
              <TypeButton
                type="text"
                onClick={() => setSortBySentiment(!sortBySentiment)}
              >
                Sort By {sortBySentiment ? 'Frequency' : 'Sentiment'}
                <SwapOutlined rotate={90} />
              </TypeButton>
            )}

            <Button
              onClick={() => {
                // setIsModalOpened(true)
                setSummaryIsOpen(true)

                const summaryRequest = {
                  headers: {
                    proj_uuid: projectId,
                  },
                  body: {
                    chart_id: '5_5_7_rating_sentiment',
                    // api needs a tier1 value, but it doesn't matter what it is
                    tier1: 'Brand',
                    tier1_value: '',
                    tier2_value: null,
                    tier3_value: null,
                    search_terms: selectedPhrase
                      ? [selectedPhrase]
                      : formik.values.searchPhrase
                      ? [formik.values.searchPhrase]
                      : [],
                    search_criteria: filterValues.searchCondition,
                    criteria: filterValues.values,
                  },
                }

                mixpanel.track('summary control', {
                  action: 'keywords',
                  value: summaryRequest.body,
                  ...route,
                })
                setSummaryRequest(summaryRequest)
              }}
              type="text"
              style={{
                background: 'white',
                border: `2px solid ${cardBorderGrey}`,
                borderRadius: '4px',
                boxShadow: 'none',
                display: 'flex',
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <img
                style={{
                  height: '120%',
                  marginTop: -3,
                  marginLeft: -10,
                  marginRight: 3,
                }}
                src={AIcon}
              />
              Summarize
            </Button>

            {!selectedPhrase && (
              <>
                <YogiButton
                  type="ghost"
                  icon={
                    <Tooltip
                      title={tooltip['copy-button']}
                      placement={'topLeft'}
                    >
                      <CopyOutlined />
                    </Tooltip>
                  }
                  onClick={() => {
                    mixpanel.track('chart download', {
                      action: 'copy',
                      value: 'keywords chart',
                    })
                    onCopyKeywords()
                  }}
                  style={{
                    border: `2px solid ${cardBorderGrey}`,
                    borderRadius: '4px',
                    boxShadow: 'none',
                    marginRight: '0px',
                  }}
                />
                <YogiButton
                  type="ghost"
                  icon={
                    <Tooltip
                      title={tooltip['print-button']}
                      placement={'topLeft'}
                    >
                      <DownloadOutlined />
                    </Tooltip>
                  }
                  onClick={() => {
                    mixpanel.track('chart download', {
                      action: 'image',
                      value: 'keywords chart',
                    })
                    onPrintChart()
                  }}
                  style={{
                    border: `2px solid ${cardBorderGrey}`,
                    borderRadius: '4px',
                    boxShadow: 'none',
                    marginRight: '10px',
                  }}
                />
              </>
            )}
          </ButtonWrapper>
        </WindowSizeControl>
        {isFetching && <Fetcher />}

        {!isFetching && !!searchKeywordsResults && !selectedPhrase && (
          <ScrollableContainer ref={wrapperRef}>
            <Chart
              data={searchKeywordsResults.chart}
              onPhraseSelect={(phrase: string) => {
                mixpanelTrack('keywords chart click')
                setSelectedPhrase(phrase)
              }}
              sortBySentiment={sortBySentiment}
              chartRef={canvasRef}
            />
          </ScrollableContainer>
        )}
        {!isFetching && selectedPhrase && (
          <PhraseList
            phrase={selectedPhrase}
            useKeywordsSearch={formik.values.currentPhrase === ''}
            showHighlights={showHighlights}
            comparativeIndex={comparativeIndex}
          />
        )}

        {downloadLoading && (
          <Overlay>
            <LoaderWrapper>
              <Fetcher />
            </LoaderWrapper>
          </Overlay>
        )}
      </ContentWrapper>
    </>
  )
}

export const Keywords: React.FC = () => {
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const keywordsControls = useProjectStore(
    (state: ProjectState) => state.keywordsControls
  )
  const { ref } = useResizeObserver<HTMLDivElement>()

  if (keywordsControls.length < comparativePanelsNumber) {
    return <Container />
  }

  return (
    <Container ref={ref}>
      <>
        <Wrapper>
          {_.times(comparativePanelsNumber, (index) => (
            <Panel key={index}>
              <KeywordsContent comparativeIndex={index} />
            </Panel>
          ))}
        </Wrapper>
      </>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0px 16px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
  gap: var(--default-padding-half);
  margin: 16px;
`

const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
`
const ScrollableContainer = styled.div`
  border-radius: 6px;
  max-height: calc(100vh - 270px);
  overflow-y: scroll;
  box-shadow: var(--light-box-shadow);
`

const ContentWrapper = styled.div`
  .ant-input-number:hover,
  .ant-input-number-disabled:hover {
    border-bottom: 1px solid black;
  }

  height: calc(100vh - 128px);
  width: 100%;
`

const ExcludedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const PhraseWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`

const WindowSizeControl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  & > * {
    margin-bottom: var(--default-padding-half);
  }
`
const StyledInput = styled(Input)`
  border: 2px solid var(--card-border-grey);
  border-radius: 6px;
`
const StyledInputNumber = styled(InputNumber)`
  border: 2px solid var(--card-border-grey);
  border-radius: 6px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > button {
    margin: 0 var(--default-padding-half);
  }
`
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.4);
`
const LoaderWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  line-height: 3em;
`

const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const HighlightsLink = styled.a`
  margin-left: auto;
  margin-right: 10px;
  color: black;
  color: var(--color-text-black);
  font-size: 12px;
`

const TypeButton = styled(Button)`
  font-size: 12px;
  margin-left: auto !important;
  padding: 0 !important;
  margin-top: -5px;
  color: #666666 !important;
  background: none !important;
`
