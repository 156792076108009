import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Modal, Switch } from 'antd'
import { home, projectURL } from 'utils/links'
import { AuthContext, HeaderContainer } from 'features/auth'
import YogiLogo from 'assets/images/logos/Peach_Horizontal.png'
import { useProjectStore } from '../../features/project/projectStore/projectStore'
import { ProjectState } from '../../features/project/types'
import { YogiButton } from '../../components/UI/YogiButton'
import { secondaryNavy } from '../../assets/styles/variables'
import { toast } from 'react-toastify'
import { useFeatureFlags } from '../../features/project/hooks/useFeatureFlags'

type Props = {
  children?: React.ReactNode
}

const { confirm } = Modal
export const Header: React.FC<Props> = ({ children }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const isHyperCopy = useProjectStore(
    (state: ProjectState) => state.isHyperCopy
  )
  const setIsHyperCopy = useProjectStore(
    (state: ProjectState) => state.setIsHyperCopy
  )
  const history = useHistory()
  const location = useLocation()
  const authContext = useContext(AuthContext)

  window.addEventListener('keydown', (evt) => {
    if (evt.shiftKey && evt.ctrlKey && evt.code === 'KeyX') {
      setIsHyperCopy(!isHyperCopy)
    }
  })
  const isAdmin = details.role === 'admin'

  const { data: feature_flags } = useFeatureFlags()

  return (
    <Container>
      <Left>
        {authContext?.userDetails?.yogi_user ||
        authContext?.userDetails?.email?.includes('@churchdwight.com') ? (
          <HomeLink
            to={
              !!authContext?.userDetails?.yogi_user ||
              authContext?.userDetails?.email?.includes('@churchdwight.com')
                ? home
                : ''
            }
            onClick={(e) => {
              e.preventDefault()
              confirm({
                content: 'Do you want to return to All Projects?',
                onOk() {
                  history.push(home)
                },
                onCancel() {},
              })
            }}
          >
            <Logo src={YogiLogo} />
            {/*<Title>Yogi</Title>*/}
          </HomeLink>
        ) : (
          <HomeLink
            to={window.location.pathname}
            onClick={(e) => {
              e.preventDefault()
              history.push(projectURL(projectId))
            }}
          >
            <Logo src={YogiLogo} />
          </HomeLink>
        )}
        {children}

        {/*{(isAdmin || feature_flags?.copy_share_link) && (*/}
        {feature_flags?.copy_share_link && (
          <div style={{ marginLeft: 20 }}>
            {/*<YogiButton*/}
            {/*  type={'default'}*/}
            {/*  onClick={() => setIsHyperCopy(!isHyperCopy)}*/}
            {/*>*/}
            {/*  Copy Mode is {isHyperCopy ? 'On' : 'Off'}*/}
            {/*</YogiButton>*/}

            {/*<Tooltip*/}
            {/*  title={*/}
            {/*    'Enables copy to clipboard by clicking certain elements.\nShortcut: Ctrl + Shift + X'*/}
            {/*  }*/}
            {/*>*/}
            {/*  <StyledSwitch*/}
            {/*    checkedChildren="Copy On"*/}
            {/*    unCheckedChildren="Copy Off"*/}
            {/*    checked={isHyperCopy}*/}
            {/*    onChange={() => setIsHyperCopy(!isHyperCopy)}*/}
            {/*  />*/}
            {/*</Tooltip>*/}
            <YogiButton
              type={'primary'}
              style={{ padding: '0px 8px', height: '29px', marginRight: '0px' }}
              onClick={() => {
                const searchParams = new URLSearchParams(location.search)
                searchParams.set('share_type', 'insights')
                searchParams.set('share_user', authContext.user.username)
                const url =
                  'https://app.meetyogi.com' +
                  window.location.pathname +
                  '?' +
                  searchParams.toString()
                navigator.clipboard
                  .writeText(url)
                  .then(() => toast.info('Copied to clipboard'))
              }}
            >
              Copy Share Link
            </YogiButton>
          </div>
        )}
      </Left>
      <div>
        <HeaderContainer />
      </div>
    </Container>
  )
}

const Container = styled.div`
  height: var(--header-height);
  color: var(--color-text-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0 8px 0 var(--default-padding);
`
const Logo = styled.img`
  height: 30px;
`
const Title = styled.div`
  white-space: nowrap;
  color: var(--color-text-black);
`
const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const Home = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const StyledSwitch = styled(Switch)`
  background-color: ${(props) =>
    props.checked ? secondaryNavy : 'rgba(0, 0, 0, 0.25)'};

  .ant-switch-inner {
    font-size: 12px;
  }
`
