import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import _, { flatten } from 'lodash'
import { Button, Input } from 'antd'
import { toast } from 'react-toastify'
import {
  FilterOption,
  ApplyFilterPayload,
  AppliedFilterOption,
  SortableFilterOption,
  AppliedFilterOptionSelect,
  ApplyGroupFilterPayload,
} from 'features/project/features/filters/types'
import mixpanel from 'features/trackers/mixpanel'
import {
  applyFilterValue,
  applyGroupFilterValue,
  HardcodedFilterOptionsEnum,
} from 'features/project/features/filters/helpers'
import { FilterPanelSelector } from './filter-panel-selector'
import { FilterPanelListItem } from './filter-panel-list-item'
import {
  applyEveryFilterValue,
  isFilterOptionApplied,
  transformFilterOptions,
  getAppliedFilterOptionsInfo,
} from './helpers'

import {
  DndContext,
  closestCenter,
  DragOverlay,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers'
import { DragEndEvent } from '@dnd-kit/core/dist/types'
import { saveFilterOrder } from 'features/project/features/filters/model'
import {
  HolderOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import {
  secondaryNavy,
  superLightGrey,
} from '../../../../../../assets/styles/variables'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import { SavedFilterSelector } from './saved-filter-selector'
import Divider from '../../../../../../components/UI/YogiDivider'

type Props = {
  onClose?: () => void
  height?: string
  dragged?: boolean
  setDragDisabled?: (v: any) => void
  comparativeIndex: number
  filterListData: FilterOption[] | undefined
  filterList?: FilterOption[] | undefined
  filterValues: Filters
  // these are for custom dash charts to have their own filterlists
  localOpenedOptions?: string[]
  setLocalOpenedOptions?: (openedOptions: string[]) => void
  updateLocalFilterValues?: (filterValues: Filters) => void
  filterOptionsLoading?: boolean
  localSelectedOption?: string
  setLocalSelectedOption?: (selectedOption: string) => void
  isUnlinked?: boolean
  parentFilters?: Filters
}

export const FilterPanel: React.FC<Props> = ({
  onClose,
  height,
  dragged,
  setDragDisabled,
  comparativeIndex,
  filterValues,
  updateLocalFilterValues,
  filterListData,
  filterList: parentFilterList,
  localOpenedOptions,
  setLocalOpenedOptions,
  filterOptionsLoading,
  localSelectedOption,
  setLocalSelectedOption,
  isUnlinked,
  parentFilters,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative
  )
  // const filterList = useProjectStore(
  //   (state: ProjectState) => state.filterList[comparativeIndex]
  // )
  const setFilterValues = useProjectStore(
    (state: ProjectState) => state.setFilterValues
  )
  const clearFilter = useProjectStore(
    (state: ProjectState) => state.clearFilter
  )

  const countries = useProjectStore((state: ProjectState) => state.countries)

  const updateOpenedOptions = useProjectStore(
    (state: ProjectState) => state.updateOpenedOptions
  )
  // TODO maybe localize this as well
  const globalSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.selectedOptionField[comparativeIndex]
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const selectedOptionField = setLocalSelectedOption
    ? localSelectedOption
    : globalSelectedOptionField

  const [grabbing, setGrabbing] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [localAppliedFilters, setLocalAppliedFilters] = useState<
    Array<AppliedFilterOption>
  >([])

  const [filterList, setFilterList] = useState<FilterOption[]>([])

  useEffect(() => {
    if (filterListData) {
      setFilterList(filterListData)
    }
  }, [filterListData])

  // useEffect(() => {
  //   setFilterList(
  //     //@ts-ignore
  //     filterList.map((el) => ({ field: el.field, alias: el.alias }))
  //   )
  // }, [filterValues])

  useEffect(() => {
    setLocalAppliedFilters(filterValues.values)
  }, [filterValues.values])

  const updateFilterValues = (filterOptions: Array<AppliedFilterOption>) => {
    if (updateLocalFilterValues) {
      updateLocalFilterValues({
        values: filterOptions,
        searchCondition: filterValues.searchCondition,
        searchQuery: filterValues.searchQuery,
      })
    } else {
      setFilterValues(comparativeIndex, [
        // ...filterValues.values.filter(({ field }) => !excludedFields.has(field)),
        ...filterOptions,
        // { field: 'Benzoyl Peroxide', values: ['TRUE'] },
      ])
    }
    getDeletedOptions(filterOptions).forEach(({ field }) =>
      clearFilter(comparativeIndex, field)
    )
  }

  const getDeletedOptions = (filterOptions: Array<AppliedFilterOption>) => {
    return filterValues.values.filter((option) => {
      const alreadyAppliedFilter = filterOptions.find(
        ({ field }) => field === option.field
      )
      return alreadyAppliedFilter === undefined
    })
  }

  const applyFilter = useCallback(
    (payload: ApplyFilterPayload, immediatelySubmit?: boolean) => {
      const preparedFilters = applyFilterValue(payload, localAppliedFilters)

      setLocalAppliedFilters(preparedFilters)
      if (immediatelySubmit) {
        updateFilterValues(preparedFilters)
      }
    },
    [localAppliedFilters]
  )

  const applyGroupFilter = useCallback(
    (payload: ApplyGroupFilterPayload, immediatelySubmit?: boolean) => {
      const preparedFilters = applyGroupFilterValue(
        payload,
        localAppliedFilters
      )

      setLocalAppliedFilters(preparedFilters)
      if (immediatelySubmit) {
        updateFilterValues(preparedFilters)
      }
    },
    [localAppliedFilters]
  )

  const hasAppliedDateValue = filterValues.values.find(
    ({ field }) => field === HardcodedFilterOptionsEnum.CREATE_TIME
  )

  const applyAll = (
    options: Array<FilterOption | undefined>,
    search: string,
    isDeselect: boolean
  ) => {
    if (!options.length) return
    // I am assuming that anything that has ApplyAll is an AppliedFilterOptionSelect, because there are no AppliedFilterOptionRange filter types that provide a "Select All" options
    let preparedFilters = flatten(
      options
        .filter(Boolean)
        .map((option) => option as FilterOption)
        .map((option) =>
          applyEveryFilterValue(option, localAppliedFilters, search)
        )
        .filter(Boolean)
        .map(
          (option) => option as Array<AppliedFilterOption>
        ) as AppliedFilterOptionSelect[][]
    )
    if (preparedFilters) {
      // the reason this exists is that, if there are already selected values for the same property, they are getting deselected when select/deselect all is called on another selection
      // I guarantee there is a better way to do this and im probably doing some bad typescript stuff
      // upon revisitation, i have no idea why this works the way it does
      const currentFilter = preparedFilters.find(
        // cant get here without a length of options as per the first statement in this function
        (el) => el.field === options[0]!.field
      )
      let values = (currentFilter as AppliedFilterOptionSelect).values
      // this should always hit because we only run this on select type filters
      if (currentFilter && values) {
        let match = localAppliedFilters.find(
          (el) => el.field === currentFilter.field
        ) as AppliedFilterOptionSelect
        if (match) {
          let matchValues = (match as AppliedFilterOptionSelect).values
          if (isDeselect) {
            // this is n^2 but finds the unshared values of the selections and deselection array
            values = matchValues.filter((obj) => {
              return values.indexOf(obj) === -1
            })
          } else {
            // if selecting, dedupe the values
            values = values.concat(matchValues)
            values = Array.from(new Set(values))
          }
          // update prepared filters entry with merged values
          preparedFilters.find(
            (el) => el.field === currentFilter.field
          )!.values = values
        }
      }
      preparedFilters = preparedFilters.filter(
        (el) => !el.hasOwnProperty('values') || el.values.length > 0
      )
      setLocalAppliedFilters(preparedFilters)
    }
  }

  const stableFilterList = parentFilterList ? parentFilterList : filterList

  const filterListToShow = transformFilterOptions(
    stableFilterList.length ? stableFilterList : defaultFilterList
  )
  //   .filter(
  //   (item) =>
  //     getFilterValueName(item.field) !== HardcodedFilterOptionsEnum.CREATE_TIME
  // )
  const { count, isEqual } = getAppliedFilterOptionsInfo(
    filterValues.values,
    localAppliedFilters
  )

  const selectedFilterOptionObject = transformFilterOptions(
    defaultFilterList
  ).find((el) => el.field === selectedOptionField)

  const [items, setItems] = useState<SortableFilterOption[]>(
    filterListToShow.map((e) => ({ ...e, id: e.field }))
  )
  useEffect(() => {
    setItems(filterListToShow.map((e) => ({ ...e, id: e.field })))
  }, [stableFilterList])

  const itemsSearched = !!searchTerm
    ? items.filter((el) =>
        el.alias?.toLowerCase().startsWith(searchTerm?.toLowerCase())
      )
    : items

  const [activeId, setActiveId] = useState<string | null>()
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 15,
      },
    })
  )

  function handleDragStart(event: DragEndEvent) {
    if (!dragged) {
      const { active } = event
      setActiveId(active.id)
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    if (event.active.id !== event.over!.id && !dragged) {
      const oldIndex = items.findIndex((f) => f.id === event.active.id)
      const newIndex = items.findIndex((f) => f.id === event.over!.id)
      let res = arrayMove(items, oldIndex, newIndex)
      setItems(res)
      saveFilterOrder(projectId, Array.from(res.map((e) => e.field)))
        .then(() => {
          // TODO something else ?
          // filterList.refetch()
        })
        .catch((e) => {
          toast.error('Error saving filters order')
        })
    }
    setActiveId(null)
  }

  return (
    <>
      <Top>
        <Left
          style={{
            height: isComparative ? '350px' : '400px',
            maxHeight: height,
            flex: isComparative ? 3 : 2,
          }}
        >
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[
              restrictToVerticalAxis,
              restrictToFirstScrollableAncestor,
            ]}
            collisionDetection={closestCenter}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <FilterSearch>
                <SearchInput
                  prefix={<SearchOutlined style={{ color: '#93ABB5' }} />}
                  placeholder={'Search...'}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                />
                {/*<Clear>X</Clear>*/}
              </FilterSearch>
              {!route.isDashboard && (
                <FilterPanelListItem
                  id={''}
                  key={'saved'}
                  isSelected={!selectedOptionField}
                  isApplied={false}
                  onClick={() => {
                    // TODO handle
                    updateSelectedOptionField(comparativeIndex, '')
                  }}
                >
                  <div>Saved Filters</div>
                  <div>
                    <RightOutlined style={{ color: '#9FB7C3' }} />
                  </div>
                </FilterPanelListItem>
              )}
              {items
                .filter((el) => el.field === 'proj_uuid')
                .map((option) => (
                  <FilterPanelListItem
                    id={option.id}
                    key={option.field}
                    disabled={option.disabled}
                    isSelected={option.field === selectedOptionField}
                    isApplied={isFilterOptionApplied(
                      filterValues.values,
                      option
                    )}
                    onClick={() => {
                      updateSelectedOptionField(comparativeIndex, option.field)
                      setLocalSelectedOption &&
                        setLocalSelectedOption(option.field)
                      updateOpenedOptions(comparativeIndex, option.field)
                      // TODO refactor this
                      if (localOpenedOptions && setLocalOpenedOptions) {
                        if (!localOpenedOptions.includes(option.field)) {
                          setLocalOpenedOptions([
                            ...localOpenedOptions,
                            option.field,
                          ])
                        }
                      }
                    }}
                  >
                    <div>{option.alias}</div>
                    <div>
                      <RightOutlined style={{ color: '#9FB7C3' }} />
                    </div>
                  </FilterPanelListItem>
                ))}
              <Divider style={{ borderColor: secondaryNavy, opacity: '.5' }} />
              {/*<Divider style={{ borderColor: secondaryNavy }} />*/}
              {itemsSearched
                .filter((el) => el.field !== 'proj_uuid')
                .map((option) => (
                  <FilterPanelListItem
                    id={option.id}
                    key={option.field}
                    disabled={option.disabled}
                    isSelected={option.field === selectedOptionField}
                    isApplied={isFilterOptionApplied(
                      filterValues.values,
                      option
                    )}
                    onClick={() => {
                      updateSelectedOptionField(comparativeIndex, option.field)
                      setLocalSelectedOption &&
                        setLocalSelectedOption(option.field)
                      updateOpenedOptions(comparativeIndex, option.field)
                      if (localOpenedOptions && setLocalOpenedOptions) {
                        if (!localOpenedOptions.includes(option.field)) {
                          setLocalOpenedOptions([
                            ...localOpenedOptions,
                            option.field,
                          ])
                        }
                      }
                    }}
                  >
                    <div>{option.alias}</div>
                    <div>
                      <RightOutlined style={{ color: '#9FB7C3' }} />
                    </div>
                  </FilterPanelListItem>
                ))}
            </SortableContext>
            <DragOverlay>
              {activeId ? (
                <Overlay id={activeId}>
                  {items.find((e) => e.id === activeId)!.alias}
                </Overlay>
              ) : null}
            </DragOverlay>
          </DndContext>
        </Left>
        <Right
          style={{
            height: isComparative ? '350px' : '400px',
            maxHeight: height,
          }}
        >
          {!selectedOptionField && !route.isDashboard ? (
            <SavedFilterSelector
              updateLocalFilterValues={updateLocalFilterValues}
              comparativeIndex={comparativeIndex}
              onClose={onClose}
            />
          ) : (
            <FilterPanelSelector
              option={selectedFilterOptionObject}
              applyFilter={applyFilter}
              applyGroupFilter={applyGroupFilter}
              appliedFilters={localAppliedFilters}
              applyEveryFilter={(search) => {
                applyAll([selectedFilterOptionObject], search, false)
              }}
              clearFilters={(search) => {
                applyAll([selectedFilterOptionObject], search, true)
              }}
              comparativeIndex={comparativeIndex}
              filterListData={filterListData}
              filterList={stableFilterList}
              filterOptionsLoading={filterOptionsLoading}
              parentFilters={parentFilters}
              isUnlinked={isUnlinked}
              filterValues={filterValues}
            />
          )}
        </Right>
      </Top>
      <Bottom>
        {setDragDisabled && (
          <HolderOutlined
            style={{
              marginRight: 'auto',
              cursor: grabbing ? 'grabbing' : 'grab',
            }}
            onMouseEnter={() => setDragDisabled(false)}
            onMouseLeave={() => setDragDisabled(true)}
            onMouseDown={() => setGrabbing(true)}
            onMouseUp={() => setGrabbing(false)}
          />
        )}

        {onClose && (
          <Button type="text" onClick={onClose}>
            Close
          </Button>
        )}
        <Button
          type="primary"
          disabled={
            !filterValues.values.length &&
            !localAppliedFilters.length &&
            !hasAppliedDateValue
          }
          onClick={() => {
            if (onClose) {
              onClose()
              updateSelectedOptionField(comparativeIndex, '')
            }
            clearOpenedOptions(comparativeIndex)
            setLocalOpenedOptions && setLocalOpenedOptions([])
            setLocalAppliedFilters(
              localAppliedFilters.filter(
                (el) =>
                  (countries?.enabled && el.field.includes('country')) ||
                  el.field === 'create_time'
              )
            )
            updateFilterValues(
              localAppliedFilters.filter(
                (el) =>
                  (countries?.enabled && el.field.includes('country')) ||
                  el.field === 'create_time'
              )
            )
          }}
          danger
          style={{
            borderRadius: '8px',
            borderWidth: '2px',
          }}
        >
          Clear All
        </Button>
        <YogiButton
          type="primary"
          disabled={
            // isEqual || filterList.isLoading || filterList.isPageDataLoading
            isEqual
          }
          onClick={() => {
            mixpanel.track('filter', {
              action: 'panel',
              ...route,
              value: localAppliedFilters,
            })
            // onClose && updateSelectedOptionField(comparativeIndex, '')
            clearOpenedOptions(comparativeIndex)
            updateFilterValues(
              localAppliedFilters
              // localAppliedFilters.filter(
              //   // comparative index doesnt really work like this anymore
              //   // (item) => getComparativeIndex(item.field) === cI
              //   (item) => getComparativeIndex(item.field) === 0
              //   // (item) => getFilterValueName(item.field)
              // )
            )
            onClose && onClose()
          }}
        >
          Apply
        </YogiButton>
      </Bottom>
    </>
  )
}

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Bottom = styled.div`
  border-top: 1px solid var(--color-grey);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--default-padding-half);
  background: ${superLightGrey};

  & > * {
    margin-left: var(--default-padding-half);
  }
`
const Left = styled.div`
  overflow-y: auto;
  border-right: 1px solid var(--color-grey);
`
const Right = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const Overlay = styled.div`
  cursor: pointer;
  user-select: none;
  padding: calc(var(--default-padding-half) / 2) var(--default-padding-half);
  background: var(--color-grey);
  font-weight: 400;
`

const FilterSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  user-select: none;
  background: #f5f8f9;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #e7edf0;

  outline: 0 !important;
  box-shadow: none !important;
`

const SearchInput = styled(Input)`
  background: #f5f8f9;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #e7edf0;
  padding: 12px 12px;
  border: 0px;

  outline: 0 !important;
  box-shadow: none !important;

  .ant-input-prefix {
    margin-right: 8px;
  }

  .ant-input {
    background: transparent;
    margin-top: 2px;
  }
`

const Clear = styled.div`
  cursor: pointer;
  padding: 0px 5px;
`
