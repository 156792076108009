import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Chart } from '../../dashboard/components/Dashboard/Charts/chart'
import { Filters, ProjectState } from '../../../types'
import { PostType, TimeInterval } from '../../charts/types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Keywords } from '../../summary/components/Keywords'
import { useQuery } from 'react-query'
import { getSummary } from '../../charts/model'
import { Themes } from '../../summary/components/Themes'
import { InnerTitle } from '../../../../../components/InnerTitle'
import {
  BarChartOutlined,
  FileSearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Loader } from '../../../../../shared/components'
import { CHARTS_URL } from '../../../utils'
import mixpanel from '../../../../trackers/mixpanel'
import { Link } from 'react-router-dom'
import { buttonBlue, mainText } from '../../../../../assets/styles/variables'
import { Stats } from '../../summary/components/Stats'
import _ from 'lodash'
import { FocusListOption } from '../FocusList'
import { OpenInNewOutlined } from '@mui/icons-material'
import { getBookmarkUrlPost } from '../../../../url-params/model'
import ThemeSelector from './ThemeSelector'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import NextSteps from './NextSteps'
import ProductSelector from './ProductSelector'

type Props = {
  item: FocusListOption
  filterValues: Filters
  tier1: string
  chartId: string
  postType: PostType | undefined
  timeInterval: TimeInterval | undefined
  reviewsCount: number | undefined
  disabled?: boolean
}
const Overview: React.FC<Props> = ({
  item,
  filterValues,
  tier1,
  chartId,
  postType,
  timeInterval,
  reviewsCount,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const [activeTier1, setActiveTier1] = React.useState<string>(tier1)
  const [activeValues, setActiveValues] = React.useState<string[]>(
    item.identifiers
  )

  const tierName =
    defaultFilterList.find((el) => el.field === activeTier1)?.alias ??
    activeTier1

  const isProduct = tier1 === 'product_hierarchy'
  const isTheme = tier1 === 'theme'

  const activeFilters = useMemo(() => {
    return {
      ...filterValues,
      values: [
        ...filterValues.values.filter((el) => el.field !== activeTier1),
        { field: activeTier1, values: activeValues },
      ].filter((el) =>
        defaultFilterList.find((item) => item.field === el.field)
      ),
    }
  }, [filterValues, tier1, activeTier1, activeValues])

  // TODO get this garbage out of here
  const chartItem = {
    type: 'chart',
    content: {
      name: 'Rating',
      image: '4_16.png',
      chart_ids: [
        {
          chart_id: '6_4_16_rating',
          model: null,
          field: null,
        },
      ],
      tier1: tierName,
    },
    dashboardControls: {
      chartId: '6_4_16_rating',
      tier1: tierName,
      tier2: null,
      tier3: null,
      legend: true,
      fullAxis: true,
      hasLabels: false,
      hasOverlay: false,
      treeView: true,
      origin: true,
      labels: false,
      trendlines: false,
      shadedZones: true,
      sortedByCategory: false,
      isAlignScales: true,
      isAlignOrder: true,
      multiChart: true,
      fontSize: 'medium',
      timeInterval: null,
      reviewsCount: 0,
      postType: 'post',
      interval: null,
      isExpandChart: false,
      order: null,
      isDelta: false,
      showPercentChange: true,
      wrapLabels: false,
      maxAxesLength: 30,
    },
    filterValues: {
      values: [],
      searchQuery: [],
      searchCondition: [],
    },
  }

  let secondaryChart: any = null

  if (activeTier1 === 'brand') {
    secondaryChart = {
      type: 'chart',
      content: {
        name: 'Rating',
        image: '4_16.png',
        chart_ids: [
          {
            chart_id: '9_3_19_sentiment',
            model: null,
            field: null,
          },
        ],
        tier1: 'Theme',
      },
      dashboardControls: {
        chartId: '9_3_19_sentiment',
        tier1: 'Theme',
        tier2: null,
        tier3: null,
        legend: true,
        fullAxis: true,
        hasLabels: false,
        hasOverlay: false,
        treeView: true,
        origin: true,
        labels: false,
        trendlines: false,
        shadedZones: true,
        sortedByCategory: false,
        isAlignScales: true,
        isAlignOrder: true,
        multiChart: true,
        fontSize: 'medium',
        timeInterval: null,
        reviewsCount: 0,
        postType: 'post',
        interval: null,
        isExpandChart: false,
        order: null,
        isDelta: false,
        showCompetitors: false,
        showMyBrands: false,
        showPercentChange: true,
        wrapLabels: false,
        maxAxesLength: 30,
        matrixScaleVolume: true,
        matrixSort: true,
        matrixSortAxis: 'y',
        matrixSortValue: activeValues[0],
        matrixSpectrumColors: true,
      },
      filterValues: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
    }
  }

  const summaryRequest = {
    headers: {
      proj_uuid: projectId,
    },
    body: {
      chart_id: '5_5_7_rating_sentiment',
      tier1: 'Brand',
      tier1_value: '',
      tier2_value: null,
      tier3_value: null,
      search_terms: activeFilters.searchQuery,
      search_criteria: activeFilters.searchCondition,
      criteria: activeFilters.values,
    },
  }

  const { data, isLoading: summaryLoading } = useQuery(
    ['summary', summaryRequest],
    () => getSummary(summaryRequest.headers, summaryRequest.body),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!summaryRequest,
    }
  )

  const { data: linkData } = useQuery(
    ['getFilterHash', projectId, activeFilters, chartItem.dashboardControls],
    () =>
      getBookmarkUrlPost(projectId, {
        page: `${CHARTS_URL}`,
        filterString: JSON.stringify({
          filters: [activeFilters],
          pageControls: [chartItem.dashboardControls],
          // pageControls: [chartItem.dashboardControls].map((el) => ({
          //   ...el,
          //   chart_id: chartItem.dashboardControls.chartId,
          //   tier1: tierName,
          //   multiChart: true,
          // })),
        }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!chartItem?.dashboardControls,
    }
  )

  const { data: matrixLinkData } = useQuery(
    [
      'getFilterHash-matrix',
      projectId,
      activeFilters,
      secondaryChart?.dashboardControls,
    ],
    () =>
      getBookmarkUrlPost(projectId, {
        page: `${CHARTS_URL}`,
        filterString: JSON.stringify({
          filters: [activeFilters],
          pageControls: [secondaryChart.dashboardControls],
          // pageControls: initialState.dashboardControls.map((el) => ({
          //   ...el,
          //   chart_id: chartItem.dashboardControls.chartId,
          //   tier1: tierName,
          //   multiChart: true,
          // })),
        }),
      }),
    {
      enabled: !!projectId && !!secondaryChart?.dashboardControls,
    }
  )

  // let itemSelectorMaxHeight = 0
  //
  // document.querySelectorAll('.product-selector').forEach((el) => {
  //   itemSelectorMaxHeight = Math.max(itemSelectorMaxHeight, el.clientHeight)
  // })
  //
  // console.log(itemSelectorMaxHeight)

  return (
    <OverviewContainer>
      {/*TODO hide this whole component if items === 1, not sure how to grab that here*/}
      {isProduct && (
        <WidgetWrapper
          className={'product-selector'}
          style={{
            padding: 0,
          }}
        >
          <ProductSelector
            value={item.identifiers[0]}
            activeValues={activeValues}
            setActiveValues={setActiveValues}
            setActiveTier1={setActiveTier1}
            childSelected={tier1 !== activeTier1}
          />
        </WidgetWrapper>
      )}
      {isTheme && item?.identifiers?.length > 1 && (
        <WidgetWrapper
          className={'theme-selector'}
          style={{
            padding: 0,
          }}
        >
          <ThemeSelector
            activeValues={activeValues}
            setActiveValues={setActiveValues}
            options={item.identifiers}
          />
        </WidgetWrapper>
      )}
      {summaryLoading ? (
        <LoadingWrapper
          style={{
            height: '100%',
            width: '100%',
            padding: 8,
          }}
        >
          <div
            style={{
              top: '40%',
              display: 'flex',
              flexDirection: 'column',
              marginTop: 50,
            }}
          >
            <Loader style={{ height: '100%', maxHeight: 300, marginTop: 30 }} />
            <LoaderTitle>{`Loading ${tierName ?? 'Item'} Details`}</LoaderTitle>
          </div>
        </LoadingWrapper>
      ) : (
        <>
          <WidgetWrapper>
            <Stats
              data={data}
              summaryRequest={summaryRequest}
              filterValues={activeFilters}
              filterList={defaultFilterList}
              omittedOptions={[
                'n_fragments',
                'n_rating_only',
                'n_recommended_posts',
                'n_promo_posts',
                'n_themes_total',
                'n_themes_provided',
              ]}
              columns={4}
            />
          </WidgetWrapper>
          <div>
            <TitleWrapper>
              {/* TODO this is link to Charts */}
              <InnerTitle
                style={{
                  width: '100%',
                  fontSize: '1.5em',
                  padding: 10,
                  paddingLeft: 16,
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                {linkData ? (
                  <Link
                    target={'_blank'}
                    to={`${CHARTS_URL}?filters=${linkData}`}
                    onClick={() => {
                      mixpanel.track('catalog', {
                        action: 'open chart link',
                        value: chartItem.dashboardControls.chartId,
                      })
                    }}
                    style={{
                      color: mainText,
                      // textDecoration: 'underline',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <BarChartOutlined />
                      <div>{`${_.startCase(
                        tierName
                      )} Rating and Volume Over Time`}</div>
                      <OpenInNewOutlined fontSize={'inherit'} />
                    </div>
                  </Link>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <BarChartOutlined />
                    <div>{`${_.startCase(
                      tierName
                    )} Rating and Volume Over Time`}</div>
                  </div>
                )}

                {linkData ? (
                  <Link
                    target={'_blank'}
                    to={`${CHARTS_URL}?filters=${linkData}`}
                    onClick={() => {
                      mixpanel.track('catalog', {
                        action: 'open chart link',
                        value: chartItem.dashboardControls.chartId,
                      })
                    }}
                    style={{
                      color: mainText,
                      marginTop: -5,
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                    }}
                  >
                    <YogiButton
                      type={'primary'}
                      style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                    >
                      View in Charts <OpenInNewOutlined fontSize={'inherit'} />
                    </YogiButton>
                  </Link>
                ) : (
                  <LoadingOutlined />
                )}
              </InnerTitle>
            </TitleWrapper>
            <ChartContainer>
              <Chart
                filters={activeFilters}
                chartId={chartId}
                tier1={tierName}
                comparativeIndex={0}
                isComparative={false}
                dashboardControls={chartItem.dashboardControls}
                item={chartItem}
                hideHeader
              />
            </ChartContainer>
          </div>
          <div style={{ display: 'flex', gap: 8 }}>
            <WidgetWrapper>
              {summaryLoading ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Loader style={{ height: '50%', maxHeight: 300 }} />
                  <LoaderTitle>{`Loading Widget`}</LoaderTitle>
                </div>
              ) : (
                <Keywords
                  data={data}
                  summaryRequest={summaryRequest}
                  selectedTheme={''}
                  filterListData={defaultFilterList}
                />
              )}
            </WidgetWrapper>
            <WidgetWrapper>
              <NextSteps
                type={
                  activeTier1 as
                    | 'theme'
                    | 'brand'
                    | 'source_type'
                    | 'product_hierarchy'
                }
                filters={activeFilters}
                value={activeValues[0]}
              />
            </WidgetWrapper>
          </div>
          <WidgetWrapper>
            {summaryLoading ? (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Loader style={{ height: '50%', maxHeight: 300 }} />
                <LoaderTitle>{`Loading Widget`}</LoaderTitle>
              </div>
            ) : (
              <Themes
                data={data}
                selectedTheme={activeTier1 === 'theme' ? activeValues[0] : ''}
                summaryRequest={summaryRequest}
                localFilters={activeFilters}
              />
            )}
          </WidgetWrapper>
          {secondaryChart && (
            <div>
              <TitleWrapper>
                {/* TODO this is link to Charts */}
                <InnerTitle
                  style={{
                    width: '100%',
                    fontSize: '1.5em',
                    padding: 10,
                    paddingLeft: 16,
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <BarChartOutlined />
                    <div>{`${_.startCase(
                      tierName
                    )} Theme Sentiment Matrix`}</div>
                  </div>

                  {matrixLinkData ? (
                    <Link
                      target={'_blank'}
                      // to={`${CHARTS_URL}?chart_id=${
                      //   secondaryChart.dashboardControls.chartId
                      // }&tier1=Theme&${getFilterURIString(
                      //   activeFilters
                      //   //)}&multiChart=true&matrixScaleVolume=true`}
                      // )}&multiChart=true&matrixScaleVolume=true&matrixSort=true&matrixSortAxis=y&matrixSortValue=${activeValue}&matrixSpectrumColors=true`}
                      to={`${CHARTS_URL}?filters=${matrixLinkData}`}
                      onClick={() => {
                        mixpanel.track('catalog', {
                          action: 'open chart link',
                          value: secondaryChart.dashboardControls.chartId,
                        })
                      }}
                      style={{
                        color: mainText,
                        marginTop: -5,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <OpenInNewOutlinedStyled fontSize={'inherit'} />
                    </Link>
                  ) : (
                    <LoadingOutlined />
                  )}
                </InnerTitle>
              </TitleWrapper>
              <ChartContainer>
                <Chart
                  filters={activeFilters}
                  chartId={'9_3_19_sentiment'}
                  tier1={'Theme'}
                  comparativeIndex={0}
                  isComparative={false}
                  dashboardControls={secondaryChart.dashboardControls}
                  item={secondaryChart}
                  hideHeader
                />
              </ChartContainer>
            </div>
          )}
        </>
      )}
    </OverviewContainer>
  )
}
export default Overview

const OverviewContainer = styled.div`
  height: 100%;
  width: 100%;
  //max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  //justify-content: center;
  overflow: auto;
  padding: var(--default-padding-quarter);
`

const TitleWrapper = styled.div`
  background: white;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  margin-bottom: -5px;
`

const ChartContainer = styled.div`
  height: 250px;
`

const LoadingWrapper = styled.div`
  //height: 125px;

  height: 100%;
  width: 100%;
  padding: 8px;
  background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
`

const WidgetWrapper = styled.div`
  //height: 125px;
  background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
  width: 100%;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const OpenInNewOutlinedStyled = styled(OpenInNewOutlined)`
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 0.2s ease !important;

  &:hover {
    color: ${buttonBlue};
  }
`
