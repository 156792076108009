import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled from 'styled-components'
import useResizeObserver from 'use-resize-observer'
import Split from 'react-split'
import { Divider, Empty } from 'antd'
import { AlertOutlined, CloseOutlined } from '@ant-design/icons'
import ChartJS from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartAnnotations from 'chartjs-plugin-annotation'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'
import { ChartLegend } from 'features/project/features/charts/components/chart-legend'
import { NumericalChart } from 'features/project/features/charts/features'
import {
  ChartTypes,
  isValuableChart,
  fontSizeDict,
  highlightHoveredData,
  alignScales,
  alignOrder,
  filterExpandedCategories,
} from '../utils'
import type {
  UnionChart,
  NumericalChart as NumericalChartType,
  TableChart as TableChartType,
} from '../types'
import {
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
} from '../../filters/types'
import { Filters, ProjectState } from 'features/project/types'
import { useClickOutside } from 'shared/hooks'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { useHistory } from 'react-router-dom'
import { lightText, secondaryRed } from '../../../../../assets/styles/variables'
import AIcon from '../../../../../assets/images/icons/AIcon_180x180.png'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import ChartTools from './ChartTools'
import { NewTableChart } from '../features/new-table-chart'
import ResizableChartContainer from './ResizableChartContainer'
import useChartConfig from '../../../hooks/useChartConfig'
import useChartUpdate from '../../../hooks/useChartUpdate'
import { useChartData } from '../../../hooks/useChartData'
import ChartTitle from './ChartTitle'
import useChart from '../../../hooks/useChart'

ChartJS.register(
  ChartDataLabels,
  ChartAnnotations,
  MatrixController,
  MatrixElement
)
type Props = {
  filters: Filters
  chart: UnionChart
  comparativeIndex: number
  isComparative: boolean
  size?: string
  isLink?: boolean
  insightChartList?: any
  title?: string
  headerContent?: any
  localDashboardControls?: any
  setLocalDashboardControls?: (localDashboardControls: any) => void
  updateLocalDashboardControls?: (key: string, value: any) => void
  localPaginationMin?: number
  legendRef: any
  splitRef: any
  initialLegendWidthPercent: number
  isEditing?: boolean
  hideHeader?: boolean
}

const exCharts = ['numerical_charts', 'table_charts']
export const Chart: React.FC<Props> = ({
  filters,
  chart,
  comparativeIndex,
  isComparative,
  size,
  isLink,
  insightChartList,
  title,
  headerContent,
  localDashboardControls,
  updateLocalDashboardControls,
  initialLegendWidthPercent,
  splitRef,
  legendRef,
  isEditing,
  hideHeader,
}) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const globalDashboardControls = useProjectStore(
    (state: ProjectState) => state.dashboardControls
  )
  const orderedTableData = useProjectStore(
    (state: ProjectState) => state.orderedTableData
  )
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const currentChartList = useProjectStore(
    (state: ProjectState) => state.currentChartList
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen
  )
  const setSummaryRequest = useProjectStore(
    (state: ProjectState) => state.setSummaryRequest
  )
  const setIsAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsAlertDrawerOpen
  )
  const setAlertRequest = useProjectStore(
    (state: ProjectState) => state.setAlertRequest
  )

  const dashboardControls = localDashboardControls
    ? [localDashboardControls]
    : globalDashboardControls
  const {
    legend: displayLegend,
    fontSize,
    treeView,
    sortedByCategory,
    tier1,
    isAlignOrder,
    isAlignScales,
    reviewsCount,
    timeInterval,
    postType,
    showPercentChange,
    matrixFlipAxis,
    order: rootOrder,
    hideParentCategories,
  } = dashboardControls[0]

  const {
    tableSort,
    order,
    isDelta,
    pageSize,
    expandedCategories,
    columnsVisibility,
    tableState,
    isFullScreen,
    comparePeriod,
  } = dashboardControls[comparativeIndex] ?? {}

  const { data: feature_flags } = useFeatureFlags()
  const { clickHandler } = useChart()

  const expandedCategoriesList = useMemo(
    () => dashboardControls.map((el) => el.expandedCategories),
    [dashboardControls]
  )

  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls
  )

  const { ref: resizeRef, width: resizeWidth = 1 } =
    useResizeObserver<HTMLDivElement>()

  const [legendWidthPercent, setLegendWidthPercent] = useState<number>(0)

  useEffect(() => {
    setLegendWidthPercent(initialLegendWidthPercent ?? 0)
  }, [])

  useEffect(() => {
    updateDashboardControlsGeneral('expandedCategories', expandedCategories)
  }, [expandedCategories])

  const updateDashboardControlsGeneral = useMemo(
    () => (key: string, value: any) => {
      if (updateLocalDashboardControls) {
        updateLocalDashboardControls(key, value)
      } else {
        updateDashboardControls(comparativeIndex, key, value)
      }
    },
    [updateLocalDashboardControls, updateDashboardControls]
  )

  const wrapperRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartWrapperRef = useRef<HTMLDivElement>(null)
  const resizeChartWrapperRef = useRef<HTMLDivElement>(null)
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const clickedChart = useRef<any>()

  // this is the ChartJS instance
  const [chartAPI, setChartAPI] = useState<ChartJS | null>(null)
  // const [legend, setLegend] = useState<Array<ChartLegendType> | null>(null)
  const [highlightedLegendItems, setHighlightedLegendItems] = useState<any>([])
  const [currentChart, setCurrentChart] = useState(chart)
  const [isEditingTitle, setIsEditingTitle] = useState(!!isEditing)
  const [chartTitle, setChartTitle] = useState<string | undefined>(undefined)

  // If you have some items expanded, and then modify the filter such that the
  // parent value no longer exists in the return set, we don't want to include
  // it in the expanded categories list
  const filteredExpandedCategories: string[] = useMemo(() => {
    return filterExpandedCategories(currentChart, expandedCategories)
  }, [expandedCategories, currentChart])

  const isAlignOrderTypeChart =
    chart.chart_type === ChartTypes.TABLE ||
    chart.chart_type === ChartTypes.BAR ||
    chart.chart_type === ChartTypes.STACK_BAR ||
    chart.chart_type === ChartTypes.MATRIX
  const isAlignScalesTypeChart =
    chart?.chart_type === ChartTypes.STACK_BAR ||
    chart?.chart_type === ChartTypes.BAR ||
    chart?.chart_type === ChartTypes.BUBBLE ||
    chart?.chart_type === ChartTypes.LINE

  const disableGrouping =
    (tier1 === 'Brand' && details?.disable_brand_grouping) ||
    (tier1 === 'Product' && details?.disable_brand_grouping)

  // TODO should this also go in a hook? we have 3 useEffects that modify the currentChart
  useEffect(() => {
    if (
      comparativePanelsNumber > 1 &&
      chart.chart_type !== ChartTypes.TABLE &&
      chart.chart_type !== ChartTypes.PIE &&
      chart.chart_type !== ChartTypes.NUMERICAL &&
      chart.chart_type !== ChartTypes.MATRIX
    ) {
      if (isAlignOrder && isAlignScales && comparativeIndex !== undefined) {
        setCurrentChart(
          alignScales(
            isAlignOrderTypeChart
              ? alignOrder(
                  chart,
                  insightChartList ?? currentChartList,
                  comparativeIndex,
                  filteredExpandedCategories,
                  order,
                  sortedByCategory
                )
              : chart,
            currentChartList,
            dashboardControls,
            comparativeIndex
          )
        )
      } else if (isAlignScales && comparativeIndex !== undefined) {
        setCurrentChart(
          alignScales(
            chart,
            currentChartList,
            dashboardControls,
            comparativeIndex
          )
        )
      } else if (isAlignOrder && comparativeIndex !== undefined) {
        setCurrentChart(
          isAlignOrderTypeChart
            ? alignOrder(
                chart,
                insightChartList ?? currentChartList,
                comparativeIndex,
                filteredExpandedCategories,
                order,
                sortedByCategory
              )
            : chart
        )
      } else {
        setCurrentChart(chart)
      }
    } else {
      setCurrentChart(chart)
    }
  }, [
    isAlignOrder,
    isAlignScales,
    currentChartList,
    chart,
    expandedCategoriesList,
    rootOrder,
  ])

  const [timeoutId, setTimeoutId] = useState<any>(null)

  // useEffect(() => {
  //   if (chartAPI) {
  //     debounceChartUpdate(chartAPI)
  //   }
  // }, [chart])

  // handles a ChartJS bug that, when you switch tabs, the chart doesn't redraw
  function handleVisibilityChange() {
    if (!document.hidden) {
      // Tab is visible again
      // Force a redraw of the chart
      // chartAPI?.resize()
      chartAPI?.update()
    }
  }
  // Add event listener for visibilitychange
  document.addEventListener('visibilitychange', handleVisibilityChange)

  function debounceChartUpdate(chart: ChartJS, delay = 100) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }

    let timeout = setTimeout(() => {
      chart.update('none')
    }, delay)
    setTimeoutId(timeout)
  }

  const [disabledCategories, setDisabledCategories] = useState<string[]>([])

  const {
    currentConfiguredChart,
    currentPage,
    toggleOrder,
    onPageChange,
    isPreviousChartLoading,
  } = useChartConfig({
    currentChart,
    chartRef,
    dashboardControls,
    comparativeIndex,
    isComparative,
    comparativePanelsNumber,
    filters,
    updateDashboardControls: updateDashboardControlsGeneral,
    filteredExpandedCategories,
    disabledCategories,
    disableGrouping,
  })

  const { updatedChart } = useChartUpdate({
    // legend,
    isFullScreen,
    currentConfiguredChart,
    chartRef,
    treeView,
  })

  const [prevChartType, setPrevChartType] = useState<string | undefined>('')

  // should be able to pull all updates into here
  useEffect(() => {
    if (currentConfiguredChart && updatedChart && chartRef.current) {
      const { type, legend: legendObject, plugins } = currentConfiguredChart

      const { data: dataObject, options } = updatedChart

      const newPlugins = plugins
        ? [...plugins, contextMenuPlugin]
        : [contextMenuPlugin]

      if (prevChartType !== type) {
        setPrevChartType(type)
        if (chartAPI) {
          chartAPI.destroy()
        }
        const chart = new ChartJS(chartRef.current, {
          type,
          options,
          data: dataObject,
          plugins: newPlugins as any,
        })
        setChartAPI(chart)
        // setLegend(legendObject)
      } else {
        if (chartAPI) {
          chartAPI.data = dataObject
          chartAPI.options = options
          debounceChartUpdate(chartAPI)
          // // chartAPI.destroy()
        } else {
          const chart = new ChartJS(chartRef.current, {
            type,
            options,
            data: dataObject,
            plugins: newPlugins as any,
          })
          setChartAPI(chart)
        }
      }
    }
  }, [updatedChart])

  const [prevRange, setPrevRange] = useState<
    AppliedFilterOptionRange | undefined
  >(undefined)

  useEffect(() => {
    if (currentChart?.chart_type === ChartTypes.BUBBLE && chartAPI) {
      debounceChartUpdate(chartAPI)
    }
  }, [chartAPI])

  useEffect(() => {
    highlightHoveredData(
      chartAPI,
      currentChart,
      highlightedLegendItems,
      treeView
    )
  }, [highlightedLegendItems.length])

  useClickOutside(contextMenuRef, () => {
    const menu = contextMenuRef.current
    if (menu && menu.style.display !== 'none') {
      clickedChart.current = []
      menu.style.display = 'none'
    }
  })

  function handleLeftClick(e: React.MouseEvent) {
    const menu = contextMenuRef.current
    e.preventDefault()
    e.stopPropagation()

    // TODO does removing this introduce a bug? why is filterlist not as expected?
    if (
      !clickedChart.current ||
      clickedChart.current.length === 0 ||
      currentChart.chart_id === '9_3_26_rating' ||
      currentChart.chart_id === '9_3_27_sentiment' ||
      currentChart.chart_id === '9_3_28_rating' ||
      currentChart.chart_id === '9_3_29_sentiment' ||
      currentChart.chart_id === '9_3_1_themes' ||
      (currentChart.chart_type === ChartTypes.LINE &&
        (chartAPI as any)?.data?.datasets[
          clickedChart.current[0].datasetIndex
        ] &&
        ((chartAPI as any)?.data?.datasets[clickedChart.current[0].datasetIndex]
          ?.label === 'My Brands' ||
          (chartAPI as any)?.data?.datasets[
            clickedChart.current[0].datasetIndex
          ]?.label === 'Competitors'))
    ) {
      if (menu) {
        // menu.style.display = 'none'
        setTimeout(() => {
          menu.style.display = 'none'
        }, 110) // need to avoid race condition on useClickOutside hook
      }
      return
    }

    if (menu) {
      setTimeout(() => {
        try {
          menu.style.left = e.clientX + 'px'
          menu.style.top = e.clientY + 'px'
          menu.style.display = 'block'
        } catch (e) {
          console.log('error', e)
        }
      }, 110) // need to avoid race condition on useClickOutside hook
    }
    return false
  }

  const contextMenuPlugin = {
    afterInit: (chart: any) => {
      chart.canvas?.addEventListener('click', handleLeftClick, false)
    },
  }

  const contextMenuTableChartAdapter = (e: React.MouseEvent) => {
    const menu = contextMenuRef.current

    const handleContextMenu = (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      e.persist()

      if (
        !clickedChart.current ||
        clickedChart.current.length === 0 ||
        currentChart.chart_id === '9_3_26_rating' ||
        currentChart.chart_id === '9_3_27_sentiment' ||
        currentChart.chart_id === '9_3_28_rating' ||
        currentChart.chart_id === '9_3_29_sentiment' ||
        currentChart.chart_id === '9_3_1_themes'
      )
        return

      if (menu) {
        setTimeout(() => {
          try {
            menu.style.left = e.clientX + 'px'
            menu.style.top = e.clientY + 'px'
            menu.style.display = 'block'
          } catch (e) {
            console.log('error', e)
          }
        }, 110) // need to avoid race condition on useClickOutside hook
      }
      return false
    }

    handleContextMenu(e)
  }

  const clickHandlerTableChartAdapter = (e: React.MouseEvent, row: any) => {
    if (tier1 === 'Theme' && row.getParentRow()) {
      clickedChart.current = [
        {
          ...row.original,
          category: `${row.getParentRow().original.category}: ${
            row.original.category
          }`,
        },
      ]
    } else {
      clickedChart.current = [row.original]
    }
    contextMenuTableChartAdapter(e)
  }

  const contextMenuHandlerLeftClick = (click: any) => {
    const localClickedChart = chartAPI?.getElementsAtEventForMode(
      click,
      'nearest',
      { intersect: true },
      true
    )

    clickedChart.current = localClickedChart
  }

  if (chartRef && chartRef.current) {
    ;(chartRef.current as any).onclick = contextMenuHandlerLeftClick
  }

  const isValuableChartEmpty =
    currentChart &&
    isValuableChart(currentChart) &&
    currentChart.values?.length === 0

  const { data: chartData } = useChartData(
    comparativeIndex,
    filters,
    tier1,
    currentChart.chart_id,
    postType,
    timeInterval,
    reviewsCount,
    false
  )

  // maybe break this out
  if ((!currentChart || isValuableChartEmpty) && !currentChart?.msg) {
    return (
      <EmptyContainer size={size}>
        <ChartWrapper style={{ width: '100%', background: 'transparent' }}>
          <EmptyWrapper>
            <Empty description={'No data to visualize with current filters'} />

            {!!reviewsCount && (
              <ReviewCountWrapper>
                <ReviewCount>
                  <div>
                    Minimum R&R Set to{' '}
                    <span style={{ fontWeight: 500 }}>{reviewsCount}</span>
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      color: secondaryRed,
                    }}
                    onClick={() => {
                      updateDashboardControls(0, 'reviewsCount', 0)
                    }}
                  >
                    <CloseOutlined style={{ marginTop: '-2px' }} /> Clear
                  </div>
                </ReviewCount>
              </ReviewCountWrapper>
            )}
          </EmptyWrapper>
        </ChartWrapper>
      </EmptyContainer>
    )
  }

  const isCanvasChart =
    currentChart.chart_type &&
    !isValuableChartEmpty &&
    !exCharts.includes(currentChart.chart_type)

  return (
    <Wrapper
      ref={wrapperRef}
      params={{
        fontSize: fontSize,
        canvasChart: !!isCanvasChart,
        size: size,
      }}
    >
      {/* optional override for header content - used in custom dashboards*/}
      {headerContent ? (
        <>{headerContent}</>
      ) : (
        <>
          {!hideHeader && (
            <Flex
              ref={resizeRef}
              className={'drag-handle'}
              style={{ cursor: isEditing ? 'move' : 'inherit' }}
            >
              <ChartTitle
                currentChart={currentChart}
                isEditing={isEditingTitle}
                fontSize={fontSize}
                chartTitle={chartTitle}
                setChartTitle={setChartTitle}
                filters={filters}
                dashboardControls={dashboardControls[0]}
                isLink={isLink}
                comparativePanelsNumber={comparativePanelsNumber}
                comparativeIndex={comparativeIndex}
                updateDashboardControls={updateDashboardControlsGeneral}
              />
              <ChartTools
                wrapperRef={wrapperRef}
                chartWrapperRef={chartWrapperRef}
                currentChart={currentChart}
                comparativeIndex={comparativeIndex}
                isDelta={isDelta}
                isUnlinked={true}
                currentPage={currentPage}
                pageSize={pageSize}
                order={order}
                toggleOrder={toggleOrder}
                onPageChange={onPageChange}
                prevRange={prevRange}
                fontSize={fontSize}
                isFullScreen={isFullScreen}
                expandedCategories={filteredExpandedCategories}
                sortedByCategory={sortedByCategory}
                chartData={chartData}
                comparePeriod={comparePeriod}
                timeInterval={timeInterval}
                isPreviousChartLoading={isPreviousChartLoading}
                hideParentCategories={hideParentCategories}
                disableGrouping={disableGrouping}
              />
            </Flex>
          )}
        </>
      )}

      <Divider style={{ margin: 0 }} />
      {currentChart && !isValuableChartEmpty ? (
        <ChartWrapper
          ref={chartWrapperRef}
          style={{
            // todo YEAH IK
            maxHeight:
              currentChart.chart_type === ChartTypes.TABLE || size
                ? ''
                : `calc(100% - ${
                    route.isDashboard ? '65px' : route.isCharts ? '55px' : '0px'
                  })`,
            height:
              currentChart.chart_type === ChartTypes.TABLE || size
                ? 'auto'
                : '100%',
            width: 'auto',
            borderRadius: '5px',
          }}
          size={size}
          canvasChart={!!isCanvasChart}
        >
          <span
            style={{
              position: 'absolute',
              height: 0,
              width: 'calc(100% - 150px)',
            }}
            ref={splitRef}
          ></span>
          {currentChart.chart_type === ChartTypes.NUMERICAL ||
          currentChart.chart_type === ChartTypes.TABLE ? (
            <>
              {currentChart.chart_type === ChartTypes.NUMERICAL && (
                <NumericalChart data={currentChart as NumericalChartType} />
              )}
              {currentChart.chart_type === ChartTypes.TABLE &&
                !isValuableChartEmpty && (
                  <NewTableChart
                    key={hideParentCategories ? 'hideParentCategories' : ''}
                    data={currentChart as TableChartType}
                    orderedTableData={orderedTableData}
                    isAlignOrder={isAlignOrder}
                    comparativeIndex={comparativeIndex}
                    rowContextCb={(e, row) => {
                      clickHandlerTableChartAdapter(e, row)
                    }}
                    filterValues={filters}
                    tier1={tier1}
                    timeInterval={timeInterval}
                    postType={postType}
                    isDelta={isDelta}
                    showPercentChange={showPercentChange}
                    pageSize={pageSize}
                    tableSort={tableSort}
                    prevRange={prevRange}
                    setPrevRange={setPrevRange}
                    expandedCategories={filteredExpandedCategories}
                    columnsVisibility={columnsVisibility}
                    updateDashboardControls={updateDashboardControlsGeneral}
                    tableState={tableState}
                    hideParentCategories={hideParentCategories}
                    disableGrouping={disableGrouping}
                  />
                )}
            </>
          ) : (
            <Split
              sizes={[100 - (legendWidthPercent ?? 0), legendWidthPercent ?? 0]}
              // TODO leftoff - might be time to bring the loading into here instead of above
              gutterSize={displayLegend ? 7 : 0}
              direction="horizontal"
              className="split"
            >
              {currentChart?.chart_type &&
              !isValuableChartEmpty &&
              !exCharts.includes(currentChart.chart_type) ? (
                <>
                  <CanvasWrapper size={size}>
                    <canvas
                      ref={chartRef}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </CanvasWrapper>
                  <LegendContainer
                    ref={legendRef}
                    style={{
                      display:
                        currentConfiguredChart?.legend &&
                        displayLegend &&
                        !isValuableChartEmpty
                          ? 'block'
                          : 'none',
                      minWidth: comparativePanelsNumber > 2 ? '130px' : '175px',
                    }}
                  >
                    {currentConfiguredChart?.legend &&
                      displayLegend &&
                      !isValuableChartEmpty && (
                        <LegendWrapper>
                          <ChartLegend
                            legend={currentConfiguredChart?.legend}
                            // change={(items) => setLegend(items)}
                            setHighlightedLegendItems={
                              setHighlightedLegendItems
                            }
                            disabledLegendItems={disabledCategories}
                            setDisabledLegendItems={setDisabledCategories}
                            expandedCategories={filteredExpandedCategories}
                            currentChart={currentChart}
                            updateDashboardControls={
                              updateDashboardControlsGeneral
                            }
                            tier1={tier1}
                          />
                        </LegendWrapper>
                      )}
                  </LegendContainer>
                </>
              ) : (
                <></>
              )}
            </Split>
          )}
        </ChartWrapper>
      ) : (
        <ChartWrapper>
          <EmptyWrapper>
            <TextCenter>{currentChart.msg}</TextCenter>
          </EmptyWrapper>
        </ChartWrapper>
      )}
      <ContextMenu ref={contextMenuRef} style={{ display: 'none' }}>
        <ContextMenuListItem
          className="menu-item"
          onClick={() => {
            const clickObject = clickHandler(
              contextMenuRef,
              clickedChart,
              currentChart,
              chartAPI,
              treeView,
              projectId,
              filters,
              tier1,
              !!matrixFlipAxis,
              comparePeriod,
              timeInterval
            )

            if (clickObject) {
              mixpanel.track('summary control', {
                action: 'chart',
                value: clickObject.body,
                ...route,
              })
              setIsAlertDrawerOpen(false)
              setSummaryRequest(clickObject)
              setSummaryIsOpen(true)
            }
          }}
          style={{
            display: 'flex',
            gap: 5,
          }}
        >
          <div>Summarize</div>
          <img
            style={{
              height: 22,
              width: 22,
            }}
            src={AIcon}
          />
        </ContextMenuListItem>
        <ContextMenuListItem
          className="menu-item"
          onClick={() => {
            const clickObject = clickHandler(
              contextMenuRef,
              clickedChart,
              currentChart,
              chartAPI,
              treeView,
              projectId,
              filters,
              tier1,
              !!matrixFlipAxis,
              comparePeriod,
              timeInterval
            )

            if (clickObject) {
              mixpanel.track('alert', {
                action: 'chart',
                value: clickObject.body,
                ...route,
              })

              const parsedClickFilters: Filters = {
                values: clickObject.body.criteria,
                searchQuery: clickObject.body.search_terms,
                searchCondition: clickObject.body.search_criteria,
              }

              parsedClickFilters.values.filter(
                (el) => el.field !== 'create_time'
              )

              if (clickObject) {
                setSummaryIsOpen(false)
                setAlertRequest(parsedClickFilters)
                setIsAlertDrawerOpen(true)
              }
            }
          }}
          style={{
            display: 'flex',
            gap: 5,
          }}
        >
          <div>Set Alert</div>
          <AlertOutlined
            style={{ marginTop: -1, marginRight: 3 }}
          ></AlertOutlined>
        </ContextMenuListItem>
      </ContextMenu>
      {/* this sucks but i couldnt get it to work when i broke this out into a component so its gonna be like this for now */}
      {!!feature_flags?.allow_fullscreen && !!isFullScreen && (
        <ResizableChartContainer
          comparativeIndex={comparativeIndex}
          dashboardControls={dashboardControls}
          updateDashboardControls={updateDashboardControlsGeneral}
          currentChart={currentChart}
          filters={filters}
          prevRange={prevRange}
          setPrevRange={setPrevRange}
          filteredExpandedCategories={filteredExpandedCategories}
          legendRef={legendRef}
          currentConfiguredChart={currentConfiguredChart}
          highlightedLegendItems={highlightedLegendItems}
          setHighlightedLegendItems={setHighlightedLegendItems}
          disabledCategories={disabledCategories}
          setDisabledCategories={setDisabledCategories}
        />
      )}
    </Wrapper>
  )
}

type WrapperProps = {
  params: { fontSize?: string; canvasChart?: boolean; size: string | undefined }
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  align-items: stretch;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  ${({ params: { canvasChart, size } }: WrapperProps) => {
    if (canvasChart && !size) return 'min-height: 100%'
    if (!canvasChart) return 'height: 100%'
  }};

  border-radius: var(--border-radius);

  @media print {
    page-break-after: always;
    padding: var(--default-padding);
  }

  * {
    font-size: ${({ params: { fontSize } }: WrapperProps) =>
      fontSizeDict[fontSize || 'medium']};
  }

  .split {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .gutter-horizontal {
    background: rgba(0, 0, 0, 0.06);
    cursor: ew-resize;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: ew-resize;
  }
`

export const ChartWrapper = styled.div<{
  canvasChart?: boolean
  size?: string
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  //min-height: inherit;
  background: white;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  overflow-x: auto;
`

export const CanvasWrapper = styled.div<{ size?: string }>`
  flex: 1;
  width: fit-content;
  background: white;
  //padding: var(--default-padding-half);
  padding: 10px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  max-height: ${(props) => {
    if (props.size === 'small') {
      return '200px'
    }
    if (props.size === 'medium') {
      return '400px'
    }
    if (props.size === 'large') {
      return '1000px'
    }
    return '100%'
  }};
  overflow: auto;
  overflow-y: overlay;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 11px var(--default-padding-half);
  background: white;
  justify-content: space-between;

  & > div {
    margin-left: 5px;
  }

  border-radius: var(--border-radius) var(--border-radius) 0 0;
`
export const LegendContainer = styled.div`
  background: white;
  overflow-y: auto;
  margin: 8px 0px;
  display: flex;
  overflow-x: hidden;
  padding: 0 8px;
`

export const LegendWrapper = styled.div`
  width: 100%;
`

const EmptyContainer = styled.div<{ size?: string }>`
  min-height: calc(100vh - 185px);
  display: flex;
  align-items: center;
  min-height: ${(props) => {
    if (props.size === 'small') {
      return '200px'
    }
    if (props.size === 'medium') {
      return '400px'
    }
    if (props.size === 'large') {
      return '1000px'
    }
    return 'calc(100vh - 185px)'
  }};
  overflow: auto;
`

export const EmptyWrapper = styled.div`
  padding: var(--default-padding-double);
  width: 100%;
`

export const TextCenter = styled.div`
  text-align: center;
`

const ContextMenu = styled.div`
  position: fixed;
  border: 1px solid #dedede;
  background: white;
  list-style: none;
  border-radius: 0 6px 6px;
  padding: 0 2px;
  z-index: 100;

  .menu-item {
    font-size: 14px;
    line-height: 24px;
    width: 13em;
    font-family: sans-serif;
    padding: 4px 10px;
    border-bottom: 1px solid #eee;
  }

  .menu-item:hover {
    background: #dddddd;
    text-decoration: underline;
    cursor: pointer;
  }
`

const ReviewCountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`
const ReviewCount = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 8px;
  padding: 10px 20px;
  border: 2px solid ${lightText};
`

const ContextMenuListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
