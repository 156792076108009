import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form as antdForm } from 'antd'
import { Input } from '../components/input'
import { Button } from '../components/button'
import { FormItem } from '../components/form_item'
import { FormSent } from '../components/form_sent'
import { ServerValidationError } from '../components/server_validation_error'
import { AuthContext } from 'features/auth'
import { AuthWrapper } from 'shared/wrappers'
import { Loader } from 'shared/components'
import { signinURL } from 'utils/links'
import ArrowLeft from 'assets/images/Arrow-Left.svg'
import { resetPasswordSendCode } from '../model'
import { YogiButton } from '../../../components/UI/YogiButton'

const layout = {
  layout: 'vertical',
}

const ResetPasswordSchema = Yup.object().shape({
  username: Yup.string().email('Invalid email').required('Required'),
})

export const ResetPasswordForm = () => {
  const [isFormSent, setIsFormSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [serverValidationError, setServerValidationError] = useState('')
  const authContext = useContext(AuthContext)
  const formik = useFormik({
    initialValues: { username: '' },
    validationSchema: ResetPasswordSchema,
    onSubmit: async ({ username }) => {
      try {
        setLoading(true)
        let normalized_username = username.toLowerCase()
        await resetPasswordSendCode(normalized_username)
        authContext.updateUsername(normalized_username)
        setIsFormSent(true)
      } catch (e) {
        setLoading(false)
        setServerValidationError(
          e.code === 'UserNotFoundException'
            ? 'Email address not found'
            : e.message
        )
      }
    },
  })

  const onChange = (e) => {
    setServerValidationError('')
    formik.handleChange(e)
  }

  const usernameError = formik.touched.username && formik.errors.username
  return (
    <AuthWrapper>
      <Wrapper>
        {!isFormSent ? (
          <Form onFinish={formik.submitForm} {...layout}>
            <FormTitle>Reset Password</FormTitle>
            <Description>
              Please enter your email below and we will send you a reset link
            </Description>
            <StyledFormItem
              label="Work Email"
              validateStatus={usernameError && 'error'}
              help={usernameError}
            >
              <Input
                placeholder="john.smith@acmelabs.com"
                name="username"
                autoComplete="username"
                onChange={onChange}
                value={formik.values.username}
              />
            </StyledFormItem>
            {serverValidationError && (
              <ServerValidationError message={serverValidationError} />
            )}
            <Bottom>
              <YogiButton
                type="primary"
                htmlType="submit"
                style={{
                  width: '100%',
                  maxWidth: '273px',
                  margin: '0 auto',
                  height: '50px',
                }}
              >
                Submit
              </YogiButton>
              <Back>
                <StyledArrowLeft src={ArrowLeft} />
                Back to <StyledLink to={signinURL}>Login Page</StyledLink>
              </Back>
            </Bottom>
            {loading && (
              <Overlay>
                <Loader />
              </Overlay>
            )}
          </Form>
        ) : (
          <FormSent email={formik.values.username} />
        )}
      </Wrapper>
    </AuthWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  color: var(--color-auth-grey);
  background: #fff;
`

const Form = styled(antdForm)`
  position: relative;
  max-width: 410px;
  width: 100%;
  margin: 0 auto;
  padding: 35px 45px;

  color: var(--color-auth-grey);
  border: 2px solid var(--card-border-grey);
  border-radius: 6px;

  @media (max-width: 768px) {
    padding: 35px;
    border: none;
  }
`

const FormTitle = styled.div`
  margin-bottom: 9px;

  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
`

const Description = styled.div`
  margin-bottom: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  max-width: 273px;
`

const Bottom = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 22px;
  justify-content: space-between;
  align-items: center;
`

const Back = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  margin-top: 22px;
`

const StyledArrowLeft = styled.img`
  width: 20px;
  height: 16px;
  margin-right: 10px;
`

const StyledLink = styled(Link)`
  margin-left: 5px;
  font-size: var(--font-size-md);
`
const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const StyledFormItem = styled(FormItem)`
  .ant-input {
    background: white;
    border: 2px solid var(--card-border-grey);
    border-radius: 6px;
  }
`
