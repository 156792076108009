import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 30px 0;
  display: flex;
  gap: 30px;
  background: white;
  height: calc(100vh - 40px);
  width: 100%;
`

export const ErrorImage = styled.img`
  object-fit: contain;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  margin: -50px auto 0;
`

export const InfoContainer = styled.div`
  margin: 0 auto;
  font-size: 18px;
`
