import type { CSSProperties, FC } from 'react'
import { useDrag } from 'react-dnd'

import { ItemTypes } from './ItemTypes'
import React from 'react'
import styled from 'styled-components'
import { secondaryNavy } from '../../../../../../../assets/styles/variables'
import { Button } from 'antd'
import { AddBoxOutlined, VerticalSplitOutlined } from '@mui/icons-material'
import { useDashboardStore } from '../../../dashboardStore'

export interface DraggableChartProps {
  chart: any
  items: any[]
  // index: number
  addItem: (item: any) => void
  onAddItem: (item: any, layoutItem: any) => void
  tier1: string
}

interface DropResult {
  name: string
}

interface CardProps {
  selected?: boolean
  localSelected?: boolean
}

export const DraggableChart: FC<DraggableChartProps> = ({
  chart,
  items,
  addItem,
  tier1,
  onAddItem,
}) => {
  const setDraggedItem = useDashboardStore((state) => state.setDraggedItem)
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: chart,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResult>()
        if (item && dropResult) {
          addItem(item)
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [items]
  )
  const handleDragStart = (e: any) => {
    setIsHovering(false)
    e.dataTransfer.setData('text/plain', '')
    e.dataTransfer.effectAllowed = 'move'

    const draggableItem = {
      type: 'chart',
      content: { ...chart, tier1 },
    }
    setDraggedItem(draggableItem)
  }

  const [isHovering, setIsHovering] = React.useState(false)
  return (
    <div
      className="droppable-element drag-handle"
      draggable={true}
      unselectable="on"
      style={{ cursor: 'move', position: 'relative' }}
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      // this is a hack for firefox
      // Firefox requires some kind of initialization
      // which we can do by adding this attribute
      // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
      // onDragStart={(e) => e.dataTransfer.setData('text/plain', '')}
      onDragStart={handleDragStart}
    >
      {isHovering && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(180,207,255,0.4)',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: 5,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type={'default'}
              icon={
                <AddBoxOutlined
                  style={{ fontSize: 26, marginTop: -2, marginLeft: -1 }}
                />
              }
              onClick={() => {
                onAddItem(
                  {
                    type: 'chart',
                    content: { ...chart, tier1 },
                  },
                  {
                    w: 12,
                    h: 9,
                    // @ts-ignore
                    minW: 4,
                    // @ts-ignore
                    minH: 4,
                  }
                )
              }}
              style={{ display: 'flex', gap: 5 }}
            >
              Add Full Width
            </Button>
          </div>
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: 5,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type={'default'}
              icon={
                <VerticalSplitOutlined
                  style={{ fontSize: 25, marginTop: -2 }}
                />
              }
              onClick={() => {
                onAddItem(
                  {
                    type: 'chart',
                    content: { ...chart, tier1 },
                  },
                  {
                    w: 6,
                    h: 9,
                    // @ts-ignore
                    minW: 4,
                    // @ts-ignore
                    minH: 9,
                  }
                )
              }}
              style={{ display: 'flex', gap: 5 }}
            >
              Add Half Width
            </Button>
          </div>
        </div>
      )}
      <Chart
        unselectable="on"
        key={`${chart.name}_${chart.chart_ids?.[0]?.chart_id}`}
        selected={false}
      >
        <ChartImage>
          <img
            src={`${window.location.origin}/images/${chart.image}`}
            alt={chart.name}
            draggable={false}
          />
        </ChartImage>
        <ChartTitle selected={false}>{chart.name}</ChartTitle>
      </Chart>
    </div>
  )
}

const ChartTitle = styled.span`
  font-size: var(--font-size-md);
  text-align: center;
  margin-top: calc(var(--default-padding-half) / 2);
  color: black;
  ${({ selected }: CardProps) => selected && 'color: white;'};
`
const Chart = styled.div`
  width: 180px;
  padding: calc(var(--default-padding-half) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  border: none;
  z-index: 5;

  ${({ selected }: CardProps) =>
    selected && 'background-color: ' + secondaryNavy + ' !important;'}
`

const ChartImage = styled.span`
  & > img {
    max-width: 100%;
  }
`
