/* eslint-disable */
import mixpanel from 'features/trackers/mixpanel'
import { env, isProd } from 'utils/axios'
import * as Sentry from '@sentry/react'

export function runTrackers() {
  if (isProd) {
    mixpanel.init()
  }
  if (env !== 'local') {
    Sentry.init({
      dsn: 'https://4f92db334277daaa319ed99cece7da16@o4505937448468480.ingest.sentry.io/4505937451548672',
      integrations: [
        // new Sentry.BrowserTracing({
        //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //
        // }),
        new Sentry.Replay(),
      ],
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/api\.meetyogi\.com/,
        /^https:\/\/api-dev\.meetyogi\.com/,
        /^https:\/\/api-staging\.meetyogi\.com/,
      ],
      environment: env,
      // Performance Monitoring
      tracesSampleRate: env === 'prod' ? 0.1 : env === 'local' ? 0.0 : 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 'prod' ? 0.1 : env === 'local' ? 0.0 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
