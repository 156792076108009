import React from 'react'
import styled from 'styled-components'

import personImage from 'assets/images/YogiStroke.svg'
import YogiLogo from 'assets/images/logos/Peach_Horizontal.png'
import textImage from 'assets/images/YogiText.svg'

type Props = {
  children: React.ReactNode
}

export const AuthWrapper = ({ children }: Props) => (
  <Wrapper>
    <LogoBox>
      <Logo>
        <LogoImage src={YogiLogo} alt="logo-person" />
        {/*<LogoText src={textImage} alt="logo-text" />*/}
      </Logo>
    </LogoBox>
    <Body>
      {children}
      <Footer className="footer">
        <Copyright>
          Simulated Aptitude Inc. © All Rights Reserved 2023
        </Copyright>
        <Links>
          <PageLink
            href="https://yogi-app.s3.us-west-2.amazonaws.com/YogiTermsAndConditions.pdf"
            target="_blank"
          >
            Terms of Use
          </PageLink>
          <PageLink
            href="https://yogi-app.s3.us-west-2.amazonaws.com/YogiPrivacyPolicy.pdf"
            target="_blank"
          >
            Privacy Policy
          </PageLink>
        </Links>
      </Footer>
    </Body>
  </Wrapper>
)

const headerHeight = 72

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: #fff;
`

const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: ${headerHeight}px;
  padding-left: 46px;
  padding-right: 46px;
  max-width: 1440px;
  width: 100%;
  margin-left: 100%;
  margin-right: 100%;

  @media only screen and (max-width: 768px) {
    padding-left: 35px;
    padding-right: 35px;
  }
`

const Logo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const LogoImage = styled.img`
  height: 44px;
  margin-right: 10px;
`
const LogoText = styled.img`
  width: 45px;
  height: 24px;
  margin-top: 8px;
`
const Body = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  min-height: calc(100vh - ${headerHeight}px);
  width: 100%;
  padding-bottom: ${headerHeight}px;
`

const Footer = styled.div`
  margin-top: 42px;
`

const Links = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 31px;

  a {
    position: relative;
    margin-left: 22px;
  }

  a::before {
    content: '';
    display: block;
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #acb5bd;
  }

  a:first-child {
    margin-left: 0;
  }

  a:first-child::before {
    display: none;
  }
`

const Copyright = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: #acb5bd;
`

const PageLink = styled.a`
  color: #acb5bd;
  text-decoration: underline;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
