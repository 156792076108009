import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchClusters } from '../model'
import { Cluster, Domain, Group } from '../types'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'

export const useClusters = (
  projectId: string,
  comparativeIndex: number,
  domain?: Domain
) => {
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )
  const details = useProjectStore((state: ProjectState) => state.details)
  const isAdmin = details.role === 'admin'
  const nodesLimit =
    details.clientSettings.project.pages.network.nodes_limit || 5000
  const limit = isAdmin ? undefined : nodesLimit

  const [clusters, setClusters] = useState<Cluster[]>([])
  const [groups, setGroups] = useState<Group[]>([])
  const [error, setError] = useState<string | null>(null)
  const [totalNodes, setTotalNodes] = useState(0)

  const { data, isLoading, isFetching, refetch } = useQuery(
    [`clusters-${comparativeIndex}`, domain],
    () => {
      return fetchClusters(projectId, domain!.name, {
        limit,
        criteria: filterValues.values,
        search_terms: filterValues.searchQuery,
        search_criteria: filterValues.searchCondition,
      })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )

  useEffect(() => {
    if (data !== undefined) {
      setError(data?.data.msg || null)
      setClusters(data?.data.values || [])
      setGroups(data?.data.groups || [])
      setTotalNodes(data?.data.total_nodes || 0)
    }
  }, [data])

  useEffect(() => {
    if (domain) {
      refetch()
    }
  }, [
    projectId,
    filterValues.values,
    filterValues.searchQuery,
    filterValues.searchCondition,
    domain?.name,
  ])

  // useEffect(() => {
  //   filterList.setPageDataLoading(isFetching)
  // }, [isFetching])

  // useEffect(() => {
  //   return () => {
  //     filterList.setPageDataLoading(false)
  //   }
  // }, [])

  return {
    error,
    groups,
    totalNodes,
    clusters,
    isLoading,
    isFetching,
    fetchClusters: refetch,
  }
}
