import { AxiosResponse } from 'axios'
import {
  PROJECT_COUNTRIES,
  SETTINGS_UPDATE,
  POST_SUMMARY_URL,
  POST_SENTENCES_SUMMARY_URL,
  THEME_SUMMARY,
} from 'utils/apiLinks'
import { axiosInstance } from 'utils/axios'
import {
  DashboardChartPayload,
  DashboardChartResponse,
  DashboardChartListResponse,
  CountriesResponse,
  DashboardPayload,
  SummaryResponse,
  ReviewsCountResponse,
  PostSentences,
  GPTThemeSummary,
} from './types'
import { AppliedFilterOption } from '../filters/types'

export const fetchDashboard = (
  proj_uuid: string,
  payload: DashboardChartPayload,
  customParams?: any
): Promise<DashboardChartResponse> => {
  let config = { params: { proj_uuid } }
  if (customParams) {
    config = { ...config, ...customParams }
  }
  return axiosInstance
    .post('/dashboard/charts', payload, config)
    .then(({ data }) => data)
}

export const fetchMultiDashboard = (
  proj_uuid: string,
  payload: DashboardChartPayload[],
  customParams?: any
): Promise<Array<DashboardChartResponse>> => {
  let config = { params: { proj_uuid } }
  if (customParams) {
    config = { ...config, ...customParams }
  }
  return axiosInstance
    .post('/dashboard/multi-charts', payload, config)
    .then(({ data }) => data)
}
export const fetchChartsForSearching = (
  proj_uuid: string
): Promise<DashboardChartListResponse> =>
  axiosInstance
    .get('/dashboard/chart-search', { params: { proj_uuid } })
    .then(({ data }) => data)

export const getCountries = ({
  proj_uuid,
}: DashboardPayload): Promise<AxiosResponse<CountriesResponse>> =>
  axiosInstance.get(PROJECT_COUNTRIES, { params: { proj_uuid } })

export const getSummary = (
  params: any,
  body: any,
  customParams?: any
): Promise<SummaryResponse> => {
  let config = { params }
  if (customParams) config = { ...config, ...customParams }
  return axiosInstance
    .post(POST_SUMMARY_URL, body, config)
    .then(({ data }) => data)
}

export const getSummaryPosts = (
  params: any,
  body: any,
  customParams?: any
): Promise<AxiosResponse<PostSentences>> => {
  let config = { params }
  if (customParams) config = { ...config, ...customParams }
  return axiosInstance.post(POST_SENTENCES_SUMMARY_URL, body, config)
}

export const getReviewsCount = ({
  proj_uuid,
}: DashboardPayload): Promise<ReviewsCountResponse> =>
  axiosInstance
    .get('/dashboard/reviews-filter', { params: { proj_uuid } })
    .then(({ data }) => data)

export const updateSettings = (
  proj_uuid: string
): Promise<AxiosResponse<void>> =>
  axiosInstance.put(SETTINGS_UPDATE, ['theme'], { params: { proj_uuid } })

export const getInsightsList = (proj_uuid: string): any =>
  axiosInstance.options('/insights/options', { params: { proj_uuid } })

export const fetchInsights = (
  proj_uuid: string,
  payload: any,
  customParams?: any
): Promise<AxiosResponse<void>> => {
  let config = { params: { proj_uuid, insight_id: 1 } }
  if (customParams) {
    config = { ...config, ...customParams }
  }
  return axiosInstance
    .post('/insights', payload, config)
    .then(({ data }) => data)
}

interface ThemeSummaryPayload {
  criteria: AppliedFilterOption[]
  search_terms: string[]
  search_criteria: string[]
}

export const getThemeSummary = async (
  proj_uuid: string,
  payload: ThemeSummaryPayload
): Promise<GPTThemeSummary> => {
  let config = { params: { proj_uuid } }
  const { data } = await axiosInstance.post(THEME_SUMMARY, payload, config)
  return data
}
