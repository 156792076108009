import React, { useState } from 'react'
import {
  EyeInvisibleOutlined,
  GroupOutlined,
  CaretDownOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { Button, Checkbox, Modal } from 'antd'
import { Loader } from 'shared/components'
import {
  useClustersOperations,
  ModalType,
} from '../hooks/use-clusters-operations'
import { Cluster as ClusterComponent } from './cluster'
import { NewCluster } from './new-cluster'
import { useClusterContext } from '../cluster-provider'
import { prepareClusterList } from '../utils'
import { Cluster, Group } from '../types'
import { AssignThemeGroup } from './assign-theme-group'
import { ActionButtons } from './action-buttons'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'
import { useGroupProjectList } from '../../../../../hooks/useGroupProjectList'
import { AppliedFilterOptionSelect } from '../../../../filters/types'

type ModalWithCustomSeletedClusters = {
  modal: ModalType
  selectedClusters: string[]
}

const { confirm } = Modal
type Props = {
  comparativeIndex: number
}

export const ClusterList: React.FC<Props> = ({ comparativeIndex }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )
  const [modal, setModal] = useState<ModalWithCustomSeletedClusters | null>(
    null
  )

  const [isColorPickerOpened, setIsColorPickerOpened] = useState<boolean>(false)
  const { groups, clusters, selectedDomain, fetchClusters, isThemeDomain } =
    useClusterContext()

  const {
    editMode,
    isLoading,
    needToUpdateSettings,
    setNeedToUpdateSettings,
    setLoading,
    operations,
    setEditMode,
    onClusterClick,
    checkIfGroupIsSelected,
    selectGroup,
    unselectGroup,
    selectedClusters,
    restrictedOperations,
  } = useClustersOperations(clusters, fetchClusters)
  const [hiddenGroupIds, setHiddenGroupIds] = useState<number[]>(
    groups.map((g) => g.id)
  )
  const isAdmin = details.role === 'admin'

  const { data: groupProjects, isLoading: groupLoading } =
    useGroupProjectList(projectId)

  const isGroupProject =
    groupProjects?.data?.projects?.length &&
    groupProjects?.data?.projects?.length > 1

  let canEditGroup =
    !isGroupProject ||
    (isGroupProject &&
      (
        filterValues.values.find(
          (el) => el.field === 'proj_uuid'
        ) as AppliedFilterOptionSelect
      )?.values.length === 1)

  let selectedDataset =
    (
      filterValues.values.find(
        (el) => el.field === 'proj_uuid'
      ) as AppliedFilterOptionSelect
    )?.values.length === 1
      ? (
          filterValues.values.find(
            (el) => el.field === 'proj_uuid'
          ) as AppliedFilterOptionSelect
        )?.values[0]
      : null

  const nodesLength = clusters.reduce((acc, value) => {
    return acc + value.nodes.length
  }, 0)

  const { hasLength, data: clusterAndGroupList } = prepareClusterList(
    clusters.filter(
      (cluster) => !hiddenGroupIds.includes(cluster.group_id as number)
    ),
    isThemeDomain ? groups : [],
    editMode,
    isThemeDomain
  )

  if (!selectedDomain || isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  const confirmationWrapper = (callback: () => void) => {
    confirm({
      content: 'Are you sure?',
      onOk() {
        callback()
      },
      onCancel() {},
    })
  }

  const toggleGroup = (groupId: number) =>
    setHiddenGroupIds(
      hiddenGroupIds.includes(groupId)
        ? hiddenGroupIds.filter((g) => g !== groupId)
        : [...hiddenGroupIds, groupId]
    )
  // TODO: combine modals
  return (
    <Wrapper>
      <Scrollable>
        {clusterAndGroupList.map((groupOrCluster) => {
          const group = groupOrCluster as Group
          const cluster = groupOrCluster as Cluster
          const isCluster = !groupOrCluster.hasOwnProperty('group')

          const key = isCluster
            ? cluster.cluster_uuid || cluster.value
            : group.id
          const selectedIdentifier = isCluster
            ? cluster.cluster_uuid
            : group.group
          const isGroupHidden = hiddenGroupIds.includes(group.id)

          const isGroupSelected = checkIfGroupIsSelected(group.id)

          return (
            <ClusterWrapper key={key}>
              {editMode && isCluster && (
                <ClusterStatus>
                  <StyledCheckbox
                    checked={selectedClusters.includes(selectedIdentifier)}
                    onClick={() => onClusterClick(selectedIdentifier)}
                  />
                  {!cluster.visible && <EyeInvisibleOutlined />}
                </ClusterStatus>
              )}
              {editMode && !isCluster && (
                <ClusterStatus>
                  <StyledCheckbox
                    checked={isGroupSelected}
                    onClick={() =>
                      isGroupSelected
                        ? unselectGroup(group.id)
                        : selectGroup(group.id)
                    }
                  />
                </ClusterStatus>
              )}
              {isCluster && cluster.group_id !== undefined && <GroupOutlined />}
              <ClusterComponent
                data={group}
                isAdmin={isAdmin}
                editMode={editMode}
                projectId={projectId}
                field={selectedDomain}
                nodesLength={nodesLength}
                fetchClusters={fetchClusters}
                setLoading={setLoading}
                isColorPickerOpened={isColorPickerOpened}
                setIsColorPickerOpened={setIsColorPickerOpened}
                needToUpdateSettings={needToUpdateSettings}
                setNeedToUpdateSettings={setNeedToUpdateSettings}
                selectedDataset={selectedDataset}
              />
              {editMode && !isCluster && (
                <CaretWrapper
                  reversed={!isGroupHidden}
                  onClick={() => toggleGroup(group.id)}
                >
                  <CaretDownOutlined />
                </CaretWrapper>
              )}
            </ClusterWrapper>
          )
        })}
      </Scrollable>
      {isAdmin &&
        hasLength &&
        isThemeDomain &&
        canEditGroup &&
        (!editMode ? (
          <YogiButton type="primary" onClick={() => setEditMode(true)}>
            Edit mode
          </YogiButton>
        ) : (
          <ActionButtons
            operations={{ ...operations, setModal, setEditMode }}
            restrictedOperations={restrictedOperations}
            selectedClusters={selectedClusters}
            clusters={clusters}
            fetchClusters={fetchClusters}
            confirmationWrapper={confirmationWrapper}
            needToUpdateSettings={needToUpdateSettings}
            setNeedToUpdateSettings={setNeedToUpdateSettings}
          />
        ))}
      {modal && [ModalType.Merge, ModalType.Group].includes(modal.modal) && (
        <NewCluster
          modal={!!modal}
          onCreate={(clusterName) => {
            confirmationWrapper(() => {
              operations.onClusterOperation(
                clusterName,
                modal.modal,
                modal.selectedClusters
              )
              setModal(null)
            })
          }}
          onClose={() => setModal(null)}
          title={
            modal.modal === ModalType.Merge ? 'Merge Themes' : 'Group Themes'
          }
        />
      )}
      {modal && [ModalType.AssignToGroup].includes(modal.modal) && (
        <AssignThemeGroup
          isVisible={!!modal}
          onCreate={(clusterName) => {
            confirmationWrapper(() => {
              operations.onClusterOperation(
                clusterName,
                modal.modal,
                modal.selectedClusters
              )
              setModal(null)
            })
          }}
          onClose={() => setModal(null)}
        />
      )}
      {!hasLength && <NoResults>No results</NoResults>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
`
const Scrollable = styled.div`
  overflow-y: auto;
  flex: 1;
`
const ClusterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`
const ClusterStatus = styled.div`
  display: flex;
  align-items: center;
`
const StyledCheckbox = styled(Checkbox)`
  margin-right: 4px;
`
const NoResults = styled.div`
  font-size: var(--font-size-l);
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LoaderWrapper = styled.div`
  margin-bottom: auto;
`
const CaretWrapper = styled.div`
  font-size: 18px;
  cursor: pointer;
  color: var(--color-text-black);
  margin-top: 6px;

  ${({ reversed }: { reversed: boolean }) =>
    reversed ? 'transform: rotate(180deg);margin-top: 4px;' : ''}
`
