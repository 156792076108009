import React from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Button, Collapse } from 'antd'
import Markdown from 'marked-react'
import { Loader } from 'shared/components'

import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { getOutlierAnalysis } from '../model'
import { pageBackgroundGrey } from '../../../../../assets/styles/variables'

type OutlierAnalysisProps = {
  outlier: {
    data: Array<{ body: string }>
    weighted_zscore: number
    theme: string
    product: string
  }
  model: string
}

const { Panel } = Collapse

const TimeSeriesOutlierAnalysis: React.FC<OutlierAnalysisProps> = ({
  outlier,
  model,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const reviews = outlier.data.map((el) => el.body).filter(Boolean)

  const prompt = generatePrompt(outlier)

  const {
    data: analysis,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['timeseries-analysis', projectId, reviews, prompt, model],
    () => getOutlierAnalysis(projectId, reviews, prompt, model),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    }
  )

  return (
    <Container>
      <Content>
        {isLoading || isFetching ? (
          <LoaderWrapper>
            <Loader style={{ height: 100 }} />
          </LoaderWrapper>
        ) : (
          <>
            <Markdown>{analysis}</Markdown>
            <Collapse>
              <Panel header={`Reviews (${reviews.length})`} key="reviews">
                {reviews.map((review, index) => (
                  <ReviewItem key={index}>{review}</ReviewItem>
                ))}
              </Panel>
            </Collapse>
          </>
        )}
      </Content>
      <ButtonWrapper>
        <Button onClick={() => refetch()}>Refetch</Button>
      </ButtonWrapper>
    </Container>
  )
}

const generatePrompt = (outlier: OutlierAnalysisProps['outlier']) => {
  const satisfaction =
    outlier.weighted_zscore <= 0 ? 'dissatisfaction' : 'satisfaction'
  const sentimentType = outlier.weighted_zscore <= 0 ? 'negative' : 'positive'

  return `
What are the main causes of user ${satisfaction} in the given reviews for the theme of "${outlier.theme}"? Think step by step, be specific, and give examples to support your claims.

Note that the selection of reviews has been identified as a set of outliers for a specific period in time.

ONLY FOCUS ON user ${satisfaction} in the reviews

Emphasize specific explanations for why this set of reviews may be a ${sentimentType} outlier, and what the implications are for the product. Broad analyses are not helpful.

Insight about why this particular moment in time may be an outlier is key. If it is not clear that an item is particularly relevant to the time period, it is not a good explanation.

Present these specific explanations in a clear and concise manner, and separate them into their own sections.

Format in sections with Markdown headers, use bold to emphasize key points of analysis, and use bullet points for lists
  `.trim()
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  margin-bottom: 10px;
`

const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ReviewItem = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: ${pageBackgroundGrey};
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  margin: 10px 0;
`

export default TimeSeriesOutlierAnalysis
