import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SavedView, SavedViewPayload } from './types'
import { deleteSavedView, saveView } from './model'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { detectCurrentRoute, getCurrentRouteName } from '../../utils'
import { useQueryClient } from 'react-query'

export function useSavedViews() {
  const history = useHistory()

  const queryClient = useQueryClient()
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const setHasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.setHasLoadedFilters
  )
  const route = useProjectStore((state: ProjectState) => state.route)
  const setRoute = useProjectStore((state: ProjectState) => state.setRoute)

  const updateFilters = (view: SavedView) => {
    const viewUrl = view.page
    const searchUrl = view.search_url
    if (viewUrl) {
      setHasLoadedFilters(false)
      // if (route.isCharts) {
      //   // setTimeout(() => queryClient.resetQueries('multi-chart'), 2000)
      // } else {
      // queryClient.resetQueries('multi-chart')
      // }

      // todo - skip reset if going to same chart? NTH
      let url = `/project/${projectId}/${viewUrl}${searchUrl}`
      setHasLoadedFilters(false)
      // for some reason we have to set the route here /shrug
      // setRoute(detectCurrentRoute(url))
      // // if (route.isDashboard) {
      // //   // setTimeout(() => queryClient.resetQueries('multi-chart'), 2000)
      // // } else {
      // // queryClient.resetQueries('multi-chart')
      // // }
      //
      // setTimeout(() => {
      //   // this can race with hasLoadedFilters if we don't delay - not ideal but this works for now
      history.push(url)
      // }, 100)
    }
  }

  const deleteView = async (view_uuid: string) => {
    try {
      await deleteSavedView(projectId, view_uuid)
      await queryClient.resetQueries(['saved-views', projectId])
      toast.success('Filter deleted')
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail?.msg || 'Error deleting view.'
      )
    }
  }

  const saveSavedView = async (view: SavedViewPayload) => {
    try {
      await saveView(projectId, view)
      await queryClient.resetQueries(['saved-views', projectId])
      toast.success('View saved')
    } catch (error) {
      toast.error(
        (error as any).response.data.detail.msg || 'Error saving view.'
      )
    }
  }

  return {
    updateFilters,
    deleteView,
    saveView: saveSavedView,
  }
}
