import { AxiosResponse } from 'axios'
import { getS3 } from 'config'
import { axiosInstance, isProd } from 'utils/axios'
import {
  PROJECT_CREATE_URL,
  SOURCE_CONNECT_URL,
  PRODUCT_LIST_PROJECT_FORM,
} from 'utils/apiLinks'
import {
  CreateProjectResponse,
  FormProduct,
  ProjectSourcesPayload,
} from './types'

/**
 * Creates a new project
 * @param {string} title - Project name
 * @param {string} description - Project description
 * @returns {Promise<CreateProjectResponse>} UUID of created project
 */
export const createProject = (
  title: string,
  description: string
): Promise<AxiosResponse<CreateProjectResponse>> =>
  axiosInstance.post(PROJECT_CREATE_URL, { title, description })

/**
 *
 * @param {string} proj_uuid - The project UUID
 * @param {Array<ProjectSourceFile | ProjectSourceURL>} sources - The list of project sources
 * @param {Array<Product>} products - The list of products
 * @param {string} stopWords - Reserved words for the project source
 * @param {boolean} parse_sentences
 * @param {boolean} bypass_nlp
 * @param {boolean} source_addition
 * @returns nothing
 */
export const setProjectSources = ({
  sources,
  products,
  proj_uuid,
  stop_words,
  source_addition,
}: ProjectSourcesPayload): Promise<AxiosResponse<void>> =>
  axiosInstance.post(
    SOURCE_CONNECT_URL,
    {
      sources,
      products,
      stop_words,
      source_addition,
      sentiment_level: isProd ? 'high' : 'low',
    },
    { params: { proj_uuid } }
  )

/**
 *
 * @param {string} workingPath - The upload URL for the source
 * @param {File} file - The uploaded file
 * @param {string} bucket - Extra option
 * @returns nothing
 */
export const uploadFile = (
  workingPath: string,
  file: File,
  bucket: string
): Promise<void> => {
  const s3: any = getS3(bucket)

  return s3
    .putObject({
      Key: workingPath,
      Body: file,
      ContentType: 'text/csv',
    })
    .promise()
}

export async function getProjectProducts(
  proj_uuid: string
): Promise<FormProduct[]> {
  const { data } = await axiosInstance.get(PRODUCT_LIST_PROJECT_FORM, {
    params: { proj_uuid },
  })
  return data.items
}
