import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import {
  AppstoreAddOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import {
  initialState,
  useProjectStore,
} from '../project/projectStore/projectStore'
import { ProjectState } from '../project/types'
import { useComparativeNavigation } from '../project/hooks/useComparativeNavigation'
import { YogiButton } from '../../components/UI/YogiButton'
import { buttonBlue } from '../../assets/styles/variables'

type Props = {
  className?: string
}

export const ComparativeNavigation: React.FC<Props> = ({
  className,
}: Props) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )

  const isComparativeModeDisabled = useMemo(
    () =>
      details?.clientSettings.project?.comparative_mode?.hide_compare_mode ||
      false,
    [details]
  )

  const { addPanel, removePanel } = useComparativeNavigation()

  return (
    <Container className={className}>
      {isComparativeModeDisabled || (
        <>
          {comparativePanelsNumber > 1 ? (
            <Buttons>
              <ButtonWrapperLeft>
                <Tooltip
                  placement="topLeft"
                  title={'Remove compare mode panel'}
                >
                  <Button
                    disabled={
                      !isComparative ||
                      !comparativePanelsNumber ||
                      comparativePanelsNumber <= 1
                    }
                    onClick={removePanel}
                    style={{
                      padding: '0px 5px',
                      lineHeight: '.5',
                      height: '25px',
                    }}
                  >
                    <MinusOutlined style={{ background: 'none' }} />
                  </Button>
                </Tooltip>
              </ButtonWrapperLeft>
              <ButtonWrapperCenter>
                <div style={{ marginBottom: '-1px' }}>
                  {comparativePanelsNumber} Panels
                </div>
              </ButtonWrapperCenter>
              <ButtonWrapperRight>
                <Tooltip placement="topLeft" title={'Add compare mode panel'}>
                  <Button
                    disabled={
                      !comparativePanelsNumber || comparativePanelsNumber >= 3
                    }
                    onClick={addPanel}
                    style={{
                      padding: '0px 5px',
                      lineHeight: '.5',
                      height: '25px',
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              </ButtonWrapperRight>
            </Buttons>
          ) : (
            <YogiButton
              type={'primary'}
              style={{ padding: '0px 8px', height: '29px', marginRight: '0px' }}
              onClick={addPanel}
            >
              Add Panel <AppstoreAddOutlined />
            </YogiButton>
          )}
          {/*<Text>Panel Controls</Text>*/}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  & > * {
    margin-right: var(--default-padding-half);
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
`

const ButtonWrapperLeft = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: inherit;
  border: 2px solid #dde2e5;
  border-right: 1px solid #dde2e5;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  transition: 0.2s ease-in-out;
  overflow: hidden;
`

const ButtonWrapperCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: inherit;
  border: 2px solid #dde2e5;
  border-left: 1px solid #dde2e5;
  border-right: 1px solid #dde2e5;
  justify-content: center;
  padding: 0px 5px;
`

const ButtonWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: inherit;
  border: 2px solid #dde2e5;
  border-left: 1px solid #dde2e5;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: 0.2s ease-in-out;
  overflow: hidden;

  // &:hover {
  //   background: ${buttonBlue};
  //   color: white;
  // }
`

const Button = styled.button`
  padding: 0;
  color: var(--color-black-text);
  background: inherit;
  border: none;
  font-size: 20px;
  cursor: pointer;
  line-height: normal;

  :disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &:hover {
    background: ${buttonBlue};
    color: white;
  }
`

const Text = styled.div`
  font-size: 8px;
  width: 100%;
  text-align: center;
  margin: 0;
  color: var(--color-black-text);
`
