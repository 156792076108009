import React, { useMemo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { Filters } from '../../../types'
import useThemes from '../../../hooks/useThemes'
import OutlierChart from './FindingChart'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import AIcon from '../../../../../assets/images/icons/AIcon_180x180.png'

type OutlierProps = {
  outlier: any // Consider creating a more specific type for outlier
  filterValues: Filters
  model: string
}

const TIER_NAME_SENTIMENT = 'Sentiment'
const TIER_NAME_REVIEW_COUNT = 'Review Count'
const TIER_NAME_PRODUCT = 'Product'
const TIER_NAME_THEME = 'Theme'

const TimeSeriesFinding: React.FC<OutlierProps> = ({
  outlier,
  filterValues,
  model,
}) => {
  const { getClusterUUIDsFromThemeName } = useThemes()

  const activeFilters = useMemo(() => {
    const tempFilters = _.cloneDeep(filterValues)
    // tempFilters.values = filterValues.values.filter(
    //   (filter) => !['product_hierarchy', 'theme'].includes(filter.field)
    // )
    //
    // tempFilters.values.push(
    //   { field: 'product_hierarchy', values: [outlier.product] },
    //   {
    //     field: 'theme',
    //     values: getClusterUUIDsFromThemeName(
    //       outlier.theme.split(': ')[0],
    //       outlier.theme.split(': ')[1]
    //     ),
    //   },
    //   { field: 'score', min: 0, max: 5, value_type: 1 }
    // )

    return tempFilters
  }, [filterValues, outlier, getClusterUUIDsFromThemeName])

  const themeFilters = useMemo(() => {
    const tempFilters = _.cloneDeep(filterValues)
    // tempFilters.values = filterValues.values.filter(
    //   (filter) => !['product_hierarchy', 'theme'].includes(filter.field)
    // )
    //
    // tempFilters.values.push(
    //   // { field: 'product_hierarchy', values: [outlier.product] },
    //   {
    //     field: 'theme',
    //     values: getClusterUUIDsFromThemeName(
    //       outlier.theme.split(': ')[0],
    //       outlier.theme.split(': ')[1]
    //     ),
    //   },
    //   { field: 'score', min: 0, max: 5, value_type: 1 }
    // )

    return tempFilters
  }, [filterValues, outlier, getClusterUUIDsFromThemeName])

  const outlierChartItems = useMemo(
    () => [
      // createChartItem(
      //   '1_1_8_posts',
      //   TIER_NAME_REVIEW_COUNT,
      //   '# Reviews',
      //   activeFilters
      // ),
      createChartItem(
        '3_2_17_sentiment',
        TIER_NAME_SENTIMENT,
        'Product+Theme Sentiment Breakdown',
        activeFilters,
        outlier.weighted_zscore
      ),
      createChartItem(
        '3_2_17_sentiment',
        TIER_NAME_SENTIMENT,
        'All Products Theme Sentiment Breakdown',
        filterValues,
        outlier.weighted_zscore
      ),
      // createChartItem(
      //   '3_1_17_sentiment',
      //   TIER_NAME_SENTIMENT,
      //   'Average Sentiment',
      //   activeFilters
      // ),

      createChartItem(
        '5_5_17_sentiment',
        TIER_NAME_THEME,
        'Theme Ranking',
        themeFilters,
        outlier.weighted_zscore
      ),

      createChartItem(
        '5_5_17_sentiment',
        TIER_NAME_PRODUCT,
        'Product Ranking',
        themeFilters,
        outlier.weighted_zscore
      ),
      createChartItem(
        '3_4_21_sentiment',
        TIER_NAME_SENTIMENT,
        'Sentiment and Volume over Time',
        activeFilters,
        outlier.weighted_zscore
      ),
    ],
    [outlier]
  )

  return (
    <Container>
      {/*<h2 style={{ borderBottom: '4px solid #f2f2f2', paddingBottom: 10 }}>*/}
      {/*  [dataset] is{' '}*/}
      {/*  {outlier.weighted_zscore <= 0 ? 'underperforming' : 'overperforming'} in*/}
      {/*  the time period of <InfoValue>[time period]</InfoValue>*/}
      {/*</h2>*/}
      <ChartWrapper style={{ marginTop: 20 }}>
        <InfoSection style={{ minWidth: 600, maxWidth: 600 }}>
          <ChartSection
            style={{
              // height: item.title === 'Sentiment Breakdown' ? '300px' : 'auto',
              height: '100%',
              maxHeight: 300,
              // maxWidth: 600,
            }}
          >
            <OutlierChart chartItem={outlierChartItems[2]} />
          </ChartSection>
          <ChartSection
            style={{
              // height: item.title === 'Sentiment Breakdown' ? '300px' : 'auto',
              height: '100%',
              maxHeight: 300,
              // maxWidth: 600,
            }}
          >
            <OutlierChart chartItem={outlierChartItems[3]} />
          </ChartSection>
        </InfoSection>
        <div>
          <ChartWrapper>
            {/*<InfoSection>*/}
            {/*  <OutlierInfo outlier={outlier} />*/}
            {/*  <PanelBadges*/}
            {/*    filterValues={activeFilters}*/}
            {/*    comparativeIndex={0}*/}
            {/*    infoOnly*/}
            {/*    isSummary*/}
            {/*  />*/}
            {/*</InfoSection>*/}

            <InfoSection>
              <ChartSection
                style={{
                  // height: item.title === 'Sentiment Breakdown' ? '300px' : 'auto',
                  height: '100%',
                  maxHeight: 300,
                  minHeight: 300,
                }}
              >
                <OutlierChart chartItem={outlierChartItems[0]} />
              </ChartSection>
            </InfoSection>
            <InfoSection>
              <ChartSection
                style={{
                  // height: item.title === 'Sentiment Breakdown' ? '300px' : 'auto',
                  height: '100%',
                  maxHeight: 300,
                  minHeight: 300,
                }}
              >
                <OutlierChart chartItem={outlierChartItems[1]} />
              </ChartSection>
            </InfoSection>
          </ChartWrapper>
          <ChartSection
            style={{
              height: '300px',
              borderTop: '4px solid #f2f2f2',
              marginTop: 10,
              paddingTop: 10,
            }}
          >
            <OutlierChart chartItem={outlierChartItems[4]} />
            {/*</InfoSection>*/}
          </ChartSection>
        </div>
      </ChartWrapper>
    </Container>
  )
}

const OutlierInfo: React.FC<{ outlier: any }> = ({ outlier }) => (
  <InfoContainer>
    <InfoItem>
      Brand: <InfoValue>{outlier.brand}</InfoValue>
    </InfoItem>
    <InfoItem>
      Product: <InfoValue>{outlier.product}</InfoValue>
    </InfoItem>
    <InfoItem>
      Theme: <InfoValue>{outlier.theme}</InfoValue>
    </InfoItem>
    <InfoItem>
      Volume: <InfoValue>{outlier.volume}</InfoValue>
    </InfoItem>
    <InfoItem>
      Outlier Intensity:{' '}
      <InfoValue>{outlier.weighted_zscore.toFixed(2)}</InfoValue>
    </InfoItem>
  </InfoContainer>
)

const createChartItem = (
  chartId: string,
  tier1: string,
  title: string,
  filters: Filters,
  zscore: number
) => ({
  title: title,
  type: 'chart',
  content: {
    name: 'Rating',
    image: '4_16.png',
    chart_ids: [{ chart_id: chartId, model: null, field: null }],
    tier1,
  },
  dashboardControls: {
    chartId,
    tier1,
    tier2: null,
    tier3: null,
    legend: false,
    fullAxis: true,
    hasLabels: true,
    hasOverlay: false,
    treeView: false,
    origin: true,
    labels: true,
    trendlines: false,
    shadedZones: true,
    sortedByCategory: false,
    isAlignScales: true,
    isAlignOrder: true,
    multiChart: true,
    fontSize: 'medium',
    timeInterval: null,
    reviewsCount: 10,
    postType: 'post',
    interval: null,
    isExpandChart: false,
    order: zscore <= 0 ? 'asc' : 'desc',
    pageSize: 5,
    isDelta: false,
    showPercentChange: true,
    wrapLabels: true,
    maxAxesLength: 30,
    hideXAxis: true,
    hideYAxis: true,
  },
  filterValues: filters,
})

const Container = styled.div``
const ChartWrapper = styled.div`
  display: flex;
  gap: 20px;
`

const InfoSection = styled.div`
  //min-width: 400px;
  //max-width: 400px;
  border-right: 4px solid #f2f2f2;
  flex-grow: 1;
`

const AnalysisSection = styled.div`
  margin-top: 20px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const GradingSection = styled.div`
  min-width: 250px;
`

const InfoContainer = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  padding-right: 10px;
`

const InfoItem = styled.div`
  margin-bottom: 5px;
`

const InfoValue = styled.span`
  font-weight: 500;
`

const ChartContainer = styled.div`
  height: 400px;
`

const ChartSection = styled.div`
  //margin-top: 20px;
  overflow: hidden;
`

export default TimeSeriesFinding
