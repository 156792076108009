import { useQuery } from 'react-query'
import { getProject } from '../model'
import html2canvas from 'html2canvas'
import logo from '../../../assets/images/logos/Peach_Horizontal.png'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { Simulate } from 'react-dom/test-utils'
import copy = Simulate.copy

export const useHyperCopy = (
  selectedElement: HTMLElement | null,
  setSelectedElement: (
    value:
      | ((prevState: HTMLDivElement | null) => HTMLDivElement | null)
      | HTMLDivElement
      | null
  ) => void
) => {
  const [isCopying, setIsCopying] = useState(false)
  const [lastCopied, setLastCopied] = useState()

  useEffect(() => {
    if (selectedElement) {
      // selectedElement.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
      onCopy()
    }
  }, [selectedElement])
  const onCopy = async () => {
    setIsCopying(true)
    setTimeout(async () => {
      // if (chartWrapperRef.current && wrapperRef.current) {
      if (selectedElement) {
        let canvas = await html2canvas(selectedElement, { scale: 1.5 })
        var tempCanvas = document.createElement('canvas')
        var tempCtx = tempCanvas.getContext('2d')
        const text = 'Yogi'

        if (tempCtx) {
          // console.log(tempCtx)
          let cw: number, ch: number
          cw = tempCanvas.width = canvas.width
          ch = tempCanvas.height = canvas.height
          tempCtx.drawImage(canvas, 0, 0)
          tempCtx.font = '24px verdana'
          let textWidth = tempCtx.measureText(text).width
          tempCtx.globalAlpha = 0.5

          // TODO enable logo - has new styling in it
          const logoImg = document.createElement('img')
          logoImg.height = 50
          logoImg.width = 50
          logoImg.style.position = 'absolute'
          logoImg.style.left = '50%'
          logoImg.style.top = '0px'
          // logoImg.style.opacity = '.5'

          logoImg.onload = function () {
            // tempCtx!.drawImage(logoImg, cw / 2 - 174, -10, 174, 75)

            tempCanvas.toBlob(function (blob) {
              const item = new ClipboardItem({ 'image/png': blob ?? '' })
              navigator.clipboard.write([item])
            })
          }
          logoImg.src = logo
        }
        setIsCopying(false)
        // @ts-ignore
        setLastCopied(selectedElement)
        toast.info('Chart copied to clipboard')
        setSelectedElement(null)
      }
    }, 10)
  }

  return { isCopying, lastCopied }
}
