import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { SummaryResponse } from '../../charts/types'
import { HyperGrid } from '../../../../../components/HyperCopy/HyperGrid'
import {
  CampaignOutlined,
  ChatOutlined,
  FavoriteBorderOutlined,
  StarOutlineOutlined,
  ThumbUpOutlined,
} from '@mui/icons-material'
import {
  mainText,
  secondaryBlue,
  secondaryGreen,
  secondaryOrange,
  secondaryRed,
  starYellow,
  tertiaryGreen,
} from '../../../../../assets/styles/variables'
import {
  FallOutlined,
  LoadingOutlined,
  PartitionOutlined,
  RiseOutlined,
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import { useQuery } from 'react-query'
import { getSummary } from '../../charts/model'
import { FilterOption } from '../../filters/types'
import { Filters } from '../../../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { numberFormatter } from '../../../../../utils/numberFormat'
import { getAdjustedDateRangeString } from '../../charts/utils'
import { findDateWindow } from '../../../utils'
import { usePrevFilters } from '../../../hooks/usePrevFilters'

const infoPanelObj = {
  n_reviews_and_rating: {
    title: '# Reviews & Ratings',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  n_fragments: {
    title: '# Sentences',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  n_posts: {
    title: '# Reviews',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  n_rating_only: {
    title: '# Ratings Only',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  n_rating_avg: {
    title: 'Average Rating',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  n_sentiment_avg: {
    title: 'Average Sentiment',
    icon: <FavoriteBorderOutlined style={{ padding: '1px' }} />,
    color: secondaryGreen,
  },
  n_promo_posts: {
    title: 'Promotional Reviews',
    icon: <CampaignOutlined />,
    color: secondaryOrange,
  },
  n_recommended_posts: {
    title: 'Product Recommendations',
    icon: <ThumbUpOutlined />,
    color: secondaryBlue,
  },
  n_themes_total: {
    title: '# Related Themes',
    icon: <PartitionOutlined style={{ fontSize: '20px' }} />,
  },
  n_themes_provided: {
    title: '# Provided Themes',
    icon: <PartitionOutlined style={{ fontSize: '20px' }} />,
  },
}

type Props = {
  data: SummaryResponse | undefined
  cardName?: string
  summaryRequest: any
  filterValues: Filters
  filterList: FilterOption[]
  omittedOptions?: string[]
  columns?: number
}
export const Stats: React.FC<Props> = ({
  data,
  cardName,
  summaryRequest,
  filterValues,
  filterList,
  omittedOptions,
  columns = 3,
}) => {
  const dateWindows = useProjectStore((state) => state.dateWindows)
  const defaultFilterList = useProjectStore((state) => state.defaultFilterList)

  const colWidth = useMemo(() => 12 / columns, [columns])

  const { dateFilter, prevFilters } = usePrevFilters({
    filters: filterValues,
    dateWindows,
    defaultFilterList,
    prevIsDelta: false,
  })

  const prevPeriodText = useMemo(() => {
    const label = findDateWindow(
      dateWindows,
      dateFilter?.min,
      dateFilter?.max
    )?.label

    return label
      ? `in ${label}`
      : `from ${getAdjustedDateRangeString(dateFilter)}`
  }, [dateFilter])

  const prevSummaryRequest = JSON.parse(JSON.stringify(summaryRequest))

  prevSummaryRequest.body.search_terms = prevFilters.searchQuery
  prevSummaryRequest.body.search_criteria = prevFilters.searchCondition
  prevSummaryRequest.body.criteria = prevFilters.values

  const { isLoading: prevLoading, data: prevData } = useQuery(
    ['summary', prevSummaryRequest],
    () => getSummary(prevSummaryRequest.headers, prevSummaryRequest.body),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!prevSummaryRequest && !!dateFilter,
    }
  )

  const cardContent = (el: any, prev?: any) => {
    let percentChange =
      prev &&
      prev.value &&
      (Math.round(((el.value - prev.value) / prev.value) * 100) || 0)
    if (el.field === 'n_sentiment_avg' && prev && prev.value) {
      percentChange = el.value - prev.value
    }
    // @ts-ignore TODO: fix type
    const style = infoPanelObj[el.field]
    if (!style) return
    let color = style.color
    return (
      <InfoCard elementName={'summary-info-card'}>
        <ColorBar color={color} />
        <Info>
          <Data style={{ height: '24px' }}>
            <Icon style={{ color: color }}>{style.icon}</Icon>{' '}
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '1.1',
                  fontWeight: '500',
                }}
              >
                {el.value?.toLocaleString()}
              </div>
              {prevLoading && <LoadingOutlined />}
              {!!dateFilter &&
                prev &&
                percentChange !== undefined &&
                percentChange !== Infinity && (
                  <Tooltip
                    title={
                      <div>
                        {`Compared to ${prev?.value?.toLocaleString()}
                          ${prevPeriodText}`}
                      </div>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: '14px',
                          lineHeight: '1',
                          fontWeight: '400',
                          color:
                            percentChange === 0
                              ? mainText
                              : percentChange > 0
                              ? tertiaryGreen
                              : secondaryRed,
                        }}
                      >
                        <>
                          {percentChange >= 0 && '+'}
                          {numberFormatter(percentChange)}
                          {el.field !== 'n_sentiment_avg' && '%'}
                        </>
                      </div>
                      <div style={{ marginLeft: -5 }}>
                        {percentChange === 0 ? (
                          '-'
                        ) : percentChange > 0 ? (
                          <RiseOutlined style={{ color: tertiaryGreen }} />
                        ) : (
                          <FallOutlined style={{ color: secondaryRed }} />
                        )}
                      </div>
                    </div>
                  </Tooltip>
                )}
            </div>
          </Data>
          <Label>{style.title}</Label>
        </Info>
      </InfoCard>
    )
  }

  if (!data) return <div></div>
  return (
    <>
      {cardName ? (
        <>
          {data?.post_sentences_summary?.find((el) => el.field === cardName) &&
            cardContent(
              data?.post_sentences_summary?.find((el) => el.field === cardName),
              prevData?.post_sentences_summary?.find(
                (el) => el.field === cardName
              )
            )}
        </>
      ) : (
        <InfoCardWrapper container spacing={2}>
          {data?.post_sentences_summary?.map((el, index) => (
            <>
              {!(omittedOptions ?? []).includes(el.field) &&
                el.field !== 'n_themes_provided' && (
                  <Grid key={index} item sm={12} md={6} lg={colWidth}>
                    {cardContent(el, prevData?.post_sentences_summary[index])}
                  </Grid>
                )}
            </>
          ))}
        </InfoCardWrapper>
      )}
    </>
  )
}

const InfoCardWrapper = styled(Grid)``

const InfoCard = styled(HyperGrid)<{ color?: string }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;

  background: #fcfafa;
  border: 1px solid #eff0f6;
  box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.04),
    inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  border-radius: 12px;

  color: ${(props) => (props.color ? props.color : '#28364F')};
`
const ColorBar = styled.div<{ color?: string }>`
  width: 8px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
`
const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  gap: 5px;
`
const Data = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  overflow: visible;
  width: 100%;
  gap: 10px;
`
const Icon = styled.div`
  display: flex;
  font-size: 14px;
`
const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  overflow: visible;
  width: 100%;
  color: #8a8c8f;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
`
