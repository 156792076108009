import React from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import useResizeObserver from 'use-resize-observer'
import { LogoSpinner } from '../../../../components/LogoSpinner'
import { isInitialFlow } from '../../../home/utils'
import { getCompassDetails } from '../../model'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { FilterOptionDate } from '../filters/types'
import { getSavedViews } from '../saved-vews/model'
import ReviewComponent from './ReviewComponent/ReviewComponent'
import { Content } from './sections/content'
import { Header } from './sections/header'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
} from '../filters/helpers'
import moment from 'moment/moment'
import { emptyFilters } from '../../utils'

export const Home: React.FC = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const { ref, height } = useResizeObserver<HTMLDivElement>()

  const { data: feature_flags } = useFeatureFlags()

  const { isLoading, data } = useQuery(
    ['home', projectId],
    () => getCompassDetails(projectId, emptyFilters),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!projectId,
    }
  )

  const { data: savedViews } = useQuery(
    ['saved-views', projectId],
    () => getSavedViews(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  )

  let greeting = ''
  var today = new Date()
  var curHr = today.getHours()
  if (curHr < 12) {
    greeting = 'Good Morning'
  } else if (curHr < 18) {
    greeting = 'Good Afternoon'
  } else {
    greeting = 'Good Evening'
  }

  const min = (
    defaultFilterList?.find(
      (el) => el.field === 'create_time'
    ) as FilterOptionDate
  )?.min

  const max = (
    defaultFilterList?.find(
      (el) => el.field === 'create_time'
    ) as FilterOptionDate
  )?.max

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const startDate = new Date(
    formatDateToUTCEndOfDay(moment.unix(min)).unix() * 1000
  ).toLocaleString('en-US', options)
  const endDate = new Date(
    formatDateToUTCMidnight(moment.unix(max)).unix() * 1000
  ).toLocaleString('en-US', options)

  if (
    isInitialFlow(details?.state) ||
    isLoading ||
    !data ||
    !savedViews ||
    !min ||
    !max ||
    !greeting ||
    !defaultFilterList.length
  ) {
    return <LogoSpinner>{`Loading Homepage...`}</LogoSpinner>
  }

  return (
    <Container>
      <Header
        data={data}
        resizeRef={ref}
        greeting={greeting}
        startDate={startDate}
        endDate={endDate}
      />
      <Divider />
      {feature_flags?.verbatim_homepage && (
        <>
          <ReviewComponent />
          <Divider />
        </>
      )}
      <Content
        data={data}
        savedViews={savedViews}
        headerHeight={height}
        filterList={defaultFilterList}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 40px);
  background: var(--color-background-grey);
  background: var(--card-background-white);
  overflow: auto;
`

const Divider = styled.div`
  width: 100%;
  border: 1px solid rgba(159, 183, 195, 0.15);
`
