import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Row, Col, Modal, Button, Tooltip, Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { ProjectStatuses } from 'features/home/types'
import { Loader } from 'shared/components'
import { tooltip } from 'utils/tooltip-data'
import { SettingsForm } from './settings-form'
import { AuthContext } from 'features/auth'
import {
  startProcessing,
  refreshPreferences,
  rerunStopWords,
  runProjectUpdate,
  createReports,
  startNLP,
  switchUserRole,
  getProjectMetadata,
  getStopwordsSuggestion,
} from './model'
import { embedder_types } from './types'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useProject } from '../../hooks/useProject'
import { fetchProjectsByGroup } from '../../../home/model'
import { active } from 'd3'
import { useGroupProjectList } from '../../hooks/useGroupProjectList'
import { ProjectEditForm } from '../../../project-form'
import { ProductEdit } from '../products/containers/product-edit'

const { Option } = Select

export const Settings = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const [selectedProject, setSelectedProject] = useState('')
  const activeProject = selectedProject ? selectedProject : projectId
  const { data: details, refetch: refetchProject } = useProject(activeProject)
  const isAdmin = details?.role === 'admin'

  const { data: groupProjects } = useGroupProjectList(projectId)

  const authContext = useContext(AuthContext)
  const isYogi = authContext.user?.attributes.email.includes('@meetyogi.com')
  const [loading, setLoading] = useState<string | null>(null)
  const [embedder, setEmbedder] = useState<string | undefined>(
    'universal-sentence-encoder-1-hub'
  )
  const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false)
  const [isProjectEditModalVisible, setProjectEditModalVisible] =
    useState(false)
  const projectDetails = details

  const {
    data: metadata,
    isLoading,
    refetch: refetchMetadata,
  } = useQuery(
    ['metadata', activeProject],
    () => getProjectMetadata(activeProject),
    {
      enabled: isAdmin,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  useEffect(() => {
    mixpanel.track('settings page')
  }, [])

  if (isLoading || !projectDetails) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading ${
          details?.clientSettings?.project?.pages?.settings?.alias ?? 'Settings'
        } Page`}</LoaderTitle>
      </>
    )
  }

  const isCreated = projectDetails.state === ProjectStatuses.CREATED
  const isPreprocessing = projectDetails.state === ProjectStatuses.PREPROCESSING
  const isPreprocessingUpdate =
    projectDetails.state === ProjectStatuses.PREPROCESSING_UPDATE
  const isPreprocessingComplete =
    projectDetails.state === ProjectStatuses.PREPROCESSING_COMPLETE
  const isPreprocessingUpdateComplete =
    projectDetails.state === ProjectStatuses.PREPROCESSING_UPDATE_COMPLETE
  const isNetworkProcessingUpdate =
    projectDetails.state === ProjectStatuses.NETWORK_PROCESSING_UPDATE
  const isNetworkUpdateComplete =
    projectDetails.state === ProjectStatuses.NETWORK_UPDATE_COMPLETE
  const isClusteringUpdateComplete =
    projectDetails.state === ProjectStatuses.CLUSTERING_UPDATE_COMPLETE
  const isComplete = projectDetails.state === ProjectStatuses.COMPLETE

  const commonButtonCond = !isCreated && !isPreprocessing
  const viewableButtons = {
    reports: commonButtonCond,
    processing: commonButtonCond && isPreprocessingComplete,
    preferences:
      commonButtonCond &&
      (isPreprocessingUpdate ||
        isPreprocessingUpdateComplete ||
        isNetworkProcessingUpdate ||
        isNetworkUpdateComplete ||
        isClusteringUpdateComplete ||
        isComplete),
    role: isYogi,
    words: commonButtonCond && isComplete,
    update: commonButtonCond && isComplete,
    stopwords: commonButtonCond && isComplete,
    embedder: ['yogi', 'turtle'].includes(
      String(authContext.userDetails?.org).toLowerCase()
    ),
  }

  const buttonHandlerWrapper =
    (
      fn: (s: string, data?: any) => Promise<unknown>,
      type: string,
      data?: any
    ) =>
    async () => {
      setLoading(type)
      try {
        await fn(projectDetails.proj_uuid, data)
        await refetchProject() // TODO?
        toast.success('Success')
      } catch (e) {
        toast.error((e as any).message)
      }
      setLoading(null)
    }

  const onCreateReports = buttonHandlerWrapper(createReports, 'reports')
  const onStartProcessing = buttonHandlerWrapper(startProcessing, 'processing')
  const onRunUpdate = buttonHandlerWrapper(runProjectUpdate, 'update')
  const onRefreshPreferences = buttonHandlerWrapper(
    refreshPreferences,
    'preferences'
  )
  const onSwitchUserRole = buttonHandlerWrapper(switchUserRole, 'role')
  const onStartNLP = buttonHandlerWrapper(startNLP, 'nlp')
  const onRerunStopWords = buttonHandlerWrapper(rerunStopWords, 'words', {
    stop_words: metadata?.stop_words.value || [],
    embedder: embedder,
  })
  const onGetStopwords = buttonHandlerWrapper(
    getStopwordsSuggestion,
    'stopwords'
  )

  const { confirm } = Modal
  let connectors = Object.entries(details.connectors || {})
  let connector_comps = []
  if (connectors.length !== 0) {
    for (let connector in details.connectors) {
      connector_comps.push(
        <div key={connector}>
          {connector}: {details.connectors[connector]}
        </div>
      )
    }
  }

  const isGroupParent =
    !!groupProjects?.data?.projects.length &&
    groupProjects?.data?.projects.length > 1 &&
    !selectedProject

  return (
    <Row
      justify="center"
      style={{ maxHeight: 'calc(100vh - 40px)', overflowY: 'scroll' }}
    >
      <Col md={12} xs={24}>
        {/*TODO this probably gets simplified*/}
        {groupProjects?.data?.projects?.length &&
          groupProjects?.data?.projects?.length > 1 && (
            <SelectBody>
              <Select
                size="large"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e)}
                style={{ marginTop: '10px' }}
              >
                <Select.Option value={''}>{'All Datasets'}</Select.Option>
                {groupProjects.data.projects.map((project) => (
                  <Select.Option key={project.uuid} value={project.uuid}>
                    {project.title}
                  </Select.Option>
                ))}
              </Select>
            </SelectBody>
          )}
        <Wrapper>
          <SettingsForm
            metadata={metadata}
            project={projectDetails}
            // refetchProject={refetchProject}
            refetchMetadata={refetchMetadata}
            showEmbedder={viewableButtons['embedder']}
            isGroupParent={isGroupParent}
          />
        </Wrapper>
        {connectors.length !== 0 && !isGroupParent && (
          <ConnectorsWrapper>
            <b>Connectors:</b>
            {connector_comps}
          </ConnectorsWrapper>
        )}
        <ButtonsWrapper>
          {isAdmin && commonButtonCond && (
            <>
              {viewableButtons['reports'] && !isGroupParent && (
                <Button
                  size="large"
                  type="primary"
                  loading={loading === 'reports'}
                  onClick={onCreateReports}
                >
                  Create Integrity Reports{' '}
                  <Tooltip
                    title={tooltip['integrity-reports']}
                    placement="right"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
              )}
              {viewableButtons['processing'] && !isGroupParent && (
                <Button
                  size="large"
                  type="primary"
                  loading={loading === 'nlp'}
                  onClick={onStartNLP}
                >
                  Start NLP and Postprocessing{' '}
                  <Tooltip title={tooltip['run-nlp']} placement="right">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
              )}
              {/*{viewableButtons['processing'] && !isGroupParent && (*/}
              {/*  <Button*/}
              {/*    size="large"*/}
              {/*    type="primary"*/}
              {/*    loading={loading === 'processing'}*/}
              {/*    onClick={onStartProcessing}*/}
              {/*  >*/}
              {/*    Start Postprocessing{' '}*/}
              {/*    <Tooltip*/}
              {/*      title={tooltip['start-postprocessing']}*/}
              {/*      placement="right"*/}
              {/*    >*/}
              {/*      <InfoCircleOutlined />*/}
              {/*    </Tooltip>*/}
              {/*  </Button>*/}
              {/*)}*/}
              {viewableButtons['preferences'] && (
                <Button
                  size="large"
                  type="primary"
                  loading={loading === 'preferences'}
                  onClick={onRefreshPreferences}
                >
                  Refresh Preferences{' '}
                  <Tooltip
                    title={tooltip['refresh-preferences']}
                    placement="right"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
              )}
              {viewableButtons['stopwords'] && !isGroupParent && (
                <Button
                  size="large"
                  type="primary"
                  loading={loading === 'stopwords'}
                  onClick={onGetStopwords}
                >
                  Get Suggested Stopwords{' '}
                  <Tooltip
                    title={tooltip['suggested-stopwords']}
                    placement="right"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
              )}
              {viewableButtons['update'] && !isGroupParent && (
                <Button
                  size="large"
                  type="primary"
                  loading={loading === 'update'}
                  onClick={(e) => {
                    e.preventDefault()
                    confirm({
                      content:
                        'Are you sure you want to update the project?\n' +
                        'You can still use the project while new data is added.',
                      onOk() {
                        onRunUpdate()
                      },
                      onCancel() {},
                    })
                  }}
                  danger
                >
                  Update Project{' '}
                  <Tooltip title={tooltip['update-project']} placement="right">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
              )}
              {viewableButtons['words'] && !isGroupParent && (
                <RerunStopWordsWrapper>
                  {viewableButtons['embedder'] && (
                    <>
                      <b>Embedder:</b>
                      <Select
                        defaultValue="Select a theme"
                        style={{ width: '100%' }}
                        value={embedder}
                        onChange={(v) => {
                          setEmbedder(v as string)
                        }}
                      >
                        {embedder_types.map((embedder: string) => (
                          <Option key={embedder} value={embedder}>
                            {embedder}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}
                  <Button
                    size="large"
                    type="primary"
                    loading={loading === 'words'}
                    onClick={(e) => {
                      e.preventDefault()
                      confirm({
                        content:
                          'Are you sure you want to rerun the project with new stop words?' +
                          ' This will delete your existing themes.',
                        onOk() {
                          onRerunStopWords()
                        },
                        onCancel() {},
                      })
                    }}
                    danger
                  >
                    Rerun Stop Words{' '}
                    <Tooltip
                      title={tooltip['rerun-stop-words']}
                      placement="right"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Button>
                </RerunStopWordsWrapper>
              )}
            </>
          )}
          {viewableButtons['role'] && (
            <Button
              size="large"
              type="primary"
              loading={loading === 'role'}
              onClick={onSwitchUserRole}
            >
              Switch to {isAdmin ? 'Viewer' : 'Admin'} role
              <Tooltip title="Switch user role." placement="right">
                <InfoCircleOutlined />
              </Tooltip>
            </Button>
          )}
          {activeProject && (
            <>
              {/*<Button*/}
              {/*  size="large"*/}
              {/*  type="primary"*/}
              {/*  disabled={details.state !== ProjectStatuses.COMPLETE}*/}
              {/*  onClick={() => setProjectEditModalVisible(true)}*/}
              {/*>*/}
              {/*  Add Sources*/}
              {/*</Button>*/}
              <>
                <Button
                  size="large"
                  type="primary"
                  disabled={details.state !== ProjectStatuses.COMPLETE}
                  onClick={() => setProjectEditModalVisible(true)}
                >
                  Add Products/Sources
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => setEditModalVisible(true)}
                >
                  Edit Products
                </Button>
              </>
            </>
          )}
        </ButtonsWrapper>
      </Col>

      <Modal
        title="Edit Products"
        visible={isEditModalVisible}
        width={800}
        footer={null}
        onCancel={() => setEditModalVisible(false)}
      >
        <ProductEdit />
      </Modal>
      {isProjectEditModalVisible && (
        <ProjectEditForm
          handleClose={() => setProjectEditModalVisible(false)}
          projectId={activeProject}
        />
      )}
    </Row>
  )
}

const Wrapper = styled.div`
  padding: var(--default-padding) 0;
`
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: var(--default-padding) 0;
  flex-direction: column;

  & > button {
    width: 300px;
    margin-bottom: var(--default-padding-half);
  }

  .ant-btn > .ant-btn-loading-icon {
    padding-right: 4px;
  }

  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0px;
  }
`

const RerunStopWordsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`

const ConnectorsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: var(--default-padding) 0;
  flex-direction: column;
`

const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`
const SelectBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
`
