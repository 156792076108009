import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { LogoSpinner } from '../../components/LogoSpinner'
import { LogoSpinnerSmall } from '../../components/LogoSpinnerSmall'

type Props = { paddingTop?: boolean; small?: boolean; style?: CSSProperties }

export const Loader: React.FC<Props> = ({
  paddingTop = true,
  small = false,
  style,
}) => <LogoSpinner style={style} />
// export const Loader: React.FC<Props> = ({ paddingTop = true }) => (
//   <LoaderWrapper paddingTop={paddingTop}>
//     <Spheres src={LoadingShperesIcon} />
//     <Face src={FaceIcon} paddingTop={paddingTop} />
//   </LoaderWrapper>
// )

const LoaderWrapper = styled.div`
  padding-top: ${({ paddingTop }: { paddingTop: boolean }) =>
    paddingTop ? '50px' : '0'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`
