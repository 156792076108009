import React from 'react'
import styled from 'styled-components'
import { DeleteFilled, Loading3QuartersOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import mixpanel from 'features/trackers/mixpanel'
import { projectURL } from 'utils/links'
import { numberFormatter } from 'utils/numberFormat'
import { ProjectStatuses } from '../types'
import { Project } from '../../project/types'

type Props = {
  project: Project
  handleRemoveProject: (id: string) => void
}

type WrapperProps = {
  custom: {
    isError: boolean
    isLoading: boolean
  }
}

const statuses: any = {
  [ProjectStatuses.CREATED]: 'Created',
  [ProjectStatuses.PREPROCESSING]: 'Reading Data',
  [ProjectStatuses.PREPROCESSING_UPDATE]: 'Reading New Data',
  [ProjectStatuses.PREPROCESSING_COMPLETE]: 'Awaiting Your Review',
  [ProjectStatuses.PREPROCESSING_UPDATE_COMPLETE]: 'Synthesizing New Data',
  [ProjectStatuses.NETWORK_PROCESSING]: 'Synthesizing Data',
  [ProjectStatuses.NETWORK_PROCESSING_UPDATE]: 'Synthesizing New Data',
  [ProjectStatuses.NETWORK_COMPLETE]: 'Creating Themes',
  [ProjectStatuses.NETWORK_UPDATE_COMPLETE]: 'Updating Themes',
  [ProjectStatuses.CLUSTERING_UPDATE_COMPLETE]: 'Updating Themes',
  [ProjectStatuses.COMPLETE]: '',
  [ProjectStatuses.ERROR]: 'Error',
}

export const ProjectCard = React.memo(function ProjectCard({
  project,
  handleRemoveProject,
}: Props) {
  const history = useHistory()
  const isError = project.state === 'error'
  const isLoading = !isError && project.state !== 'complete'

  return (
    <ProjectWrapper
      href={projectURL(project.uuid)}
      onClick={(e) => {
        e.preventDefault()
        history.push(projectURL(project.uuid))
        mixpanel.track('project', {
          proj_uuid: project.uuid,
          title: project.title,
        })
        mixpanel.track('dashboard', { proj_uuid: project.uuid })
      }}
      custom={{ isError, isLoading }}
    >
      <ProjectTitle>{project.title}</ProjectTitle>
      <ProjectDescription>{project.description}</ProjectDescription>
      <ProjectCustomInfo>
        <b>{numberFormatter(project.n_brands)}</b> Brands{' • '}
        <b>{numberFormatter(project.n_products)}</b> Products{' • '}
        <b>{numberFormatter(project.n_sources)}</b> Sources
      </ProjectCustomInfo>
      <ProjectCustomInfo>
        <b>{numberFormatter(project.n_posts)}</b> Reviews{' • '}
        <b>{numberFormatter(project.n_ratings)}</b> Only Ratings
      </ProjectCustomInfo>
      <ProjectCustomInfo>
        <b>{format(new Date(project.last_update * 1000), 'MMMM do, yyyy')}</b>{' '}
        Last Updated
      </ProjectCustomInfo>
      <ProjectIcons>
        <ProjectStatus>{statuses[project.state]}</ProjectStatus>
        {isLoading && (
          <ProjectIcon
            onClick={(e: React.SyntheticEvent) => e.preventDefault()}
            custom={{ loading: true }}
          >
            <Loading3QuartersOutlined />
          </ProjectIcon>
        )}
        {project.role === 'admin' && (
          <ProjectIcon
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              e.stopPropagation()
              handleRemoveProject(project.uuid)
            }}
          >
            <DeleteFilled />
          </ProjectIcon>
        )}
      </ProjectIcons>
    </ProjectWrapper>
  )
})

const ProjectWrapper = styled.a`
  display: block;
  width: 100%;
  padding: var(--default-padding);
  background: #fff;
  box-shadow: var(--default-box-shadow);
  color: black;
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  border-radius: var(--border-radius);
  cursor: pointer;
  ${(props: WrapperProps) => props.custom.isError && 'color: var(--color-red)'};

  &:hover {
    text-decoration: none;
    transform: scale(1.05);
    color: black;
  }
`
const ProjectTitle = styled.div`
  font-size: var(--font-size-l);
  font-weight: 500;
  margin-bottom: 8px;
`
const ProjectDescription = styled.div`
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ProjectCustomInfo = styled.div`
  font-size: var(--font-size-md);
`
const ProjectIcons = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  font-size: var(--font-size-l);
`
const ProjectStatus = styled.div`
  font-weight: 500;
  font-size: 14px;
`
const ProjectIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--default-padding-half);
  color: var(--color-grey);

  &:hover {
    ${(props: { custom?: { loading: boolean } }) =>
      !props.custom?.loading && 'color: var(--color-red);'}
  }

  ${(props: { custom?: { loading: boolean } }) =>
    props.custom?.loading && 'animation: spin 1s linear infinite;'}
`
