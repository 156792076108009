import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Input, Form, Button } from 'antd'
import { toast } from 'react-toastify'
import mixpanel from 'features/trackers/mixpanel'
import { THEME_GROUP_DOMAIN } from '../utils'
import { ColorPicker } from './color-picker'
import { Domain, Cluster as ClusterType, Group } from '../types'
import { setClusterValue, setGroupValue } from '../model'
import { useClusterContext } from '..'
import { CLUSTER_TYPE, GROUP_TYPE } from '../type-names'
import { useNetworkContext } from '../../network/network-provider'
import { RefreshViewPayload } from '../../network/types'

type Props = {
  data: ClusterType | Group
  field: Domain
  editMode: boolean
  nodesLength: number
  projectId: string
  isAdmin: boolean
  fetchClusters: () => void
  setLoading: (b: boolean) => void
  isColorPickerOpened: boolean
  setIsColorPickerOpened: (b: boolean) => void
  needToUpdateSettings: boolean
  setNeedToUpdateSettings: (v: boolean) => void
  selectedDataset: string | null
}
type UpdateOpts = { color?: string; theme?: string }

export const Cluster: React.FC<Props> = ({
  data,
  field,
  isAdmin,
  editMode,
  projectId,
  setLoading,
  isColorPickerOpened,
  setIsColorPickerOpened,
  needToUpdateSettings,
  setNeedToUpdateSettings,
  selectedDataset,
}) => {
  const [isNameChanging, setNameChanging] = useState(false)
  const { groups, clusters, isThemeDomain, setClusters, setGroups } =
    useClusterContext()

  const cluster = data as ClusterType
  const group = data as Group
  const entityIds = {
    [CLUSTER_TYPE]: isThemeDomain ? cluster.cluster_uuid : cluster.value,
    [GROUP_TYPE]: group.group,
  }

  const withLoading = async (f: Function) => {
    setLoading(true)
    try {
      await f()
    } finally {
      setLoading(false)
    }
  }

  const { refreshClusterViewOnDetailChange } = useNetworkContext()

  const updateCluster = useCallback(
    async ({ color, theme }: UpdateOpts) => {
      if (!cluster.value) return
      if (theme === cluster.value) return

      await withLoading(async () => {
        try {
          const clusterId = entityIds[CLUSTER_TYPE]
          await setClusterValue({
            field: field.name,
            value: clusterId,
            proj_uuid: projectId,
            theme: theme,
            color: color,
            selected_dataset: selectedDataset,
          })
          mixpanel.track('theme color', { value: theme })

          const viewUpdatePayload = {
            entityType: CLUSTER_TYPE,
            entityId: clusterId,
            theme,
            color,
          } as RefreshViewPayload
          // updateClusterListAndFilterView(viewUpdatePayload)
          refreshClusterViewOnDetailChange(viewUpdatePayload)
        } catch (e) {
          toast.error((e as any).message)
        }
      })
    },
    [data]
  )

  const updateGroup = useCallback(
    async ({ theme, color }: UpdateOpts) => {
      if (group.id === undefined) return
      if (theme === group.group) return

      await withLoading(async () => {
        try {
          await setGroupValue({
            field: THEME_GROUP_DOMAIN,
            value: group.group,
            proj_uuid: projectId,
            theme_group: theme,
            color: color,
            selected_dataset: selectedDataset,
          })
          // await Promise.all([fetchClusters(), filterList.refetch()])

          const viewUpdatePayload = {
            entityType: GROUP_TYPE,
            entityId: entityIds[GROUP_TYPE],
            theme,
            color,
          } as RefreshViewPayload
          // updateClusterListAndFilterView(viewUpdatePayload)
          refreshClusterViewOnDetailChange(viewUpdatePayload)
        } catch (e) {
          toast.error((e as any).message)
        }
      })
    },
    [data]
  )

  if (group.group) {
    return (
      <Wrapper>
        {!editMode && (
          <ColorPicker
            color={group.color}
            onChange={(color) => updateGroup({ color })}
            isColorPickerOpened={isColorPickerOpened}
            setIsColorPickerOpened={setIsColorPickerOpened}
          />
        )}
        <Topic onClick={(e) => e.stopPropagation()}>
          {editMode && isNameChanging ? (
            <StyledForm
              initialValues={{ theme: group.group }}
              onFinish={(e: any) => {
                if (!e.theme.length) return
                updateGroup({ theme: e.theme })
                setNameChanging(false)
                setNeedToUpdateSettings(true)
              }}
            >
              <Form.Item name="theme">
                <Input />
              </Form.Item>
              <Button htmlType="submit">OK</Button>
            </StyledForm>
          ) : (
            <div
              onClick={() => {
                if (isThemeDomain && isAdmin && editMode) {
                  setNameChanging(true)
                } else {
                  const found = groups.map((item) =>
                    item.id === group.id
                      ? { ...item, hidden: !group.hidden }
                      : item
                  )
                  group.hidden = !group.hidden
                  setGroups(found)
                }
              }}
              style={{
                fontSize: '12px',
                textDecoration: group.hidden ? 'line-through' : '',
              }}
            >
              {group.group} ({group.percent}%)
            </div>
          )}
        </Topic>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {!editMode && (
        <ColorPicker
          color={cluster.color}
          onChange={(color) => updateCluster({ color })}
          isColorPickerOpened={isColorPickerOpened}
          setIsColorPickerOpened={setIsColorPickerOpened}
        />
      )}

      <Topic onClick={(e) => e.stopPropagation()}>
        {editMode && isNameChanging ? (
          <StyledForm
            initialValues={{ theme: cluster.value }}
            onFinish={(e: any) => {
              if (!e.theme.length) return
              updateCluster({ theme: e.theme })
              setNameChanging(false)
              setNeedToUpdateSettings(true)
            }}
          >
            <Form.Item name="theme">
              <Input />
            </Form.Item>
            <Button htmlType="submit">OK</Button>
          </StyledForm>
        ) : (
          <div
            onClick={() => {
              if (isThemeDomain && isAdmin && editMode) setNameChanging(true)
              else {
                const found = clusters.map((item) =>
                  (cluster.cluster_uuid &&
                    item.cluster_uuid === cluster.cluster_uuid) ||
                  (cluster.value && item.value === cluster.value)
                    ? { ...item, hidden: !cluster.hidden }
                    : item
                )
                cluster.hidden = !cluster.hidden
                setClusters(found)
              }
            }}
            style={{
              fontSize: '12px',
              textDecoration: cluster.hidden ? 'line-through' : '',
            }}
          >
            {cluster.value}{' '}
            {(cluster.visible === undefined || cluster.visible) && (
              <>({cluster.percent}%)</>
            )}
          </div>
        )}
      </Topic>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
`
const Topic = styled.div`
  font-weight: 500;
  margin-left: 8px;

  span {
    cursor: pointer;
    font-size: 12px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
`
const StyledForm = styled(Form)`
  display: flex;
`
