import styled from 'styled-components'
import {
  darkBlue,
  lightBlueFontColor,
} from '../../../../../../assets/styles/variables'

export const Container = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 20px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const Title = styled.h2`
  color: ${darkBlue};
  flex-grow: 2;
`

export const Bookmark = styled.h2`
  color: ${lightBlueFontColor};
  margin-top: 5px;
`
