import { Node } from 'features/project/features/network/types'
import { Cluster, Group } from '../cluster/types'
import { GraphType } from './types'

const INITIAL_NODE_SIZE = 3

const getNodeAttribute = (node: Node) => ({
  size: INITIAL_NODE_SIZE,
  color: node.lastColor || '#fff',
  x: node.x,
  y: node.y,
  z: 0,
  selected: false,
})

const renderNode = (graph: GraphType, node: Node) => {
  if (!graph.hasNode(node.uuid)) {
    graph.addNode(node.uuid)
  }
  graph.mergeNodeAttributes(node.uuid, getNodeAttribute(node))
}

export const setGraphColors = (
  graph: GraphType,
  clusters: Cluster[],
  groups: Group[]
) => {
  graph.clear()

  clusters.forEach(({ nodes, color, visible, hidden, group_id }) => {
    const groupFound = groups?.find(({ id }) => id === group_id)
    nodes.forEach((node) => {
      node.lastColor = groupFound ? groupFound.color || '#000' : color

      const isVisible =
        (visible === undefined || visible) && !hidden && !groupFound?.hidden

      if (isVisible) {
        renderNode(graph, node)
      }
    })
  })
}

export function nodeUnhighlighted(graph: any, node: any) {
  if (graph.hasNode(node.node)) {
    graph.mergeNodeAttributes(node.node, {
      size: INITIAL_NODE_SIZE,
      z: 0,
    })
  }
}

export function nodeHighlighted(graph: GraphType, node: string) {
  const oldSize = graph.getNodeAttribute(node, 'size')
  graph.mergeNodeAttributes(node, { size: 13, z: 1, oldSize: oldSize })
}

export function highlightSelected(graph: GraphType, nodeIdsList: string[]) {
  const highlightedColor = '#FF0000'

  graph.forEachNode((node: string) => {
    const isSelected = graph.getNodeAttribute(node, 'selected')

    if (isSelected) {
      graph.setNodeAttribute(
        node,
        'color',
        graph.getNodeAttribute(node, 'oldColor') || '#fff'
      )
      graph.setNodeAttribute(node, 'selected', false)
    }
  })

  nodeIdsList.forEach((uuid) => {
    const currentColor = graph.getNodeAttribute(uuid, 'color')
    graph.setNodeAttribute(uuid, 'color', highlightedColor)
    graph.setNodeAttribute(uuid, 'oldColor', currentColor)
    graph.setNodeAttribute(uuid, 'selected', true)
  })
}
