import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { OutlierType } from '../../model'
import { Bookmark, BookmarkBorder } from '@mui/icons-material'
import { Filters, ProjectState } from '../../../../types'
import useAutoInsights from '../../hooks/useAutoInsights'
import { toast } from 'react-toastify'
import { updateDashboard, saveDashboard } from '../../../dashboard/model'
import { useProjectStore } from '../../../../projectStore/projectStore'
import useCustomDashboards from '../../../../hooks/useCustomDashboards'

interface Props {
  outlier: OutlierType
  filterValues: Filters
}

const FavoriteInsightButton: React.FC<Props> = ({ outlier, filterValues }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const { data: customDashboardsResponse, refetch: refetchCustomDashboards } =
    useCustomDashboards()
  const { convertOutlierToCustomDashboard } = useAutoInsights(
    outlier,
    filterValues
  )

  const [matchingDashboard, setMatchingDashboard] = useState<any>(null)
  const [isFavorite, setIsFavorite] = useState(false)

  const identifyMatchingDashboard = useCallback(() => {
    return customDashboardsResponse?.find(
      ({ dashboard }) =>
        dashboard.dashboard_settings.extra_settings?.outlier?.product ===
          outlier.product &&
        dashboard.dashboard_settings.extra_settings?.outlier?.theme ===
          outlier.theme
    )
  }, [customDashboardsResponse, outlier])

  useEffect(() => {
    const match = identifyMatchingDashboard()
    setMatchingDashboard(match)
    setIsFavorite(
      !!match?.dashboard.dashboard_settings?.extra_settings?.is_active
    )
  }, [customDashboardsResponse, identifyMatchingDashboard])

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFavoriteState = !isFavorite

    // Optimistic update
    setIsFavorite(newFavoriteState)

    try {
      if (matchingDashboard) {
        const updatedDashboard = {
          ...matchingDashboard,
          dashboard: {
            ...matchingDashboard.dashboard,
            dashboard_settings: {
              ...matchingDashboard.dashboard.dashboard_settings,
              extra_settings: {
                ...matchingDashboard.dashboard.dashboard_settings
                  .extra_settings,
                is_active: newFavoriteState,
              },
            },
          },
        }

        await updateDashboard(projectId, {
          dashboard_id: updatedDashboard.dashboard.id,
          name: updatedDashboard.dashboard.name,
          dashboard_settings: updatedDashboard.dashboard.dashboard_settings,
        })
      } else if (newFavoriteState) {
        const newDashboard = convertOutlierToCustomDashboard(true)
        await saveDashboard(projectId, newDashboard)
      }

      refetchCustomDashboards()

      // toast.success(
      //   newFavoriteState
      //     ? 'Insight added to favorites'
      //     : 'Insight removed from favorites'
      // )
    } catch (error) {
      // Revert optimistic update on error
      setIsFavorite(!newFavoriteState)
      console.error('Error updating favorite status:', error)
      toast.error('Failed to update favorite status')
    }
  }

  return (
    <Container>
      {isFavorite ? (
        <Bookmark onClick={handleClick} />
      ) : (
        <BookmarkBorder onClick={handleClick} />
      )}
    </Container>
  )
}

export default FavoriteInsightButton

const Container = styled.div`
  cursor: pointer;
`
