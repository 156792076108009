import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useClusterDomain } from './hooks/use-cluster-domain'
import { useClusters } from './hooks/use-clusters'
import { Cluster, ClusterContextType, Group } from './types'
import { CLUSTER_TYPE, GROUP_TYPE } from './type-names'
import { useNetworkContext } from '../network/network-provider'
import { RefreshViewPayload } from '../network/types'
import { arraysAreEqual } from './utils'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'

type Props = { comparativeIndex: any; children: React.ReactNode }

const ClusterContext = createContext<ClusterContextType | undefined>(undefined)
export const ClusterProvider: React.FC<Props> = ({
  children,
  comparativeIndex,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )
  const { addClusterContext, removeClusterContext } = useNetworkContext()

  const {
    domains,
    selectedDomain,
    setSelectedDomain,
    isThemeDomain,
    ...domainData
  } = useClusterDomain(projectId, comparativeIndex)
  const {
    groups: initialGroups,
    clusters: initialClusters,
    totalNodes,
    ...clusterData
  } = useClusters(projectId, comparativeIndex, selectedDomain)
  const [clusters, setClusters] = useState<Cluster[]>([])
  const [groups, setGroups] = useState<Group[]>([])

  // TODO: maybe combine ?
  const updateGroupAttribute = (groupName: string, keyValue: object) => {
    const groupList = groups.map((group) =>
      group.group !== groupName ? group : { ...group, ...keyValue }
    )
    mergeAndSetGroups(groupList)
  }

  const mergeAndSetGroups = (groupList: Group[]) => {
    const groupNamesSet = new Set(groupList.map((group) => group.group))
    const toMerge = [] as Group[]
    const groupNameToIds: Map<number, number> = new Map()
    groupNamesSet.forEach((groupName) => {
      const filtered = groupList.filter((group) => group.group === groupName)
      if (filtered.length > 1) {
        const [first, ...rest] = filtered
        toMerge.push(
          rest.reduce(
            (acc, group) => ({
              ...acc,
              percent: acc.percent + group.percent,
            }),
            first
          )
        )
        rest.forEach((group) => {
          groupNameToIds.set(group.id, first.id)
        })
      }
    })

    const groupNamesToMerge = new Set(toMerge.map((group) => group.group))
    const updatedGroups = Array.from(groupNamesSet).map((groupName) => {
      const listToSearch = groupNamesToMerge.has(groupName)
        ? toMerge
        : groupList
      return listToSearch.find((group) => group.group === groupName) as Group
    })
    setGroups(updatedGroups)

    const updatedClusters = clusters.map((cluster) => {
      return groupNameToIds.has(cluster.group_id as number)
        ? {
            ...cluster,
            group_id: groupNameToIds.get(cluster.group_id as number),
          }
        : cluster
    })
    setClusters(updatedClusters)
  }

  const updateClusterAttribute = (clusterUuid: string, keyValue: object) => {
    setClusters(
      clusters.map((cluster) =>
        (cluster.cluster_uuid && cluster.cluster_uuid === clusterUuid) ||
        (cluster.value && cluster.value === clusterUuid)
          ? { ...cluster, ...keyValue }
          : cluster
      )
    )
  }

  const changeEntityNameInFilter = (
    entityType: string,
    entityId: string,
    newName: string
  ) => {
    // TODO
    // filterList.changeThemeOrGroupNameInFilter(entityType, entityId, newName)
  }

  const updateClusterListAndFilterViewOnDetailChange = ({
    entityType,
    entityId,
    theme,
    color,
  }: RefreshViewPayload) => {
    const updateEntityAttribute = {
      [GROUP_TYPE]: updateGroupAttribute,
      [CLUSTER_TYPE]: updateClusterAttribute,
    }

    if (theme != null) {
      const themePayloads = {
        [GROUP_TYPE]: { group: theme },
        [CLUSTER_TYPE]: { value: theme },
      }

      updateEntityAttribute[entityType](entityId, themePayloads[entityType])
      changeEntityNameInFilter(entityType, entityId, theme)
    }
    if (color != null) {
      updateEntityAttribute[entityType](entityId, { color })
    }

    /*
            TODO: check why do we call /color after changing values
            filterList.updateSelectFilterGroupValue(
              'theme',
              group.group,
              theme as string
            )
            */
  }

  useEffect(() => {
    addClusterContext(
      { updateClusterListAndFilterViewOnDetailChange },
      comparativeIndex
    )
  }, [updateClusterListAndFilterViewOnDetailChange])

  useEffect(
    () => () => {
      removeClusterContext(comparativeIndex)
    },
    []
  )

  useEffect(() => {
    if (!arraysAreEqual(initialClusters, clusters))
      setClusters(
        initialClusters.map((item) => {
          return { ...item, hidden: false }
        })
      )
  }, [initialClusters])

  useEffect(() => {
    if (!arraysAreEqual(initialGroups, groups))
      setGroups(
        initialGroups.map((item) => {
          return { ...item, hidden: false }
        })
      )
  }, [initialGroups])

  const isLoading = domainData.isLoading || clusterData.isLoading
  const providerValue = useMemo(
    () => ({
      ...clusterData,
      isLoading,
      isThemeDomain,
      selectedDomain,
      setSelectedDomain,
      groups,
      setGroups,
      domains,
      clusters,
      setClusters,
      totalNodes,
      updateGroupAttribute,
      updateClusterAttribute,
      updateClusterListAndFilterViewOnDetailChange,
    }),
    [
      groups,
      clusters,
      totalNodes,
      domains,
      selectedDomain,
      isLoading,
      clusterData.isFetching,
      clusterData.error,
    ]
  )

  return (
    <ClusterContext.Provider value={providerValue}>
      {children}
    </ClusterContext.Provider>
  )
}

export const useClusterContext = () => {
  const context = useContext(ClusterContext)
  if (!context) {
    throw new Error('useClusterContext must be used within a ClusterProvider')
  }
  return context
}
