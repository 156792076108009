import React, { createContext, useState } from 'react'
import { ClusterViewUpdateMethodsType, NetworkContextType, RefreshViewPayload } from './types'

type Props = { children: React.ReactNode }

const NetworkContext = createContext<NetworkContextType | undefined>(undefined)

export const NetworkProvider: React.FC<Props> = ({ children }) => {
  const [clusterContexts, setClusterContexts] = useState<ClusterViewUpdateMethodsType[]>([])

  const addClusterContext = (clusterContext: ClusterViewUpdateMethodsType, idx?: number) => {
    setClusterContexts((prevState) => {
      prevState[idx || 0] = clusterContext
      return prevState
    })
  }

  const removeClusterContext = (idx?: number) => {
    if (idx) {
      setClusterContexts((prevState) => {
        prevState.splice(idx, 1)
        return prevState
      })
    }
  }

  const refreshClusterViewOnDetailChange = (payload: RefreshViewPayload) => {
    clusterContexts.forEach((c) => {
      c.updateClusterListAndFilterViewOnDetailChange(payload)
    })
  }

  const networkApi = {
    addClusterContext,
    removeClusterContext,
    refreshClusterViewOnDetailChange,
  }

  return <NetworkContext.Provider value={networkApi}>
            {children}
          </NetworkContext.Provider>
}

export const useNetworkContext = () => {
  const context = React.useContext(NetworkContext)
  if (!context) {
    throw new Error('useNetworkContext must be used within a NetworkProvider.')
  }
  return context
}
