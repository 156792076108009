import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import Outlier from './Outlier'
import Finding from './Finding'
import TimeSeriesOutlier from './TimeSeriesOutlier'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import AIcon from '../../../../../assets/images/icons/AIcon_180x180.png'
import TimeSeriesFinding from './TimeSeriesFinding'
import { OutlierType } from '../model'
import moment from 'moment'
import { useAnalysisStream } from '../hooks/useAnalysisStream'

type Props = {
  outlier: OutlierType // Consider creating a more specific type for outlier
  filterValues: Filters
  model: string
  outlierType: 'lens' | 'timeseries'
  threshold: number
}

const OutlierContainer: React.FC<Props> = ({
  outlier,
  filterValues,
  model,
  outlierType,
  threshold,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const [isAnalysisOpen, setIsAnalysisOpen] = useState(false)

  function getTimePeriodString(outlier: OutlierType) {
    if (!outlier.date) return ''
    const startTime = moment(outlier.date)
    const endTime = startTime.clone().add(1, 'month')
    return `${startTime.toDate().toLocaleDateString()} to  ${endTime
      .toDate()
      .toLocaleDateString()}`
  }

  const {
    analysis,
    completeAnalysis,
    isStreaming,
    error,
    refetch: refetchAnalysis,
  } = useAnalysisStream(projectId, outlier, model)

  const llmAnalysis = completeAnalysis ?? analysis ?? {}

  return (
    <Container>
      <Header>
        <h2 style={{ marginBottom: 0 }}>
          {outlierType === 'lens' ? (
            <>
              <InfoValue>{outlier.product}</InfoValue> is{' '}
              {outlier.weighted_zscore <= 0
                ? 'underperforming'
                : 'overperforming'}{' '}
              other Products in the theme of{' '}
              <InfoValue>{outlier.theme}</InfoValue>
            </>
          ) : (
            <>
              Selection is{' '}
              {outlier.weighted_zscore <= 0
                ? 'underperforming'
                : 'overperforming'}{' '}
              in the time period of{' '}
              <InfoValue>{getTimePeriodString(outlier)}</InfoValue>
            </>
          )}
        </h2>
      </Header>

      {isAnalysisOpen ? (
        <>
          {outlierType === 'lens' ? (
            <Outlier
              outlier={outlier}
              filterValues={filterValues}
              model={model}
              threshold={threshold}
              isAnalysisOpen={isAnalysisOpen}
              setIsAnalysisOpen={setIsAnalysisOpen}
            />
          ) : (
            <TimeSeriesOutlier
              outlier={outlier}
              filterValues={filterValues}
              model={model}
            />
          )}
        </>
      ) : (
        <>
          {outlierType === 'lens' ? (
            <Finding
              outlier={outlier}
              filterValues={filterValues}
              model={model}
              analysis={llmAnalysis}
              refetchAnalysis={refetchAnalysis}
              threshold={threshold}
              isAnalysisOpen={isAnalysisOpen}
              setIsAnalysisOpen={setIsAnalysisOpen}
            />
          ) : (
            <TimeSeriesFinding
              outlier={outlier}
              filterValues={filterValues}
              model={model}
            />
          )}
        </>
      )}
      {/*<AnalysisSection>*/}
      {/*  <div>*/}
      {/*    [Yogi has identified{' '}*/}
      {/*    {outlier.weighted_zscore <= 0 ? 'issues with' : 'strengths in'} traits*/}
      {/*    x, y, and z in the reviews for [product]]*/}
      {/*  </div>*/}
      {/*  */}
      {/*</AnalysisSection>*/}
    </Container>
  )
}

export default OutlierContainer

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px 30px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: var(--default-box-shadow);
`

const AnalysisSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  gap: 10px;
  border-top: 4px solid #f2f2f2;
  margin-top: 20px;
  padding-top: 20px;
`
const InfoValue = styled.span`
  font-weight: 500;
  text-decoration: underline;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #f2f2f2;
  padding-bottom: 10px;
  gap: 40px;
`
