import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AuthContext } from 'features/auth'
import { ComparativeNavigation } from 'features/comparative-view/comparative-navigation'
import { useLocation, useHistory } from 'react-router-dom'
import { Badge, Button, Dropdown, Menu, Modal, Popover, Radio } from 'antd'
import { profileURL, requestDasboardURL, trialURL } from 'utils/links'
import { DownOutlined, InboxOutlined } from '@ant-design/icons'
import { SaveViewForm } from 'features/project/features/saved-vews/components/view-save-form'
import { useSavedViews } from 'features/project/features/saved-vews/utils'
import { SavedViewPayload } from 'features/project/features/saved-vews/types'
import { ViewLoadForm } from 'features/project/features/saved-vews/components/view-load-form'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../project/projectStore/projectStore'
import { ProjectState } from '../../project/types'
import { useQuery } from 'react-query'
import { getSavedViews } from '../../project/features/saved-vews/model'
import { YogiButton } from '../../../components/UI/YogiButton'
import { Notifications } from '@mui/icons-material'

export const HeaderContainer = () => {
  const authContext = useContext(AuthContext)
  const location = useLocation()
  const history = useHistory()
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const countries = useProjectStore((state: ProjectState) => state.countries)
  const route = useProjectStore((state: ProjectState) => state.route)
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber
  )
  const filters = useProjectStore((state: ProjectState) => state.filters)
  const clearFilter = useProjectStore(
    (state: ProjectState) => state.clearFilter
  )
  const setFilterValues = useProjectStore(
    (state: ProjectState) => state.setFilterValues
  )
  const selectedCountry = useProjectStore(
    (state: ProjectState) => state.selectedCountry
  )
  const setSelectedCountry = useProjectStore(
    (state: ProjectState) => state.setSelectedCountry
  )
  const notifications = useProjectStore(
    (state: ProjectState) => state.localNotifications
  )
  const notificationsIsOpen = useProjectStore(
    (state: ProjectState) => state.notificationsIsOpen
  )
  const setNotificationsIsOpen = useProjectStore(
    (state: ProjectState) => state.setNotificationsIsOpen
  )

  const settings = details?.clientSettings.project.pages
  const { saveView } = useSavedViews()
  const { refetch } = useQuery(
    [projectId],
    () => {
      return getSavedViews(projectId)
    },
    { refetchOnWindowFocus: false, enabled: !!projectId }
  )

  const [savedViewRadioValue, setSavedViewRadioValue] = useState('0')
  const [isSavingView, setIsSavingView] = useState(false)
  const [isCountryModalOpened, setIsCountryModalOpened] = useState(false)

  const is_trial = authContext.userDetails?.free_trial

  useEffect(() => {
    setIsCountryModalOpened(false)
  }, [projectId])

  useEffect(() => {
    if (location.pathname.split('/').length < 4) {
      if (!selectedCountry.length) setIsCountryModalOpened(true)
    }
  }, [location.pathname])

  const resCountries = ['All Countries']
    .concat(countries?.countries || [])
    .filter((country) => country !== 'Null')

  const countriesContent = (
    <>
      {resCountries.map((country) => (
        <Button
          type={'text'}
          key={country}
          onClick={() => {
            setSelectedCountry(country === 'All Countries' ? [] : [country])
            if (country === 'All Countries') {
              for (let i = 0; i < comparativePanelsNumber; i++) {
                clearFilter(i, 'country')
              }
            } else {
              for (let i = 0; i < comparativePanelsNumber; i++) {
                setFilterValues(i, [
                  ...filters[i].values.filter((el) => el.field !== 'country'),
                  { field: 'country', values: [country] },
                ])
              }
            }
            setIsCountryModalOpened(false)
          }}
          className={selectedCountry?.includes(country) ? 'selected' : ''}
        >
          {country === 'Null' ? 'Missing Country' : country}
        </Button>
      ))}
    </>
  )

  const username = authContext.user?.attributes.given_name

  useEffect(() => {
    if ((window as any).$crisp) {
      ;(window as any).$crisp.push(['do', 'chat:hide'])
    }
  }, [])
  ;(window as any).$crisp.push([
    'on',
    'chat:closed',
    () => {
      ;(window as any).$crisp.push(['do', 'chat:hide'])
    },
  ])

  return (
    <Wrapper>
      {is_trial && (
        <Button
          color="blue"
          style={{
            marginRight: '16px',
          }}
          onClick={() => history.push(`${trialURL}${requestDasboardURL}`)}
        >
          Request Dashboard
        </Button>
      )}
      {(route?.isCharts ||
        route?.isNetwork ||
        route?.isFeedback ||
        route?.isKeywords) && (
        <>
          {location.pathname !== '/' && location.pathname !== '/profile' && (
            <>
              {countries?.enabled && countries?.countries.length !== 0 && (
                <>
                  <Popover
                    trigger={'click'}
                    content={<CountriesList>{countriesContent}</CountriesList>}
                    placement="bottomRight"
                    overlayClassName="country-popover"
                  >
                    <Button type={'text'}>
                      {selectedCountry.length
                        ? selectedCountry
                        : 'All Countries'}
                      <DownOutlined />
                    </Button>
                  </Popover>
                  <Modal
                    title="Choose a country"
                    visible={isCountryModalOpened}
                    footer={null}
                    centered
                    onCancel={() => {
                      setIsCountryModalOpened(false)
                    }}
                  >
                    <Description>
                      Please select the country of the reviews & ratings you'd
                      like to view.
                    </Description>
                    <CountriesGrid
                      params={{
                        elementsCount: resCountries.length,
                      }}
                    >
                      {countriesContent}
                    </CountriesGrid>
                  </Modal>
                </>
              )}
              {!settings?.saved_views?.hidden && (
                <Popover
                  visible={isSavingView}
                  onVisibleChange={(v: boolean) => setIsSavingView(v)}
                  title={<div style={{ padding: '5px' }}>Saved Views</div>}
                  trigger="click"
                  style={{ padding: '0 !important' }}
                  placement={'bottomLeft'}
                  content={
                    <SavedViewsPopoverContent>
                      <Radio.Group
                        value={savedViewRadioValue}
                        onChange={(event) => {
                          setSavedViewRadioValue(event.target.value)
                        }}
                        style={{ marginBottom: '8px' }}
                      >
                        <Radio value={'0'}>Save View</Radio>
                        <Radio value={'1'}>Load View</Radio>
                      </Radio.Group>
                      {savedViewRadioValue === '0' ? (
                        <SaveViewForm
                          saveFunction={async (view: SavedViewPayload) => {
                            await saveView(view)
                            setIsSavingView(false)
                            refetch()
                          }}
                        />
                      ) : (
                        <ViewLoadForm toggleIsOpen={setIsSavingView} />
                      )}
                    </SavedViewsPopoverContent>
                  }
                >
                  <ViewContainer>
                    {/*<EyeOutlined />*/}
                    <YogiButton
                      type={'ghost'}
                      style={{ padding: '0px 8px', height: '29px' }}
                    >
                      Saved Views <InboxOutlined />
                    </YogiButton>
                    {/*<Text>Saved Views</Text>*/}
                  </ViewContainer>
                </Popover>
              )}
              <ComparativeNavigation />
            </>
          )}
          {(location.pathname === '/' || location.pathname === '/profile') &&
            authContext.isAuthenticated && (
              <>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="0"
                        onClick={() => {
                          mixpanel.track('profile', {
                            proj_uuid: projectId,
                          })
                          history.push(profileURL)
                        }}
                      >
                        Profile
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="2" onClick={authContext.signOut}>
                        Logout
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <NameWrapper>
                    <NameBody
                      onClick={(e: React.SyntheticEvent) => e.preventDefault()}
                      style={{ marginRight: 8 }}
                    >
                      {username}
                    </NameBody>
                    <UserWrapper>
                      <AvatarWrapper>{username[0]}</AvatarWrapper>
                    </UserWrapper>
                  </NameWrapper>
                </Dropdown>
              </>
            )}
        </>
      )}
      <div>
        <Badge
          dot={!!notifications?.find?.((el) => !el.acknowledged_at)}
          style={{ zIndex: 1, marginLeft: -10, marginBottom: -2 }}
          offset={[-10, 10]}
        >
          <AlertButton
            type={'ghost'}
            onClick={() => {
              mixpanel.track('open notifications')
              setNotificationsIsOpen(!notificationsIsOpen)
            }}
            icon={
              <Notifications
                style={{ width: 16, height: 16, margin: '0 auto' }}
              />
            }
            // style={{ background: 'white' }}
          />
        </Badge>
      </div>
      {location.pathname === '/' && authContext.isAuthenticated && (
        <div style={{ cursor: 'pointer' }} onClick={authContext.signOut}>
          Sign out
        </div>
      )}
    </Wrapper>
  )
}

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;

  & > * {
    padding-top: 4px;
    cursor: pointer;
  }
`

type GridProps = {
  params: { elementsCount: number }
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: var(--color-text-black);
`
const NameWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
const NameBody = styled.div`
  position: relative;
  cursor: pointer;
`
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
`
const AvatarWrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 2px solid var(--color-text-black);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`
const CountriesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 480px;
  overflow-y: auto;
  width: 100%;

  button {
    width: fit-content;
  }

  .selected {
    background: #eaf6fe;
    font-weight: bold;
  }
`
const CountriesGrid = styled.div`
  display: grid;
  justify-items: center;
  grid-auto-flow: dense;
  max-height: 320px;
  overflow-y: auto;
  grid-auto-flow: column;

  ${({ params: { elementsCount } }: GridProps) =>
    elementsCount < 11
      ? 'grid-template-columns: 1fr'
      : elementsCount < 21
      ? 'grid-template-columns: 1fr 1fr'
      : 'grid-template-columns: 1fr 1fr 1fr'};

  ${({ params: { elementsCount } }: GridProps) =>
    elementsCount < 11
      ? `grid-template-rows: repeat(${elementsCount},1fr)`
      : elementsCount < 21
      ? `grid-template-rows: repeat(${Math.ceil(elementsCount / 2)},1fr)`
      : `grid-template-rows: repeat(${Math.ceil(elementsCount / 3)},1fr)`};

  button {
    width: fit-content;
    padding: 0 20px;
  }

  .selected {
    background: #eaf6fe;
    font-weight: bold;
  }
`
const Description = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`
const SavedViewsPopoverContent = styled.div`
  width: 500px;
`
const AlertButton = styled(Button)`
  border: none !important;
  //padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px !important;
    width: 20px !important;
  }
  ::after {
    all: unset;
  }
`
