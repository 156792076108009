import {
  GEMINI_CHART,
  OUTLIER_ANALYSIS_URL,
  OUTLIERS_URL,
} from 'utils/apiLinks'
import { axiosInstance } from 'utils/axios'
import { Filters } from '../../types'

type OutlierResponse = {
  outliers: any[]
  posts: any[]
}
export const getOutliers = async (
  proj_uuid: string,
  filterValues: Filters
): Promise<OutlierResponse> => {
  const { data } = await axiosInstance.post(
    OUTLIERS_URL,
    {
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
    },
    { params: { proj_uuid } }
  )
  return data
}

export const getOutlierAnalysis = async (
  proj_uuid: string,
  reviews: any[],
  user_prompt: string,
  model: string
): Promise<string> => {
  const { data } = await axiosInstance.post(
    OUTLIER_ANALYSIS_URL,
    {
      prompt: user_prompt,
      reviews,
      model,
    },
    { params: { proj_uuid } }
  )
  return data
}

export const getChartId = async (proj_uuid: string, question: string) => {
  const { data } = await axiosInstance.post(
    GEMINI_CHART,
    {
      question,
    },
    { params: { proj_uuid, question } }
  )
  return data
}
