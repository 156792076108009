import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Button, Collapse } from 'antd'
import Markdown from 'marked-react'
import { Loader } from 'shared/components'

import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import {
  getOutlierAnalysis,
  OutlierType,
  OutlierAnalysisResponse,
} from '../model'
import { pageBackgroundGrey } from '../../../../../assets/styles/variables'
import { constructThemeOutlierPrompt } from '../utils'
import KeyPhrase from './KeyPhrase'
import { SyncOutlined } from '@ant-design/icons'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import AIcon from '../../../../../assets/images/icons/AIcon_180x180.png'

type OutlierAnalysisProps = {
  outlier: OutlierType
  model: string
  threshold: number
  isAnalysisOpen: boolean
  setIsAnalysisOpen: (value: boolean) => void
}

interface ParsedAnalysis {
  subheader: string
  key_phrases: string[]
}

const { Panel } = Collapse

const OutlierAnalysisSummary: React.FC<OutlierAnalysisProps> = ({
  outlier,
  model,
  threshold,
  isAnalysisOpen,
  setIsAnalysisOpen,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const reviews = outlier.data.map((el) => el.body).filter(Boolean)

  const prompt = constructThemeOutlierPrompt(outlier)

  const {
    data: analysis,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['analysis', projectId, reviews, prompt, model],
    () => getOutlierAnalysis(projectId, reviews, prompt, model),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    }
  )

  const [tries, setTries] = useState(0)

  const parsedAnalysis: OutlierAnalysisResponse = useMemo(() => {
    if (!analysis) return false
    try {
      return JSON.parse(analysis)
    } catch (error) {
      if (tries < 3) {
        refetch()
      }
      console.log(analysis)
      return null
    }
  }, [analysis])

  return (
    <Container>
      <Content>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: 10 }}>
            <h2 style={{ marginBottom: 0 }}>Outlier Overview</h2>

            <Button onClick={() => refetch()}>
              <SyncOutlined />
            </Button>
          </div>

          <ButtonWrapper>
            <YogiButton
              type={'primary'}
              onClick={() => setIsAnalysisOpen(!isAnalysisOpen)}
            >
              <img
                style={{
                  height: '100%',
                  marginRight: 5,
                }}
                src={AIcon}
              />
              {isAnalysisOpen
                ? 'View Outlier Overview'
                : 'View Full Yogi AI Analysis'}
            </YogiButton>
          </ButtonWrapper>
        </div>
        {isLoading || isFetching ? (
          <LoaderWrapper>
            <Loader style={{ height: 100 }} />
          </LoaderWrapper>
        ) : (
          <>
            {parsedAnalysis && (
              <AnalysisContainer>
                <BlurbHook>
                  <SubheaderWithKeyPhrases
                    parsedAnalysis={parsedAnalysis}
                    outlier={outlier}
                    threshold={threshold}
                  />
                </BlurbHook>
                {/*<div>*/}
                {/*  {parsedAnalysis.subheader*/}
                {/*    .split(' ')*/}
                {/*    .map((word: any, index: number) => (*/}
                {/*      <>*/}
                {/*        {parsedAnalysis.key_phrases.includes(word) ? (*/}
                {/*          <KeyPhrase phrase={word} />*/}
                {/*        ) : (*/}
                {/*          <span>{word}</span>*/}
                {/*        )}{' '}*/}
                {/*      </>*/}
                {/*    ))}*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  {parsedAnalysis.key_phrases.map(*/}
                {/*    (phrase: any, index: number) => (*/}
                {/*      <div>{phrase}</div>*/}
                {/*    )*/}
                {/*  )}*/}
                {/*</div>*/}
                {/*{JSON.parse(analysis).body.map(*/}
                {/*  (finding: any, index: number) => (*/}
                {/*    <AnalysisSection>*/}
                {/*      <Markdown>{'### ' + finding.title}</Markdown>*/}
                {/*      <Markdown>{finding.explanation}</Markdown>*/}
                {/*      // evidence will accept multiple quotes*/}
                {/*      <Markdown>{finding.evidence}</Markdown>*/}
                {/*      <Markdown>{finding.implication}</Markdown>*/}
                {/*    </AnalysisSection>*/}
                {/*  )*/}
                {/*)}*/}
                {/*<Collapse>*/}
                {/*  {parsedAnalysis.body.map((finding: any, index: number) => (*/}
                {/*    <Panel header={finding.title} key={index}>*/}
                {/*      <Markdown>{finding.explanation}</Markdown>*/}
                {/*      // evidence will accept multiple quotes*/}
                {/*      <Markdown>{finding.evidence}</Markdown>*/}
                {/*      <Markdown>{finding.implication}</Markdown>*/}
                {/*    </Panel>*/}
                {/*  ))}*/}
                {/*</Collapse>*/}

                {/*<ul>*/}
                {/*  {parsedAnalysis.body.map((finding: any, index: number) => (*/}
                {/*    <li>*/}
                {/*      <Markdown>{'### ' + finding.title}</Markdown>*/}
                {/*    </li>*/}
                {/*    // <Panel header={finding.title} key={index}>*/}
                {/*    //   <Markdown>{finding.explanation}</Markdown>*/}
                {/*    //   // evidence will accept multiple quotes*/}
                {/*    //   <Markdown>{finding.evidence}</Markdown>*/}
                {/*    //   <Markdown>{finding.implication}</Markdown>*/}
                {/*    // </Panel>*/}
                {/*  ))}*/}
                {/*</ul>*/}
              </AnalysisContainer>
            )}
            {/*<Collapse style={{ maxHeight: 400, overflow: 'auto' }}>*/}
            {/*  <Panel header={`Reviews (${reviews.length})`} key="reviews">*/}
            {/*    {reviews.map((review, index) => (*/}
            {/*      <ReviewItem key={index}>{review}</ReviewItem>*/}
            {/*    ))}*/}
            {/*  </Panel>*/}
            {/*</Collapse>*/}
          </>
        )}
      </Content>
    </Container>
  )
}
const SubheaderWithKeyPhrases: React.FC<{
  parsedAnalysis: ParsedAnalysis
  outlier: OutlierType
  threshold: number
}> = ({ parsedAnalysis, outlier, threshold }) => {
  const { subheader, key_phrases } = parsedAnalysis

  const identifyKeyPhrases = (
    text: string,
    phrases: string[]
  ): React.ReactNode[] => {
    const result: React.ReactNode[] = []
    let remainingText = text

    while (remainingText.length > 0) {
      let matchFound = false

      for (const phrase of phrases) {
        const lowercasePhrase = phrase.toLowerCase()
        const lowercaseText = remainingText.toLowerCase()
        const index = lowercaseText.indexOf(lowercasePhrase)

        if (index === 0) {
          // Match found at the start
          const actualPhrase = remainingText.slice(0, phrase.length)
          result.push(
            <KeyPhrase
              key={result.length}
              phrase={actualPhrase}
              products={outlier.product ? [outlier.product] : []}
              // themes={outlier.theme ? [outlier.theme] : []}
              threshold={threshold}
            />
          )
          remainingText = remainingText.slice(phrase.length)
          matchFound = true
          break
        } else if (index > 0) {
          // Non-phrase text before the match
          const beforeMatch = remainingText.slice(0, index)
          result.push(beforeMatch)

          // The matched phrase
          const actualPhrase = remainingText.slice(index, index + phrase.length)
          result.push(
            <KeyPhrase
              key={result.length}
              phrase={actualPhrase}
              products={outlier.product ? [outlier.product] : []}
              // themes={outlier.theme ? [outlier.theme] : []}
            />
          )

          remainingText = remainingText.slice(index + phrase.length)
          matchFound = true
          break
        }
      }

      if (!matchFound) {
        // No match found, add the first character and continue
        result.push(remainingText[0])
        remainingText = remainingText.slice(1)
      }
    }

    return result
  }
  return <div>{identifyKeyPhrases(subheader, key_phrases)}</div>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Content = styled.div`
  margin-bottom: 10px;
  width: 100%;
`

const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ReviewItem = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: ${pageBackgroundGrey};
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  margin: 10px 0;
`

const AnalysisContainer = styled.div`
  max-height: 800px;
  overflow: auto;
`

const AnalysisSection = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  box-shadow: var(--default-box-shadow);
  background: ${pageBackgroundGrey};
`

const BlurbHook = styled.div`
  font-size: 16px;
`

export default OutlierAnalysisSummary
