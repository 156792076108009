import React from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { SummaryResponse } from '../../charts/types'
import { FileSearchOutlined } from '@ant-design/icons'
import { InnerTitle } from '../../../../../components/InnerTitle'
import { Tooltip } from 'antd'
import mixpanel from '../../../../trackers/mixpanel'
import {
  initialState,
  useProjectStore,
} from '../../../projectStore/projectStore'
import { getBookmarkUrlPost } from '../../../../url-params/model'
import {
  getCurrentRouteName,
  pickTextColorBasedOnBgColorAdvanced,
  pickTextColorBasedOnBgColorSimple,
} from '../../../utils'
import { ProjectState } from '../../../types'
import { FilterOption } from '../../filters/types'
import useThemes from '../../../hooks/useThemes'
import useSummary from '../../../hooks/useSummary'

const formatter = new Intl.NumberFormat()
type Props = {
  data: SummaryResponse | undefined
  summaryRequest: any
  selectedTheme: string
  filterListData: FilterOption[]
}
export const Keywords: React.FC<Props> = ({
  data,
  summaryRequest,
  selectedTheme,
  filterListData,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)

  const { getFilterFromChartClick } = useSummary()

  const openKeywords = async (e: React.MouseEvent, keyword: string) => {
    e.preventDefault()
    document.body.style.cursor = 'wait'
    const themeSummaryBody = getFilterFromChartClick(
      summaryRequest,
      selectedTheme
    )

    const filters = {
      values: themeSummaryBody.values,
      searchQuery: [],
      searchCondition: [],
    }
    const pageControls = JSON.parse(
      JSON.stringify(initialState.keywordsControls[0])
    )
    pageControls.searchTerm = keyword
    const body = {
      filters: [filters],
      pageControls: [pageControls],
    }

    const filterHash = await getBookmarkUrlPost(projectId, {
      page: getCurrentRouteName(window.location.pathname),
      filterString: JSON.stringify(body),
    })

    const url = `${window.location.origin}/project/${projectId}/keywords?filters=${filterHash}`
    window.open(url, '')
    document.body.style.cursor = ''
  }

  return (
    <>
      <InnerTitle>
        <FileSearchOutlined style={{ marginRight: '5px' }} /> Most Frequent
        Keywords
      </InnerTitle>
      <KeywordsContainer>
        {data?.keywords_summary?.map((item) => (
          <Tooltip
            placement="topLeft"
            title={
              <>
                <div>
                  Avg Sentiment: {formatter.format(item.avg_sentiment)}{' '}
                </div>
                <div>Frequency: {formatter.format(item.volume)} </div>
                <div># of Reviews: {formatter.format(item.n_reviews)}</div>
                <div># of Sentences: {formatter.format(item.n_sentences)}</div>
              </>
            }
          >
            <Keyword
              color={item.color}
              style={{
                backgroundColor: item.color,
                // color: pickTextColorBasedOnBgColorAdvanced(item.color),
              }}
              onClick={(e) => {
                mixpanel.track('summary', {
                  action: 'keyword',
                  value: item.keyword,
                  data: summaryRequest,
                  proj_uuid: projectId,
                  ...route,
                })
                openKeywords(e, item.keyword)
              }}
            >
              {item.keyword}
            </Keyword>
          </Tooltip>
        ))}
      </KeywordsContainer>
    </>
  )
}
const KeywordsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  //justify-content: center;
`
const Keyword = styled.div<{ color: string }>`
  text-align: center;
  color: white;
  //color: var(--main-text);
  background: var(--secondary-navy);
  padding: 6px 8px;
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) => props.color};
  font-weight: 500;

  &:hover {
    text-decoration: none;
    //opacity: 0.8;
    background-image: linear-gradient(rgb(0 0 0/10%) 0%, rgb(0 0 0/10%) 100%);
  }
`
