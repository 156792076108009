import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { secondaryNavy } from '../../assets/styles/variables'

interface LoadingTextProps {
  strings: string[]
  interval?: number // in milliseconds
  speed?: number
}

const LoadingText: React.FC<LoadingTextProps> = ({
  strings,
  interval = 3000,
  speed = 1,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [fade, setFade] = useState(true)
  const [progress, setProgress] = useState(0)
  const [stopProgressAt, setStopProgressAt] = useState(Math.random() * 10 + 70) // Random number between 70-80

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFade(false)

      const timeoutId2 = setTimeout(() => {
        if (currentIndex < strings.length - 1) {
          setCurrentIndex((currentIndex + 1) % strings.length)
          setFade(true)
        } else {
          setFade(true)
        }
      }, 500) // Adjust as needed

      return () => clearTimeout(timeoutId2)
    }, interval - 500)

    return () => clearTimeout(timeoutId)
  }, [currentIndex, interval, strings.length])

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(progressInterval)
          return 100
        }
        // Slow down after 60%
        const increment =
          prevProgress < 20
            ? 5 * speed
            : prevProgress < 60
            ? 0.2 * speed
            : prevProgress < stopProgressAt
            ? 0.05 * speed
            : progress < 95
            ? Math.random() * 100 < 5 * speed
              ? 1 * speed
              : 0
            : 0
        return prevProgress + increment
      })
    }, 100)

    return () => {
      clearInterval(progressInterval)
      // Complete the progress when component unmounts
      setProgress(100)
    }
  }, [stopProgressAt])

  return (
    <Container>
      <RotateText className={fade ? 'fade-in' : 'fade-out'}>
        {strings[currentIndex]}
        <BouncingDots>
          <Dot className="dot">.</Dot>
          <Dot className="dot">.</Dot>
          <Dot className="dot">.</Dot>
        </BouncingDots>
      </RotateText>
      <ProgressBar>
        <Progress width={progress} />
      </ProgressBar>
    </Container>
  )
}

export default LoadingText

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RotateText = styled.div`
  transition: opacity 0.5s ease-in-out;
  opacity: 1;

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }
`

const ProgressBar = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 20px;
`

const Progress = styled.div<{ width: number }>`
  height: 4px;
  background-color: ${secondaryNavy};
  border-radius: 4px;
  transition: width 0.5s ease-in-out;
  width: ${({ width }) => width}%;
`

const BouncingDots = styled.span``

const Dot = styled.span`
  display: inline-block;
  animation-name: bouncing;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  &:nth-child(2) {
    animation-delay: 165ms;
  }
  &:nth-child(3) {
    animation-delay: 330ms;
  }
  @keyframes bouncing {
    0% {
      transform: none;
    }

    33% {
      transform: translateY(-0.3em);
    }

    66% {
      transform: none;
    }
  }
`
