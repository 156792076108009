import React, { useState } from 'react'
import { OutlierType } from '../../../../model'
import {
  Analysis,
  Body,
  Container,
  Content,
  Section,
  Sections,
} from './InsightAnalysis.styles'
import { Filters } from '../../../../../../types'
import { Button } from 'antd'
import Markdown from 'marked-react'
import SemanticKeywords from '../../../../../../../../components/SemanticKeywords/SemanticKeywords'
import SemanticReviewCarousel from '../../../../../../../../components/SemanticReviewCarousel/SemanticReviewCarousel'
import { LogoSpinnerSmall } from '../../../../../../../../components/LogoSpinnerSmall'
import InsightReviews from './InsightReviews/InsightReviews'
import { useFeatureFlags } from '../../../../../../hooks/useFeatureFlags'
import YogiInfo from '../../../../../../../../components/UI/YogiInfo/YogiInfo'
import { lightBlueFontColor } from '../../../../../../../../assets/styles/variables'
import useAutoInsights from '../../../../hooks/useAutoInsights'
import useOutlierAnalysis from '../../../../hooks/useOutlierAnalysis'

interface Props {
  outlier: OutlierType
  filterValues: Filters
}

const InsightAnalysis: React.FC<Props> = ({ outlier, filterValues }) => {
  // local state
  const [analysisBodyIndex, setAnalysisBodyIndex] = useState(-1)

  // hooks
  const { data: feature_flags } = useFeatureFlags()

  // consts needed for api calls
  const model = 'gemini-1.5-flash'
  // const reviews = outlier.data.map((el) => el.body).filter(Boolean)
  // const prompt = constructThemeOutlierPrompt(outlier)

  // api
  const { analysis: parsedAnalysis } = outlier
  const { outlierFilters } = useAutoInsights(outlier, filterValues)

  return (
    <Container>
      <Body>
        <Sections>
          <Section
            isSelected={analysisBodyIndex === -1}
            onClick={() => setAnalysisBodyIndex(-1)}
          >
            Outlier Summary
          </Section>
          {parsedAnalysis?.body.map((section, index) => (
            <Section
              key={index}
              isSelected={analysisBodyIndex === index}
              onClick={() => setAnalysisBodyIndex(index)}
            >
              <div>{section.title}</div>
            </Section>
          ))}
          <Section
            isSelected={analysisBodyIndex === -2}
            onClick={() => setAnalysisBodyIndex(-2)}
          >
            Reviews ({outlier.data.length})
          </Section>
        </Sections>
        <Content style={{ paddingTop: analysisBodyIndex === -1 ? 0 : 10 }}>
          <>
            {parsedAnalysis ? (
              <>
                <Analysis
                  key={-1}
                  style={{
                    display: analysisBodyIndex === -1 ? 'block' : 'none',
                  }}
                >
                  <h2>{parsedAnalysis.header}</h2>
                  {/*<Markdown>{'### ' + parsedAnalysis.header}</Markdown>*/}
                  <SemanticKeywords
                    body={parsedAnalysis?.subheader}
                    keywords={parsedAnalysis?.key_phrases}
                    products={outlier.product ? [outlier.product] : []}
                    themes={outlier.theme ? [outlier.theme] : []}
                    filterValues={outlierFilters}
                  />
                </Analysis>
                {parsedAnalysis.body.map((section, index) => (
                  <Analysis
                    key={index}
                    style={{
                      display: analysisBodyIndex === index ? 'block' : 'none',
                    }}
                  >
                    <Markdown>{section.explanation}</Markdown>
                    {feature_flags?.semantic_search_proj ? (
                      <SemanticReviewCarousel
                        phrase={section.title}
                        products={outlier.product ? [outlier.product] : []}
                        themes={outlier.theme ? [outlier.theme] : []}
                        style={{ marginBottom: '20px' }}
                      />
                    ) : (
                      <YogiInfo
                        color={lightBlueFontColor}
                        body={<div>"{section.evidence}"</div>}
                        style={{ marginBottom: 15 }}
                      ></YogiInfo>
                    )}
                    <Markdown>{section.implication}</Markdown>
                  </Analysis>
                ))}
              </>
            ) : (
              <>
                {analysisBodyIndex !== -2 && (
                  <LogoSpinnerSmall>
                    <p>Loading Analysis...</p>
                  </LogoSpinnerSmall>
                )}
              </>
            )}
          </>
          <Analysis
            key={-2}
            style={{
              display: analysisBodyIndex === -2 ? 'block' : 'none',
            }}
          >
            <InsightReviews outlier={outlier} filterValues={outlierFilters} />
          </Analysis>
        </Content>
      </Body>
      {/*<Button onClick={() => refetch()}>Refetch</Button>*/}
    </Container>
  )
}

export default InsightAnalysis
